/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../_redux/data-validation/dataValidationActions"
import TermsAndConditions from "./terms-and-conditions/TermsAndConditions"
import ValidationForm from "./validation-form/ValidationForm"
import TrainingVideos from "./training-videos/TrainingVideos"
import "./DataValidation.css"
import DataValidationFinalized from "./data-validation-finalized/DataValidationFinalized"
import TermsAndConditionsRejected from "./terms-and-conditions/TermsAndConditionsRejected"

const DataValidation = () => {
	const dispatch = useDispatch()

	// ━━━━━━━━━ useSelector  ━━━━━━━━━ \\
	const { personData } = useSelector(
		state => ({
			personData: state.dataValidation?.personData
		}),
		shallowEqual
	)

	// ━━━━━━━━━ States  ━━━━━━━━━ \\
	const [selectedPage, setSelectedPage] = useState()
	const [validationCode, setValidationCode] = useState()

	useEffect(() => {
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		const person = urlParams.get("person")
		setValidationCode(person)
		dispatch(actions.getPersonData(person))
	}, [])

	useEffect(() => {
		if (!personData) return

		if (personData.failedFinalQuiz === false || personData.failedFinalQuiz === true) {
			setSelectedPage("dataValidationFinalized")
			return
		}

		if (
			personData.termsAndConditionsAccepted === null ||
			personData.termsAndConditionsAccepted === undefined
		) {
			setSelectedPage("termsAndConditions")
			return
		}
		if (personData.termsAndConditionsAccepted === true) {
			if (personData.dataValidated) {
				setSelectedPage("trainingVideos")
				return
			}
			setSelectedPage("dataValidationForm")
			return
		}
		if (personData.termsAndConditionsAccepted === false) {
			setSelectedPage("rejectedTermsAndConditionsWarning")
			return
		}
	}, [personData])

	return (
		<div
			data-testid="data-validation_DataValidation"
			style={{
				width: "100%",
				height: "fit-content",
				minHeight: "100%",
				display: "flex",
				overflow: "auto",
				flexDirection: "column",
				background:
					"url('../media/bg/bg-auth.png'), linear-gradient(270deg, #0A1B3D 0%, #0A1B3D 38.02%, #1065EF 100%)",
				backgroundOrigin: "content-box",
				backgroundSize: "auto 100%",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "right"
			}}
		>
			<div
				style={{
					margin: "15px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				{selectedPage === "termsAndConditions" ? (
					<TermsAndConditions validationCode={validationCode} />
				) : selectedPage === "dataValidationForm" ? (
					<ValidationForm validationCode={validationCode} setSelectedPage={setSelectedPage} />
				) : selectedPage === "trainingVideos" ? (
					<TrainingVideos validationCode={validationCode} setSelectedPage={setSelectedPage} />
				) : selectedPage === "dataValidationFinalized" ? (
					<DataValidationFinalized />
				) : (
					selectedPage === "rejectedTermsAndConditionsWarning" && (
						<TermsAndConditionsRejected />
					)
				)}
			</div>
		</div>
	)
}

export default DataValidation
