import { firestoreOld } from "../../../../firebase"
import { gapi } from "gapi-script"
import moment from "moment"
import { Timestamp } from "firebase/firestore"

const customersRef = firestoreOld.collection("Customers")

export function getResources(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return customersRef
		.doc(queryParams.customer)
		.collection(`Resources`)
		.where("networkId", "==", queryParams.location)
		.get()
}

export function getBookingDay(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return customersRef
		.doc(queryParams.customerId)
		.collection("Bookings")
		.doc(queryParams.date.format("YYYY-MM-DD"))
		.get()
}

export async function bookResource(queryParams) {
	var attendees = []
	if (queryParams.attendees) {
		attendees = queryParams.attendees.map(val => {
			return { email: val }
		})
	}
	attendees.push({ email: queryParams.resource.calendarResource })

	let calendarEventId = null
	if (queryParams.resource.type === "meeting") {
		const response = await gapi?.client?.calendar?.events.insert({
			calendarId: "primary",
			summary: queryParams.summary,
			start: {
				dateTime: queryParams.start
			},
			end: {
				dateTime: queryParams.end
			},
			sendUpdates: "all",
			attendees: attendees
		})
		calendarEventId = response?.result.id
	} else if (queryParams.resource.calendarType) {
		const response = await gapi?.client?.calendar?.events.insert({
			calendarId: "primary",
			summary: queryParams.resource.name,
			start: {
				dateTime: queryParams.start
			},
			end: {
				dateTime: queryParams.end
			}
		})
		calendarEventId = response?.result.id
	}

	const ref = customersRef.doc(queryParams.customerId).collection("Bookings").doc()

	var data

	if (queryParams.resource.type === "meeting") {
		data = {
			deleted: false,
			calendarEventId: calendarEventId,
			attendees: queryParams.attendees || [],
			summary: queryParams.summary,
			resource: queryParams.resource.id,
			date: queryParams.date.toDate(),
			start: queryParams.start.toDate(),
			end: queryParams.end.toDate(),
			created: queryParams.created
		}
		await ref.set(data)
	} else {
		data = {
			deleted: false,
			calendarEventId: calendarEventId,
			resource: queryParams.resource.id,
			date: queryParams.date.toDate(),
			start: queryParams.start.toDate(),
			end: queryParams.end.toDate(),
			created: queryParams.created
		}
		await ref.set(data)
	}

	sendToBigQuery({
		customerId: queryParams.customerId,
		raw: {
			bookingId: ref.id,
			status: "booked",
			resourceId: queryParams.resource.id,
			resourceType: queryParams.resource.type,
			bookingStart: queryParams.start.toDate(),
			bookingEnd: queryParams.end.toDate(),
			attendees: queryParams.attendees || [],
			summary: queryParams.summary,
			createdBy: queryParams.created.email,
			createdOn: moment(Timestamp.now().toDate()).format()
		}
	})

	return {
		...data,
		id: ref.id,
		created: queryParams.created,
		date: queryParams.date.startOf("day"),
		start: queryParams.start,
		end: queryParams.end
	}
}

export async function editBooking(queryParams) {
	var attendees = []
	if (queryParams.attendees) {
		attendees = queryParams.attendees.map(val => {
			return { email: val }
		})
	}
	attendees.push({ email: queryParams.resource.calendarResource })

	let calendarEventId = null
	if (queryParams.resource.type === "meeting") {
		if (queryParams.booking.calendarEventId) {
			await gapi?.client?.calendar?.events.update({
				calendarId: "primary",
				eventId: queryParams.booking.calendarEventId,
				summary: queryParams.summary,
				start: {
					dateTime: queryParams.start
				},
				end: {
					dateTime: queryParams.end
				},
				sendUpdates: "all",
				attendees: attendees
			})
			calendarEventId = queryParams.booking.calendarEventId
		} else {
			const response = await gapi?.client?.calendar?.events.insert({
				calendarId: "primary",
				summary: queryParams.summary,
				start: {
					dateTime: queryParams.start
				},
				end: {
					dateTime: queryParams.end
				},
				sendUpdates: "all",
				attendees: attendees
			})
			calendarEventId = response?.result.id
		}
	} else if (queryParams.booking.calendarEventId) {
		const response = await gapi?.client?.calendar?.events.update({
			calendarId: "primary",
			eventId: queryParams.booking.calendarEventId,
			summary: queryParams.resource.name,
			start: {
				dateTime: queryParams.start
			},
			end: {
				dateTime: queryParams.end
			}
		})
		calendarEventId = response?.result.id
	} else {
		const response = await gapi?.client?.calendar?.events.insert({
			calendarId: "primary",
			summary: queryParams.resource.name,
			start: {
				dateTime: queryParams.start
			},
			end: {
				dateTime: queryParams.end
			}
		})
		calendarEventId = response?.result.id
	}

	const data = calendarEventId
		? {
				deleted: false,
				calendarEventId: calendarEventId,
				attendees: queryParams.attendees || [],
				summary: queryParams.summary || "",
				resource: queryParams.resource.id,
				date: queryParams.date.toDate(),
				start: queryParams.start.toDate(),
				end: queryParams.end.toDate(),
				updated: queryParams.updated
		  }
		: {
				deleted: false,
				summary: queryParams.summary || "",
				resource: queryParams.resource.id,
				date: queryParams.date.toDate(),
				start: queryParams.start.toDate(),
				end: queryParams.end.toDate(),
				updated: queryParams.updated
		  }

	await customersRef
		.doc(queryParams.customerId)
		.collection("Bookings")
		.doc(queryParams.bookingId)
		.update(data)

	sendToBigQuery({
		customerId: queryParams.customerId,
		raw: {
			bookingId: queryParams.bookingId,
			status: "updated",
			resourceId: queryParams.resource.id,
			resourceType: queryParams.resource.type,
			bookingStart: queryParams.start.toDate(),
			bookingEnd: queryParams.end.toDate(),
			attendees: queryParams.attendees || [],
			summary: queryParams.summary || "",
			updatedBy: queryParams.updated.email,
			updatedOn: moment(Timestamp.now().toDate()).format()
		}
	})

	return {
		...data,
		id: queryParams.bookingId,
		created: queryParams.created,
		date: queryParams.date.startOf("day"),
		start: queryParams.start,
		end: queryParams.end
	}
}

export async function cancelBooking(queryParams) {
	var attendees = []
	if (queryParams.booking.attendees) {
		attendees = queryParams.booking.attendees.map(val => {
			return { email: val }
		})
		attendees.push({ email: queryParams.resource.calendarResource })
	}

	if (queryParams.resource.type === "meeting" && queryParams.booking.calendarEventId) {
		await gapi?.client?.calendar?.events
			.delete({
				calendarId: "primary",
				eventId: queryParams.booking.calendarEventId
			})
			.then(
				() => {},
				function (error) {
					console.error("Error", error)
					if (!error.errors?.some(val => val.reason === "deleted")) {
						throw Error()
					}
				}
			)
	} else if (queryParams.booking.calendarEventId) {
		try {
			await gapi?.client?.calendar?.events.delete({
				calendarId: "primary",
				eventId: queryParams.booking.calendarEventId
			})
		} catch (error) {
			console.error("Error", error)
		}
	}

	await customersRef
		.doc(queryParams.customerId)
		.collection("Bookings")
		.doc(queryParams.booking.id)
		.update({
			deleted: true,
			deletedInfo: {
				reason: "deleted_in_app",
				userInteraction: true,
				id: queryParams.user.id,
				email: queryParams.user.email,
				date: Timestamp.now()
			}
		})

	sendToBigQuery({
		customerId: queryParams.customerId,
		raw: {
			bookingId: queryParams.booking.id,
			status: "deleted",
			resourceId: queryParams.resource.id,
			resourceType: queryParams.resource.type,
			bookingStart: queryParams.booking.start.toDate(),
			bookingEnd: queryParams.booking.end.toDate(),
			attendees: queryParams.booking.attendees || [],
			summary: queryParams.booking.summary,
			updatedBy: queryParams.user.email,
			updatedOn: moment(Timestamp.now().toDate()).format()
		}
	})
	return
}

export function getAverageTimeAtTheOffice(queryParams) {
	const _networks = queryParams.customer.merakiNetworks.filter(val => !val.disabled)
	const networkIds = _networks.map(val => val.netId)
	return fetchAPI({
		customerId: queryParams.customer.id,
		url: "bigquery/reporting/avgtime",
		raw: {
			typechart: "day",
			networkId: networkIds,
			macAddress: queryParams.macAddress,
			startDate: queryParams.start.format("YYYY-MM-DDT00:00:00"),
			endDate: queryParams.end.format("YYYY-MM-DDT23:59:59")
		}
	})
}

export function getHybridWorkBalance(queryParams) {
	const _networks = queryParams.customer.merakiNetworks.filter(val => !val.disabled)
	const networkIds = _networks.map(val => val.netId)
	return fetchAPI({
		customerId: queryParams.customer.id,
		url: "bigquery/reporting/avgtime",
		raw: {
			typechart: "day",
			networkId: networkIds,
			macAddress: queryParams.macAddress,
			startDate: queryParams.start.format("YYYY-MM-DDT00:00:00"),
			endDate: queryParams.end.format("YYYY-MM-DDT23:59:59")
		}
	})
}

function sendToBigQuery(values) {
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
	myHeaders.append("Content-Type", "application/json")
	const raw = JSON.stringify(values.raw)
	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/booking/streamBQ/${values.customerId}`,
		requestOptions
	).catch(error => console.log("Error", error))
}

function fetchAPI({ customerId, raw, url }) {
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
	myHeaders.append("Content-Type", "application/json")
	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify(raw),
		redirect: "follow"
	}
	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/${url}/${customerId}`,
		requestOptions
	)
}
