import React from "react"
import ReactDOM from "react-dom"
// import { IconButton, Tooltip } from "@material-ui/core/";
import { Dropdown } from "react-bootstrap"
import ClearAllIcon from "@material-ui/icons/ClearAll"

export default class ChangeFloorControlNew {
	constructor(props) {
		this.props = props
	}

	onAdd(map) {
		this.map = map
		this.container = document.createElement("div")
		this.container.className = "mapboxgl-ctrl"
		// this.container.id = "mapboxgl-ctrl-bottom-right";
		// console.log(this.props, "props");
		// console.log(this.props?.selectedFloorPlan?.level, "level");

		this.content = (
			// {*/ Hide if !(props.selectedSite / selectedLocation) */}
			<div style={{ display: !this.props.selectedSite ? "none" : "block" }}>
				<Dropdown
					drop="up"
					onSelect={eventKey => {
						// console.log("selected ->", eventKey);
						this.props.handleClick(eventKey)
					}}
					style={{ height: "31px" }}
				>
					<Dropdown.Toggle
						className="floorplans_control_toggle_dashboard"
						style={{ height: "31px" }}
					>
						{/* <ClearAllIcon style={{ color: "rgb(70, 78, 95)" }} /> */}
						<b
							// className='floorplans_control_toggle_dashboard_span'
							style={{
								color: "#FFFFFF",
								fontFamily: "Poppins",
								fontWeight: 600,
								fontSize: "12px"
							}}
						>
							{this.props?.selectedFloorPlan?.level !== undefined ? (
								this.props?.selectedFloorPlan?.name
							) : (
								<ClearAllIcon style={{ color: "#FFFFFF" }} />
							)}
						</b>
					</Dropdown.Toggle>
					<Dropdown.Menu className="floorplans_control_toggle_dashboard_menu">
						{this.props.floorPlans &&
							this.props.floorPlans.map(floor => {
								return (
									<Dropdown.Item
										className="floorplans_control_toggle_dashboard_item"
										key={floor.id}
										eventKey={floor.id}
										active={floor.id === this.props?.selectedFloorPlan?.id} // Shows by default the selected floorplan
									>
										{floor.name}
									</Dropdown.Item>
								)
							})}
					</Dropdown.Menu>
				</Dropdown>
			</div>
		)

		// Old buttons floor control
		// this.content = (
		//   // {*/ Hide if !(props.selectedSite / selectedLocation) */}
		//   <div style={{ display: !this.props.selectedSite ? "none" : "block" }}>
		//     <Tooltip
		//       title={this.props?.selectedFloorPlan?.name}
		//       arrow
		//       placement='left'
		//     >
		//       <IconButton
		//         className='floorsBtnOutside'
		//         style={{
		//           borderRadius: "50%",
		//           width: "3.2rem",
		//           height: "3.2rem",
		//           color: "black",
		//           backgroundColor: "white",
		//           textAlign: "center",
		//           fontSize: "14px",
		//           marginTop: "2px",
		//           border: "2px solid #C6C3C0",
		//         }}
		//         onClick={(e) => {
		//           this.props.handleClick(e);
		//         }}
		//       >
		//         <b>{this.props?.selectedFloorPlan?.level}</b>
		//       </IconButton>
		//     </Tooltip>
		//   </div>
		// );

		ReactDOM.render(this.content, this.container)
		return this.container
	}

	onRemove() {
		this.container.parentNode && this.container.parentNode.removeChild(this.container)
		this.map = undefined
	}
}
