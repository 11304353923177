import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import * as actions from "../../../_redux/admin/inventory/InventoryActions"
import { makeStyles } from "@material-ui/core/styles"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Button } from "../../../../_partials/Button"
import { Input } from "../../../../_partials/inputs/Input"
import { Autocomplete_People } from "../../../../_partials/inputs/Autocomplete_PeopleTable"
import { startButtonProgress, finishButtonProgress } from "../../utils/utils"

const EditSchema = Yup.object().shape({
	version: Yup.string()
		.nullable()
		.trim()
		.required("Version is required")
		.min(1, "Version must not be empty"),
	severity: Yup.string()
		.nullable()
		.trim()
		.required("Severity is required")
		.min(1, "Severity must not be empty"),
	name: Yup.string()
		.nullable()
		.trim()
		.required("Name is required")
		.min(1, "Name must not be empty"),
	vendor: Yup.string()
		.nullable()
		.trim()
		.required("Vendor is required")
		.min(1, "Vendor Vendor must not be empty"),
	notes: Yup.string()
		.nullable()
		.trim()
		.required("Notes is required")
		.min(1, "Notes must not be empty")
})

const useStyles = makeStyles(theme => ({
	checkbox: {
		borderRadius: "4px",
		background: "#ECF1F4",
		width: "100%",
		"& .MuiFormControlLabel-label": {
			color: "#4A4A68",
			textAlign: "center",
			fontFamily: "Poppins",
			fontSize: "14px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "22px"
		}
	}
}))

const initialValues = {
	name: "",
	model: "",
	severity: "",
	vendor: "",
	version: "",
	notes: ""
}

export function AddInventoryFirmwaresModalForm({
	modelsInventory,
	setShowNewModelModal,
	listLoading,
	technology,
	user,
	onHide,
	saving,
	setSaving
}) {
	const dispatch = useDispatch()
	const classes = useStyles()

	// ━━━━━━━━━━━━━  useState  ━━━━━━━━━━━━━ \\
	const [formValues, setFormValues] = useState(initialValues)
	const [file, setFile] = useState(null)
	const [selectedModel, setSelectedModel] = useState("")
	const [modelList, setModelList] = useState([])
	// ━━━━━━━━━━━━━  useEffect  ━━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!modelsInventory) return

		const listForModel = modelsInventory || []
		setModelList(listForModel)
	}, [modelsInventory, technology])

	// ━━━━━━━━━━━━━  handlers  ━━━━━━━━━━━━━ \\

	// Function to convert file to Base64 encoding
	const fileToBase64 = async file => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result.split(",")[1]) // Removes data URL prefix
			reader.onerror = error => reject(error)
		})
	}
	// Function to validate file type
	const isValidFileType = file => {
		const allowedExtensions = ["otap"] // Add more extensions if necessary
		const fileExtension = file.name.split(".").pop().toLowerCase()

		return allowedExtensions.includes(fileExtension)
	}

	const onSubmit = async values => {
		if (!formValues || !technology || !user.id) return
		setSaving(true)
		// Starts the change of the saving UI button like progress bar
		const buttonToProgressEl = document.getElementById("buttonToProgress_editPoi")
		buttonToProgressEl &&
			startButtonProgress(buttonToProgressEl, "button__progress_editPoi", "button__text_editPoi")
		let base64String = null
		if (file) {
			if (!isValidFileType(file)) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: "Invalid file type! Please select an OTAP package file."
				})
				return
			}
			try {
				base64String = await fileToBase64(file)
				//console.log(base64String) // Base64 encoded string
			} catch (error) {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: "Error converting file to Base64."
				})
				console.error("Error converting file to Base64:", error)
				return
			}
		}
		// if (!base64String) return

		const data = {
			name: formValues.name,
			modelId: selectedModel.id,
			severity: formValues.severity,
			vendor: formValues.vendor,
			version: formValues.version,
			notes: formValues.notes,
			callersUserId: user.id,
			firmware: base64String
		}
		dispatch(
			actions.createFirmware({
				data,
				technology: technology,
				onHide
			})
		)
			.then(() => {
				setSaving(false)
				onHide()
			})
			.catch(error => {
				console.error("Error saving:", error) // Handle error
				setSaving(false) // Reset saving state on error
			})
	}

	const hide = () => {
		setShowNewModelModal(false)
	}

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={EditSchema}
			onSubmit={values => {
				onSubmit(values)
			}}
			data-testid="AddInventoryFirmwaresModalForm-Formik"
		>
			{({ setFieldValue, handleSubmit }) => {
				return (
					<Form className="form form-label-right" data-testid="AddInventoryFirmwaresModalForm">
						{/* File, Model ID */}
						<div className="form-group row mb-5">
							<div
								className="col"
								style={{
									textAlignLast: "center",
									alignSelf: "center"
								}}
							>
								<Button
									variant="contained"
									color="primary"
									component="label"
									style={{ color: "#fff" }}
									onChange={e => setFile(e.target.files[0])}
									text="Upload Firmware File"
									disabled={saving}
								>
									<input type="file" hidden />
								</Button>
								{file && file.name && (
									<div className="col align-self-center">
										<span>{file.name}</span>
									</div>
								)}
							</div>
							<div className="col">
								<Field
									id="model-autocomplete"
									customColor="#ECF1F4"
									name="model"
									label="Model"
									disabled={saving}
									component={Autocomplete_People}
									requiredField
									placeholder="Model"
									options={
										modelList && modelList.length > 0
											? modelList.map(val => {
													const { name, vendor, battery, features, id } = val

													// Create an array of feature strings
													const featureStrings = Object.entries(features).map(
														([key, value]) => `${key}: ${value}`
													)
													const featuresText = featureStrings.join(", ")

													const tooltipText = `Battery: ${battery}, Features: ${featuresText}`

													return {
														value: name,
														name,
														group: vendor,
														tooltip: tooltipText,
														id: id
													}
											  })
											: []
									}
									grouped={true}
									value={selectedModel}
									onChange={(event, newValue) => {
										if (!newValue) {
											setSelectedModel("")
											setFieldValue("model", "")
										} else {
											setSelectedModel(newValue)
											setFieldValue("model", newValue)
										}
									}}
									getOptionLabel={option => {
										// Ensure `option` is an object and `option.value` exists
										return option?.name || "" // Return an empty string if name is null/undefined
									}}
								/>
							</div>
						</div>

						{/* Version, Severity */}
						<div className="form-group row mb-5">
							<div className="col">
								<Field
									variant="filled"
									name="version"
									label="Version"
									requiredField={true}
									size="small"
									disabled={saving}
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter version"
									onChange={event => {
										if (!event.target.value) {
											setFieldValue("version", "")
											setFormValues({ ...formValues, name: "" })
										} else {
											setFieldValue("version", event.target.value)
											setFormValues({ ...formValues, version: event.target.value })
										}
									}}
								/>
							</div>
							<div className="col">
								<Field
									variant="filled"
									name="severity"
									label="Severity"
									disabled={saving}
									requiredField={true}
									size="small"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter Severity"
									onChange={event => {
										if (!event.target.value) {
											setFieldValue("severity", "")
											setFormValues({ ...formValues, severity: "" })
										} else {
											setFieldValue("severity", event.target.value)
											setFormValues({ ...formValues, severity: event.target.value })
										}
									}}
								/>
							</div>
						</div>

						{/* Name, Vendor */}
						<div className="form-group row mb-5">
							<div className="col">
								<Field
									variant="filled"
									name="name"
									label="Name"
									disabled={saving}
									requiredField={true}
									size="small"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter name"
									onChange={event => {
										if (!event.target.value) {
											setFieldValue("name", "")
											setFormValues({ ...formValues, name: "" })
										} else {
											setFieldValue("name", event.target.value)
											setFormValues({ ...formValues, name: event.target.value })
										}
									}}
								/>
							</div>
							<div className="col">
								<Field
									variant="filled"
									name="vendor"
									label="Vendor"
									requiredField={true}
									size="small"
									disabled={saving}
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter Vendor"
									onChange={event => {
										if (!event.target.value) {
											setFieldValue("vendor", "")
											setFormValues({ ...formValues, vendor: "" })
										} else {
											setFieldValue("vendor", event.target.value)
											setFormValues({ ...formValues, vendor: event.target.value })
										}
									}}
								/>
							</div>
						</div>

						{/* Notes */}
						<div className="form-group row mb-5">
							<div className="col">
								<Field
									variant="filled"
									name="notes"
									label="Notes"
									multiline
									disabled={saving}
									rows={4} // Adjust the number of rows as needed
									component={Input}
									InputProps={{
										disableUnderline: true,
										style: { height: "auto", padding: "6px 12px" }
									}}
									data-testid="Formik_Form_Notes"
									onChange={event => {
										if (!event.target.value) {
											setFieldValue("notes", "")
											setFormValues({ ...formValues, notes: "" })
										} else {
											setFieldValue("notes", event.target.value)
											setFormValues({ ...formValues, notes: event.target.value })
										}
									}}
								/>
							</div>
						</div>

						{/* Cancel, Save */}
						<div className="form-group row mb-1 justify-content-end">
							<div className="d-flex align-items-center">
								<Button
									variant="outlined"
									color="primary"
									style={{ width: "85px", height: "35px", marginRight: "10px" }}
									text="Cancel"
									disabled={saving}
									onClick={hide}
									data-testid="Formik_Form_Button"
								/>

								<Button
									data-testid="Formik_Form_Button"
									variant="contained"
									disabled={!selectedModel}
									style={{
										width: "65px",
										height: "35px",
										marginRight: "10px",
										// fontFamily: "Poppins",
										fontWeight: "700",
										backgroundColor: "#3077d3",
										color: "#f4f4f4"
									}}
									// text="Save"
									onClick={() => {
										handleSubmit()
									}}
									className="ml-2"
									id="buttonToProgress_editPoi"
									data-progress-text="Saving..."
									data-complete-text="Save complete."
								>
									<div className="button__progress_editPoi"></div>
									<span className="button__text_editPoi">Save</span>
								</Button>
							</div>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}
