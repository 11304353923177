/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect } from "react"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import * as THREE from "three"
import { CSS2DRenderer, CSS2DObject } from "three-css2drender"
import ReactDOM from "react-dom"
import "./LiveMapStyle.css"

mapboxgl.accessToken =
	"pk.eyJ1IjoiZGlvZ29tIiwiYSI6ImNra3p5aXoxeTAxZ3Eybm80aXNhbTN0dDAifQ.QBLSLKu6azLM9HZkUogIRA"

export function MapWidget5() {
	const mapContainerRef = useRef(null)

	useEffect(() => {
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: "mapbox://styles/mapbox/streets-v11",
			center: [-9.158062711431398, 38.57793500061726],
			zoom: 19
		})

		map.on("style.load", function () {
			map.addLayer(customLayer, "waterway-label")
		})

		return () => map.remove()
	}, [])

	var modelOrigin = [-9.158062711431398, 38.57793500061726]
	var modelAltitude = 0
	var labelAltitude = 10
	var modelRotate = [Math.PI / 2, 0, 0]

	var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
		modelOrigin,
		modelAltitude
	)

	// transformation parameters to position, rotate and scale the 3D model onto the map
	var modelTransform = {
		translateX: modelAsMercatorCoordinate.x,
		translateY: modelAsMercatorCoordinate.y,
		translateZ: modelAsMercatorCoordinate.z,
		rotateX: modelRotate[0],
		rotateY: modelRotate[1],
		rotateZ: modelRotate[2],
		/* Since our 3D model is in real world meters, a scale transform needs to be
		 * applied since the CustomLayerInterface expects units in MercatorCoordinates.
		 */
		scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
	}

	// configuration of the custom layer for a 3D model per the CustomLayerInterface
	var customLayer = {
		id: "marker-elevation",
		type: "custom",
		renderingMode: "3d",
		onAdd: function (map, gl) {
			this.camera = new THREE.Camera()
			this.scene = new THREE.Scene()
			this.map = map

			// use the Mapbox GL JS map canvas for three.js
			this.renderer = new THREE.WebGLRenderer({
				canvas: map.getCanvas(),
				context: gl,
				antialias: true
			})

			this.renderer.autoClear = false

			//create the renderer
			this.popupRenderer = new CSS2DRenderer()
			this.popupRenderer.setSize(
				this.map.getCanvas().clientWidth,
				this.map.getCanvas().clientHeight
			)
			this.popupRenderer.domElement.style.position = "absolute"
			this.popupRenderer.domElement.id = "labelCanvas" //TODO: this value must come by parameter
			this.popupRenderer.domElement.style.top = 0
			this.map.getCanvasContainer().appendChild(this.popupRenderer.domElement)
			let divToolTip = drawTooltip(true)
			let popupAlt = new CSS2DObject(divToolTip)
			popupAlt.position.set(0, labelAltitude, 0)
			this.scene.add(popupAlt)
		},
		render: function (gl, matrix) {
			var rotationX = new THREE.Matrix4().makeRotationAxis(
				new THREE.Vector3(1, 0, 0),
				modelTransform.rotateX
			)
			var rotationY = new THREE.Matrix4().makeRotationAxis(
				new THREE.Vector3(0, 1, 0),
				modelTransform.rotateY
			)
			var rotationZ = new THREE.Matrix4().makeRotationAxis(
				new THREE.Vector3(0, 0, 1),
				modelTransform.rotateZ
			)

			var m = new THREE.Matrix4().fromArray(matrix)
			var l = new THREE.Matrix4()
				.makeTranslation(
					modelTransform.translateX,
					modelTransform.translateY,
					modelTransform.translateZ
				)
				.scale(
					new THREE.Vector3(modelTransform.scale, -modelTransform.scale, modelTransform.scale)
				)
				.multiply(rotationX)
				.multiply(rotationY)
				.multiply(rotationZ)

			this.camera.projectionMatrix = m.multiply(l)
			this.renderer.state.reset()
			this.renderer.render(this.scene, this.camera)
			this.popupRenderer.render(this.scene, this.camera)
			this.map.triggerRepaint()
		}
	}

	function drawTooltip(mapboxStyle = false) {
		let divToolTip
		if (mapboxStyle) {
			divToolTip = document.createElement("div")
			ReactDOM.render(
				<div className="pin">
					<img src="https://storage.googleapis.com/ufind-dev-profile/C000001/demo1_user007_face.jpg" />
				</div>,
				divToolTip
			)
			return divToolTip
		}
	}

	return (
		<div>
			<div style={{ height: "500px" }} ref={mapContainerRef} />
		</div>
	)
}
