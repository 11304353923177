import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import CloseIcon from "@material-ui/icons/Close"
import { ModalProgressBar } from "../../../_partials/ModalProgressBar"
import { Bold } from "../../../_partials/typography"
import "./MessagesBroadcast.css"
import { Input } from "../../../_partials/inputs/Input"
import { Button } from "../../../_partials/Button"
import SVG from "react-inlinesvg"
import {
	Timestamp,
	addDoc,
	collection,
	getDocs,
	query,
	serverTimestamp,
	where
} from "firebase/firestore"
import { firestore } from "../../../../firebase"
import moment from "moment"
import { Dropdown } from "react-bootstrap"
import {
	makeStyles,
	Checkbox,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	FormControl,
	Switch,
	Grid,
	Select as SelectMui
} from "@material-ui/core"
import isEqual from "lodash/isEqual"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	},
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "center"
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "center"
	},
	variant: "menu"
}

const useStyles = makeStyles(() => ({
	textfieldLight: {
		borderRadius: "5px",
		overflow: "hidden",
		"& .MuiInputBase-root": {
			// padding: "15px",
			// minHeight: "100px",
			alignItems: "start"
		},
		"& .MuiFilledInput-input": {
			padding: "10px"
		}
	},
	textfield: {
		borderRadius: "5px",
		overflow: "hidden",
		"& .MuiInputBase-root": {
			padding: "10px",
			minHeight: "100px",
			alignItems: "start"
		}
	},
	formControl: {
		width: "100%",
		// width: "350px",
		marginTop: "2px"
	},
	indeterminateColor: {
		color: "#f50057"
	},
	selectAllText: {
		fontWeight: 400
	},
	selectedAll: {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.08)"
		}
	},
	filled: {
		backgroundColor: "#ECF1F4",
		"& .MuiSelect-underline": {
			display: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	select: {
		height: "38px",
		backgroundColor: "#F2F2F2",
		borderRadius: "5px",
		"& .MuiSelect-select:focus": {
			borderRadius: "0",
			backgroundColor: "transparent"
		},
		"& .MuiSelect-root": {
			padding: "16px 12px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		"& .MuiInput-underline:before": {
			borderBottom: "none"
		},
		"& .MuiInput-underline:after": {
			borderBottom: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		},
		"&:hover": {
			backgroundColor: "#ECF1F4",
			border: "1px solid grey",
			borderRadius: "5px"
		},
		"& .MuiSelect-icon": {
			color: "#4A4A68", // arrow icon color
			marginRight: "0.5rem"
		}
		// "& .Mui-disabled":{

		// }
	},
	datePicker: {
		// marginTop: "0.9rem",
		marginTop: "2px",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		margin: 0,
		padding: "0 12px",
		height: "38px",
		"&:hover": {
			cursor: "pointer",
			border: "1px solid grey"
		},
		"& .MuiInputBase-root": {
			cursor: "pointer",
			height: "38px"
		},
		"&:hover .MuiInputBase-input": {
			cursor: "pointer"
		}
	},
	checkboxIcon: {
		"& .MuiSvgIcon-root": {
			color: "#3077D3"
		}
	}
}))

const MassTextAlertsModalBody = ({ onHide }) => {
	const { selectedCustomer, selectedSite, people, user, tagSeenTimeout } = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			people: state.basePage?.people || [],
			user: state.auth?.user,
			tagSeenTimeout: state.basePage?.tagSeenTimeout
		}),
		shallowEqual
	)

	const handleOnHide = () => {
		onHide()
	}

	const [isLoading, setIsLoading] = useState(false)
	const [confirmationOpen, setConfirmationOpen] = useState(false)
	const [broadcast, setBroadcast] = useState(true)
	const [send, setSend] = useState("active_msg")
	const [inputValue, setInputValue] = useState("")
	const [inputSubject, setInputSubject] = useState("")
	const [messageType, setMessageType] = useState("APP")
	const [successMessage, setSuccessMessage] = useState(false)
	const [messageCount, setMessageCount] = useState(0)
	const [selectedPeople, setSelectedPeople] = useState([])
	const [role, setRole] = useState([])
	const [selectedRole, setSelectedRole] = useState([])

	useEffect(() => {
		if (!successMessage) return
		setTimeout(() => {
			handleOnHide()
		}, 5000)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [successMessage])

	useEffect(() => {
		if (!people) return

		const roles = people.map(person => person.role).filter(role => role) // Filter out empty or null roles
		const noDoubles = [...new Set(roles.map(role => role))]

		if (!isEqual(noDoubles, role)) {
			setRole(noDoubles)
		}
	}, [people])

	// useEffect(() => {
	// 	if (!role) return

	// 	setSelectedRole(role.map(p => p))
	// }, [role])

	return (
		<div
			style={{
				width: "542px",
				backgroundColor: "#FFFFFF",
				borderRadius: "5px"
			}}
			data-testid="MassTextAlertsModalBodyMainDiv"
		>
			{isLoading && <ModalProgressBar />}
			{/* ━━★  TITLE  ★━━ */}
			<Title onHide={onHide} confirmationOpen={confirmationOpen} />

			{/* ━━★  BODY  ★━━ */}
			{successMessage ? (
				<SuccessBody onHide={onHide} messageCount={messageCount} />
			) : confirmationOpen ? (
				<ConfirmationBody
					selectedCustomer={selectedCustomer}
					selectedSite={selectedSite}
					selectedPeople={selectedPeople}
					people={people}
					role={role}
					selectedRole={selectedRole}
					onHide={onHide}
					tagSeenTimeout={tagSeenTimeout}
					setIsLoading={setIsLoading}
					user={user}
					broadcast={broadcast}
					send={send}
					messageType={messageType}
					message={inputValue}
					subject={inputSubject}
					setSuccessMessage={setSuccessMessage}
					setMessageCount={setMessageCount}
					setConfirmationOpen={setConfirmationOpen}
				/>
			) : (
				<MessageBroadcastBody
					broadcast={broadcast}
					people={people}
					selectedPeople={selectedPeople}
					setSelectedPeople={setSelectedPeople}
					role={role}
					selectedRole={selectedRole}
					setSelectedRole={setSelectedRole}
					setBroadcast={setBroadcast}
					send={send}
					setSend={setSend}
					inputValue={inputValue}
					setInputValue={setInputValue}
					inputSubject={inputSubject}
					setInputSubject={setInputSubject}
					setMessageType={setMessageType}
					onHide={onHide}
					setConfirmationOpen={setConfirmationOpen}
				/>
			)}
		</div>
	)
}

export default MassTextAlertsModalBody

const Title = ({ onHide, confirmationOpen }) => {
	const handleOnHide = () => {
		onHide()
	}

	return (
		<>
			<div
				className="d-flex w-100 align-items-center justify-content-between"
				style={{
					padding: "0px 8px 0px 8px"
				}}
			>
				<div className="d-flex align-items-center">
					<SVG
						style={{ marginRight: "8px" }}
						src={"/svg/communication/mass-communication-send.svg"}
					/>
					<span className="modal-title">Send Message</span>
				</div>
				<div>
					<IconButton
						onClick={handleOnHide}
						style={{ left: "12px" }}
						data-testid="close-button"
					>
						<CloseIcon />
					</IconButton>
				</div>
			</div>
		</>
	)
}

const MessageBroadcastBody = ({
	broadcast,
	setBroadcast,
	people,
	selectedPeople,
	setSelectedPeople,
	role,
	selectedRole,
	setSelectedRole,
	send,
	setSend,
	inputValue,
	setInputValue,
	inputSubject,
	setInputSubject,
	setMessageType,
	onHide,
	setConfirmationOpen
}) => {
	const classes = useStyles()

	//Role Handle
	const isAllSelectedRole = role.length > 0 && selectedRole.length === role.length

	const handleChangeRole = event => {
		const value = event.target.value

		const foundedSelectAll = value.some(val => val.id === "all")

		if (foundedSelectAll) {
			// console.log("foundedSelectAll roles ->>>>>>>>>>", foundedSelectAll)
			setSelectedRole(selectedRole.length === role.length ? [] : role.map(option => option))
			return
		}
		setSelectedRole(value)
	}

	// People Handle
	const isAllSelectedPeople = people.length > 0 && selectedPeople.length === people.length

	const handleChangePeople = event => {
		const value = event.target.value

		const foundedSelectAll = value.some(val => val.id === "all")

		if (foundedSelectAll) {
			// console.log("foundedSelectAll roles ->>>>>>>>>>", foundedSelectAll)
			setSelectedPeople(
				selectedPeople.length === people.length ? [] : people.map(option => option)
			)
			return
		}
		setSelectedPeople(value)
	}

	const handleOnHide = () => {
		onHide()
	}

	return (
		<div style={{ padding: "5px 15px 15px 15px" }}>
			{/* Send To All or By Site Switch */}
			<span className="box-title">Send To</span>
			<div className="row justify-content-between" style={{ padding: "5px 12.5px" }}>
				<div
					className={`col-6 broadcast-custom-selection ${
						!broadcast ? "active_msg" : "inactive_msg"
					}`}
					onClick={() => setBroadcast(false)}
				>
					<section>
						<SVG
							style={{ marginRight: "8px" }}
							src={"/svg/communication/mass-communication-current-site.svg"}
						/>
						<span>Current site</span>
					</section>
					<div className={!broadcast ? "active_msg" : "inactive_msg"} />
				</div>
				<div
					className={`col-6 broadcast-custom-selection ${
						broadcast ? "active_msg" : "inactive_msg"
					}`}
					onClick={() => setBroadcast(true)}
				>
					<section>
						<SVG
							style={{ marginRight: "8px" }}
							src={"/svg/communication/mass-communication-all-sites.svg"}
						/>
						<span>All sites</span>
					</section>
					<div className={broadcast ? "active_msg" : "inactive_msg"} />
				</div>
			</div>
			{/* Role Dropdown */}
			<div style={{ marginBottom: "10px" }} className="row">
				<div className="col-12">
					<span className={!broadcast ? "box-title" : "disabled-box-title"}>Roles</span>
					<FormControl className={classes.formControl}>
						<SelectMui
							id="reporting_data_filter_roles_dropdown"
							data-testid="data_filter_select_roles_dropdown"
							classes={{
								filled: classes.filled,
								icon: classes.checkboxIcon
							}}
							disableUnderline
							disabled={broadcast}
							className={classes.select}
							labelId="mutiple-select-label"
							multiple
							value={selectedRole}
							onChange={handleChangeRole}
							renderValue={() => {
								if (isAllSelectedRole) {
									return "Select All"
								} else {
									const selectedRoleNames = selectedRole.map(role => role)
									return selectedRoleNames.join(", ")
								}
							}}
							MenuProps={MenuProps}
							// onClose={() => {
							// 	console.log("onClose People!!!!")
							// 	// handleChangeQueryParams()
							// }}
						>
							<MenuItem
								id="reporting_roles_dropdown_select_all_value"
								value={{
									id: "all",
									name: "Select All"
								}}
								classes={{
									root: isAllSelectedRole ? classes.selectedAll : ""
								}}
							>
								<ListItemIcon>
									<Checkbox
										className={classes.checkboxIcon}
										classes={{ indeterminate: classes.indeterminateColor }}
										checked={isAllSelectedRole}
										indeterminate={
											selectedRole.length > 0 && selectedRole.length < role.length
										}
									/>
								</ListItemIcon>
								<ListItemText
									classes={{ primary: classes.selectAllText }}
									primary="Select All"
								/>
							</MenuItem>
							{role &&
								role.map((option, i) => {
									return (
										<MenuItem
											key={i}
											value={option}
											id={"reporting_roles_dropdown_" + option + "_" + i}
										>
											<ListItemIcon>
												<Checkbox
													className={classes.checkboxIcon}
													checked={selectedRole.indexOf(option) > -1}
												/>
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.selectAllText }}
												primary={option}
											/>
										</MenuItem>
									)
								})}
						</SelectMui>
					</FormControl>
				</div>
			</div>
			{/* People Dropdown */}
			<div style={{ marginBottom: "10px" }} className="row">
				<div className="col-12">
					<span className={!broadcast ? "box-title" : "disabled-box-title"}>People</span>
					<FormControl className={classes.formControl}>
						<SelectMui
							id="reporting_data_filter_roles_dropdown"
							data-testid="data_filter_select_roles_dropdown"
							classes={{
								filled: classes.filled,
								icon: classes.checkboxIcon
							}}
							disableUnderline
							disabled={broadcast}
							className={classes.select}
							labelId="mutiple-select-label"
							multiple
							value={selectedPeople}
							onChange={handleChangePeople}
							renderValue={() => {
								if (isAllSelectedPeople) {
									return "Select All"
								} else {
									const selectedPeopleNames = selectedPeople.map(
										person => person.personalData?.name
									)
									return selectedPeopleNames.join(", ")
								}
							}}
							MenuProps={MenuProps}
							// onClose={() => {
							// 	console.log("onClose People!!!!")
							// 	// handleChangeQueryParams()
							// }}
						>
							<MenuItem
								id="reporting_roles_dropdown_select_all_value"
								value={{
									id: "all",
									name: "Select All"
								}}
								classes={{
									root: isAllSelectedPeople ? classes.selectedAll : ""
								}}
							>
								<ListItemIcon>
									<Checkbox
										className={classes.checkboxIcon}
										classes={{ indeterminate: classes.indeterminateColor }}
										checked={isAllSelectedPeople}
										indeterminate={
											selectedPeople.length > 0 && selectedPeople.length < people.length
										}
									/>
								</ListItemIcon>
								<ListItemText
									classes={{ primary: classes.selectAllText }}
									primary="Select All"
								/>
							</MenuItem>
							{people &&
								people.map((option, i) => {
									return (
										<MenuItem
											key={i}
											value={option}
											id={
												"reporting_roles_dropdown_" +
												option.personalData?.name +
												"_" +
												i
											}
										>
											<ListItemIcon>
												<Checkbox
													className={classes.checkboxIcon}
													checked={selectedPeople.indexOf(option) > -1}
												/>
											</ListItemIcon>
											<ListItemText
												classes={{ primary: classes.selectAllText }}
												primary={option.personalData?.name}
											/>
										</MenuItem>
									)
								})}
						</SelectMui>
					</FormControl>
				</div>
			</div>
			{/* People Dropdown Counter */}
			{selectedPeople && selectedPeople.length > 0 && (
				<div style={{ marginBottom: "10px" }} className="row">
					<div className="col-6">
						<div className="row" style={{ paddingLeft: "12.5px" }}>
							<div
								className="col-4 d-flex"
								style={{ position: "relative", overflow: "auto", height: "31px" }}
							>
								{selectedPeople &&
									selectedPeople.slice(0, 6).map((data, index) => (
										<div
											key={index}
											className="icon-preview"
											style={{
												position: "absolute",
												left: `${index * 10}px`
											}}
										>
											{data.picture ? (
												<img
													src={data.picture}
													alt="people profile image placeholder"
												/>
											) : (
												<SVG src={"/media/crowdkeep/person.svg"} />
											)}
										</div>
									))}
							</div>
							<div className="col-1 select-counter" style={{ fontWeight: 700 }}>
								{selectedPeople.length}
							</div>
							<div className="col select-counter">People Selected</div>
						</div>
					</div>
				</div>
			)}
			{/* Subject */}
			<div style={{ marginBottom: "10px" }}>
				<span className="box-title">Subject</span>
				<sup style={{ color: "red" }}> *</sup>
				<div>
					<Input
						// multiline
						fullWidth
						variant="filled"
						InputProps={{
							disableUnderline: true
						}}
						placeholder="Type subject"
						className={classes.textfieldLight}
						value={inputSubject}
						onChange={e => setInputSubject(e.target.value)}
					/>
				</div>
			</div>
			{/* Message Area */}
			<div style={{ marginBottom: "20px" }}>
				<span className="box-title">Message</span>
				<sup style={{ color: "red" }}> *</sup>
				<div>
					<Input
						multiline
						fullWidth
						variant="filled"
						InputProps={{
							disableUnderline: true
						}}
						placeholder="Type your message here"
						className={classes.textfield}
						value={inputValue}
						onChange={e => setInputValue(e.target.value)}
					/>
				</div>
			</div>
			{/* Send && Cancel Buttonss */}
			<div className="d-flex justify-content-end">
				<Button
					text="CANCEL"
					variant="outlined"
					color="primary"
					className="mr-2"
					onClick={handleOnHide}
				/>
				<Dropdown
					className="message-broadcast-dropdown"
					onSelect={eventKey => {
						setMessageType(eventKey)
						setConfirmationOpen(true)
					}}
					style={{ marginLeft: "10px" }}
				>
					<Dropdown.Toggle
						disabled={inputValue.length > 5 && inputSubject ? false : true}
						className="export_daily_workers_dropdown_btn"
					>
						SEND
					</Dropdown.Toggle>
					<Dropdown.Menu style={{ marginTop: "5px" }}>
						<Dropdown.Item key="inapp" eventKey="APP">
							<span style={{ fontFamily: "Poppins" }}>Via in app</span>
						</Dropdown.Item>
						<Dropdown.Item key="sms" eventKey="SMS">
							<span style={{ fontFamily: "Poppins" }}>Via SMS</span>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</div>
	)
}

const ConfirmationBody = ({
	selectedCustomer,
	selectedSite,
	selectedPeople,
	people,
	role,
	selectedRole,
	onHide,
	tagSeenTimeout,
	setIsLoading,
	user,
	broadcast,
	send,
	messageType,
	message,
	subject,
	setSuccessMessage,
	setMessageCount,
	setConfirmationOpen
}) => {
	return (
		<div style={{ padding: "5px 10px 15px 15px" }}>
			<div className="mb-3">
				You are going to send a message to all {/*send === "active_msg" && "active_msg"*/}{" "}
				people in current {broadcast ? "customer" : "site"}. <br />
				Do you wish to continue?
			</div>
			<div className="d-flex justify-content-end">
				<Button
					text="BACK"
					variant="outlined"
					color="primary"
					className="mr-2"
					onClick={() => {
						setConfirmationOpen(prev => !prev)
					}}
				/>
				<Button
					text="OK, SEND"
					variant="contained"
					color="primary"
					className="mr-2"
					onClick={() => {
						handleBroadcast(
							selectedCustomer,
							selectedSite,
							people,
							selectedPeople,
							role,
							selectedRole,
							user,
							broadcast,
							send,
							messageType,
							message,
							subject,
							tagSeenTimeout,
							setIsLoading,
							setSuccessMessage,
							setMessageCount
						)
					}}
				/>
			</div>
		</div>
	)
}

const SuccessBody = ({ onHide, messageCount }) => {
	const handleOnHide = () => {
		onHide()
	}
	return (
		<div style={{ padding: "5px 10px 15px 15px" }}>
			<div className="mb-3">{messageCount} messages sent!</div>
			<div className="d-flex justify-content-end">
				<Button
					text="CLOSE"
					variant="contained"
					color="primary"
					className="mr-2"
					onClick={handleOnHide}
				/>
			</div>
		</div>
	)
}

async function handleBroadcast(
	selectedCustomer,
	selectedSite,
	people,
	selectedPeople,
	role,
	selectedRole,
	user,
	broadcast,
	send,
	messageType,
	message,
	subject,
	tagSeenTimeout,
	setIsLoading,
	setSuccessMessage,
	setMessageCount
) {
	if (!selectedCustomer || !selectedSite) return

	setIsLoading(true)

	var peopleToSend = []
	if (broadcast) {
		//TODO GET ALL PEOPLE FROM ALL SITE
		const peopleRef = collection(firestore, `Customers/${selectedCustomer.id}/People`)
		const q = query(peopleRef)
		const peopleSnapshot = await getDocs(q)

		peopleSnapshot.forEach(person => {
			const p = person.data()
			if (p.personalData?.phoneNumber || p.uuid) {
				peopleToSend.push(p)
			}
		})
	} else {
		// console.log("🚀 ~ selectedRole:", selectedRole)
		// selectedPeople.forEach(val => {
		// 	if (val.uuid && val.siteId === selectedSite.id) {
		// 		peopleToSend.push(val)
		// 	}
		// })
		// Create an array to store the values that meet the conditions
		let filteredPeopleByRole = []

		people.forEach(val => {
			// Check if uuid and siteId conditions are met
			if (val.uuid && val.siteId === selectedSite.id) {
				// Check if the role is one of the selected roles
				if (selectedRole.includes(val.role)) {
					// If both conditions are met, add the value to filteredPeopleByRole
					filteredPeopleByRole.push(val)
				}
			}
		})

		// Create an array to store the filtered values
		let filteredPeople = []

		selectedPeople.forEach(val => {
			if (val.uuid && val.siteId === selectedSite.id) {
				// If the condition is met, store the value in filteredPeople array
				filteredPeople.push(val)
			}
		})

		// Create a Set to ensure uniqueness in the combined array
		let combinedPeopleSet = new Set([...filteredPeopleByRole, ...filteredPeople])

		// Convert the Set back to an array
		let combinedPeople = Array.from(combinedPeopleSet)

		// Finally, push the combined values to peopleToSend
		peopleToSend.push(...combinedPeople)
	}
	// if (send === "active_msg") {
	// 	// Filter only active
	// 	const q = query(nodesRef, where("nodeType", "==", "tag"))
	// 	const nodesSnapshot = await getDocs(q)
	// 	var activeTags = []
	// 	nodesSnapshot.forEach(val => {
	// 		const tag = val.data()
	// 		//TODO: This is used here and in LiveDataSection (It should be a common function called by both to avoid error on change)
	// 		//TODO: Create a function to check a node state and use it to fix error above
	// 		if (tag?.lastSeen) {
	// 			const lastSeen = moment(tag.lastSeen.toDate())
	// 			const xTimeAgo = moment(Timestamp.now().toDate()).subtract(tagSeenTimeout, "seconds")
	// 			if (lastSeen.isAfter(xTimeAgo)) {
	// 				activeTags.push(tag)
	// 			}
	// 		}
	// 	})

	// 	peopleToSend = peopleToSend.filter(val => {
	// 		return activeTags.some(value => value.uuid === val.uuid)
	// 	})
	// }

	var messagesCount = 0
	if (messageType === "SMS") {
		messagesCount = await sendViaSMS({
			people: peopleToSend,
			message,
			customerId: selectedCustomer.id,
			customerName: selectedCustomer.customerInfo?.name,
			siteId: selectedSite.id,
			siteName: selectedSite.siteInfo?.name,
			user
		})
	} else {
		messagesCount = await sendViaAPP({
			people: peopleToSend,
			message,
			subject,
			user,
			customerId: selectedCustomer.id
		})
	}

	setMessageCount(messagesCount)
	setSuccessMessage(true)
	setIsLoading(false)
}

async function sendViaSMS({ people, message, customerId, customerName, siteId, siteName, user }) {
	const phoneNumbers = people.map(val => val.personalData?.phoneNumber).filter(val => val)
	// const testPhoneNumbers = phoneNumbers.filter(val => val === "+351 935645520")
	const uniquePhoneNumbers = phoneNumbers.filter(
		(item, index, array) => array.indexOf(item) === index
	)

	var promises = []

	uniquePhoneNumbers.forEach(phoneNumber => {
		const docData = {
			from: process.env.REACT_APP_TWILLIO_FROM_NUMBER,
			to: phoneNumber || phoneNumber,
			body: message,
			senderInformation: {
				customerId: customerId,
				customerName: customerName,
				siteId: siteId,
				siteName: siteName || null,
				userId: user.id,
				userEmail: user.email,
				date: serverTimestamp() // Use serverTimestamp to set the current date and time
			}
		}

		try {
			promises.push(addDoc(collection(firestore, "TwilioMessages"), docData))
		} catch (e) {
			console.error("Send message error: ", e)
		}
	})

	await Promise.all(promises)
	return promises.length
}

async function sendViaAPP({ people, message, subject, user, customerId }) {
	const userIDs = people.map(val => val.UID).filter(val => val)
	// const testUserIDs = userIDs.filter(val => val === "DGPRgYya8mcwPKakvoD9ECrZO3K2")
	const uniqueUserIDs = userIDs.filter((item, index, array) => array.indexOf(item) === index)

	var promises = []

	uniqueUserIDs.forEach(UID => {
		var myHeaders = new Headers()
		myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
		myHeaders.append("Content-Type", "application/json")
		var raw = JSON.stringify({
			topic: UID + "-messages",
			title: subject,
			body: message,
			sentFrom: "crowdkeep_broadcast",
			sentById: user.id,
			sentByEmail: user.email,
			to: "",
			toEmail: "",
			customerId: customerId
		})
		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		}
		promises.push(
			fetch(`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/send-notification`, requestOptions)
		)
	})

	await Promise.all(promises)
	return promises.length
}
