import React from "react"

import inventoryIcon from "../../../../../app/assets/ck-icons/Inventory-icon.svg"

import "../Inventory.css"

const InventoryHeader = () => {
	return (
		<>
			<div
				className="d-flex align-items-center justify-content-between mb-4"
				style={{ width: "100%", height: "4rem" }}
				data-testid="InventoryHeader"
			>
				<div className="d-flex align-items-center">
					<div
						style={{
							width: "26px",
							height: "26px",
							marginRight: "12px",
							marginBottom: "10px"
						}}
					>
						<img
							alt="people"
							src={inventoryIcon}
							style={{ width: "100%", height: "100%", objectFit: "contain" }}
						/>
					</div>

					<h2 style={{ color: "#4A4A68" }}>
						<b>Inventory</b>
					</h2>
				</div>
			</div>
		</>
	)
}

export default InventoryHeader
