import React from "react"

const AuditToolTitle = () => {
	return (
		<>
			<div
				className="d-flex align-items-center justify-content-between mb-4"
				style={{ width: "100%", height: "4rem" }}
				data-testid="main-div-AuditToolTitle"
			>
				<div className="d-flex align-items-center" data-testid="inner-div-AuditToolTitle">
					<img
						alt="Audit"
						src="/icons/audit tool title.svg"
						style={{}}
						data-testid="AuditToolTitle-img"
					/>

					<h2 style={{ color: "#4A4A68", margin: "0 1rem" }} data-testid="AuditToolTitle-h2">
						<b>Audit Tool</b>
					</h2>
				</div>
			</div>
		</>
	)
}

export default AuditToolTitle
