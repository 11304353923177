import {
	firestoreOld as db
	//firebaseAppOld as firebase,
} from "../../../../../../firebase"

const sitesRef = db.collection("Sites")

// export function getFloorPlans(customerId) {
//   if (!customerId) {
//     return Promise.resolve(null);
//   }

//   return customersRef
//     .doc(customerId.customerId)
//     .collection(`FloorPlans`)
//     .get();
// }

export function saveRoutesGeoJsonNewConnectorPosition(queryParams) {
	// console.log(queryParams, "queryParams CRUD");
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return Promise.resolve(
		sitesRef.doc(`${queryParams.networkId}/FloorPlans/${queryParams.floorPlanId}`).update({
			geoJsonRoutes: queryParams.geoJsonRoutes
		})
	)
}

export function saveRoutesGeoJson(queryParams) {
	// console.log(queryParams, "queryParams CRUD");
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return Promise.resolve(
		sitesRef.doc(`${queryParams.networkId}/FloorPlans/${queryParams.floorPlanId}`).update({
			geoJsonRoutes: queryParams.geoJsonRoutes
		})
	)
}

export function deleteRoutes(queryParams) {
	// console.log(queryParams, "queryParams CRUD");
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return Promise.resolve(
		sitesRef.doc(`${queryParams.networkId}/FloorPlans/${queryParams.floorPlanId}`).update({
			geoJsonRoutes: queryParams.geoJsonRoutes
		})
	)

	// old code
	// if (queryParams.zoneToDelete.type === "baseLayer") {
	//   return Promise.resolve(
	//     customersRef
	//       .doc(`${queryParams.customerId}/FloorPlans/${queryParams.floorPlanId}`)
	//       .update({
	//         geoJson: queryParams.geoJson,
	//       })
	//   );
	// }

	// return Promise.all([
	//   customersRef
	//     .doc(`${queryParams.customerId}`)
	//     .collection("Zones")
	//     .doc(queryParams.zoneToDelete.geoJson.properties.zoneId)
	//     .delete(),
	//   customersRef
	//     .doc(`${queryParams.customerId}/FloorPlans/${queryParams.floorPlanId}`)
	//     .update({
	//       geoJson: queryParams.geoJson,
	//     }),
	// ]);
}
