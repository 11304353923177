// helper function to search the data
export const filterBySearchString = (arrayToSearch, searchString, fieldsToSearch) => {
	if (
		!searchString ||
		searchString.trim() === "" ||
		!Array.isArray(fieldsToSearch) ||
		fieldsToSearch.length === 0
	) {
		return arrayToSearch
	}

	const searchText = searchString.trimStart().toLowerCase()

	return arrayToSearch.filter(row => {
		return fieldsToSearch.some(field => {
			// Split the field string to access nested properties
			const fieldParts = field.split(".")
			let fieldValue = row

			for (const part of fieldParts) {
				fieldValue = fieldValue[part]
				if (fieldValue === undefined || fieldValue === null) {
					break
				}
			}

			if (typeof fieldValue === "string") {
				return fieldValue.toLowerCase().includes(searchText)
			}
			return false
		})
	})
}
