import React from "react"
import ReactDOM from "react-dom"
import { IconButton } from "@material-ui/core"
import { GrFilter } from "react-icons/gr"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers"
import SearchIcon from "@material-ui/icons/Search"
import "./DrawerOptions.css"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import FilterGeneralInfo from "./drawer-options/FilterGeneralInfo"
import MarkersSizeSlider from "./drawer-options/MarkersSizeSlider"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import NewSearch from "./drawer-options/NewSearch"
import DirectionsOutlinedIcon from "@material-ui/icons/DirectionsOutlined"
import NewDirectionsControl from "./drawer-options/NewDirectionsControl"
import { MdOutlineDirectionsOff } from "react-icons/md"
import { FiLayers } from "react-icons/fi"
import OccupancyFilter from "./drawer-options/OccupancyFilter"
import LayersView from "./drawer-options/LayersView"
import HeatmapFilter from "./drawer-options/HeatmapFilter"

export default class DrawerOptions {
	constructor(props) {
		this.props = props
	}

	onAdd(map) {
		this.map = map
		this.container = document.createElement("div")
		this.container.className = "mapboxgl-ctrl"
		// this.container.id = "drawerOptions_main_container";
		// this.container.style.cursor = "pointer";

		// console.log("🚀🚀🚀 all _ this.props: ", this.props)
		// console.log("🎃🎃🎃  openDrawerBar:", this.props.openDrawerBar)
		// console.log("🚀🚀🚀  drawerOptions:", this.props.drawerOptions)
		// console.log("👾👾👾  openFilter:", this.props.drawerOptions.openFilter);
		// console.log("🚀  showAreaInfoMarkers:", this.props.showAreaInfoMarkers);

		// Note: everytime the seach bar is closed, if we have done a search, we remove the green marker on the searched option and reset the goTo state
		if (!this.props.drawerOptions.openSearch) {
			this.props.setGoTo() // reset state
			// ↓ remove green layer/marker
			typeof map.getLayer("goToThingMarker") !== "undefined" &&
				map.removeLayer("goToThingMarker")
			typeof map.getSource("goToThingMarker") !== "undefined" &&
				map.removeSource("goToThingMarker")
		}

		// Note: If the user changes the floorplan and has a search active with a green marker/layer we remove it and reset state
		if (this.props.goTo && this.props.goTo?.floorPlanId !== this.props.floorPlan?.id) {
			this.props.setGoTo() // reset state
			// ↓ remove green layer/marker if the user searched for something and then changed the floorplan
			typeof map.getLayer("goToThingMarker") !== "undefined" &&
				map.removeLayer("goToThingMarker")
			typeof map.getSource("goToThingMarker") !== "undefined" &&
				map.removeSource("goToThingMarker")
		}

		const icon = this.props.openDrawerBar ? (
			<ExpandLessIcon fontSize="default" style={{ color: "#2C3E50" }} />
		) : (
			<ExpandMoreIcon fontSize="default" style={{ color: "#2C3E50" }} />
		)

		this.content = (
			<div
				className="container_drawer_bar_content"
				style={{ display: !this.props.selectedSite ? "none" : "flex" }} //  ━━━━ Hide if !selectedSite
			>
				{/* // ━━━━━━━━━━━━━━━━━━━ Components drawer options  ━━━━━━━━━━━━━━━━━━━━━━ \\  */}
				{/* // ━━━━ ↓ Search component */}
				{this.props.drawerOptions.openSearch && (
					<div className="search_wrapper">
						<NewSearch
							drawerOptions={this.props.drawerOptions}
							setDrawerOptions={this.props.setDrawerOptions}
							selectedSite={this.props.selectedSite}
							map={this.props.map}
							dispatch={this.props.dispatch}
							people={this.props.people}
							assets={this.props.assets}
							tags={this.props.tags}
							floorPlans={this.props.floorPlans}
							floorPlan={this.props.floorPlan}
							userEmail={this.props.userEmail}
							areas={this.props.areas}
							pois={this.props.pois}
							goTo={this.props.goTo}
							setGoTo={this.props.setGoTo}
							searchFilteredOptions={this.props.searchFilteredOptions}
							setSearchFilteredOptions={this.props.setSearchFilteredOptions}
							setShowPeople={this.props.setShowPeople}
							setShowAssets={this.props.setShowAssets}
							setShowPOIs={this.props.setShowPOIs}
							setShowAreaInfoMarkers={this.props.setShowAreaInfoMarkers}
							searchedPersonOrAssetOld={this.props.searchedPersonOrAssetOld}
							setSearchedPersonOrAssetOld={this.props.setSearchedPersonOrAssetOld}
							showRoleAndTypeFunction={this.props.showRoleAndTypeFunction}
							markersSize={this.props.markersSize}
							setTypingSearch={this.props.setTypingSearch}
							setNewSearchedTag={this.props.setNewSearchedTag}
							access={this.props.access}
						/>
					</div>
				)}
				{/* // ━━━━ ↓ Filter Info component */}
				{this.props.openDrawerBar && this.props.drawerOptions.openFilter && (
					<div className="filter_info_wrapper">
						<FilterGeneralInfo
							setDrawerOptions={this.props.setDrawerOptions}
							showAreaInfoMarkers={this.props.showAreaInfoMarkers}
							setShowAreaInfoMarkers={this.props.setShowAreaInfoMarkers}
							showPeople={this.props.showPeople}
							setShowPeople={this.props.setShowPeople}
							showAssets={this.props.showAssets}
							setShowAssets={this.props.setShowAssets}
							showUnassignedTags={this.props.showUnassignedTags}
							setShowUnassignedTags={this.props.setShowUnassignedTags}
							showPOIs={this.props.showPOIs}
							setShowPOIs={this.props.setShowPOIs}
							showConnectors={this.props.showConnectors}
							setShowConnectors={this.props.setShowConnectors}
							user={this.props.user}
							dispatch={this.props.dispatch}
							drawerPreferences={this.props.drawerPreferences}
							showAnchors={this.props.showAnchors}
							setShowAnchors={this.props.setShowAnchors}
							showGateways={this.props.showGateways}
							setShowGateways={this.props.setShowGateways}
							roles={this.props.roles}
							roleFilterButtonStates={this.props.roleFilterButtonStates}
							setRoleFilterButtonStates={this.props.setRoleFilterButtonStates}
							assets={this.props.assets}
							assetFilterButtonStates={this.props.assetFilterButtonStates}
							setAssetFilterButtonStates={this.props.setAssetFilterButtonStates}
							differentAssetTypes={this.props.differentAssetTypes}
							setDifferentAssetTypes={this.props.setDifferentAssetTypes}
						/>
					</div>
				)}
				{/* // ━━━━ ↓ Path / Routes component */}
				{this.props.openDrawerBar && this.props.drawerOptions.openPath && (
					<div className="path_drawer_wrapper">
						<NewDirectionsControl
							setDrawerOptions={this.props.setDrawerOptions}
							selectedSite={this.props.selectedSite}
							zones={this.props.areas}
							getDirections={this.props.getDirections}
							pois={this.props.pois}
							floorPlanData={this.props.floorPlans}
							floorPlan={this.props.floorPlan}
							people={this.props.people}
							assets={this.props.assets}
							tags={this.props.tags}
							setGetDirections={this.props.setGetDirections}
							geoJsonRoutes={this.props.geoJsonRoutes}
							setPathFound={this.props.setPathFound}
							setConnectorAccess={this.props.setConnectorAccess}
							iconTab={this.props.iconTab}
							setIconTab={this.props.setIconTab}
							typeByDefault={this.props.typeByDefault}
							setTypeByDefault={this.props.setTypeByDefault}
							setCopyOfConnectorCoords={this.props.setCopyOfConnectorCoords}
							setPoiToDoorCoords={this.props.setPoiToDoorCoords}
							setPoiToDoorEndPointCoords={this.props.setPoiToDoorEndPointCoords}
							map={this.props.map}
							dispatch={this.props.dispatch}
							userEmail={this.props.userEmail}
							setShowPathOnMap={this.props.setShowPathOnMap}
							// pathFound={this.props.pathFound}
							endingPoint={this.props.endingPoint}
							setEndingPoint={this.props.setEndingPoint}
							startingPoint={this.props.startingPoint}
							setStartingPoint={this.props.setStartingPoint}
							geoJsonRoutesUndefinedSnackBar={this.props.geoJsonRoutesUndefinedSnackBar}
							selectedConnector={this.props.selectedConnector}
							setSelectedConnector={this.props.setSelectedConnector}
							copyOfConnectorCoords={this.props.copyOfConnectorCoords}
							setCopyOfStartPointCoords={this.props.setCopyOfStartPointCoords}
							setShowPeople={this.props.setShowPeople}
							setShowAssets={this.props.setShowAssets}
							setShowPOIs={this.props.setShowPOIs}
							setShowAreaInfoMarkers={this.props.setShowAreaInfoMarkers}
							setShowConnectors={this.props.setShowConnectors}
						/>
					</div>
				)}
				{/* // ━━━━ ↓ Resize Info component */}
				{this.props.openDrawerBar && this.props.drawerOptions.openSlider && (
					<div className="resize_info_wrapper">
						<MarkersSizeSlider
							setDrawerOptions={this.props.setDrawerOptions}
							user={this.props.user}
							dispatch={this.props.dispatch}
							drawerPreferences={this.props.drawerPreferences}
							markersSize={this.props.markersSize}
							setMarkersSize={this.props.setMarkersSize}
							showConnectors={this.props.showConnectors}
							showPOIs={this.props.showPOIs}
							showPeople={this.props.showPeople}
							showAssets={this.props.showAssets}
							showUnassignedTags={this.props.showUnassignedTags}
							showAreaInfoMarkers={this.props.showAreaInfoMarkers}
							showAnchors={this.props.showAnchors}
							showGateways={this.props.showGateways}
							roleFilterButtonStates={this.props.roleFilterButtonStates}
							assetFilterButtonStates={this.props.assetFilterButtonStates}
						/>
					</div>
				)}
				{/* // ━━━━ ↓ Heatmap filter component */}
				{this.props.openDrawerBar && this.props.drawerOptions.openHeatmap && (
					<div className="heatmap_filter_wrapper">
						<HeatmapFilter
							setDrawerOptions={this.props.setDrawerOptions}
							heatmapFilter={this.props.heatmapFilter}
							setHeatmapFilter={this.props.setHeatmapFilter}
						/>
					</div>
				)}
				{/* // ━━━━ ↓ Occupancy component */}
				{this.props.openDrawerBar && this.props.drawerOptions.openOccupancy && (
					<div className="occupancy_filter_wrapper">
						<OccupancyFilter
							setDrawerOptions={this.props.setDrawerOptions}
							showTags={this.props.showTags}
							setShowTags={this.props.setShowTags}
							people={this.props.people}
							tags={this.props.tags}
							showRoles={this.props.showRoles}
							setShowRoles={this.props.setShowRoles}
							handleClickRolesButton={this.props.handleClickRolesButton}
							roles={this.props.roles}
							roleFilterButtonStates={this.props.roleFilterButtonStates}
							setRoleFilterButtonStates={this.props.setRoleFilterButtonStates}
							user={this.props.user}
							dispatch={this.props.dispatch}
							drawerPreferences={this.props.drawerPreferences}
						/>
					</div>
				)}

				{/* // ━━━━ ↓ Satellite view / Layers component */}
				{/* //! Commented this because it's not working [needs to updated mapbox-gl package]*/}
				{/* {this.props.openDrawerBar && this.props.drawerOptions.openSatellite && (
					<div className="layers_view_wrapper">
						<LayersView
							setDrawerOptions={this.props.setDrawerOptions}
							changeMapStyle={this.props.changeMapStyle}
							dispatch={this.props.dispatch}
							mapStyleView={this.props.mapStyleView}
							setMapStyleView={this.props.setMapStyleView}
						/>
					</div>
				)} */}

				{/* // ━━━━━━━━━━━━━━━━━━━ Drawer Bar ━━━━━━━━━━━━━━━━━━━━━━ \\  */}
				<div
					className={
						this.props.siteFinished
							? "wrapper_drawer_bar_content_disabled"
							: "wrapper_drawer_bar_content"
					}
				>
					{/* // ━━━━━━ Option - Search filter */}
					{/* ↓ If openSearch is opened, it closes the overlayTrigger */}
					{!this.props.drawerOptions.openSearch ? (
						<OverlayTrigger
							trigger={["hover", "focus"]}
							placement="left"
							overlay={
								<Tooltip
									style={{ display: this.props.siteFinished ? "none" : "block" }}
									className="drawer_tooltips"
								>
									Search
								</Tooltip>
							}
						>
							<div className="drawer_icon">
								<IconButton
									disabled={this.props.siteFinished}
									className="livemap_drawer_open_search"
									style={{
										background: this.props.drawerOptions.openSearch ? "#DDE9FD" : null,
										padding: "8px",
										fontSize: "12px"
									}}
									onClick={() => {
										this.props.setOpenDrawerBar(true)
										this.props.setDrawerOptions(prevState => ({
											// ...prevState,
											openSearch: !prevState.openSearch,
											openFilter: false,
											openPath: false,
											openOccupancy: false,
											openSatellite: false,
											open3d: false
										}))
									}}
								>
									<SearchIcon
										style={{
											fontSize: "20px",
											color: this.props.drawerOptions.openSearch ? "#0277DA" : "#2C3E50"
										}}
									/>
								</IconButton>
							</div>
						</OverlayTrigger>
					) : (
						<div className="drawer_icon">
							<IconButton
								className="livemap_drawer_open_search"
								style={{
									background: this.props.drawerOptions.openSearch ? "#DDE9FD" : null,
									padding: "8px",
									fontSize: "12px"
								}}
								onClick={() => {
									this.props.setOpenDrawerBar(true)
									this.props.setDrawerOptions(prevState => ({
										// ...prevState,
										openSearch: !prevState.openSearch,
										openFilter: false,
										openPath: false,
										openOccupancy: false,
										openSatellite: false,
										open3d: false
									}))
								}}
							>
								<SearchIcon
									style={{
										fontSize: "20px",
										color: this.props.drawerOptions.openSearch ? "#0277DA" : "#2C3E50"
									}}
								/>
							</IconButton>
						</div>
					)}

					<div
						id="drawer_class"
						className={this.props.openDrawerBar ? "expanded_drawer" : "collapsed_drawer"}
					>
						<div>
							{/* // ━━━━━━ Option - Filter General Info */}
							{!this.props.drawerOptions.openFilter ? (
								<OverlayTrigger
									trigger={["hover", "focus"]}
									placement="left"
									overlay={<Tooltip className="drawer_tooltips">Filter</Tooltip>}
								>
									<div className="drawer_icon">
										<IconButton
											className="livemap_drawer_open_general_filter"
											style={{
												background: this.props.drawerOptions.openFilter
													? "#DDE9FD"
													: null,
												padding: "10px",
												fontSize: "12px"
											}}
											onClick={() => {
												this.props.setDrawerOptions(prevState => ({
													openFilter: !prevState.openFilter,
													openSearch: false,
													openPath: false,
													openOccupancy: false,
													openSatellite: false,
													open3d: false
												}))
											}}
										>
											<GrFilter
												style={{
													fontSize: "16px",
													filter: this.props.drawerOptions.openFilter
														? "invert(37%) sepia(95%) saturate(1594%) hue-rotate(187deg) brightness(83%) contrast(108%)"
														: "invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
												}}
											/>
										</IconButton>
									</div>
								</OverlayTrigger>
							) : (
								<div className="drawer_icon">
									<IconButton
										className="livemap_drawer_open_general_filter"
										style={{
											background: this.props.drawerOptions.openFilter ? "#DDE9FD" : null,
											padding: "10px",
											fontSize: "12px"
										}}
										onClick={() => {
											this.props.setDrawerOptions(prevState => ({
												openFilter: !prevState.openFilter,
												openSearch: false,
												openPath: false,
												openOccupancy: false,
												openSatellite: false,
												open3d: false
											}))
										}}
									>
										<GrFilter
											style={{
												fontSize: "16px",
												filter: this.props.drawerOptions.openFilter
													? "invert(37%) sepia(95%) saturate(1594%) hue-rotate(187deg) brightness(83%) contrast(108%)"
													: "invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
											}}
										/>
									</IconButton>
								</div>
							)}

							{/* // ━━━━━━ Option - Path / Routes */}
							{!this.props.drawerOptions.openPath ? (
								<OverlayTrigger
									trigger={["hover", "focus"]}
									placement="left"
									overlay={
										<Tooltip className="drawer_tooltips">
											{!this.props.showPathOnMap && !this.props.pathFound
												? "Path"
												: "Clear Path"}{" "}
										</Tooltip>
									}
								>
									<div className="drawer_icon">
										{!this.props.showPathOnMap && !this.props.pathFound ? (
											<IconButton
												className="livemap_drawer_open_indoor_nav"
												style={{
													background: this.props.drawerOptions.openPath
														? "#DDE9FD"
														: null,
													padding: "10px",
													fontSize: "12px"
												}}
												onClick={() => {
													this.props.setDrawerOptions(prevState => ({
														openFilter: false,
														openSearch: false,
														openPath: !prevState.openPath,
														openOccupancy: false,
														openSatellite: false,
														open3d: false
													}))
												}}
											>
												<DirectionsOutlinedIcon
													style={{
														fontSize: "19px",
														color: this.props.drawerOptions.openPath
															? "#0277DA"
															: "#2C3E50"
													}}
												/>
											</IconButton>
										) : (
											<IconButton
												className="livemap_drawer_open_indoor_nav"
												style={{
													background: "#FBE6DE",
													padding: "10px",
													fontSize: "12px"
												}}
												onClick={() => {
													this.props.resetPathButton()
													this.props.setShowPathOnMap(false)
												}}
											>
												<MdOutlineDirectionsOff
													style={{
														fontSize: "19px",
														filter:
															"invert(38%) sepia(92%) saturate(3356%) hue-rotate(356deg) brightness(96%) contrast(87%)"
													}}
												/>
											</IconButton>
										)}
									</div>
								</OverlayTrigger>
							) : (
								<div className="drawer_icon">
									<IconButton
										style={{
											background: this.props.drawerOptions.openPath ? "#DDE9FD" : null,
											padding: "10px",
											fontSize: "12px"
										}}
										onClick={() => {
											this.props.setDrawerOptions(prevState => ({
												openFilter: false,
												openSearch: false,
												openPath: !prevState.openPath,
												openOccupancy: false,
												openSatellite: false,
												open3d: false
											}))
										}}
									>
										<DirectionsOutlinedIcon
											style={{
												fontSize: "19px",
												color: this.props.drawerOptions.openPath ? "#0277DA" : "#2C3E50"
											}}
										/>
									</IconButton>
								</div>
							)}

							{/* // ━━━━━━ Slider - Markers Size Control*/}
							{!this.props.drawerOptions.openSlider ? (
								<OverlayTrigger
									trigger={["hover", "focus"]}
									placement="left"
									overlay={<Tooltip className="drawer_tooltips">Resize markers</Tooltip>}
								>
									<div className="drawer_icon">
										<IconButton
											className="livemap_drawer_open_general_filter"
											style={{
												background: this.props.drawerOptions.openSlider
													? "#DDE9FD"
													: null,
												padding: "10px",
												fontSize: "10px"
											}}
											onClick={() => {
												this.props.setDrawerOptions(prevState => ({
													openFilter: false,
													openSearch: false,
													openPath: false,
													openOccupancy: false,
													openSatellite: false,
													openSlider: !prevState.openSlider,
													open3d: false
												}))
											}}
										>
											<SVG
												src={toAbsoluteUrl("/media/crowdkeep/Resize.svg")}
												style={{
													fontSize: "10px",
													width: "18px",
													height: "18px"
												}}
											/>
										</IconButton>
									</div>
								</OverlayTrigger>
							) : (
								<div className="drawer_icon">
									<IconButton
										className="livemap_drawer_open_general_filter"
										style={{
											background: this.props.drawerOptions.openSlider ? "#DDE9FD" : null,
											padding: "10px",
											fontSize: "10px"
										}}
										onClick={() => {
											this.props.setDrawerOptions(prevState => ({
												openFilter: false,
												openSearch: false,
												openPath: false,
												openOccupancy: false,
												openSatellite: false,
												openSlider: !prevState.openSlider,
												open3d: false
											}))
										}}
									>
										<SVG
											src={toAbsoluteUrl("/media/crowdkeep/Resize.svg")}
											style={{
												fontSize: "10px",
												width: "18px",
												height: "18px",
												filter: this.props.drawerOptions.openSlider
													? "invert(37%) sepia(95%) saturate(1594%) hue-rotate(187deg) brightness(83%) contrast(108%)"
													: "invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
											}}
										/>
									</IconButton>
								</div>
							)}

							{/* // ━━━━━━ Heatmap ━━━━━━ // */}
							{!this.props.drawerOptions.openHeatmap ? (
								<OverlayTrigger
									trigger={["hover", "focus"]}
									placement="left"
									overlay={<Tooltip className="drawer_tooltips">Heatmap</Tooltip>}
								>
									<div className="drawer_icon">
										<IconButton
											className="livemap_drawer_open_general_filter"
											style={{
												background: this.props.drawerOptions.openHeatmap
													? "#DDE9FD"
													: null,
												padding: "10px",
												fontSize: "10px"
											}}
											onClick={() => {
												this.props.setDrawerOptions(prevState => ({
													openFilter: false,
													openSearch: false,
													openPath: false,
													openOccupancy: false,
													openSatellite: false,
													openSlider: false,
													open3d: false,
													openHeatmap: !prevState.openHeatmap
												}))
											}}
										>
											<SVG
												src={toAbsoluteUrl("/media/crowdkeep/Heatmap.svg")}
												style={{
													fontSize: "10px",
													width: "18px",
													height: "18px"
												}}
											/>
										</IconButton>
									</div>
								</OverlayTrigger>
							) : (
								<div className="drawer_icon">
									<IconButton
										className="livemap_drawer_open_general_filter"
										style={{
											background: this.props.drawerOptions.openHeatmap
												? "#DDE9FD"
												: null,
											padding: "10px",
											fontSize: "10px"
										}}
										onClick={() => {
											this.props.setDrawerOptions(prevState => ({
												openFilter: false,
												openSearch: false,
												openPath: false,
												openOccupancy: false,
												openSatellite: false,
												openSlider: false,
												open3d: false,
												openHeatmap: !prevState.openHeatmap
											}))
										}}
									>
										<SVG
											src={toAbsoluteUrl("/media/crowdkeep/Heatmap.svg")}
											style={{
												fontSize: "10px",
												width: "18px",
												height: "18px",
												filter: this.props.drawerOptions.openHeatmap
													? "invert(37%) sepia(95%) saturate(1594%) hue-rotate(187deg) brightness(83%) contrast(108%)"
													: "invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
											}}
										/>
									</IconButton>
								</div>
							)}
							{/* // ━━━━━━ Option - Occupancy filter */}
							{/* {!this.props.drawerOptions.openOccupancy ? (
								<OverlayTrigger
								trigger={['hover', 'focus']}
									placement="left"
									overlay={<Tooltip className="drawer_tooltips">Occupancy</Tooltip>}
								>
									<div className="drawer_icon">
										<IconButton
											style={{
												background: this.props.drawerOptions.openOccupancy
													? "#DDE9FD"
													: null,
												padding: "10px",
												fontSize: "12px"
											}}
											onClick={() => {
												this.props.setDrawerOptions(prevState => ({
													openSearch: false,
													openFilter: false,
													openPath: false,
													openOccupancy: !prevState.openOccupancy,
													openSatellite: false
												}))
											}}
										>
											<GrGroup
												style={{
													fontSize: "16px",
													filter: this.props.drawerOptions.openOccupancy
														? "invert(37%) sepia(95%) saturate(1594%) hue-rotate(187deg) brightness(83%) contrast(108%)"
														: "invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
												}}
											/>
										</IconButton>
									</div>
								</OverlayTrigger>
							) : (
								<div className="drawer_icon">
									<IconButton
										style={{
											background: this.props.drawerOptions.openOccupancy
												? "#DDE9FD"
												: null,
											padding: "10px",
											fontSize: "12px"
										}}
										onClick={() => {
											this.props.setDrawerOptions(prevState => ({
												openSearch: false,
												openFilter: false,
												openPath: false,
												openOccupancy: !prevState.openOccupancy,
												openSatellite: false
											}))
										}}
									>
										<GrGroup
											style={{
												fontSize: "16px",
												filter: this.props.drawerOptions.openOccupancy
													? "invert(37%) sepia(95%) saturate(1594%) hue-rotate(187deg) brightness(83%) contrast(108%)"
													: "invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
											}}
										/>
									</IconButton>
								</div>
							)} */}
						</div>

						{/* //! Commented this because it's not working [needs to updated mapbox-gl package]*/}
						{/* <div> */}
						{/* // ━━━━━━ Option - Satellite view */}
						{/* {!this.props.drawerOptions.openSatellite ? (
								<OverlayTrigger
									trigger={["hover", "focus"]}
									placement="left"
									overlay={<Tooltip className="drawer_tooltips">Layers</Tooltip>}
								>
									<div className="drawer_icon">
										<IconButton
											className="livemap_drawer_open_satellite_view"
											style={{
												background: this.props.drawerOptions.openSatellite
													? "#DDE9FD"
													: null,
												padding: "10px",
												fontSize: "12px"
											}}
											onClick={() => {
												this.props.setDrawerOptions(prevState => ({
													openSearch: false,
													openFilter: false,
													openPath: false,
													openOccupancy: false,
													openSatellite: !prevState.openSatellite
												}))
											}}
										>
											<FiLayers
												style={{
													fontSize: "16px",
													color: this.props.drawerOptions.openSatellite
														? "#0277DA"
														: "#2C3E50"
												}}
											/>
										</IconButton>
									</div>
								</OverlayTrigger>
							) : (
								<div className="drawer_icon">
									<IconButton
										className="livemap_drawer_open_satellite_view"
										style={{
											background: this.props.drawerOptions.openSatellite
												? "#DDE9FD"
												: null,
											padding: "10px",
											fontSize: "12px"
										}}
										onClick={() => {
											this.props.setDrawerOptions(prevState => ({
												openSearch: false,
												openFilter: false,
												openPath: false,
												openOccupancy: false,
												openSatellite: !prevState.openSatellite
											}))
										}}
									>
										<FiLayers
											style={{
												fontSize: "16px",
												color: this.props.drawerOptions.openSatellite
													? "#0277DA"
													: "#2C3E50"
											}}
										/>
									</IconButton>
								</div>
							)} */}
						{/* </div> */}
					</div>
					{/* ━━━━━━ Open/Close Drawer  */}
					<div
						className={
							this.props.siteFinished
								? "expand_button_drawer_disabled"
								: "expand_button_drawer"
						}
						onClick={() => {
							// Close all options when drawer bar closes
							this.props.setDrawerOptions({
								openSearch: false,
								openFilter: false,
								openPath: false,
								openOccupancy: false,
								openSatellite: false
							})

							this.props.setOpenDrawerBar(prev => !prev) // Close drawer bar
						}}
					>
						<div style={{ width: "42px", background: "#BDC3C7", height: "1px" }} />
						{icon}
					</div>
				</div>
			</div>
		)

		ReactDOM.render(this.content, this.container)
		return this.container
	}

	onRemove() {
		this.container.parentNode && this.container.parentNode.removeChild(this.container)
		this.map = undefined
	}
}
