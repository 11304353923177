export const CustomerStatusCssClasses = ["danger", "success", "info", ""]
export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""]
export const CustomerTypeCssClasses = ["success", "primary", ""]
export const CustomerTypeTitles = ["Business", "Individual", ""]
export const defaultSorted = [{ dataField: "id", order: "asc" }]
export const sizePerPageList = [
	{ text: "5", value: 5 },
	{ text: "20", value: 20 },
	{ text: "50", value: 50 },
	{ text: "100", value: 100 },
	{ text: "300", value: 300 }
]
export const initialFilter = {
	filter: {
		searchText: "",
		searchField: ""
	},
	sortOrder: "asc", // asc||desc
	sortField: "displayName",
	pageNumber: 1,
	pageSize: 50,
	pageToken: null
}

export const getAvg = array => {
	if (!array) {
		return null
	} else {
		const total = array.reduce((acc, c) => acc + c, 0)
		const average = total / array.length
		return average
	}
}

export const arraySumAllItems = array => {
	let result = array.reduce((a, b) => a + b, 0)
	return result
}

export const arrayPercentage = (summedArray, array) => {
	let totalValue = summedArray / array.length
	return totalValue
}

export const arrayPercentageByObjectKey = array => {
	let quartersum = {}
	const getKeys = array.map(function (entry) {
		quartersum[entry.label] = (quartersum[entry.label] || 0) + entry.y
		return getKeys
	})

	const getPercentage = array.map(function (entry) {
		entry.y = ((entry.y / quartersum[entry.label]) * 100).toFixed(1)
		return entry
	})
	let allWeekPercentage = [
		{
			data: getPercentage
		}
	]
	return allWeekPercentage
}

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

// Sum objects with same floorPlans and do average
export const sumFloorsAndAverage = array => {
	let holderOne = []
	array.forEach(function (d) {
		if (holderOne.some(val => val.floorPlans === d.floorPlans)) {
			const index = holderOne.findIndex(val => val.floorPlans === d.floorPlans)

			holderOne[index] = {
				...holderOne[index],
				data: holderOne[index].data + d.data,
				counter: holderOne[index].counter + 1
			}
		} else {
			holderOne.push({
				floorPlans: d.floorPlans,
				data: d.data,
				counter: 1
			})
		}
	})
	return holderOne.map((val, i) => {
		return {
			floorPlans: val.floorPlans,
			value: Math.round(val.data / val.counter)
		}
	})
}

// remove all duplicates from array
export const arrayWithoutDuplicates = array => {
	return array.reduce((filtered, item) => {
		if (!filtered.some(filteredItem => JSON.stringify(filteredItem) === JSON.stringify(item)))
			filtered.push(item)
		return filtered
	}, [])
}

// Filter for unique values (ex: arr.name)
export const uniqValues = arr => {
	const uniq = {}
	const arrFiltered = arr.filter(obj => !uniq[obj.name] && (uniq[obj.name] = true))
	// console.log("arrFiltered", arrFiltered);
	return arrFiltered
}

// insert total and val to find percentage
export const getPercentageFromTotal = (total, val) => {
	const result = (val * 100) / total
	// console.log("🚀 ~ result", result.toFixed(1));
	return Number(result.toFixed(1))
}

// insert price or total and percentage value and find the total without the percentage
export const percentageOff = (price, percentageValue) => {
	var num = parseFloat(price)
	var val = (num * percentageValue) / 100
	return Number(val.toFixed(1))
}

// find duplicates with same id or object.source in this example and sum if object.source is the same
//    const endresult = Object.values(
//   ARRAY.reduce((value, object) => {
//     if (value[object.source]) {
//       value[object.source].amount += object.value;
//     } else {
//       value[object.source] = { ...object };
//     }
//     return value;
//   }, {})
// );

export const rootStylesTimesheet = {
	root: {
		display: "flex",
		border: "none",
		"& .MuiPagination-root makeStyles-root-130": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		"& .MuiDataGrid-cell:focus": {
			outline: "none"
		},
		"& .MuiDataGrid-selectedRowCount": {
			display: "none"
		},
		"& .MuiDataGrid-colCell": {
			minWidth: "0" // Ensure that minWidth is set to 0 to allow percentage width
		},
		"& .MuiDataGrid-columnSeparator": {
			opacity: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
			fontWeight: 600,
			fontSize: "1rem",
			color: "#4a4a68"
		},
		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--textLeft": {
			fontSize: "14px",
			fontWeight: 500
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			paddingLeft: 0,
			paddingRight: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
			paddingRight: "0px",
			paddingLeft: "10px"
		},
		// "@media screen and (min-width: 1000px)": {
		// 	"& .MuiDataGrid-window": {
		// 		overflowX: "hidden"
		// 	}
		// },
		"& .hide-scrollbar .MuiDataGrid-scrollbar": {
			display: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
			paddingLeft: "2px"
		},
		"& .Mui-even .MuiDataGrid-row:hover, & .MuiDataGrid-root .MuiDataGrid-row:hover": {
			backgroundColor: "white !important"
		},

		"& .MuiDataGrid-row": {
			maxHeight: "65px !important",
			minHeight: "65px !important"
		},
		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
			maxHeight: "65px !important",
			minHeight: "65px !important"
		},
		"& .MuiPagination-root": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		"& .MuiPaginationItem-root": {
			borderRadius: "3px !important",
			color: "#3077d3 !important",
			padding: "0 !important",
			margin: "0 !important",
			border: "1px solid #f4f8fe !important"
		},
		"& .MuiPaginationItem-textPrimary.Mui-selected": {
			color: "#fff !important",
			border: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon, & .MuiDataGrid-root .MuiDataGrid-filterIcon, & .MuiDataGrid-root .MuiDataGrid-sortIcon":
			{
				color: "#3077d3"
			},
		"& .MuiDataGrid-root .MuiDataGrid-viewport": {
			minHeight: "700px",
			maxHeight: "none"
		},
		"& .MuiDataGrid-renderingZone": {
			maxHeight: "none"
		},
		"& .MuiDataGrid-footerContainer": {
			// minHeight: "100px"
			paddingTop: "40px"
		}
	},

	"& .MuiDataGrid-columnSeparator": {
		opacity: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
		fontWeight: "600 !important",
		fontSize: "1rem",
		color: "#4a4a68"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--textLeft": {
		fontSize: "14px !important",
		fontWeight: "500 !important"
	},

	"& .MuiDataGrid-columnHeaderTitleContainer": {
		paddingLeft: "0 !important",
		paddingRight: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
		paddingRight: "0px !important",
		paddingLeft: "10px !important"
	},

	/* .MuiDataGrid-window {
	overflow-x: hidden !important;
} */

	// @media screen and (min-width: 1000px) {
	// 	.MuiDataGrid-window {
	// 		overflow-x: hidden !important;
	// 	}
	// }

	// .hide-scrollbar .MuiDataGrid-scrollbar {
	// 	display: none !important;
	// }

	"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
		paddingLeft: "2px"
	},

	"& .Mui-even .MuiDataGrid-row:hover": {
		backgroundColor: "white !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-row:hover": {
		backgroundColor: "white !important"
	},

	"& .MuiDataGrid-colCell": {
		minWidth: 0
	},

	"& .actions-header": {
		marginLeft: "10px"
	},

	"& .MuiDataGrid-row": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiPagination-root": {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},

	"& .MuiPaginationItem-root": {
		borderRadius: "3px !important",
		color: "#3077d3 !important",
		padding: "0 !important",
		margin: "0 !important",
		border: "1px solid #f4f8fe !important"
	},

	"& .MuiPaginationItem-textPrimary.Mui-selected": {
		color: "#fff !important",
		border: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon":
		{
			color: "#3077d3"
		},

	"& .MuiDataGrid-root .MuiDataGrid-sortIcon, .MuiDataGrid-root .MuiDataGrid-filterIcon": {
		color: "#3077d3"
	},

	"& .MuiDataGrid-root .MuiDataGrid-viewport": {
		minHeight: "700px",
		maxHeight: "none"
	},

	"& .MuiDataGrid-renderingZone": {
		maxHeight: "none"
	}
}
