export const manageCustomerDefaultSortedByDisabled = [
	{ dataField: "disabled", order: "asc" },
	{ dataField: "name", order: "asc" }
]
export const manageCustomerPeopleDefaultSortedByDisabled = [
	// { dataField: "disabled", order: "asc" },
	{ dataField: "personalData.name", order: "asc" }
]
export const defaultSorted = [{ dataField: "name", order: "asc" }]
export const sizePerPageList = [
	{ text: "5", value: 5 },
	{ text: "10", value: 10 },
	{ text: "20", value: 20 },
	{ text: "35", value: 35 },
	{ text: "50", value: 50 },
	{ text: "100", value: 100 }
]
export const initialFilter = {
	filter: {
		searchText: "",
		searchField: ""
	},
	sortOrder: "asc", // asc||desc
	sortField: "displayName",
	pageNumber: 1,
	pageSize: 10
}

// create universal unique ID (better than Math.random)
export function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		// eslint-disable-next-line no-mixed-operators
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	)
}

//filter an array data based on a search string and specified fields.
//Then put this function within a useEffect hook to update the filtered rows when either the arrayToSearch or searchString changes.
export const filterBySearchString = (arrayToSearch, searchString, fieldsToSearch) => {
	// Return the original array if the search string is empty or invalid, or if fieldsToSearch is not an array or empty
	if (
		!searchString ||
		searchString.trim() === "" ||
		!Array.isArray(fieldsToSearch) ||
		fieldsToSearch.length === 0
	) {
		return arrayToSearch
	}

	// Trim leading and trailing whitespace from the search string and convert it to lowercase
	const searchText = searchString.trim().toLowerCase()
	const searchWords = searchText.split(/\s+/) // Split search string into words

	// Filter the array based on the search string
	return arrayToSearch.filter(row => {
		// Check if any of the specified fields contain the search string
		return fieldsToSearch.some(field => {
			// Split the field string to handle nested fields
			const fieldParts = field.split(".")
			let fieldValue = row

			// Traverse the nested fields
			for (const part of fieldParts) {
				fieldValue = fieldValue[part]
				if (fieldValue === undefined || fieldValue === null) {
					return false
				}
			}

			// If the fieldValue is a string, check if it includes the searchText
			if (typeof fieldValue === "string") {
				const fieldValueLower = fieldValue.toLowerCase()

				// Handle multi-word search
				if (searchWords.length > 1) {
					return fieldValueLower.includes(searchText)
				}

				// Handle single-word search
				return fieldValueLower.split(/\s+/).some(part => part.startsWith(searchText))
			}

			return false
		})
	})
}

// The custom sorting function will split the version strings by the dot (.) character, convert the parts to numbers, and then compare them
// Made for firmware version sorting on inventory
export function compareVersionStrings(a, b) {
	const aParts = a.split(".").map(part => (isNaN(Number(part)) ? part : Number(part)))
	const bParts = b.split(".").map(part => (isNaN(Number(part)) ? part : Number(part)))

	for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
		const aPart = aParts[i] !== undefined ? aParts[i] : 0
		const bPart = bParts[i] !== undefined ? bParts[i] : 0

		if (typeof aPart === "number" && typeof bPart === "number") {
			if (aPart > bPart) return 1
			if (aPart < bPart) return -1
		} else {
			// Handle cases where one part is numeric and the other is non-numeric
			if (typeof aPart === "number" && typeof bPart === "string") return -1 // Numbers before strings
			if (typeof aPart === "string" && typeof bPart === "number") return 1 // Strings after numbers
			// Both parts are non-numeric, compare lexicographically
			if (aPart > bPart) return 1
			if (aPart < bPart) return -1
		}
	}
	return 0
}
