import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../_partials/typography/Bold"
import CloseIcon from "@material-ui/icons/Close"
import { IconButton } from "@material-ui/core"
import { EditInventoryModalForm } from "./EditInventoryModalForm"

const EditInventoryModal = ({ show, onHide }) => {
	function hide() {
		onHide()
		setSaving(false)
	}

	const [saving, setSaving] = React.useState(false)
	const [showUpdateModal, setShowUpdateModal] = useState(false)

	return (
		<Modal
			show={show}
			onHide={hide}
			data-testid="EditInventoryModal"
			backdrop="static"
			style={{ backgroundColor: showUpdateModal ? "rgba(0, 0, 0, 0.2)" : "initial" }}
		>
			<Modal.Header className="px-5 py-2">
				{/* ━━★  TITLE  ★━━ */}
				<div className="d-flex w-100 align-items-center justify-content-between">
					<div>
						<Bold>EDIT NODES</Bold>
					</div>
					<div className="col-auto">
						<IconButton
							onClick={() => {
								onHide()
							}}
							style={{ left: "12px" }}
							data-testid="close-button"
							disabled={saving}
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Modal.Header>

			<Modal.Body
				style={{
					padding: "0"
				}}
			>
				{/* {isLoading && <ModalProgressBar  />} */}
				<div className="p-5">
					{/* ━━★  BODY  ★━━ */}
					<EditInventoryModalForm
						onHide={onHide}
						saving={saving}
						setSaving={setSaving}
						show={showUpdateModal}
						setShow={setShowUpdateModal}
					/>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default EditInventoryModal
