import React from "react"
import ReactDOM from "react-dom"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit"
import { CgArrowsExpandRight } from "react-icons/cg"

export default class Options {
	constructor(props) {
		this.props = props
	}

	onAdd(map) {
		this.map = map
		this.container = document.createElement("div")
		this.container.className = "mapboxgl-ctrl"
		this.fullscreen = false
		this.container.style.cursor = "pointer"

		this.content = <OptionsComponent map={map} />

		ReactDOM.render(this.content, this.container)
		return this.container
	}

	onRemove() {
		this.container.parentNode.removeChild(this.container)
		this.map = undefined
	}
}

class OptionsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			fullscreen: false
		}
	}

	handleClick(val) {
		this.setState({ fullscreen: val })
	}

	openFullscreen() {
		;["", "webkit", "moz", "ms"].forEach(prefix => {
			return document.addEventListener(
				prefix + "fullscreenchange",
				() => {
					if (!window.screenTop && !window.screenY) {
						this.handleClick(false)
					}
				},
				false
			)
		})
		var mapContainer = this.props.map.getContainer()
		if (mapContainer.requestFullscreen) {
			mapContainer.requestFullscreen()
		} else if (mapContainer.webkitRequestFullscreen) {
			/* Safari */
			mapContainer.webkitRequestFullscreen()
		} else if (mapContainer.msRequestFullscreen) {
			/* IE11 */
			mapContainer.msRequestFullscreen()
		}
	}

	closeFullscreen() {
		if (document.exitFullscreen) {
			document.exitFullscreen()
		} else if (document.webkitExitFullscreen) {
			/* Safari */
			document.webkitExitFullscreen()
		} else if (document.msExitFullscreen) {
			/* IE11 */
			document.msExitFullscreen()
		}
	}

	render() {
		return (
			<div className="fullscreen-button">
				{this.state.fullscreen ? (
					<FullscreenExitIcon
						onClick={() => {
							this.handleClick(false)
							this.closeFullscreen()
						}}
						style={{
							width: "100%",
							height: "100%"
						}}
					/>
				) : (
					<CgArrowsExpandRight
						onClick={() => {
							this.handleClick(true)
							this.openFullscreen()
						}}
						size={18}
						// style={{
						//   fontSize: "18px",
						//   // width: "100%",
						//   // height: "100%",
						// }}
					/>
				)}
			</div>
		)
	}
}
