import React from "react"
import { IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { GrFilter } from "react-icons/gr"
import { Button } from "../../../../../../_partials/Button"
import CheckIcon from "@material-ui/icons/Check"
import AddIcon from "@material-ui/icons/Add"
import "perfect-scrollbar/css/perfect-scrollbar.css"

const HeatmapFilter = ({ setDrawerOptions, heatmapFilter, setHeatmapFilter }) => {
	const checkedIcon = <CheckIcon style={{ color: "#FFFFFF" }} />
	const addIcon = <AddIcon style={{ color: "#8C8CA2" }} />

	return (
		<div className="d-flex flex-column h-100">
			<div className="drawer_title_container d-flex w-100 align-items-center justify-content-between">
				<div>
					<GrFilter
						className="drawer_option_title_icon1"
						style={{
							filter:
								"invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
						}}
					/>
					<span className="drawer_option_title_span">Heatmap</span>
				</div>
				<div>
					<IconButton
						className="livemap_drawer_filter_close_btn"
						size="small"
						onClick={() =>
							setDrawerOptions(prevState => ({
								...prevState,
								openHeatmap: false
							}))
						}
					>
						<CloseIcon style={{ fontSize: "18px" }} />
					</IconButton>
				</div>
			</div>
			<p className="drawer_option_title_secondary">Filter</p>
			<div>
				<div className="d-flex flex-column justify-content-between">
					<Button
						className="drawer_options_filter_button"
						endIcon={heatmapFilter?.displayPeople ? checkedIcon : addIcon}
						style={{
							backgroundColor: heatmapFilter?.displayPeople ? "#3077D3" : "#DBE1E5",
							color: heatmapFilter?.displayPeople ? "#FFFFFF" : "#8C8CA2"
						}}
						variant="contained"
						onClick={() => {
							setHeatmapFilter(prevState => ({
								...prevState,
								displayPeople: !prevState.displayPeople
							}))
						}}
					>
						<span>People</span>
					</Button>
					<Button
						className="drawer_options_filter_button"
						endIcon={heatmapFilter?.displayAssets ? checkedIcon : addIcon}
						style={{
							backgroundColor: heatmapFilter?.displayAssets ? "#3077D3" : "#DBE1E5",
							color: heatmapFilter?.displayAssets ? "#FFFFFF" : "#8C8CA2"
						}}
						variant="contained"
						onClick={() => {
							setHeatmapFilter(prevState => ({
								...prevState,
								displayAssets: !prevState.displayAssets
							}))
						}}
					>
						<span>Assets</span>
					</Button>

					<Button
						className="drawer_options_filter_button"
						endIcon={heatmapFilter?.displayUnassigned ? checkedIcon : addIcon}
						style={{
							backgroundColor: heatmapFilter?.displayUnassigned ? "#3077D3" : "#DBE1E5",
							color: heatmapFilter?.displayUnassigned ? "#FFFFFF" : "#8C8CA2"
						}}
						variant="contained"
						onClick={() =>
							setHeatmapFilter(prevState => ({
								...prevState,
								displayUnassigned: !prevState.displayUnassigned
							}))
						}
					>
						<span>Unassigned tags</span>
					</Button>
				</div>
			</div>
		</div>
	)
}

export default HeatmapFilter
