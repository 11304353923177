import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import Chart from "react-apexcharts"
import _ from "lodash"
import { liveDataSlice } from "../../../_redux/liveData/liveDataSlice"
import { Timestamp } from "firebase/firestore"
import { firestoreOld } from "../../../../../firebase"

const nodesCollection = global.nodesCollection

export function TotalPeople() {
	const dispatch = useDispatch()

	const { selectedCustomer, selectedSite, people, liveDataTimeoutSeconds, tagSeenTimeout, tags } =
		useSelector(
			state => ({
				selectedCustomer: state.profile?.currentCustomer,
				selectedSite: state.profile?.currentSite,
				people: state.basePage?.people,
				liveDataTimeoutSeconds:
					state.basePage?.globalOptions?.generalInfo?.liveDataTimeoutSeconds,
				tagSeenTimeout: state.basePage?.globalOptions?.map?.tagSeenTimeout || 600,
				tags: state.liveData?.tags
			}),
			shallowEqual
		)

	const [isLoading, setIsLoading] = useState(false)
	const [graphData, setGraphData] = useState({
		labels: [],
		series: []
	})

	// Fetch Live data
	useEffect(() => {
		if (!selectedCustomer || !selectedSite) return

		const minutesAgoDate = Timestamp.now().toDate()
		minutesAgoDate.setSeconds(minutesAgoDate.getSeconds() - tagSeenTimeout)

		const unsubscribe = firestoreOld
			.collection(`Sites/${selectedSite.id}/${nodesCollection}`)
			.where("nodeType", "==", "tag")
			.where("lastSeen", ">=", minutesAgoDate)
			.onSnapshot(
				snapshot => {
					var data = snapshot.docs.map(doc => {
						return { id: doc.id, ...doc.data() }
					}, {})
					dispatch(liveDataSlice.actions.tagsFetched(data))
				},
				error => console.error(error)
			)

		const timer = setTimeout(
			() => {
				unsubscribe()
			},
			liveDataTimeoutSeconds ? liveDataTimeoutSeconds * 1000 : 600000
		)
		return () => {
			unsubscribe()
			clearTimeout(timer)
		}
	}, [selectedCustomer, selectedSite, liveDataTimeoutSeconds])

	// Add the person assigned to the tag directly to it
	// This helps reduce renders and filters/finds
	useEffect(() => {
		if (!people || !tags) {
			setIsLoading(true)
		} else {
			const newTags = tags.map(tag => {
				const person = people && people.find(val => val?.uuid === tag?.uuid)
				if (person) {
					return { ...tag, person }
				}
			})
			// console.log("🚀  newTags:", newTags)

			const activePeople = newTags && newTags.filter(tag => tag?.person).length

			let idlePeople = people.length - activePeople

			if (idlePeople < 0) idlePeople = 0

			if (activePeople && idlePeople) {
				setGraphData({
					series: [activePeople, idlePeople],
					labels: ["Active people", "Idle people"]
				})
			}

			setIsLoading(false)
		}
	}, [people, tags])

	return (
		<Card isLoading={isLoading}>
			<CardHeader title="Total People on Site" subtitle="Total number of people of the field" />
			<CardBody style={{ height: "380px" }}>
				<Chart
					options={getChartOptions(graphData)}
					series={graphData?.series}
					type="donut"
					height="100%"
				/>
			</CardBody>
		</Card>
	)
}

function getChartOptions(graphData) {
	return {
		chart: {
			type: "donut",
			toolbar: {
				show: true,
				tools: {
					download: true,
					selection: false,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: false,
					customIcons: []
				},
				export: {
					csv: {
						filename: "TotalPeopleOnSite",
						columnDelimiter: ",",
						headerCategory: "Floor",
						headerValue: "Value",
						dateFormatter(timestamp) {
							return new Date(timestamp).toDateString()
						}
					},
					svg: {
						filename: "TotalPeopleOnSite"
					},
					png: {
						filename: "TotalPeopleOnSite"
					}
				}
			},
			zoom: {
				enabled: false
			}
		},
		noData: {
			text: "No data to display."
		},
		labels: graphData.labels,
		legend: {
			position: "bottom"
		},

		colors: ["#99BDF8", "#2673F0"],
		plotOptions: {
			pie: {
				donut: {
					labels: {
						show: true,
						value: {
							fontSize: "12px",
							fontWeight: 500,
							color: "black",
							offsetY: 2
						},
						total: {
							show: true,
							label: "Total workers",
							color: "black",
							fontSize: "11px",
							fontWeight: 500,
							formatter: w => {
								const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
								return total
								// const average = total / w.globals.seriesTotals.length;
								// return Math.floor(average);
							}
						}
					}
				}
			}
		},
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200
					},
					legend: {
						position: "bottom"
					}
				}
			}
		]
	}
}
