import React, { useState, forwardRef, useEffect } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardHeader, CardBody } from "../../../_partials/Card"
import moment from "moment"
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined"
import CloseIcon from "@material-ui/icons/Close"
import { Button, IconButton, Popover } from "@material-ui/core"
import DatePicker from "react-datepicker"
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined"
import EventSeatIcon from "@material-ui/icons/EventSeat"
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom"
import { Portal } from "react-overlays"
import "./PeopleStatus.css"

export function Employees() {
	//━━━ Get data from redux ━━━\\
	const {
		currentCustomer,
		currentLocation,
		bookings,
		resources,
		department,
		users,
		userDocs,
		userId
	} = useSelector(
		state => ({
			currentCustomer: state.profile?.currentCustomer?.id,
			currentLocation: state.profile?.currentLocation?.netId,
			bookings: state.booking?.bookings,
			resources: state.booking?.resources,
			department: state.basePage?.department,
			users: state.booking?.users,
			userDocs: state.booking?.usersDocs || [],
			userId: state.auth?.user.uid
		}),
		shallowEqual
	)

	//★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
	//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
	const [date, setDate] = useState(new Date())
	const [allTimeBooks, setAllTimeBooks] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [popoverContent, setPopoverContent] = useState(null)
	const [detectedDevices, setDetectedDevices] = useState([])

	const handleClick = ({ target, content }) => {
		setPopoverContent(content)
		setAnchorEl(target)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
		return (
			<div className="row" style={{ margin: "auto" }} onClick={onClick} ref={ref}>
				<CalendarTodayOutlinedIcon />
				{moment(value).format() === moment().startOf("day").format()
					? "Today"
					: moment(value).format("MMM D")}
				<KeyboardArrowDownOutlinedIcon />
			</div>
		)
	})

	const CalendarContainer = ({ children }) => {
		const el = document.getElementById("calendar-portal")

		return <Portal container={el}>{children}</Portal>
	}

	/* useEffect(() => {
    console.log("?")
    console.log("currentCustomer: ", currentCustomer)
    console.log("currentLocation: ", currentLocation)
    const unsubscribe = firestore
      .collection("Customers")
      .doc(currentCustomer)
      .collection(currentLocation)
      .onSnapshot(querySnapshot => {
        console.log("???")
        console.log("querySnapshot: ", querySnapshot)
        const fetchedDevices = querySnapshot.docs
          .map(doc => {
            if (doc.data().employees) {
              return doc.data().employees
            }
          })
          .filter(val => val)
          .flat()
        console.log("fetchedDevices: ", fetchedDevices)
        setDetectedDevices(fetchedDevices || [])
      })

    return () => {
      unsubscribe()
    }
  }, [currentCustomer, currentLocation]) */

	return (
		<Card
			style={{
				flexGrow: "1"
			}}
		>
			<CardHeader title="Employees" />
			<CardBody>
				<div style={{ padding: "0 15px 15px 15px" }}>
					<div className="d-flex flex-row-reverse" style={{ paddingBottom: "5px" }}>
						<Button
							onClick={() => setAllTimeBooks(true)}
							style={{
								fontFamily: "Poppins",
								fontSize: "13px",
								fontWeight: "500",
								lineHeight: "170%",
								color: allTimeBooks ? "#323389" : "#B8BCCA"
							}}
						>
							All Time
						</Button>
						<Button
							onClick={() => setAllTimeBooks(false)}
							style={{
								fontFamily: "Poppins",
								fontSize: "13px",
								fontWeight: "500",
								lineHeight: "170%",
								color: allTimeBooks ? "#B8BCCA" : "#323389"
							}}
						>
							This week
						</Button>
					</div>
					{users &&
						users.map(u => {
							const booksFiltered =
								(bookings && bookings.filter(b => b.created.id === u.uid)) || []
							const activeBookings =
								booksFiltered.filter(val => moment(val.end.toDate()).isAfter(moment()))
									?.length || 0
							const weekActiveBookings =
								booksFiltered.filter(
									val =>
										moment(val.end.toDate()).isAfter(moment()) &&
										moment(val.start.toDate())
											.startOf("day")
											.isBefore(moment().endOf("week"))
								)?.length || 0

							return (
								<div
									key={u.uid}
									className="row align-items-center"
									style={{
										margin: "auto",
										border: "0.5px solid #D6D9E1",
										background: "#FAFCFE",
										padding: "11px 9px"
									}}
								>
									<div className="d-flex col-4 align-items-center">
										<div
											//title={u?.displayName || u?.email || "Error"}
											style={{
												marginRight: "5px",
												borderRadius: "50%",
												width: "25px",
												height: "25px",
												backgroundSize: "cover",
												backgroundImage: `url(${
													u?.photoURL ? u.photoURL : "/media/users/default.jpg"
												}), url(/media/users/default.jpg)`
											}}
										/>
										{"  "}
										{u.displayName || u.email || "User deleted"}
									</div>
									<div className="col-3">
										<span>0% office time</span>
									</div>
									<div className="col-3">
										<span>
											{allTimeBooks ? activeBookings : weekActiveBookings} active
											bookings
										</span>
									</div>
									<div className="col-2 text-right">
										<IconButton
											aria-label="calendar"
											component="span"
											style={{ padding: "5px" }}
											onClick={e => {
												setDate(new Date())
												const doc = userDocs.find(val => val.id === u.uid)
												var text = "Currently not in office"
												var color = "#D03157"
												if (doc?.lastSeen) {
													const lastSeen = moment(doc.lastSeen.toDate())
													const today = moment()
													const diff = today.diff(lastSeen, "minutes")
													if (diff < 15) {
														text = "In office now"
														color = "#31D0AA"
													} else if (diff < 61) {
														text = `Last seen ${diff} minutes ago`
													}
												}
												handleClick({
													target: e.currentTarget,
													content: {
														user: u,
														booksFiltered,
														inOfficeText: text,
														inOfficeColor: color
													}
												})
											}}
										>
											<CalendarTodayOutlinedIcon style={{ color: "#323389" }} />
										</IconButton>
									</div>
								</div>
							)
						})}
				</div>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "top",
						horizontal: "left"
					}}
					transformOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
				>
					<div
						style={{
							padding: "12px 20px 20px 20px",
							backgroundColor: "#f7f7f7",
							width: "300px"
						}}
					>
						<div
							className="row d-flex justify-content-between"
							style={{
								margin: "auto"
							}}
						>
							<div
								style={{
									fontFamily: "Poppins",
									fontStyle: "normal",
									fontWeight: "bold",
									fontSize: "16px",
									lineHeight: "24px",
									letterSpacing: "0.04em",
									textTransform: "uppercase",
									color: "#4A4A68",
									maxWidth: "230px",
									overflow: "hidden",
									textOverflow: "ellipsis"
								}}
							>
								{popoverContent?.user?.displayName || popoverContent?.user?.email}
							</div>
							<div>
								<IconButton
									aria-label="calendar"
									component="span"
									style={{ padding: "5px" }}
									onClick={() => setAnchorEl(null)}
								>
									<CloseIcon style={{ color: "#B8BCCA" }} />
								</IconButton>
							</div>
						</div>
						<div
							className="row"
							style={{
								margin: "auto",
								paddingTop: "10px"
							}}
						>
							<div>
								<div
									style={{
										borderRadius: "50%",
										width: "45px",
										height: "45px",
										backgroundSize: "cover",
										backgroundImage: `url(${
											popoverContent?.user?.photoURL
												? popoverContent.user.photoURL
												: "/media/users/default.jpg"
										}), url(/media/users/default.jpg)`
									}}
								/>
							</div>
							<div
								style={{
									paddingLeft: "5px",
									marginTop: "auto"
								}}
							>
								<span
									style={{
										fontFamily: "Poppins",
										fontStyle: "normal",
										fontWeight: "500",
										fontSize: "16px",
										lineHeight: "160%",
										color: "#4A4A68"
									}}
								>
									{department &&
										(department.manager === popoverContent?.user?.uid
											? department.name + " manager"
											: department.name)}
								</span>
								<br />
								<span
									style={{
										fontFamily: "Poppins",
										fontStyle: "normal",
										fontWeight: "500",
										fontSize: "13px",
										lineHeight: "160%",
										color: popoverContent?.inOfficeColor || "#D03157"
									}}
								>
									{popoverContent?.inOfficeText}
								</span>
							</div>
						</div>
						<div
							className="row  d-flex justify-content-between"
							style={{
								margin: "auto",
								paddingTop: "15px",
								paddingBottom: "5px"
							}}
						>
							<div
								style={{
									fontFamily: "Poppins",
									fontStyle: "normal",
									fontWeight: "500",
									fontSize: "16px",
									lineHeight: "160%",
									color: "#4A4A68"
								}}
							>
								{popoverContent?.user?.displayName &&
									popoverContent?.user?.displayName.replace(/ .*/, "") + "'s"}{" "}
								bookings
							</div>
							<div className="">
								<DatePicker
									selected={date}
									onChange={date => setDate(date)}
									customInput={<ExampleCustomInput />}
									popperContainer={CalendarContainer}
								/>
							</div>
						</div>
						{popoverContent?.booksFiltered &&
							popoverContent.booksFiltered.map(val => {
								if (
									moment(val.start.toDate()).startOf("day").format() !=
									moment(date).startOf("day").format()
								) {
									return
								}
								const resource = resources && resources.find(r => r.id === val.resource)
								return (
									<div
										style={{
											padding: "10px",
											marginBottom: "10px",
											backgroundColor: "#D3EEE7",
											borderRadius: "5px"
										}}
									>
										<div
											style={{
												fontFamily: "Poppins",
												fontStyle: "normal",
												fontWeight: "500",
												fontSize: "13px",
												lineHeight: "170%",
												color: "#4A4A68",
												paddingBottom: "5px"
											}}
										>
											{resource?.type === "desk" && <EventSeatIcon />}
											{"   "}
											{resource?.name}
										</div>
										<div
											style={{
												fontFamily: "Poppins",
												fontStyle: "normal",
												fontWeight: "normal",
												fontSize: "12px",
												lineHeight: "18px",
												color: "#4A4A68"
											}}
										>
											{moment(val.start.toDate()).format("H:mm")}
											{" - "}
											{moment(val.end.toDate()).format("H:mm")}
										</div>
									</div>
								)
							})}
						{popoverContent?.calendarBooksFiltered &&
							popoverContent.calendarBooksFiltered.map(val => {
								if (
									moment(val.start.dateTime).startOf("day").format() !=
									moment(date).startOf("day").format()
								) {
									return
								}
								const resource =
									resources &&
									resources.find(r =>
										val.attendees.some(v => v.email === r.calendarResource)
									)
								return (
									<div
										style={{
											padding: "10px",
											marginBottom: "10px",
											backgroundColor: "#D3EEE7",
											borderRadius: "5px"
										}}
									>
										<div
											style={{
												fontFamily: "Poppins",
												fontStyle: "normal",
												fontWeight: "500",
												fontSize: "13px",
												lineHeight: "170%",
												color: "#4A4A68",
												paddingBottom: "5px"
											}}
										>
											<MeetingRoomIcon />
											{"   "}
											{resource?.name}
										</div>
										<div
											style={{
												fontFamily: "Poppins",
												fontStyle: "normal",
												fontWeight: "normal",
												fontSize: "12px",
												lineHeight: "18px",
												color: "#4A4A68"
											}}
										>
											{moment(val.start.dateTime).format("H:mm")}
											{" - "}
											{moment(val.end.dateTime).format("H:mm")}
										</div>
									</div>
								)
							})}
					</div>
				</Popover>
			</CardBody>
		</Card>
	)
}
