import React, { useEffect, createContext, useContext, useState } from "react"
import moment from "moment"

const UIContext = createContext()

const initialColumnsOrder = [
	{
		field: "day",
		sort: null
	},
	{
		field: "worker",
		sort: null
	},
	{
		field: "role",
		sort: null
	},
	{
		field: "company",
		sort: null
	},
	{
		field: "start",
		sort: null
	},
	{
		field: "end",
		sort: null
	},
	{
		field: "hours",
		sort: null
	}
]

export function useUIContext() {
	return useContext(UIContext)
}

export const UIConsumer = UIContext.Consumer

export function UIProvider({ children }) {
	// old code to be deleted ↓
	// const [queryParams, setQueryParams] = useState({
	//   date: moment()
	//     .subtract(1, "days")
	//     .endOf("day")
	//     .toString(),
	//   timeframe: "day",
	// });
	const [queryParams, setQueryParams] = useState({
		startDate: moment().subtract(24, "hours"),
		endDate: moment()
		// areas: [],
		// roles: []
	})
	// note: we don't need .toString() if we have the timeframe on the query

	//timesheet daily with data grid
	const [ids, setIds] = useState([])
	const [columnsOrder, setColumnsOrder] = useState(initialColumnsOrder)
	const [rowsToUse, setRowsToUse] = useState([])
	const [columnVisibilityModel, setColumnVisibilityModel] = useState({}) //visibile columns object for exporting
	const [currentTablePageInfo, setCurrentTablePageInfo] = useState({}) //page and pageSize to export current page only

	const value = {
		queryParams,
		setQueryParams,

		//timesheet daily with data grid
		ids,
		setIds,
		columnsOrder,
		setColumnsOrder,
		rowsToUse,
		setRowsToUse,
		columnVisibilityModel,
		setColumnVisibilityModel,
		currentTablePageInfo,
		setCurrentTablePageInfo
	}

	// useEffect(() => {
	// 	console.log("queryParams", queryParams)
	// }, [queryParams])

	return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}
