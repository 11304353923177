import clsx from "clsx"
import React from "react"
import { Header3 } from "../typography/Header3"
import { Small } from "../typography/Small"

export function GeralInfoRow({ data }) {
	return (
		<div className="row" style={{ margin: "auto" }}>
			{data &&
				data.map((val, i) => {
					return (
						<div
							key={i}
							id={i}
							className={clsx("col", val.className, i > 1 && "dashboard-geral-info-extra")}
							style={{
								background: val.color || "#DAE3E8",
								borderRadius: "5px",
								marginLeft: i === 0 ? 0 : "10px",
								marginRight: i === data.length - 1 ? 0 : "10px",
								paddingBottom: "10px"
							}}
						>
							<div className="d-flex justify-content-between pt-5 pr-4 mb-8">
								{val.title}
								{val.icon}
							</div>
							<Header3 text={val.text} />
							<br />
							<Small text={val.subtext} />
						</div>
					)
				})}
		</div>
	)
}
