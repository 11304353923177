import React, { useEffect, useState, useMemo } from "react"
import { useUIContext } from "./UIContext"
import InventoryHeader from "./InventoryHeader/InventoryHeader"
import { NodesInventoryCard } from "./NodesInventory/NodesInventoryCard"
import { ModelsInventoryCard } from "./ModelsInventory/ModelsInventoryCard"
import { FirmwaresInventoryCard } from "./FirmwareInventory/FirmwaresInventoryCard"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import * as actionsInventory from "../../_redux/admin/inventory/InventoryActions"

const InventoryMainCard = () => {
	const dispatch = useDispatch()

	// ━━━━━━━━━━━━━  useUIContext  ━━━━━━━━━━━━━ \\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			setTechnology: UIContext.setTechnology
		}
	}, [UIContext])
	//━━━━━━━━━━━━━━━ useState ━━━━━━━━━━━━━━━\\
	//? I had to set technology here so that the state updates for Export Dropdown and Inventory Table
	//? With useUIContext was not working
	const [technology, setTechnology] = useState(null)
	const [technologyList, setTechnologyList] = useState(null)

	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const {
		isSuper,
		nodesInventory,
		modelsInventory,
		firmwaresInventory,
		customers,
		currentCustomer
	} = useSelector(
		state => ({
			isSuper: state.auth.claims.isSuper,
			currentCustomer: state.profile?.currentCustomer,
			nodesInventory: state.inventory.nodesInventory,
			modelsInventory: state.inventory.modelsInventory,
			firmwaresInventory: state.inventory.firmwaresInventory,
			customers: state.inventory.customers
		}),

		shallowEqual
	)

	// ━━━━━━━━━ useEffects  ━━━━━━━━━

	// Fetch technology list if user is super
	useEffect(() => {
		let isMounted = true

		if (!isSuper) return

		const fetchTechnologyList = async () => {
			try {
				const result = await dispatch(actionsInventory.getTechnologyList())
				if (isMounted && result && result.includes("Wirepas")) {
					setTechnologyList(result)
					setTechnology("Wirepas")
				}
			} catch (error) {
				if (isMounted) {
					console.error("Error fetching technology list:", error)
				}
			}
		}

		fetchTechnologyList()

		return () => {
			isMounted = false
		}
	}, [isSuper, dispatch])

	// Fetch inventory data based on technology
	useEffect(() => {
		let isMounted = true

		if (!technology) return

		const fetchData = async () => {
			try {
				await dispatch(
					actionsInventory.getNodes({
						technology,
						customerId: !isSuper && currentCustomer.id ? currentCustomer.id : null
					})
				)
				await dispatch(actionsInventory.getModels({ technology }))
				await dispatch(actionsInventory.getFirmwares({ technology }))
				await dispatch(actionsInventory.getCustomersAndSites())
			} catch (error) {
				if (isMounted) {
					console.error("Error fetching inventory data:", error)
				}
			}
		}

		fetchData()

		return () => {
			isMounted = false
		}
	}, [technology, dispatch])

	// ━━━━━━━━━ handlers  ━━━━━━━━━
	const handleTechnology = (event, newValue) => {
		setTechnology(newValue)
	}

	useEffect(() => {
		if (!technology) return
		UIProps.setTechnology(technology)
	}, [technology])

	return (
		<>
			<InventoryHeader data-testid="InventoryHeader" />
			<NodesInventoryCard
				nodesInventory={nodesInventory}
				technology={technology}
				setTechnology={setTechnology}
				handleTechnology={handleTechnology}
				technologyList={technologyList}
				allCustomers={customers}
				firmwaresInventory={firmwaresInventory}
				data-testid="NodesInventoryCard"
			/>
			<ModelsInventoryCard
				modelsInventory={modelsInventory}
				technology={technology}
				setTechnology={setTechnology}
				handleTechnology={handleTechnology}
				technologyList={technologyList}
				data-testid="ModelsInventoryCard"
			/>
			<FirmwaresInventoryCard
				firmwaresInventory={firmwaresInventory}
				technology={technology}
				setTechnology={setTechnology}
				handleTechnology={handleTechnology}
				technologyList={technologyList}
				data-testid="FirmwaresInventoryCard"
			/>
		</>
	)
}

export default InventoryMainCard
