import React, { useEffect, useState } from "react"
import * as turf from "@turf/turf"
import { makeStyles, TextField, FormControl, Select, InputLabel, MenuItem } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { handleSearchChange, searchHandleMap } from "../../../_helpers/SearchHelpers"

const NewSearch = ({
	drawerOptions,
	setDrawerOptions,
	selectedSite,
	map,
	dispatch,
	people = [],
	assets = [],
	tags,
	floorPlans,
	floorPlan,
	areas,
	pois,
	goTo,
	setGoTo,
	searchFilteredOptions,
	setSearchFilteredOptions,
	setShowPeople,
	setShowAssets,
	setShowPOIs,
	setShowAreaInfoMarkers,
	searchedPersonOrAssetOld,
	setSearchedPersonOrAssetOld,
	showRoleAndTypeFunction,
	markersSize,
	setTypingSearch,
	setNewSearchedTag,
	access
}) => {
	// State
	// const [newSearchedTag, setNewSearchedTag] = useState(null)

	const _areas = areas
		? areas
				.map(option => {
					return {
						group: "Areas",
						...option
					}
				})
				.sort((a, b) => -b.group.localeCompare(a.group))
		: []

	const _pois = pois
		? pois
				.map(option => {
					return {
						group: "Points of Interest",
						...option
					}
				})
				.sort((a, b) => -b.group.localeCompare(a.group))
		: []

	const _people = people
		? people
				.map(option => {
					if (!option.personalData?.name) return null
					const tag = tags && tags.find(val => val.uuid === option.uuid)
					return {
						group: "People",
						name: option?.personalData?.name,
						gpsData: tag?.gpsData,
						floorPlanId: tag?.floorPlanId,
						siteId: tag?.siteId, // Added the siteId
						...option
					}
				})
				.filter(val => selectedSite && val?.siteId === selectedSite?.id) // Filter only people of this selectedSite
				.filter(val => val.gpsData) // only people with gpsData
				.sort((a, b) => -b.group.localeCompare(a.group))
		: []
	const _assets = assets
		.map(option => {
			if (!option?.assetDetails?.name) return null
			const tag = tags.find(val => val.uuid === option.uuid)

			return {
				group: "Assets",
				name: option?.assetDetails?.name,
				gpsData: tag?.gpsData,
				floorPlanId: tag?.floorPlanId,
				siteId: tag?.siteId,
				...option
			}
		})

		.filter(val => selectedSite && val?.siteId === selectedSite?.id) // Filter only people of this selectedSite
		.sort((a, b) => -b.group.localeCompare(a.group))

	const options = [..._areas, ..._pois, ..._people, ..._assets]

	// Get uniq values com key obj.group - finding which groups exist
	const uniq = {}
	const differentGroups = options.filter(obj => !uniq[obj.group] && (uniq[obj.group] = true))

	// If the People or Asset marker changes the position our green marker changes position and camera aswell
	// useEffect(() => {
	// 	// Note: when we received new batch of data the newSearched state ends at null. So when this !null we save the search, when is null we check the old search state (searchedPersonOrAssetOld) to check if the coords are different, if they are we travel to another coords.
	// 	if (!newSearchedTag) {
	// 		// console.log("newSearchedTag  NULLLLL!!!! -->>>>>>>>>>>")
	// 		// console.log(searchedPersonOrAssetOld, "searchedPersonOrAssetOld")

	// 		const foundedSearchedPersonOrAsset =
	// 			searchedPersonOrAssetOld && searchedPersonOrAssetOld?.group === "People"
	// 				? _people && _people.find(val => val.id === searchedPersonOrAssetOld?.id)
	// 				: searchedPersonOrAssetOld && searchedPersonOrAssetOld?.group === "Assets"
	// 				? _assets && _assets.find(val => val.id === searchedPersonOrAssetOld?.id)
	// 				: null
	// 		// console.log(
	// 		// 	"🚀  🚀  🚀  🚀  🚀  🚀  foundedSearchedPersonOrAsset:",
	// 		// 	foundedSearchedPersonOrAsset
	// 		// )

	// 		if (foundedSearchedPersonOrAsset && searchedPersonOrAssetOld) {
	// 			const matchedSameCoords =
	// 				searchedPersonOrAssetOld?.gpsData?.latitude ===
	// 				foundedSearchedPersonOrAsset?.gpsData?.latitude

	// 			// console.log("🚀🚀🚀🚀🚀🚀🚀 matchedSameCoords:", matchedSameCoords)

	// 			if (matchedSameCoords) {
	// 				return
	// 			} else {
	// 				// console.log("matchedSameCoords is false so we change marker position!!!!->>>>>>>>")

	// 				// Delete search value and goTo after changing flooplan and close search bar if after searching something the user changes floorplan
	// 				if (foundedSearchedPersonOrAsset.floorPlanId !== floorPlan?.id) {
	// 					// console.log("👾 👾 👾 👾 👾 👾 👾 DIFFERENT")
	// 					setGoTo()
	// 					setSearchedPersonOrAssetOld(null)
	// 					setNewSearchedTag(null)
	// 					setDrawerOptions({
	// 						openSearch: false,
	// 						openFilter: false,
	// 						openPath: false,
	// 						openOccupancy: false,
	// 						openSatellite: false,
	// 						open3d: false
	// 					})
	// 					return
	// 				} else {
	// 					searchHandleMap({
	// 						floorPlans,
	// 						map,
	// 						floorPlan,
	// 						goTo: foundedSearchedPersonOrAsset,
	// 						setGoTo,
	// 						coords: [
	// 							foundedSearchedPersonOrAsset?.gpsData?.longitude,
	// 							foundedSearchedPersonOrAsset?.gpsData?.latitude
	// 						],
	// 						dispatch,
	// 						setShowPeople,
	// 						setShowAssets,
	// 						setShowPOIs,
	// 						setShowAreaInfoMarkers,
	// 						markersSize
	// 					})
	// 				}
	// 			}
	// 		}
	// 	} else {
	// 		setSearchedPersonOrAssetOld(newSearchedTag)
	// 	}
	// }, [newSearchedTag, searchedPersonOrAssetOld, markersSize, floorPlan])

	return (
		<div
			className="wrapper_new_search_bar"
			style={{
				padding: "6px 5px"
			}}
		>
			<div className="container_new_search_bar">
				{drawerOptions.openSearch ? (
					<div className="dropdowns_new_wrapper">
						<CustomDropdown
							differentGroups={differentGroups}
							searchFilteredOptions={searchFilteredOptions}
							setSearchFilteredOptions={setSearchFilteredOptions}
							setTypingSearch={setTypingSearch}
						/>

						<CustomAutocomplete
							// defaultValue={defaultValue} // not in use, yet.
							options={options}
							map={map}
							dispatch={dispatch}
							floorPlans={floorPlans}
							floorPlan={floorPlan}
							// devices={devices} // not in use, yet.
							// bleDevices={bleDevices} // not in use, yet.
							// bleThings={bleThings} // not in use, yet.
							goTo={goTo}
							setGoTo={setGoTo}
							searchFilteredOptions={searchFilteredOptions}
							setSearchFilteredOptions={setSearchFilteredOptions}
							setShowPeople={setShowPeople}
							setShowAssets={setShowAssets}
							setShowPOIs={setShowPOIs}
							setShowAreaInfoMarkers={setShowAreaInfoMarkers}
							showRoleAndTypeFunction={showRoleAndTypeFunction}
							markersSize={markersSize}
							setTypingSearch={setTypingSearch}
							setNewSearchedTag={setNewSearchedTag}
							access={access}
						/>
					</div>
				) : null}
			</div>
		</div>
	)
}

export default NewSearch

function CustomDropdown(props) {
	const { searchFilteredOptions, setSearchFilteredOptions, differentGroups } = props
	const classes = useStyles()

	return (
		<FormControl
			id="livemap_drawer_search_autocomplete_filter"
			variant="outlined"
			// className='search_filter_select'
			size="small"
			className={classes.filterDropdown}
		>
			<InputLabel
				shrink={false} // hides label to border
				style={{
					display: !searchFilteredOptions ? "block" : "none",
					color: "#4A4A68"
				}} // hides label when option is selected
			>
				All Categories
			</InputLabel>
			<Select
				className={classes.select}
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left"
					},
					getContentAnchorEl: null
				}}
				value={searchFilteredOptions || ""}
				onChange={e => {
					setSearchFilteredOptions(e.target.value)
				}}
				onOpen={() => {
					props.setTypingSearch(true) // Note: this helps to not re-render the popover of the dropdown
				}}
				onClose={() => {
					props.setTypingSearch(false) // Note: this helps to not re-render the popover of the dropdown
				}}
			>
				<MenuItem value="All Categories" className="livemap_drawer_autocomplete_search_group">
					All Categories
				</MenuItem>
				{differentGroups.map((g, i) => (
					<MenuItem
						key={i}
						value={g.group}
						className="livemap_drawer_autocomplete_search_group"
					>
						{g.group}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

function CustomAutocomplete(props) {
	const classes = useStyles()

	// function to filter search bar with dropdown value selected
	function filterSearchBar(options, state) {
		const searchTerm = state.inputValue.toLowerCase().replace(",", "")

		if (props.searchFilteredOptions) {
			let groupWord = null

			switch (props.searchFilteredOptions) {
				case "All Categories":
					groupWord = "All Categories"
					break
				case "Buddies":
					groupWord = "Buddies"
					break
				case "Things":
					groupWord = "Things"
					break
				case "Areas":
					groupWord = "Areas"
					break
				case "Points of Interest":
					groupWord = "Points of Interest"
					break
				case "Sensors":
					groupWord = "Sensors"
					break
				case "People":
					groupWord = "People"
					break
				case "Assets":
					groupWord = "Assets"
					break
				default:
					break
			}

			const filteredOptions = options.filter(o => o.group === groupWord)
			const allCategoriesCondition =
				filteredOptions && filteredOptions.length !== 0 ? filteredOptions : options

			let newOptions = []
			allCategoriesCondition.forEach(element => {
				// search for a person's name and uuid
				if (
					(element?.name && element.name.toLowerCase().includes(searchTerm)) ||
					(element?.uuid &&
						element.uuid.toString() &&
						element.uuid.toString().toLowerCase().includes(searchTerm))
				)
					newOptions.push(element)
			})

			return newOptions
		} else {
			let newOptions = []

			options.forEach(element => {
				// search for a person's name and uuid
				if (
					(element?.name && element.name.toLowerCase().includes(searchTerm)) ||
					(element?.uuid &&
						element.uuid.toString() &&
						element.uuid.toString().toLowerCase().includes(searchTerm))
				)
					newOptions.push(element)
			})

			return newOptions
		}
	}

	return (
		<div>
			<Autocomplete
				disablePortal
				className={classes.root}
				id="livemap_drawer_search_autocomplete_input"
				size="small"
				defaultValue={props.defaultValue || ""}
				options={props.options || []}
				groupBy={option => option.group}
				getOptionLabel={option => {
					let searchLabel = option.name
						? option?.name
						: props?.goTo?.name
						? props?.goTo?.name
						: ""

					// if group is "People" return name of person and uuid
					if (option.group === "People") {
						const uuidToString = option?.uuid && option?.uuid.toString()

						searchLabel = option.name
							? option?.name
							: props?.goTo?.name
							? props?.goTo?.name
							: ""

						if (props.access) {
							searchLabel =
								option.name && uuidToString
									? option?.name + " - " + uuidToString
									: props?.goTo?.name && uuidToString
									? props?.goTo?.name + " - " + uuidToString
									: ""
						}
					}

					return searchLabel
				}}
				getOptionSelected={(option, value) => option.name === value?.name}
				renderInput={params => {
					return (
						<>
							<TextField
								id="livemap_drawer_search_autocomplete_textfield"
								{...params}
								variant="outlined"
								className={classes.inputPlaceholder}
								placeholder={
									!props.searchFilteredOptions
										? "Search in All Categories"
										: `Search in ${props.searchFilteredOptions}`
								}
								onChange={() => {
									// Set typing state when typing starts
									props.setTypingSearch(true)
								}}
								onBlur={() => {
									// Clear typing state when typing ends
									props.setTypingSearch(false)
								}}
							/>
						</>
					)
				}}
				// filter all options by the select dropdown value
				filterOptions={filterSearchBar}
				onChange={(event, newValue) => {
					// console.log("🚀  newValue:", newValue)

					props.setNewSearchedTag(newValue) // set a copy of the searched person so we can change the position of the green marker when the person changes coords position.

					// handleSearchChange({
					// 	dispatch: props.dispatch,
					// 	map: props.map,
					// 	floorPlans: props.floorPlans,
					// 	floorPlan: props.floorPlan,
					// 	value: newValue,
					// 	// devices: props.devices, // not in use, yet.
					// 	// bleDevices: props.bleDevices, // not in use, yet.
					// 	setGoTo: props.setGoTo,
					// 	setShowPeople: props.setShowPeople,
					// 	setShowAssets: props.setShowAssets,
					// 	setShowPOIs: props.setShowPOIs,
					// 	setShowAreaInfoMarkers: props.setShowAreaInfoMarkers,
					// 	showRoleAndTypeFunction: props.showRoleAndTypeFunction,
					// 	markersSize: props.markersSize
					// })
				}}
			/>
		</div>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		"& .MuiOutlinedInput-root": {
			width: "230px"
			// textDecoration: "underline",
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			// border: "none",
			border: "1px solid #4A4A68",
			borderRadius: "4px"
		},
		// ↓ focused input
		// "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		//   borderColor: "#8181a5",
		// },
		"& .MuiSvgIcon-root": {
			marginTop: "4px"
		}
	},
	inputPlaceholder: {
		// color: "#8C8CA1",
		"& .MuiInputBase-input::placeholder": {
			color: "#8C8CA1", // Set your desired color here
			fontSize: "12px",
			fontFamili: "Poppins",
			fontWeight: "500"
		}
	},
	filterDropdown: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "none"
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: "none"
		},

		"& .MuiSelect-root": {
			minWidth: "8rem"
		},

		"& .MuiSelect-outlined": {
			// border: "1px solid green",
			border: "none"
		},
		// ↓ focused input
		"&&&&  .Mui-focused .MuiOutlinedInput-notchedOutline": {
			// border: "1px solid #4A4A68",
			border: "none"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			// border: "1px solid #4A4A68",
			border: "none"
		}
	}
}))
