import React from "react"
import { makeStyles, FormHelperText, FormControl } from "@material-ui/core"
import { default as MuiAutocomplete } from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
	formControl: {
		width: "100%",
		marginTop: "2px"
	},
	autocomplete: {
		height: "45px",
		borderRadius: "4px",
		overflow: "hidden",
		"& .MuiAutocomplete-inputRoot": {
			padding: "6px"
		},
		"& .MuiAutcomplete-filled": {
			backgroundColor: "#ECF1F4",
			border: 0
		},
		"& .MuiAutcomplete-root": {
			//padding: 0,
			padding: "16px 12px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px",
			height: "50px",
			border: "none !important"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {},
		// Removed underline
		"& .MuiInput-underline:before": {
			borderBottom: "none"
		},
		"& .MuiInput-underline:after": {
			borderBottom: "none"
		},
		"&.MuiFilledInput-underline:before": {
			borderBottom: "none" // Remove the underline
		},
		"&.MuiFilledInput-underline:after": {
			borderBottom: "none" // Remove the underline after selection
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: "none !important"
		}
	},
	filled: {
		backgroundColor: "#ECF1F4",
		"& .MuiAutcomplete-underline": {
			display: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	selectWithSize: {
		backgroundColor: "#FFFFFF",
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		}
	},
	helperText: {
		margin: 0
	},
	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "12px",
		fontWeight: "500"
	},
	fieldError: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #F64E60 !important"
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #F64E60 !important"
		}
	}
}))

export function Autocomplete({
	label,
	withFeedbackLabel = true,
	form,
	field,
	size,
	customColor,
	variant = "outlined",
	placeholder,
	requiredField,
	...props
}) {
	const classes = useStyles()
	if (field && form) {
		var { name, value } = field
		var { touched, errors, setFieldValue, setFieldTouched } = form
	}
	const error = withFeedbackLabel && touched && touched[name] && errors && errors[name]

	return (
		<>
			{label && (
				<div style={{ marginBottom: "8px" }}>
					<span className={classes.label}>
						{label}
						{requiredField ? <sup style={{ color: "red" }}> *</sup> : ""}
					</span>
				</div>
			)}
			<div>
				<FormControl
					className={classes.formControl}
					variant={variant}
					size={size}
					error={error ? true : false}
				>
					<MuiAutocomplete
						style={{
							backgroundColor: customColor ? customColor : ""
						}}
						className={clsx(classes.autocomplete, error && classes.fieldError)}
						getOptionLabel={option => option.label}
						renderInput={params => {
							return <TextField {...params} placeholder={placeholder} variant={variant} />
						}}
						onBlur={() => {
							if (!touched && !setFieldTouched) {
								return
							}
							/*!touched[name] so it doesn't set touched true when it's already true*/
							/*Without this errors will always be one update behind the real value*/
							!touched[name] && setFieldTouched(name, true)
						}}
						onChange={(e, value) => {
							if (!touched && !setFieldTouched && !setFieldValue) {
								return
							}
							!touched[name] && setFieldTouched(name, true)
							setFieldValue(name, value)
						}}
						value={value}
						{...props}
					/>
					{error && (
						<FormHelperText className={classes.helperText}>{errors[name]}</FormHelperText>
					)}
				</FormControl>
			</div>
		</>
	)
}
