import moment from "moment"

// Helper function Get Days between dates array
var getDaysBetweenDates = function (startDate, endDate) {
	var now = startDate.clone(),
		dates = []

	while (now.isSameOrBefore(endDate)) {
		dates.push(now.format("ddd MMM D"))
		now.add(1, "days")
	}
	return dates
}

export const mockDataPeopleDay = {
	allDaysSeries: [16, 32],
	allDaysLabels: ["Absent people", "Active people"]
}
export const mockDataPeopleWeek = {
	allDaysSeries: [10, 38],
	allDaysLabels: ["Absent people", "Active people"]
}
export const mockDataPeopleMonth = {
	allDaysSeries: [8, 40],
	allDaysLabels: ["Absent people", "Active people"]
}

export const mockDataTrackingDay = date => {
	// console.log(date.format("ddd MMM D"), "date");

	const result = {
		series: [
			{
				name: "Builders",
				data: [22]
			},
			{
				name: "Supervisors",
				data: [4]
			},
			{
				name: "Plumbers",
				data: [12]
			},
			{
				name: "Electricians",
				data: [10]
			}
		],
		labels: [date.format("ddd MMM D")]
	}

	return result
}

export const mockDataTrackingWeek = date => {
	// console.log(date.format("MM/DD/YYYY"), "date");

	var endDate = moment(date)
	// console.log("🚀  endDate", endDate);
	var startDate = moment(date).subtract(6, "days")
	// console.log("🚀  startDate", startDate);

	var dateList = getDaysBetweenDates(startDate, endDate)
	// console.log(dateList);

	const result = {
		series: [
			{
				name: "Builders",
				data: [22, 20, 24, 26, 21, 20, 22]
			},
			{
				name: "Supervisors",
				data: [4, 6, 2, 3, 3, 6, 4]
			},
			{
				name: "Plumbers",
				data: [12, 10, 12, 11, 14, 10, 12]
			},
			{
				name: "Electricians",
				data: [10, 12, 6, 8, 10, 12, 10]
			}
		],
		labels: dateList
		// labels: [
		//   moment()
		//     .subtract(1, "days")
		//     .format("ddd MMM D"),
		// ],
	}

	return result
}

export const mockDataTrackingMonth = date => {
	// console.log(date.format("MM/DD/YYYY"), "date");

	var endDate = moment(date)
	// console.log("🚀  endDate", endDate);
	var startDate = moment(date).subtract(1, "months")
	// console.log("🚀  startDate", startDate);

	var dateList = getDaysBetweenDates(startDate, endDate)
	// console.log(dateList);

	const result = {
		series: [
			{
				name: "Builders",
				data: [
					15, 20, 24, 26, 21, 20, 22, 15, 20, 24, 26, 21, 20, 22, 15, 20, 24, 26, 21, 20, 22,
					15, 20, 24, 26, 21, 20, 22, 22, 20, 24, 26
				]
			},
			{
				name: "Supervisors",
				data: [
					15, 20, 24, 26, 21, 20, 22, 15, 20, 24, 26, 21, 20, 22, 15, 20, 24, 26, 21, 20, 22,
					15, 20, 24, 26, 21, 20, 22, 4, 6, 2, 3
				]
			},
			{
				name: "Plumbers",
				data: [
					11, 10, 12, 11, 14, 10, 12, 11, 10, 12, 11, 14, 10, 12, 11, 10, 12, 11, 14, 10, 12,
					11, 10, 12, 11, 14, 10, 12, 12, 10, 12, 11
				]
			},
			{
				name: "Electricians",
				data: [
					11, 10, 12, 11, 14, 10, 12, 11, 10, 12, 11, 14, 10, 12, 11, 10, 12, 11, 14, 10, 12,
					11, 10, 12, 11, 14, 10, 12, 10, 12, 6, 8
				]
			}
		],
		labels: dateList
		// labels: [
		//   moment()
		//     .subtract(1, "days")
		//     .format("ddd MMM D"),
		// ],
	}
	return result
}

export const mockDataRoleDay = date => {
	// console.log("🚀  date", date);

	const result = {
		series: [
			{
				name: "Builders",
				data: [22]
			},
			{
				name: "Supervisors",
				data: [4]
			},
			{
				name: "Plumbers",
				data: [12]
			},
			{
				name: "Electricians",
				data: [10]
			}
		],
		labels: [date.format("ddd MMM D")],
		// labels: [
		//   moment()
		//     .subtract(1, "days")
		//     .format("ddd MMM D"),
		// ],
		colors: ["#323389", "#0058A9", "#0077B8", "#0095BA", "#00B0B3", "#52C8AB"]
	}

	return result
}

export const mockDataRoleWeek = date => {
	// console.log(date.format("MM/DD/YYYY"), "date");

	var endDate = moment(date)
	// console.log("🚀  endDate", endDate);
	var startDate = moment(date).subtract(6, "days")
	// console.log("🚀  startDate", startDate);

	var dateList = getDaysBetweenDates(startDate, endDate)
	// console.log(dateList);

	const result = {
		series: [
			{
				name: "Builders",
				data: [22, 20, 24, 26, 21, 20, 22]
			},
			{
				name: "Supervisors",
				data: [4, 6, 2, 3, 3, 6, 4]
			},
			{
				name: "Plumbers",
				data: [12, 10, 12, 11, 14, 10, 12]
			},
			{
				name: "Electricians",
				data: [10, 12, 6, 8, 10, 12, 10]
			}
		],
		labels: dateList,
		colors: ["#323389", "#0058A9", "#0077B8", "#0095BA", "#00B0B3", "#52C8AB"]
	}

	return result
}
export const mockDataRoleMonth = date => {
	// console.log(date.format("MM/DD/YYYY"), "date");

	var endDate = moment(date)
	// console.log("🚀  endDate", endDate);
	var startDate = moment(date).subtract(1, "months")
	// console.log("🚀  startDate", startDate);

	var dateList = getDaysBetweenDates(startDate, endDate)
	// console.log(dateList);

	const result = {
		series: [
			{
				name: "Builders",
				data: [
					15, 20, 24, 26, 21, 20, 22, 15, 20, 24, 26, 21, 20, 22, 15, 20, 24, 26, 21, 20, 22,
					15, 20, 24, 26, 21, 20, 22, 22, 20, 24, 26
				]
			},
			{
				name: "Supervisors",
				data: [
					15, 20, 24, 26, 21, 20, 22, 15, 20, 24, 26, 21, 20, 22, 15, 20, 24, 26, 21, 20, 22,
					15, 20, 24, 26, 21, 20, 22, 4, 6, 2, 3
				]
			},
			{
				name: "Plumbers",
				data: [
					11, 10, 12, 11, 14, 10, 12, 11, 10, 12, 11, 14, 10, 12, 11, 10, 12, 11, 14, 10, 12,
					11, 10, 12, 11, 14, 10, 12, 12, 10, 12, 11
				]
			},
			{
				name: "Electricians",
				data: [
					11, 10, 12, 11, 14, 10, 12, 11, 10, 12, 11, 14, 10, 12, 11, 10, 12, 11, 14, 10, 12,
					11, 10, 12, 11, 14, 10, 12, 10, 12, 6, 8
				]
			}
		],
		labels: dateList,
		colors: ["#323389", "#0058A9", "#0077B8", "#0095BA", "#00B0B3", "#52C8AB"]
	}

	return result
}

const dateToday = moment().subtract(1, "days").format("DD-MM-YYYY")

export const mockDataRowsPerRoleDay = date => {
	// console.log("🚀  date", date);
	const result = [
		{
			id: 1,
			day: date.format("DD-MM-YYYY"),
			role: "Electrician",
			workers: "10",
			hours: 10 * 8
		},
		{
			id: 2,
			day: date.format("DD-MM-YYYY"),
			role: "Plumber",
			workers: "25",
			hours: 12 * 8
		},
		{
			id: 3,
			day: date.format("DD-MM-YYYY"),
			role: "Supervisor",
			workers: "4",
			hours: 5 * 8
		},
		{
			id: 4,
			day: date.format("DD-MM-YYYY"),
			role: "Builder",
			workers: "22",
			hours: 22 * 8
		}
	]
	// console.log("🚀  result", result);
	return result
}

function randomIntFromInterval(min, max) {
	// min and max included
	return Math.floor(Math.random() * (max - min + 1) + min)
}

export const mockDataRowsPerRoleMonth = date => {
	const endDate = moment(date)
	// console.log("🚀  endDate", endDate);
	const startDate = moment(date).subtract(1, "months")
	// console.log("🚀  startDate", startDate);

	const dateList = getDaysBetweenDates(startDate, endDate)
	// console.log(dateList, "dateList");

	const newRowMonth = []

	dateList &&
		dateList.forEach((date, i) => {
			let elecRes = Math.floor(Math.random() * 10) + 1
			let plumRes = Math.floor(Math.random() * 16) + 1
			let supRes = Math.floor(Math.random() * 4) + 1
			let buildRes = Math.floor(Math.random() * 18) + 1

			newRowMonth.push(
				{
					id: i,
					day: date,
					role: "Electrician",
					workers: elecRes,
					// workers: "15",
					hours: elecRes * 8 + "h"
				},
				{
					id: i,
					day: date,
					role: "Plumber",
					workers: plumRes,
					// workers: "25",
					hours: plumRes * 8 + "h"
				},
				{
					id: i,
					day: date,
					role: "Supervisor",
					workers: supRes,
					// workers: "5",
					hours: supRes * 8 + "h"
				},
				{
					id: i,
					day: date,
					role: "Builder",
					workers: buildRes,
					// workers: "75",
					hours: buildRes * 8 + "h"
				}
			)
		})

	const newArrWithIds = []
	newRowMonth &&
		newRowMonth.forEach((row, i) => {
			newArrWithIds.push({
				...row,
				id: i + 1
			})
		})

	// console.log(newRowMonth, "newRowMonth");
	// console.log("🚀  newArrWithIds", newArrWithIds);

	return newArrWithIds
}

function getDaysArrayByMonth() {
	var daysInMonth = moment().daysInMonth()
	var arrDays = []

	while (daysInMonth) {
		const current = moment().date(daysInMonth).format("DD-MM-YYYY")
		// console.log("🚀  current", current);

		const dateToday = moment().subtract(1, "days").format("DD-MM-YYYY")
		// console.log("🚀  dateToday", dateToday);

		if (current <= dateToday) {
			arrDays.push(current)
		}

		daysInMonth--
	}

	//   console.log(arrDays, "arrDays");

	return arrDays
}

function getDaysArrayByWeek() {
	var daysInMonth = moment().daysInMonth()
	//   console.log("🚀  daysInMonth", daysInMonth);
	var arrDays = []

	while (daysInMonth) {
		const current = moment().date(daysInMonth).format("DD-MM-YYYY")
		// console.log("🚀  current", current);

		const dateToday = moment().subtract(1, "days").format("DD-MM-YYYY")
		// console.log("🚀  dateToday", dateToday);

		const weekFromToday = moment().subtract(7, "days").format("DD-MM-YYYY")
		// console.log("🚀  dateToday", dateToday);

		if (current >= weekFromToday && current <= dateToday) {
			arrDays.push(current)
		}

		daysInMonth--
	}

	//   console.log(arrDays, "arrDays");

	return arrDays
}

export const mockDataRowsPerRoleWeek = date => {
	const endDate = moment(date)
	// console.log("🚀  endDate", endDate);
	const startDate = moment(date).subtract(6, "days")
	// console.log("🚀  startDate", startDate);

	const dateList = getDaysBetweenDates(startDate, endDate)
	// console.log(dateList, "dateList");

	const newRowWeek = []

	dateList &&
		dateList.forEach((date, i) => {
			let elecRes = Math.floor(Math.random() * 10) + 1
			let plumRes = Math.floor(Math.random() * 16) + 1
			let supRes = Math.floor(Math.random() * 4) + 1
			let buildRes = Math.floor(Math.random() * 18) + 1

			newRowWeek.push(
				{
					id: i,
					day: date,
					role: "Electrician",
					workers: elecRes,
					// workers: "15",
					hours: elecRes * 8 + "h"
				},
				{
					id: i,
					day: date,
					role: "Plumber",
					workers: plumRes,
					// workers: "25",
					hours: plumRes * 8 + "h"
				},
				{
					id: i,
					day: date,
					role: "Supervisor",
					workers: supRes,
					// workers: "5",
					hours: supRes * 8 + "h"
				},
				{
					id: i,
					day: date,
					role: "Builder",
					workers: buildRes,
					// workers: "75",
					hours: buildRes * 8 + "h"
				}
			)
		})

	const newArrWithIds = []
	newRowWeek &&
		newRowWeek.forEach((row, i) => {
			newArrWithIds.push({
				...row,
				id: i + 1
			})
		})

	// console.log("🚀  newRowWeek", newRowWeek);
	// console.log("🚀  newArrWithIds", newArrWithIds);

	return newArrWithIds
}

export const mockDataRowsDailyContractedDay = date => {
	const result = [
		{
			id: 1,
			day: date.format("DD-MM-YYYY"),
			worker: "Phil Lewis",
			role: "Electrician",
			start: "8:00AM",
			end: "13:00PM",
			hours: "5:00h",
			extraHours: "-"
		},
		{
			id: 2,
			day: date.format("DD-MM-YYYY"),
			worker: "Joshua Basset",
			role: "Plumber",
			start: "9:00AM",
			end: "18:00PM",
			hours: "9:00h",
			extraHours: "-"
		},
		{
			id: 3,
			day: date.format("DD-MM-YYYY"),
			worker: "Olivia Holt",
			role: "Supervisor",
			start: "9:00AM",
			end: "16:00PM",
			hours: "7:00h",
			extraHours: "-"
		},
		{
			id: 4,
			day: date.format("DD-MM-YYYY"),
			worker: "Marc Garcia",
			role: "Builder",
			start: "8:00AM",
			end: "12:30PM",
			hours: "4:30h",
			extraHours: "-"
		},
		{
			id: 5,
			day: date.format("DD-MM-YYYY"),
			worker: "Taylor Lopez",
			role: "Builder",
			start: "8:00AM",
			end: "14:30PM",
			hours: "6:30h",
			extraHours: "-"
		},
		{
			id: 6,
			day: date.format("DD-MM-YYYY"),
			worker: "Will Brown",
			role: "Builder",
			start: "8:00AM",
			end: "13:00PM",
			hours: "5:00h",
			extraHours: "-"
		},
		{
			id: 7,
			day: date.format("DD-MM-YYYY"),
			worker: "Bratt Jones",
			role: "Builder",
			start: "9:00AM",
			end: "17:30PM",
			hours: "8:30h",
			extraHours: "-"
		}
	]

	return result
}

export const mockDataRowsDailyContractedMonth = date => {
	const endDate = moment(date)
	// console.log("🚀  endDate", endDate);
	const startDate = moment(date).subtract(1, "months")
	// console.log("🚀  startDate", startDate);

	const dateList = getDaysBetweenDates(startDate, endDate)
	// console.log(dateList, "dateList");

	const newRowMonth = []

	dateList &&
		dateList.forEach((date, i) => {
			newRowMonth.push(
				{
					id: i,
					day: date,
					worker: "Phil Lewis",
					role: "Electrician",
					start: "8:00AM",
					end: "13:00PM",
					hours: "5:00h",
					extraHours: "-"
				},
				{
					id: i,
					day: date,
					worker: "Joshua Basset",
					role: "Plumber",
					start: "9:00AM",
					end: "18:00PM",
					hours: "9:00h",
					extraHours: "-"
				},
				{
					id: i,
					day: date,
					worker: "Olivia Holt",
					role: "Supervisor",
					start: "9:00AM",
					end: "16:00PM",
					hours: "7:00h",
					extraHours: "-"
				},
				{
					id: i,
					day: date,
					worker: "Marc Garcia",
					role: "Builder",
					start: "8:00AM",
					end: "12:30PM",
					hours: "4:30h",
					extraHours: "-"
				},
				{
					id: i,
					day: date,
					worker: "Taylor Lopez",
					role: "Builder",
					start: "8:00AM",
					end: "14:30PM",
					hours: "6:30h",
					extraHours: "-"
				},
				{
					id: i,
					day: date,
					worker: "Will Brown",
					role: "Builder",
					start: "8:00AM",
					end: "13:00PM",
					hours: "5:00h",
					extraHours: "-"
				},
				{
					id: i,
					day: date,
					worker: "Bratt Jones",
					role: "Electrician",
					start: "9:00AM",
					end: "17:30PM",
					hours: "8:30h",
					extraHours: "-"
				}
			)
		})

	const newArrWithIds = []
	newRowMonth &&
		newRowMonth.forEach((row, i) => {
			newArrWithIds.push({
				...row,
				id: i + 1
			})
		})

	// console.log("🚀  newArrWithIds", newArrWithIds);

	return newArrWithIds
}
export const mockDataRowsDailyContractedWeek = date => {
	const endDate = moment(date)
	// console.log("🚀  endDate", endDate);
	const startDate = moment(date).subtract(6, "days")
	// console.log("🚀  startDate", startDate);

	const dateList = getDaysBetweenDates(startDate, endDate)
	// console.log(dateList, "dateList");

	const newRowWeek = []

	dateList &&
		dateList.forEach((date, i) => {
			newRowWeek.push(
				{
					id: i,
					day: date,
					worker: "Phil Lewis",
					role: "Electrician",
					start: "8:00AM",
					end: "13:00PM",
					hours: "5:00h",
					extraHours: "-"
				},
				{
					id: i,
					day: date,
					worker: "Joshua Basset",
					role: "Plumber",
					start: "9:00AM",
					end: "18:00PM",
					hours: "9:00h",
					extraHours: "-"
				},
				{
					id: i,
					day: date,
					worker: "Olivia Holt",
					role: "Supervisor",
					start: "9:00AM",
					end: "16:00PM",
					hours: "7:00h",
					extraHours: "-"
				},
				{
					id: i,
					day: date,
					worker: "Marc Garcia",
					role: "Builder",
					start: "8:00AM",
					end: "12:30PM",
					hours: "4:30h",
					extraHours: "-"
				},
				{
					id: i,
					day: date,
					worker: "Taylor Lopez",
					role: "Builder",
					start: "8:00AM",
					end: "14:30PM",
					hours: "6:30h",
					extraHours: "-"
				},
				{
					id: i,
					day: date,
					worker: "Will Brown",
					role: "Builder",
					start: "8:00AM",
					end: "13:00PM",
					hours: "5:00h",
					extraHours: "-"
				},
				{
					id: i,
					day: date,
					worker: "Bratt Jones",
					role: "Electrician",
					start: "9:00AM",
					end: "17:30PM",
					hours: "8:30h",
					extraHours: "-"
				}
			)
		})

	const newArrWithIds = []
	newRowWeek &&
		newRowWeek.forEach((row, i) => {
			newArrWithIds.push({
				...row,
				id: i + 1
			})
		})

	// console.log(newRowWeek, "newRowWeek");
	// console.log("🚀  newArrWithIds", newArrWithIds);

	return newArrWithIds
}
