import React from "react"
import { makeStyles, TextField } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { searchHandleMap } from "../../map-infra-helpers/SearchHelpers"

const NewSearchSensors = ({
	drawerOptions,
	map,
	dispatch,
	activeSensors = [],
	positionSensors = [],
	floorPlans,
	floorPlan,
	setGoTo,
	setShowSensorsOnline
}) => {
	const _activeSensors = activeSensors
		? activeSensors
				.map(option => {
					return {
						group: "Active Sensors",
						...option
					}
				})
				.sort((a, b) => -b.group.localeCompare(a.group))
		: []

	const _positionSensors = positionSensors
		? positionSensors
				.map(option => {
					return {
						group: "Position Sensors",
						...option
					}
				})
				.sort((a, b) => -b.group.localeCompare(a.group))
		: []

	const options = [..._activeSensors, ..._positionSensors]

	return (
		<div
			className="wrapper_new_search_bar"
			style={{
				padding: "6px 5px"
			}}
		>
			<div className="container_new_search_bar">
				{drawerOptions.openSearch ? (
					<div className="dropdowns_new_wrapper">
						<CustomAutocomplete
							options={options}
							map={map}
							dispatch={dispatch}
							floorPlans={floorPlans}
							floorPlan={floorPlan}
							setGoTo={setGoTo}
							setShowSensorsOnline={setShowSensorsOnline}
						/>
					</div>
				) : null}
			</div>
		</div>
	)
}

export default NewSearchSensors

function CustomAutocomplete(props) {
	const classes = useStyles()

	return (
		<div>
			<Autocomplete
				disablePortal
				className={classes.root}
				id="livemap_drawer_search_autocomplete_input"
				size="small"
				// defaultValue={props.defaultValue || ""}
				options={props.options || []}
				groupBy={option => option.group}
				getOptionLabel={option => option.node_name || ""}
				getOptionSelected={(option, value) => option.node_name === value?.node_name}
				renderInput={params => {
					return (
						<>
							<TextField
								id="livemap_drawer_search_autocomplete_textfield"
								{...params}
								variant="outlined"
								className={classes.inputPlaceholder}
								placeholder={
									!props.searchFilteredOptions
										? "Search in All Categories"
										: `Search in ${props.searchFilteredOptions}`
								}
								// value={props.goTo && props.goTo?.node_name}
							/>
						</>
					)
				}}
				// filter all options by the select dropdown value
				// filterOptions={options => filterSearchBar(options)}
				// filterOptions={filterSearchBar}
				onChange={(event, newValue) => {
					handleSearchChange({
						dispatch: props.dispatch,
						map: props.map,
						floorPlans: props.floorPlans,
						floorPlan: props.floorPlan,
						value: newValue,
						setGoTo: props.setGoTo,
						setShowSensorsOnline: props.setShowSensorsOnline
					})
				}}
			/>
		</div>
	)
}

function handleSearchChange(props) {
	const { dispatch, map, floorPlans, floorPlan, value, setGoTo, setShowSensorsOnline } = props

	if (!value) {
		searchHandleMap({ map, floorPlan, setGoTo })
		return
	}

	// console.log(value, "value -> handleSearchChange")

	var coords
	var polygon
	let lastSeen

	switch (value.group) {
		case "Active Sensors":
			if (!value.gpsData) break
			lastSeen = null
			coords = [value.gpsData.longitude, value.gpsData.latitude]
			break
		case "Position Sensors":
			if (!value.gpsData) break
			lastSeen = null
			coords = [value.gpsData.longitude, value.gpsData.latitude]
			break
	}

	searchHandleMap({
		floorPlans,
		map,
		floorPlan,
		goTo: value,
		setGoTo,
		coords,
		polygon,
		dispatch,
		lastSeen,
		setShowSensorsOnline
	})
}

const useStyles = makeStyles(() => ({
	root: {
		"& .MuiOutlinedInput-root": {
			width: "230px"
			// textDecoration: "underline",
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			// border: "none",
			border: "1px solid #4A4A68",
			borderRadius: "4px"
		},
		// ↓ focused input
		// "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		//   borderColor: "#8181a5",
		// },
		"& .MuiSvgIcon-root": {
			marginTop: "4px"
		}
	},
	inputPlaceholder: {
		// color: "#8C8CA1",
		"& .MuiInputBase-input::placeholder": {
			color: "#8C8CA1", // Set your desired color here
			fontSize: "12px",
			fontFamili: "Poppins",
			fontWeight: "500"
		}
	},
	filterDropdown: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "none"
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: "none"
		},

		"& .MuiSelect-root": {
			minWidth: "8rem"
		},

		"& .MuiSelect-outlined": {
			// border: "1px solid green",
			border: "none"
		},
		// ↓ focused input
		"&&&&  .Mui-focused .MuiOutlinedInput-notchedOutline": {
			// border: "1px solid #4A4A68",
			border: "none"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			// border: "1px solid #4A4A68",
			border: "none"
		}
	}
}))
