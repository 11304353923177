import React from "react"

export function ButtonText({ text, color }) {
	return (
		<span
			style={{
				fontFamily: "Poppins",
				fontWeight: "bold",
				fontSize: "16px",
				letterSpacing: "4%",
				color: color
			}}
		>
			{text}
		</span>
	)
}
