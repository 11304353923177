import React, { useState, useEffect } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody } from "../../../../_partials/Card"
import { Dropdown } from "react-bootstrap"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"
import SearchIcon from "@material-ui/icons/Search"
import { SearchBarCustom1 } from "../../../../_partials/inputs/SearchBarCustom1"
//for data grid
import { Pagination } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core"
import { DataGrid } from "@mui/x-data-grid"
import {
	filterBySearchString,
	totalHoursComparator,
	dayMonthYearComparator
} from "../../../../helpersGlobal/DataGridHelpers"
import Box from "@mui/material/Box"
import { useGridApiContext, useGridRootProps } from "@mui/x-data-grid"

//for data grid
const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		border: "none",
		"& .MuiPagination-root makeStyles-root-130": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},

		"& .MuiDataGrid-cell:focus": {
			outline: "none"
		},
		"& .MuiDataGrid-selectedRowCount": {
			display: "none"
		},
		"& .MuiDataGrid-colCell": {
			minWidth: "0" // Ensure that minWidth is set to 0 to allow percentage width
		},
		"& .MuiDataGrid-columnSeparator": {
			opacity: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
			fontWeight: 600,
			fontSize: "1rem",
			color: "#4a4a68"
		},
		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--textLeft": {
			fontSize: "14px",
			fontWeight: 500
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			paddingLeft: 0,
			paddingRight: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
			paddingRight: "0px",
			paddingLeft: "10px"
		},
		"& .hide-scrollbar .MuiDataGrid-scrollbar": {
			display: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
			paddingLeft: "2px"
		},

		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
			maxHeight: "65px !important",
			minHeight: "65px !important"
		},
		"& .MuiPagination-root": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		"& .MuiPaginationItem-root": {
			borderRadius: "3px !important",
			color: "#3077d3 !important",
			padding: "0 !important",
			margin: "-1px !important",
			border: "1px solid #f4f8fe !important"
		},

		"& .MuiPaginationItem-textPrimary.Mui-selected": {
			color: "#fff !important",
			border: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon, & .MuiDataGrid-root .MuiDataGrid-filterIcon, & .MuiDataGrid-root .MuiDataGrid-sortIcon":
			{
				color: "#3077d3"
			},
		"& .MuiDataGrid-root .MuiDataGrid-viewport": {
			minHeight: "700px",
			maxHeight: "none"
		},
		"& .MuiDataGrid-renderingZone": {
			maxHeight: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
			minHeight: "90px !important"
		}
	},

	"& .MuiDataGrid-columnSeparator": {
		opacity: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
		fontWeight: "600 !important",
		fontSize: "1rem",
		color: "#4a4a68"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--textLeft": {
		fontSize: "14px !important",
		fontWeight: "500 !important"
	},

	"& .MuiDataGrid-columnHeaderTitleContainer": {
		paddingLeft: "0 !important",
		paddingRight: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
		paddingRight: "0px !important",
		paddingLeft: "10px !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
		paddingLeft: "2px"
	},

	"& .MuiDataGrid-root .MuiDataGrid-row:hover": {
		backgroundColor: "inherit !important"
	},

	"& .MuiDataGrid-colCell": {
		minWidth: 0
	},

	"& .actions-header": {
		marginLeft: "10px"
	},

	"& .MuiDataGrid-row": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiPagination-root": {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},

	"& .MuiPaginationItem-root": {
		borderRadius: "3px !important",
		color: "#3077d3 !important",
		padding: "0 !important",
		margin: "0 !important",
		border: "1px solid #f4f8fe !important"
	},

	"& .MuiPaginationItem-textPrimary.Mui-selected": {
		color: "#fff !important",
		border: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon":
		{
			color: "#3077d3"
		},

	"& .MuiDataGrid-root .MuiDataGrid-sortIcon, .MuiDataGrid-root .MuiDataGrid-filterIcon": {
		color: "#3077d3"
	},

	"& .MuiDataGrid-root .MuiDataGrid-viewport": {
		minHeight: "700px",
		maxHeight: "none"
	},

	"& .MuiDataGrid-renderingZone": {
		maxHeight: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
		minHeight: "90px !important"
	}
}))

//for data grid
function CustomPagination() {
	const apiRef = useGridApiContext()
	const state = apiRef.current.state
	const rootElement = apiRef.current.rootElementRef
	const rootProps = useGridRootProps()
	const classes = useStyles()
	const options = [5, 10, 20, 30, 50, 100]

	const handlePageSizeChange = pageSize => {
		apiRef.current.setPageSize(pageSize)
	}

	return (
		<>
			<Dropdown>
				<Dropdown.Toggle variant="light" id="dropdown-basic">
					{state.pagination.pageSize}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{options.map(option => {
						return (
							<Dropdown.Item
								key={option}
								as="button"
								onClick={() => handlePageSizeChange(option)}
							>
								{option}
							</Dropdown.Item>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>

			<Pagination
				className={classes.root}
				id="custom-pagination"
				color="primary"
				count={state.pagination.pageCount}
				page={state.pagination.page + 1}
				onChange={(event, value) => apiRef.current.setPage(value - 1)}
			/>
		</>
	)
}

export function TimesheetPerRole() {
	const classes = useStyles()

	const { isLoading, allPeopleTimesheet } = useSelector(
		state => ({
			isLoading: state.reporting.allPeopleTimesheetLoading,
			allPeopleTimesheet: state.reporting.allPeopleTimesheet
		}),
		shallowEqual
	)

	const columns = [
		{
			field: "day",
			headerName: "Day",
			width: 300,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			sortComparator: dayMonthYearComparator
		},
		{
			field: "role",
			headerName: "Role",
			width: 300,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "counter",
			headerName: "Number of people",
			width: 300,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "duration",
			headerName: "Total Hours",
			width: 300,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			sortComparator: totalHoursComparator
		}
	]

	//added for data grid
	const [rows, setRows] = useState([])
	const [searchText, setSearchText] = useState("")
	const [page, setPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [searchedRows, setSearchedRows] = useState(null)
	const [rowsToUse, setRowsToUse] = useState([])
	const [currentRows, setCurrentRows] = useState([])
	const [rowCounter, setRowCounter] = useState(0)
	const [rowCountState, setRowCountState] = useState(rowCounter)
	const [sortModel, setSortModel] = useState([
		{
			field: "role",
			sort: "asc"
		}
	])

	//for data grid

	// Give marginTop to the pagination at bottom of table
	useEffect(() => {
		if (!allPeopleTimesheet) return

		// Give marginTop on paginationFactory div
		const htmlCollection = document.getElementsByClassName("react-bootstrap-table-pagination")

		if (htmlCollection) {
			Array.from(htmlCollection).forEach(element => {
				element.style.marginTop = "2rem"
			})
		}
	}, [allPeopleTimesheet])

	//added for data grid
	const handleSearch = value => {
		// Reset column sorting when performing a search
		setSortModel([
			{
				field: "role",
				sort: "asc"
			}
		])

		setSearchText(value)
	}

	//added for data grid
	// Update the handleSortModelChange function in your PeopleTable component
	const handleSortModelChange = newSortModel => {
		// Sort the full dataset (rowsToUse) based on the sort model
		const sortedRows = [...rowsToUse].sort((a, b) => {
			// Iterate over the sort model
			for (const sortItem of newSortModel) {
				const field = sortItem.field
				const sortOrder = sortItem.sort === "asc" ? 1 : -1 // Only consider asc and desc
				if (sortItem.sort !== null) {
					if (field === "disabled") {
						// Sort based on boolean value for the "disabled" field
						if (a[field] && !b[field]) return sortOrder
						if (!a[field] && b[field]) return -sortOrder
						continue // Move to the next sort item
					}
					// For other fields, handle sorting based on their type
					if (a[field] === null && b[field] === null) continue // If both values are null, maintain current order
					if (a[field] === null) return -sortOrder // If 'a' is null, place it before 'b'
					if (b[field] === null) return sortOrder // If 'b' is null, place it before 'a'
					// Sort non-null values based on column type
					if (typeof a[field] === "string") {
						const comparison = a[field].localeCompare(b[field], undefined, { numeric: true })
						if (comparison !== 0) return comparison * sortOrder
					} else if (typeof a[field] === "number") {
						return (a[field] - b[field]) * sortOrder
					}
				}
			}
			return 0 // If no column has a defined sort order, maintain current order
		})

		// Update the sorted data and currentRows
		setRowsToUse(sortedRows) //all data
		setCurrentRows(sortedRows.slice(page * pageSize, (page + 1) * pageSize))
		setSortModel(newSortModel)
	}

	//added for data grid
	useEffect(() => {
		if (!allPeopleTimesheet) return

		let allPeopleTimesheetData = [...allPeopleTimesheet]

		allPeopleTimesheetData.sort((a, b) => {
			const roleA = a.role.toLowerCase()
			const roleB = b.role.toLowerCase()

			if (roleA < roleB) {
				return -1
			}
			if (roleA > roleB) {
				return 1
			}
			return 0
		})

		setRows(allPeopleTimesheetData)
	}, [allPeopleTimesheet])

	//added for data grid
	useEffect(() => {
		if (!rows) return

		if (!searchText && searchText.trim() == "") setSearchedRows(filteredRows)
		// return

		const fieldsToSearch = ["day", "role", "counter", "duration"] // Define the fields to search
		const filteredRows = filterBySearchString(rows, searchText, fieldsToSearch)

		if (filteredRows && filteredRows.length > 0) {
			setSearchedRows(filteredRows)
		} else {
			setSearchedRows([])
		}
	}, [rows, searchText])

	//added for data grid
	useEffect(() => {
		if (!rows && !searchedRows) return

		if (searchedRows) {
			setRowsToUse(searchedRows)
		} else if (rows && rows.length > 0) {
			setRowsToUse(rows)
		}
	}, [rows, searchedRows])

	//added for data grid
	// Update current rows when rows or current page changes
	useEffect(() => {
		updateCurrentRows()
	}, [rowsToUse, page, pageSize])

	//added for data grid
	// Function to update the current rows based on the current page and page size
	const updateCurrentRows = () => {
		const startIndex = page * pageSize
		const endIndex = Math.min(startIndex + pageSize, rowsToUse.length)
		const newCurrentRows = rowsToUse.slice(startIndex, endIndex)
		setCurrentRows(newCurrentRows)
		setRowCounter(rowsToUse.length)
	}

	//added for data grid
	useEffect(() => {
		setRowCountState(prevRowCountState =>
			rowCounter !== undefined ? rowCounter : prevRowCountState
		)
	}, [rowCounter])

	return (
		<Card>
			<CardBody
				style={{
					height: "auto"
					// height: "600px"
				}}
			>
				<div>
					<div className="row">
						<div className="col p-0">
							<h6
								style={{
									marginBottom: "0.1rem",
									fontWeight: "600",
									fontSize: "1.275rem",
									color: "#212121"
								}}
							>
								Total contracted workers per role timesheet
							</h6>
							<span
								style={{
									fontWeight: "500",
									fontSize: "0.925rem",
									color: "#B5B5C3"
								}}
							>
								Timesheet of contracted workers
							</span>
						</div>

						<div className="col d-flex justify-content-end align-items-end">
							<SearchBarCustom1
								onSearch={handleSearch}
								searchText={searchText}
								disabled={isLoading ? true : false}
								placeholder="Search"
								size="small"
								style={{
									width: "200px",
									background: "#F2F2F2",
									marginRight: "10px"
								}}
								InputProps={{
									startAdornment: (
										<SearchIcon
											fontSize="small"
											style={{
												color: "#7F8C8D",
												marginRight: "0.7rem"
											}}
										/>
									)
								}}
							/>
						</div>
					</div>

					<div
						style={{
							height: "2rem"
						}}
					></div>

					{isLoading && <ModalProgressBar />}
					<div className={classes.root}>
						<Box sx={{ width: "100%" }}>
							<DataGrid
								rows={currentRows}
								columns={columns}
								style={{
									fontFamily: "Poppins",
									fontWeight: 400,
									fontSize: "13px",
									border: "none",
									width: "100%",
									color: "#464e5f"
								}}
								className={classes.root}
								density="standard"
								// disableExtendRowFullWidth={true}
								pagination
								disableSelectionOnClick
								loading={isLoading}
								disableColumnMenu={true}
								components={{
									Pagination: CustomPagination
								}}
								rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
								page={page}
								onPageChange={newPage => {
									setPage(newPage)
								}}
								pageSize={pageSize}
								onPageSizeChange={e => {
									setPageSize(e)
								}}
								autoHeight
								rowCount={rowCountState}
								sortingOrder={["desc", "asc"]}
								sortModel={sortModel}
								onSortModelChange={handleSortModelChange}
							/>
						</Box>
					</div>
				</div>
			</CardBody>
		</Card>
	)
}
