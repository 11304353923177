import React, { useState, useEffect } from "react"
import { IconButton, Input } from "@material-ui/core"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers"
import CloseIcon from "@material-ui/icons/Close"
import { Button } from "../../../../../../_partials/Button"
import * as liveDataActions from "../../../../../_redux/liveData/liveDataActions"
import personImageActive from "../../../../../../assets/dashboard_markers/newMarkers/Marker-people-active.png"
import assetImageActive from "../../../../../../assets/dashboard_markers/newMarkers/Marker-assets-active.png"
import unassignedImageActive from "../../../../../../assets/dashboard_markers/newMarkers/Marker-unassigned-active.png"
import defaultPOIImg from "../../../../../../assets/dashboard_markers/newMarkers/points-of-interest/default point.png"
import areasInfoImg from "../../../../../../assets/dashboard_markers/newMarkers/areas-markers/area-info-black.png"
import anchorImg from "../../../../../../assets/dashboard_markers/newMarkers/anchors/anchor active cropped.png"
import gatewayImg from "../../../../../../assets/dashboard_markers/newMarkers/gateways/gateway.png"

const MarkersSizeSlider = ({
	setDrawerOptions,
	user,
	dispatch,
	drawerPreferences,
	markersSize,
	showConnectors,
	showPOIs,
	showPeople,
	showAssets,
	showUnassignedTags,
	showAreaInfoMarkers,
	showAnchors,
	showGateways,
	roleFilterButtonStates,
	assetFilterButtonStates
}) => {
	// Markers types and props
	const propertiesArray = [
		{
			type: "people",
			title: "People",
			defaultSize: 50,
			imageUrl: personImageActive
		},
		{
			type: "assets",
			title: "Assets",
			defaultSize: 50,
			imageUrl: assetImageActive
		},
		{
			type: "unassignedTags",
			title: "Unassigned Tags",
			defaultSize: 50,
			imageUrl: unassignedImageActive
		},
		{
			type: "pois",
			title: "Points of Interest",
			defaultSize: 50,
			imageUrl: defaultPOIImg
		},
		{
			type: "anchors",
			title: "Anchors",
			defaultSize: 50,
			imageUrl: anchorImg,
			width: "30px",
			marginBottom: "2px"
		},
		{
			type: "gateways",
			title: "Gateways",
			defaultSize: 50,
			imageUrl: gatewayImg,
			width: "28px"
		},
		{
			type: "areasInfo",
			title: "Areas info",
			defaultSize: 50,
			imageUrl: areasInfoImg,
			width: "24px"
		}
	]

	// initial sizes
	const initialMarkersSizes = propertiesArray.map(property => ({
		[property.type]: property.defaultSize
	}))

	// States
	const [oldMarkersSizes, setOldMarkersSizes] = useState(
		propertiesArray &&
			propertiesArray.map(property => ({
				[property.type]: property.defaultSize
			}))
	)
	const [newMarkersSizes, setNewMarkersSizes] = useState(
		propertiesArray &&
			propertiesArray.map(property => ({
				[property.type]: property.defaultSize
			}))
	)

	// Update Input values when markersSize changes
	useEffect(() => {
		if (markersSize && Object.keys(markersSize).length > 0) {
			if (markersSize) {
				const markersSizePixels = pixelsToPercentage(markersSize)
				// console.log("🚀  markersSizePixels:", markersSizePixels)

				if (markersSizePixels) {
					setNewMarkersSizes(prevSizes => {
						const newSizes = [...prevSizes]

						propertiesArray.forEach((property, index) => {
							newSizes[index] = {
								...newSizes[index],
								[property.type]:
									markersSizePixels[property.type] ||
									markersSizePixels[property.type] === 0
										? markersSizePixels[property.type]
										: property.defaultSize
							}
						})
						return newSizes
					})
				}
			}
		}
	}, [markersSize])

	const pixelsToPercentage = markersSizes => {
		const convertedSizes = {}
		let percentage

		// Loop through each marker type in the object
		Object.keys(markersSizes).forEach(type => {
			const pixels = markersSizes[type]
			// console.log("🚀  pixels:", pixels)

			if (pixels === 30) {
				percentage = 50
			} else if (pixels < 30) {
				percentage = 0
			} else if (pixels === 0) {
				percentage = 0
			} else if (pixels > 30) {
				percentage = 100
			}

			// console.log("🚀  percentage:", percentage)

			// Store the converted percentage in the result object
			convertedSizes[type] = percentage
		})

		return convertedSizes
	}

	const handleIncrement = index => {
		setOldMarkersSizes(newMarkersSizes) // Save the old values before incrementing
		setNewMarkersSizes(prevSizes => {
			const newSizes = [...prevSizes]
			const type = propertiesArray[index].type
			// console.log("🚀  propertiesArray[index]:", propertiesArray[index])

			newSizes[index] = {
				...newSizes[index],
				[type]: Math.min(newSizes[index][type] + 50, 100)
			}

			// Increment marker size handler
			handleSlider(type, newSizes[index][type], prevSizes[index][type])

			return newSizes
		})
	}

	const handleDecrement = index => {
		setOldMarkersSizes(newMarkersSizes) // Save the old values before decrementing
		setNewMarkersSizes(prevSizes => {
			const newSizes = [...prevSizes]
			const type = propertiesArray[index].type
			newSizes[index] = {
				...newSizes[index],
				[type]: Math.max(newSizes[index][type] - 50, 0)
			}

			// Decrement marker size handler
			handleSlider(type, newSizes[index][type], prevSizes[index][type])
			return newSizes
		})
	}

	// reset
	const handleReset = () => {
		setNewMarkersSizes(initialMarkersSizes)
		resetAndSave()
	}

	const resetAndSave = () => {
		const resetObject = {
			people: 30,
			assets: 30,
			unassignedTags: 30,
			pois: 30,
			anchors: 30,
			gateways: 30,
			areasInfo: 30
		}

		if (drawerPreferences && drawerPreferences.length !== 0) {
			const foundedPreferences = drawerPreferences.find(val => val.userId === user?.id)

			if (foundedPreferences) {
				dispatch(
					liveDataActions.saveUserDrawerPreferences([
						{
							userId: user?.id,
							preferences: {
								...foundedPreferences.preferences,
								markersSize: {
									...foundedPreferences.preferences.markersSize,
									...resetObject
								}
							}
						}
					])
				)
			} else {
				dispatch(
					liveDataActions.saveUserDrawerPreferences([
						{
							userId: user?.id,
							preferences: {
								connectors: showConnectors,
								pois: showPOIs,
								people: showPeople,
								assets: showAssets,
								unassignedTags: showUnassignedTags,
								areas: showAreaInfoMarkers,
								anchors: showAnchors,
								gateways: showGateways,
								roleFilter: roleFilterButtonStates || [],
								assetTypeFilter: assetFilterButtonStates || [],
								markersSize: {
									...resetObject
								}
							}
						}
					])
				)
			}
		} else {
			dispatch(
				liveDataActions.saveUserDrawerPreferences([
					{
						userId: user?.id || null,
						preferences: {
							connectors: showConnectors,
							pois: showPOIs,
							people: showPeople,
							assets: showAssets,
							unassignedTags: showUnassignedTags,
							areas: showAreaInfoMarkers,
							anchors: showAnchors,
							gateways: showGateways,
							roleFilter: roleFilterButtonStates || [],
							assetTypeFilter: assetFilterButtonStates || [],
							markersSize: {
								...resetObject
							}
						}
					}
				])
			)
		}
	}

	const handleSlider = (type, newValue, oldValue) => {
		// console.log("🚀  type:", type)
		// console.log("🚀  newValue:", newValue)
		// console.log("🚀  oldValue:", oldValue)
		// Ensure the percentage is a number between 0 and 100
		const validPercentage = Math.min(100, Math.max(0, parseFloat(newValue)))

		// Convert percentage to pixels (assuming a linear relationship)
		let pixels = (validPercentage / 100) * 60 // Adjust the multiplier as needed
		// console.log("🚀  pixels:", pixels)

		let sizeValue = pixels && pixels

		// Min and max new values of the pixels (width, height)
		if (newValue <= 20) {
			sizeValue = 25
		} else if (newValue >= 80) {
			sizeValue = 35
		}
		// console.log("🚀 🚀 🚀 🚀 🚀  sizeValue:", sizeValue)

		sizeValue && handleSave(type, sizeValue)

		// Note: We don't need this anymore, because we don't have html markers, have layers.
		// const markersDiv = document.getElementsByClassName(
		// 	"slider-markers-size markers-" + type
		// 	// "slider-markers-size markers-" + selectedOption
		// )
		// console.log("🤖 🤖 🤖 🤖 🤖 🤖 🤖 🤖 🤖  markersDiv:", markersDiv)

		// if (!markersDiv || markersDiv.length <= 0) return

		// // Convert the HTMLCollection to an array and use map
		// Array.from(markersDiv).map(div => {
		// 	console.log("🚀  div:", div)
		// 	div.display = "flex"
		// 	div.justifyContent = "center"
		// 	div.alignItems = "center"
		// 	div.style.width = sizeValue + "px"
		// 	div.style.height = sizeValue + "px"
		// })
	}

	const handleSave = (type, newSize) => {
		// console.log("👾 👾 👾 👾 👾 👾 👾 handleSave type:", type)
		// console.log("👾 👾 👾 👾 👾 👾 👾 handleSave newSize:", newSize)

		setTimeout(() => {
			if (drawerPreferences && drawerPreferences.length !== 0) {
				const foundedPreferences = drawerPreferences.find(val => val.userId === user?.id)

				if (foundedPreferences) {
					dispatch(
						liveDataActions.saveUserDrawerPreferences([
							{
								userId: user?.id,
								preferences: {
									...foundedPreferences.preferences,
									markersSize: {
										...foundedPreferences.preferences.markersSize,
										[type]: newSize
									}
								}
							}
						])
					)
				} else {
					dispatch(
						liveDataActions.saveUserDrawerPreferences([
							{
								userId: user?.id,
								preferences: {
									connectors: showConnectors,
									pois: showPOIs,
									people: showPeople,
									assets: showAssets,
									unassignedTags: showUnassignedTags,
									areas: showAreaInfoMarkers,
									anchors: showAnchors,
									gateways: showGateways,
									roleFilter: roleFilterButtonStates || [],
									assetTypeFilter: assetFilterButtonStates || [],
									markersSize: {
										[type]: newSize
									}
								}
							}
						])
					)
				}
			} else {
				dispatch(
					liveDataActions.saveUserDrawerPreferences([
						{
							userId: user?.id || null,
							preferences: {
								connectors: showConnectors,
								pois: showPOIs,
								people: showPeople,
								assets: showAssets,
								unassignedTags: showUnassignedTags,
								areas: showAreaInfoMarkers,
								anchors: showAnchors,
								gateways: showGateways,
								roleFilter: roleFilterButtonStates || [],
								assetTypeFilter: assetFilterButtonStates || [],
								markersSize: {
									[type]: newSize
								}
							}
						}
					])
				)
			}
		}, 0)
	}

	return (
		<div
			className="d-flex flex-column h-100"
			// onMouseEnter={() => {
			// 	document.body.style.overflow = "hidden"
			// }}
			// onMouseLeave={() => {
			// 	document.body.style.overflow = "auto"
			// }}
		>
			<div className="drawer_title_container d-flex w-100 align-items-center justify-content-between">
				<div>
					<SVG
						className="drawer_option_title_icon1"
						src={toAbsoluteUrl("/media/crowdkeep/Resize.svg")}
						style={{ width: "18px", height: "18px" }}
					/>
					<span className="drawer_option_title_span">Resize your markers</span>
				</div>

				<div>
					<IconButton
						className="livemap_drawer_filter_close_btn"
						size="small"
						onClick={() => {
							document.body.style.overflow = "auto"
							setDrawerOptions(prevState => ({
								...prevState,
								openSlider: false
							}))
						}}
					>
						<CloseIcon style={{ fontSize: "18px" }} />
					</IconButton>
				</div>
			</div>
			{/* people controller */}
			{propertiesArray &&
				propertiesArray.map((property, index) => {
					return (
						<div
							key={index}
							className="d-flex w-100 align-items-end justify-content-around mt-2"
						>
							{/* Icon */}
							<div
								className="d-flex justify-content-center align-items-end"
								style={{
									width: property?.width || "26px",
									height: property?.width || "26px",
									filter: "drop-shadow(0px 4px 10px rgba(10, 27, 61, 0.15))",
									marginBottom: property?.marginBottom || "4px"
								}}
							>
								<img
									style={{
										width: "100%",
										height: "auto",
										display: "block"
									}}
									alt={property?.type}
									src={property?.imageUrl || ""}
								/>
							</div>
							<div style={{ display: "grid" }}>
								<span className="controller-title">{property?.title}</span>
								<div style={{ width: "181px", height: "33px", padding: "2px" }}>
									<div
										className="d-flex align-items-center justify-content-between border border-primary onHover"
										style={{
											width: "100%",
											height: "100%",
											borderRadius: "4px",
											padding: "2px"
										}}
									>
										<Button
											variant="contained"
											color="primaryLight"
											style={{
												color: "#2673F0",
												backgroundColor: "#D9EAFD",
												height: "100%"
											}}
											text="-"
											onClick={() => handleDecrement(index)}
										/>
										<Input
											id={`standard-${property?.type}-number-${index}`}
											type="text"
											value={`${newMarkersSizes[index][property?.type]}%`}
											readOnly
											disableUnderline
											inputProps={{ min: 0, max: 100 }}
											style={{ textAlignLast: "center", flex: 1, fontSize: "11px" }}
										/>
										<Button
											variant="contained"
											color="primaryLight"
											style={{
												color: "#2673F0",
												backgroundColor: "#D9EAFD",
												height: "100%"
											}}
											text="+"
											onClick={() => handleIncrement(index)}
										/>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			<div className="d-flex align-items-center justify-content-end mt-3">
				<Button
					className="drawer_options_filter_button_select_all_filter_one mr-2"
					variant="outlined"
					color="primary"
					style={{
						backgroundColor: "#FFFFFF",
						color: "#3077D3",
						border: "1px solid #3077D3"
					}}
					text="Reset"
					onClick={handleReset}
				/>
				{/* <Button
					className="drawer_options_filter_button_select_all_filter_one"
					color="primary"
					variant="contained"
					text="Save"
					onClick={handleSave}
				/> */}
			</div>
		</div>
	)
}

export default MarkersSizeSlider
