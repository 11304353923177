import React from "react"
import { Dropdown } from "react-bootstrap/"
import { SvgIcon } from "@material-ui/core"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl, checkIsActive } from "../../../../_metronic/_helpers"
import { useLocation } from "react-router"
import { NavLink } from "react-router-dom"

const HeaderDropdownOnMobile = () => {
	const location = useLocation()
	const getMenuItemActive = url => {
		return checkIsActive(location, url) ? true : false
	}

	const isActive = getMenuItemActive("/operations/inventory")
		? "inventory"
		: getMenuItemActive("/operations/manage-infrastructure")
		? "mapInfra"
		: null
	const pageCondition =
		isActive === "inventory" ? "Inventory" : isActive === "mapInfra" ? "Map Infra" : null

	return (
		<>
			<Dropdown
				style={{
					display: "grid",
					width: "180px",
					background: "#3077d3",
					borderRadius: "4px",
					color: "#f4f4f4"
				}}
				// onSelect={eventKey => {
				// 	setPage(page={page} setPage={setPage})
				// }}
			>
				<Dropdown.Toggle
					className="button_dropdown_header_design_studio"
					style={{
						fontFamily: "Poppins",
						fontWeight: 600
					}}
				>
					{pageCondition}
				</Dropdown.Toggle>

				<Dropdown.Menu className="w-100">
					<Dropdown.Item active={isActive === "inventory" ? true : false} eventKey="inventory">
						<NavLink className="" to="/operations/inventory">
							<SVG
								src={toAbsoluteUrl("/media/svg/icons/General/Inventory-icon.svg")}
								style={{
									height: "1.5rem",
									width: "1.5rem",
									fill: getMenuItemActive("/operations/inventory") ? "#ffffff" : "#2673F0",
									margin: "0px 10px 5px 0px"
								}}
							/>
							<span
								className="header-menu-text"
								style={{
									color: getMenuItemActive("/operations/inventory") ? "#ffffff" : "#2673F0"
								}}
							>
								Inventory
							</span>
						</NavLink>
					</Dropdown.Item>
					<Dropdown.Item active={isActive === "mapInfra" ? true : false} eventKey="mapInfra">
						<NavLink className="operations-menu-link" to="/operations/manage-infrastructure">
							<SVG
								src={toAbsoluteUrl("/media/svg/icons/General/manage-infra-icon.svg")}
								style={{
									height: "1.5rem",
									width: "1.5rem",
									fill: getMenuItemActive("/operations/manage-infrastructure")
										? "#ffffff"
										: "#2673F0",
									margin: "0px 10px 5px 0px"
								}}
							/>

							<span
								className="header-menu-text"
								style={{
									color: getMenuItemActive("/operations/manage-infrastructure")
										? "#ffffff"
										: "#2673F0"
								}}
							>
								Map Infra
							</span>
						</NavLink>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	)
}

export default HeaderDropdownOnMobile
