import React, { useMemo, useEffect, useCallback } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../_redux/reporting/assets/assetsActions"
import { useUIContext } from "../UIContext"
// import { AttendanceByRole, AttendenceByRole } from "./Charts/AttendanceByRole"
// import { TimesheetPerRole } from "./Charts/TimesheetPerRole"
// import { TimesheetDaily } from "./Charts/TimesheetDaily"
// import { AttendanceTracking } from "./Charts/AttendanceTracking"
// import { TotalContractedWorkers } from "../occupancy/FloorNew/TotalContractedWorkers"
// import { TotalPeople } from "./Charts/TotalPeople"
// import { AverageTimeSpent } from "./Charts/AverageTimeSpent"
// import { PeopleDistribution } from "./Charts/PeopleDistribution"
// import { LocationSummary } from "./Charts/LocationSummary"
import { debounce } from "lodash"
import { AttendanceTrackingAssets } from "./Charts/AttendanceTrackingAssets"
import { LocationSummaryAssets } from "./Charts/LocationSummaryAssets"
import { AverageTimeSpentAssets } from "./Charts/AverageTimeSpentAssets"
import { AssetsDistribution } from "./Charts/AssetsDistribution"

export function AssetsSummaryPage() {
	const dispatch = useDispatch()

	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			queryParams: UIContext.queryParams,
			setQueryParams: UIContext.setQueryParams
		}
	}, [UIContext])

	const { group, customer, selectedSite, siteId, floorPlans, vendors, assets } = useSelector(
		state => ({
			group: state.persistentReporting?.group,
			customer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			siteId: state.profile?.currentSite?.id,
			floorPlans: state.basePage.floorPlans,
			vendors: state.profile?.currentCustomer?.vendors,
			assets: state.basePage.assets
		}),
		shallowEqual
	)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fetchSummary = useCallback(
		debounce(() => {
			if (!customer || !selectedSite || !floorPlans) return

			dispatch(
				actions.fetchAssetSummary({
					customerId: customer.id,
					siteId,
					selectedSite,
					floorPlans,
					assets,
					vendors,
					nodeType: "assets",
					...UIProps.queryParams
				})
			)
		}, 300), // Adjust the debounce delay as needed
		[customer, UIProps.queryParams, selectedSite, siteId, floorPlans, assets, vendors]
	)

	useEffect(() => {
		fetchSummary()

		// Cleanup the debounce on component unmount
		return () => {
			fetchSummary.cancel()
		}
	}, [fetchSummary])

	return (
		<div className="row" id="asset_summary_main_div">
			<>
				<div
					className="col-12"
					// className="col-md-12 col-xxl-8"
				>
					<AttendanceTrackingAssets />
				</div>
				<div className="col-12">
					<LocationSummaryAssets />
				</div>
				<div className="col-12">
					<AverageTimeSpentAssets />
				</div>
				<div className="col-12">
					<AssetsDistribution />
				</div>
			</>
		</div>
	)
}
