import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { FormHelperText, IconButton } from "@material-ui/core"
import { ModalProgressBar } from "../../../../../_partials/ModalProgressBar"
import { Body, Bold } from "../../../../../_partials/typography"
import CloseIcon from "@material-ui/icons/Close"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { firestoreOld } from "../../../../../../firebase"
import { Button } from "../../../../../_partials/ButtonOLD"
import { Timestamp } from "@firebase/firestore"

export function SendMessageModal({ show, onHide, isLoading, setIsLoading, person, containerRef }) {
	const dispatch = useDispatch()
	const [message, setMessage] = useState()
	const [error, setError] = useState()

	const { customerId, selectedCustomer, selectedSite, user } = useSelector(
		state => ({
			customerId: state.profile?.currentCustomer?.id,
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			user: state.auth?.user
		}),
		shallowEqual
	)

	function onHideModal() {
		setError()
		setMessage()
		onHide()
	}

	return (
		<Modal
			show={show}
			onHide={onHideModal}
			style={{ zIndex: 2000, overlay: { zIndex: 2000 } }}
			contentClassName="bg-transparent"
			container={containerRef}
		>
			<Modal.Body
				style={{
					padding: "0",
					backgroundColor: "#ECF1F4",
					borderRadius: "10px",
					overflow: "hidden"
				}}
			>
				{isLoading && <ModalProgressBar />}
				<div className="pl-7 pr-7 pb-7 pt-2">
					{/* ━━★  TITLE  ★━━ */}
					<div className="d-flex align-items-center justify-content-between">
						<div className="d-flex">
							<img className="mr-3" alt="Sending" src="/icons/sendingemail.svg" />
							<div className="d-flex align-items-center">
								<Body>
									Send message to <Bold>{person?.personalData?.name || person?.name}</Bold>
								</Body>
							</div>
						</div>
						<div>
							<IconButton onClick={onHideModal} style={{ left: "12px" }}>
								<CloseIcon />
							</IconButton>
						</div>
					</div>

					{/* ━━★  BODY  ★━━ */}
					<div>
						<div className="input-group mb-1">
							<textarea
								className="form-control"
								aria-label="With textarea"
								onChange={e => setMessage(e.target.value)}
								style={{ maxHeight: "300px" }}
							/>
						</div>
						<div className="mb-4">
							<FormHelperText error>{error}</FormHelperText>
						</div>
						<div className="d-flex">
							<Button
								className="ml-3 mr-2"
								fullWidth
								disabled={isLoading || !person?.UID}
								text="SEND VIA NOTIFICATION"
								onClick={() =>
									handleSendMessageSubmit({
										user,
										message,
										person: person,
										customerId,
										dispatch,
										onHideModal,
										setIsLoading,
										setError
									})
								}
							/>
							<Button
								className="ml-2 mr-3"
								fullWidth
								disabled={
									isLoading || (!person?.personalData?.phoneNumber && !person?.phoneNumber)
								}
								text="SEND VIA SMS"
								onClick={() =>
									handleSendMessageSubmit({
										SMS: true,
										user,
										message,
										person,
										customerId,
										dispatch,
										onHideModal,
										setIsLoading,
										setError,
										selectedCustomer,
										selectedSite
									})
								}
							/>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

function handleSendMessageSubmit(props) {
	const {
		dispatch,
		user,
		message,
		person,
		customerId,
		SMS,
		onHideModal,
		setIsLoading,
		setError,
		selectedCustomer,
		selectedSite
	} = props
	setError()

	if (!user) {
		dispatch({
			type: "SNACKBAR_ERROR",
			payload: `An error occured when sending the message, try again later!`
		})
		onHideModal()
		return
	}
	if (!message) {
		setError("Message cannot be empty")
		return
	}
	if (message.length > 1000) {
		setError("Message exceeds the 1000 characters limit")
		return
	}
	if (!person?.UID && !SMS) {
		// console.log("🚀 . SMS:", SMS)
		// console.log("🚀 . person?.UID:", person?.UID)
		dispatch({
			type: "SNACKBAR_ERROR",
			payload: `This person doesn't have a mobile app!`
		})
		onHideModal()
		return
	}
	if (!person?.personalData?.phoneNumber && !person?.phoneNumber && SMS) {
		dispatch({
			type: "SNACKBAR_ERROR",
			payload: `This person doesn't have a phone number!`
		})
		onHideModal()
		return
	}

	setIsLoading(true)

	if (SMS) {
		// console.log("sending SMS...")
		firestoreOld
			.collection("TwilioMessages")
			.add({
				from: process.env.REACT_APP_TWILLIO_FROM_NUMBER,
				to: person?.personalData?.phoneNumber || person?.phoneNumber,
				body: message,
				senderInformation: {
					customerId: selectedCustomer?.id,
					customerName: selectedCustomer?.customerInfo?.name,
					siteId: selectedSite?.id,
					siteName: selectedSite?.siteInfo?.name || null,
					userId: user.id,
					userEmail: user.email,
					date: Timestamp.now()
				}
			})
			.then(() => {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: `SMS sent!`
				})
				onHideModal()
				setIsLoading(false)
			})
			.catch(e => {
				console.error("Send message error: ", e)
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: `An error occured when sending the message, try again later!`
				})
				onHideModal()
				setIsLoading(false)
			})
		return
	}

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")
	var raw = JSON.stringify({
		topic: person?.UID + "-messages",
		title: `${user.username || user.email} sent you a message.`,
		body: message,
		sentFrom: "ck-consctruction_webapp_livemap",
		sentById: user.id,
		sentByEmail: user.email,
		to: person?.personalData?.name || person?.name,
		toEmail: person?.personalData?.email || person?.email,
		customerId: customerId
	})
	// console.log("🚀 ~ file: SendMessageModal.js:235 ~ handleSendMessageSubmit ~ raw:", raw)

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	return fetch(`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/send-notification`, requestOptions)
		.then(() => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Message sent!`
			})
			onHideModal()
			setIsLoading(false)
		})
		.catch(e => {
			console.error("Send message error: ", e)
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `An error occured when sending the message, try again later!`
			})
			onHideModal()
			setIsLoading(false)
		})
}
