import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	listLoading: false,
	actionsLoading: false,
	lastError: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const buildingsSlice = createSlice({
	name: "buildings",
	initialState: initialState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		buildingCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.allBuildings = state.allBuildings
				? [...state.allBuildings, action.payload]
				: state.allBuildings
		},

		allBuildingsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.allBuildings = action.payload
		},
		buildingEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.allBuildings =
				state.allBuildings &&
				state.allBuildings.map(entity => {
					if (entity.id === action.payload.id) return action.payload
					return entity
				})
		},
		buildingDeleted: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.allBuildings = state.allBuildings.filter(val => val.id !== action.payload)
		}
	}
})
