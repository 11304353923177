import React from "react"
import ReactDOM from "react-dom"
import "./MapDimensions.css"

export default class Options {
	constructor(props) {
		this.props = props
	}

	onAdd(map) {
		this.map = map
		this.container = document.createElement("div")
		this.container.className = "mapboxgl-ctrl"
		this.threeD = false
		this.container.style.cursor = "pointer"
		this.container.style.height = "31px"
		this.container.style.width = "31px"

		this.content = <OptionsComponent map={map} />

		ReactDOM.render(this.content, this.container)
		return this.container
	}

	onRemove() {
		this.container.parentNode.removeChild(this.container)
		this.map = undefined
	}
}
class OptionsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			threeD: true,
			pitch: null
		}
	}

	componentDidMount() {
		// Set the initial pitch state when the component mounts
		const initialPitch = this.props.map.getPitch()
		this.setState({ pitch: initialPitch })
	}

	componentDidUpdate(prevProps) {
		// Everytime the map is pitched it sets newPitch state
		this.props.map.on("pitchend", () => {
			const newPitch = this.props.map.getPitch()

			if (newPitch !== this.state.pitch) {
				this.setState({ pitch: newPitch })
			}
		})
	}

	handleClick(val) {
		this.setState({ threeD: val })
	}

	render() {
		return (
			<div className="div-3d">
				{this.state.threeD && this.state.pitch === 0 ? (
					<div
						className="button-3d"
						onClick={() => {
							this.handleClick(!this.state.threeD)
							this.props.map.easeTo({ pitch: 55 })
						}}
					>
						3D
					</div>
				) : !this.state.threeD && this.state.pitch === 0 ? (
					<div
						className="button-3d"
						onClick={() => {
							this.handleClick(!this.state.threeD)
							this.props.map.easeTo({ pitch: 55 })
						}}
					>
						3D
					</div>
				) : (
					<div
						className="button-3d"
						onClick={() => {
							this.handleClick(!this.state.threeD)
							this.props.map.easeTo({ pitch: 0 })
						}}
					>
						2D
					</div>
				)}
			</div>
		)
	}
}
