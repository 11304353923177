import React from "react"
import {
	makeStyles,
	MenuItem,
	FormHelperText,
	FormControl,
	Select as SelectMUI
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	formControl: {
		width: "100%",
		marginTop: "2px"
	},
	select: {
		height: "45px",
		borderRadius: "4px",
		overflow: "hidden",
		"& .MuiSelect-filled": {
			backgroundColor: "#ECF1F4",
			border: 0
		},
		"& .MuiSelect-root": {
			//padding: 0,
			padding: "16px 12px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		// Removed underline
		"& .MuiInput-underline:before": {
			borderBottom: "none"
		},
		"& .MuiInput-underline:after": {
			borderBottom: "none"
		},
		"&.MuiFilledInput-underline:before": {
			borderBottom: "none" // Remove the underline
		},
		"&.MuiFilledInput-underline:after": {
			borderBottom: "none" // Remove the underline after selection
		}
		// "& .MuiFilledInput-input": {
		// 	paddingTop: 0,
		// 	paddingBottom: 0
		// }
	},
	filled: {
		backgroundColor: "#ECF1F4",
		"& .MuiSelect-underline": {
			display: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	// inputBox: {
	//   display: "flex",
	//   alignItems: "center",
	//   justifyContent: "center",
	// },
	selectWithSize: {
		backgroundColor: "#FFFFFF",
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		}
	},
	helperText: {
		margin: 0
	},
	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "12px",
		fontWeight: "500"
	}
}))

export function Select({
	label,
	options = [],
	withFeedbackLabel = true,
	form,
	field,
	size,
	customColor,
	variant = "outlined",
	...props
}) {
	const classes = useStyles()
	if (field && form) {
		var { name, value } = field
		var { touched, errors, setFieldValue, setFieldTouched } = form
	}
	const error = withFeedbackLabel && touched && touched[name] && errors && errors[name]

	return (
		<>
			{label && (
				<div style={{ marginBottom: "10px" }}>
					<span className={classes.label}>{label}</span>
				</div>
			)}
			<div>
				<FormControl
					className={classes.formControl}
					variant={variant}
					size={size}
					error={error ? true : false}
				>
					<SelectMUI
						// classes={{
						// 	filled: classes.filled
						// }}

						name={name}
						id="select-partial"
						className={size ? classes.selectWithSize : classes.select}
						style={{
							backgroundColor: customColor ? customColor : ""
						}}
						value={value}
						onClose={() => {
							if (!touched && !setFieldTouched) {
								return
							}
							/*!touched[name] so it doesn't set touched true when it's already true*/
							/*Without this errors will always be one update behind the real value*/
							!touched[name] && setFieldTouched(name, true)
						}}
						onChange={e => {
							if (!touched && !setFieldTouched && !setFieldValue) {
								return
							}
							!touched[name] && setFieldTouched(name, true)
							setFieldValue(name, e.target.value)
						}}
						{...props}
					>
						{options.map((val, i) => {
							return (
								<MenuItem key={i} value={val.value}>
									{val.name}
								</MenuItem>
							)
						})}
					</SelectMUI>
					{error && (
						<FormHelperText className={classes.helperText}>{errors[name]}</FormHelperText>
					)}
				</FormControl>
			</div>
		</>
	)
}
