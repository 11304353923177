// components/SuccessSnackbar.js or whatever you wanna call it
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { actionTypes } from "../snackBar.duck"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function SuccessSnackBar() {
	const dispatch = useDispatch()

	const { typeOfSnackbar, successSnackbarMessage, successSnackbarOpen } = useSelector(
		state => state.ui
	)

	function handleClose(e, reason) {
		if (reason === "clickaway") return
		dispatch({ type: actionTypes.closeSnackBar })
	}

	return (
		<Snackbar
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}
			open={successSnackbarOpen}
			autoHideDuration={6000}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={typeOfSnackbar}>
				{successSnackbarMessage}
			</Alert>
		</Snackbar>
	)
}
