import React, { useEffect, useState } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { firestoreOld } from "../firebase"

import { profileSlice } from "./redux/profile/profileSlice"

const { actions } = profileSlice
export default function Demo({ children }) {
	const dispatch = useDispatch()

	const { userClaims, customer, location, level } = useSelector(
		state => ({
			userClaims: state.auth.claims,
			customer: state.profile?.currentCustomer,
			location: state.profile?.currentLocation,
			level: state.profile?.currentLevel
		}),
		shallowEqual
	)

	const [demo, setDemo] = useState()
	const [actionIndex, setActionIndex] = useState(0)
	const [firstRun, setFirstRun] = useState(true)

	useEffect(() => {
		if (!customer || !userClaims.isDemo) return
		firestoreOld
			.collection("Customers")
			.doc(customer.id)
			.collection("Demos")
			.doc(userClaims.demo)
			.get()
			.then(doc => {
				setDemo(doc.data())
			})
	}, [customer, userClaims])

	useEffect(() => {
		if (!customer || !userClaims.isDemo || !demo?.actions?.length > 0) return

		const timeout = setTimeout(
			() => {
				const newLocation = customer.merakiNetworks.find(
					val => val.netId === demo.actions[actionIndex].netId
				)
				if (newLocation && newLocation.netId != location?.netId) {
					dispatch(actions.locationSelected(newLocation))
					const newLevel = newLocation.levels.find(
						val => val.floorId === demo.actions[actionIndex].floorId
					)
					if (newLevel && newLevel.floorId != level?.floorId) {
						dispatch(actions.levelSelected(newLevel))
					}
					const newIndex = actionIndex + 2
					newIndex > demo.actions.length - 1 ? setActionIndex(0) : setActionIndex(newIndex)
					return
				}

				const newLevel = location?.levels.find(
					val => val.floorId === demo.actions[actionIndex].floorId
				)
				if (newLevel && newLevel.floorId != level?.floorId) {
					dispatch(actions.levelSelected(newLevel))
				}
				const newIndex = actionIndex + 2
				newIndex > demo.actions.length - 1 ? setActionIndex(0) : setActionIndex(newIndex)
			},
			firstRun ? 1000 : demo.time
		)

		setFirstRun(false)

		return () => clearTimeout(timeout)
	}, [customer, demo, userClaims, actionIndex])

	return children
}
