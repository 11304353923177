/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useMemo } from "react"
import ReactDOM from "react-dom"
import { useSelector, shallowEqual, useDispatch } from "react-redux"

import { makeStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"

import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ImageIcon from "@material-ui/icons/Image"
import RouterIcon from "@material-ui/icons/Router"
import PersonRoundedIcon from "@material-ui/icons/PersonRounded"
import GroupRoundedIcon from "@material-ui/icons/GroupRounded"

import * as turf from "@turf/turf"
import "@turf/angle"

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"

import {
	addFloorPlanToMap,
	addImageLayerToMap,
	addOccupancyLayerToMap,
	addGeoJsonLayerToMap,
	mapFitBounds,
	add3DLayerToMap,
	addOccupationMarkers
} from "./_helpers/ActionHelpers"

import { useUIContext } from "../UIContext"

import { SiAirplayvideo } from "react-icons/si"
import { BsFillCameraVideoFill } from "react-icons/bs"
import { GiCoffeeCup } from "react-icons/gi"
import { GiWaterDrop } from "react-icons/gi"
import { MdRestaurant } from "react-icons/md"
import { FaGamepad } from "react-icons/fa"
import { FaTableTennis } from "react-icons/fa"
import { FaThermometerQuarter } from "react-icons/fa"
import { CgCircleci } from "react-icons/cg"
import { GiCctvCamera } from "react-icons/gi"
import { IoQrCode } from "react-icons/io5"
import { FaFireExtinguisher } from "react-icons/fa"
import RoomIcon from "@material-ui/icons/Room"

import { firestoreOld } from "../../../../firebase"
import { Carousel } from "react-bootstrap"

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(0),
		minWidth: "80px"
	},
	avatar: {
		width: "32px",
		height: "32px",
		fontSize: "12px",
		color: "#000000"
	}
}))

export function WallFrameTest() {
	const dispatch = useDispatch()
	const classes = useStyles()

	//━━━ User based information ━━━\\
	const { selectedCustomer, selectedLocation, selectedLevel } = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer?.customerId,
			selectedLocation: state.profile?.currentLocation?.netId,
			selectedLevel: state.profile?.currentLevel,
			userClaims: state.auth.claims
		}),
		shallowEqual
	)

	//━━━ Data to populate live map ━━━\\
	const {
		floorPlanData,
		devices,
		visitors,
		guests,
		users,
		pointsOfInterest,
		layerTypes,
		levels,
		currentLevel,
		profileLvl
	} = useSelector(
		state => ({
			floorPlanData: state.basePage?.floorPlans,
			devices: state.liveData?.devices?.employees,
			visitors: state.liveData?.devices?.visitors,
			users: state.liveData?.users,
			guests: state.liveData?.devices?.guests,
			pointsOfInterest: state.liveData?.pois,
			layerTypes: state.profile?.currentCustomer?.layerTypes,
			levels: state.profile?.currentLocation?.levels,
			currentLevel: state.profile?.currentLevel,
			profileLvl: state.auth.claims?.profileLvl
		}),
		shallowEqual
	)

	//━━━ Data from UIContext ━━━\\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			liveData: UIContext.liveData,
			setLiveData: UIContext.setLiveData,
			resetSnapshot: UIContext.resetSnapshot,
			setResetSnapshot: UIContext.setResetSnapshot
		}
	}, [UIContext])

	//★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
	//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
	const mapContainerRef = useRef(null)
	const [map, setMap] = useState()
	const [geoJson, setGeoJson] = useState()
	const [floorPlan, setFloorPlan] = useState()
	const [straightMap, setStraightMap] = useState(false)
	const [drawPolygon, setDrawPolygon] = useState(false)
	const [showAPS, setShowAPS] = useState(false)
	const [showGuests, setShowGuests] = useState(true)
	const [showVisitors, setShowVisitors] = useState(true)
	const [markers, setMarkers] = useState()
	const [occupationMarkers, setOccupationMarkers] = useState()
	const [elevatedMarkers, setElevatedMarkers] = useState()
	const [showMarkerCluster, setShowMarkerCluster] = useState(false)
	const [groupedMarkers, setGroupedMarkers] = useState(false)
	const [allFloors3D, setAllFloors3D] = useState(false)
	const [scenes, setScenes] = useState([])
	const [toggleValues, setToggleValues] = useState(() => ["showGuests", "showVisitors"])
	const [iconsFromDB, setIconsFromDB] = useState()
	const [showOccupancy, setShowOccupancy] = useState(false)
	const [isUser, setIsUser] = useState(false)

	//★━━━━━━━━━━━━━━━★ UseEffects ★━━━━━━━━━━━━━━━★\\
	//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
	useEffect(() => {
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: "mapbox://styles/mapbox/streets-v11",
			maxZoom: 24
		})
		map.on("load", function () {
			map.addControl(new mapboxgl.FullscreenControl(), "top-left")
			setMap(map)
		})
		return () => map.remove()
	}, [])

	useEffect(() => {
		var filter =
			floorPlanData &&
			floorPlanData.find(f => f.level == currentLevel?.level && f.netId == selectedLocation)
		filter && setFloorPlan(filter)
	}, [floorPlanData, currentLevel?.level])

	useEffect(() => {
		if (map && floorPlan) {
			markers &&
				markers.forEach(m => {
					m.remove()
				})
			addImageLayerToMap({ map, floorPlan, layerTypes, showOccupancy })
		}
	}, [map, floorPlan, layerTypes])

	useEffect(() => {
		if (map && floorPlan?.geoJson) {
			console.log("?")
			/* if (showOccupancy) { */
			addOccupancyLayerToMap({
				map,
				geoJson: floorPlan.geoJson,
				layerTypes,
				devices,
				guests,
				visitors
			})
			/* } else {
         addGeoJsonLayerToMap({ map, geoJson: floorPlan.geoJson, layerTypes })
      } */
		}
	}, [map, floorPlan, layerTypes])

	useEffect(() => {
		if (map && floorPlan?.geoJson?.features) {
			floorPlan.geoJson.features.forEach((feat, index) => {
				if (feat.properties.type === "baseLayer") {
					return
				}

				const allCoords = feat.geometry.coordinates.map(val => [val.longitude, val.latitude])
				const employeesCount = devices
					? devices.filter(val =>
							turf.booleanContains(
								turf.polygon([allCoords]),
								turf.point([val.gpsData.longitude, val.gpsData.latitude])
							)
					  ).length
					: 0
				const guestsCount = guests
					? guests.filter(val =>
							turf.booleanContains(
								turf.polygon([allCoords]),
								turf.point([val.gpsData.longitude, val.gpsData.latitude])
							)
					  ).length
					: 0
				const visitorsCount = visitors
					? visitors.filter(val =>
							turf.booleanContains(
								turf.polygon([allCoords]),
								turf.point([val.gpsData.longitude, val.gpsData.latitude])
							)
					  ).length
					: 0

				if (showOccupancy) {
					const totalPeople = employeesCount + guestsCount + visitorsCount
					const percentage =
						totalPeople === 0 ? 0 : (totalPeople * 100) / feat.properties.capacity
					const color = percentage >= 90 ? "red" : percentage >= 60 ? "#fcba03" : "green"
					map.setPaintProperty(`geoJsonLayer-${index}`, "fill-color", color)
				} else {
					const currentType = layerTypes?.find(type => type.id == feat.properties.type)
					currentType?.color &&
						map.setPaintProperty(`geoJsonLayer-${index}`, "fill-color", currentType.color)
				}

				// Create a popup, but don't add it to the map yet.
				const popup = new mapboxgl.Popup({
					closeButton: false,
					closeOnClick: false
				})

				map.on("mousemove", `geoJsonLayer-${index}`, e => {
					// Change the cursor style as a UI indicator.
					map.getCanvas().style.cursor = "pointer"

					// Copy coordinates array.
					const coordinates = [e.lngLat.lng, e.lngLat.lat]

					// Ensure that if the map is zoomed out such that multiple
					// copies of the feature are visible, the popup appears
					// over the copy being pointed to.
					while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
						coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
					}

					// Populate the popup and set its coordinates
					// based on the feature found.
					popup
						.setLngLat(coordinates)
						.setHTML(
							`<div>
                    <div>
                      <b> Employees: </b>${employeesCount}
                    </div>
                    <div>
                      <b> Guests: </b>${guestsCount}
                    </div>
                    <div>
                      <b> Visitors: </b>${visitorsCount}
                    </div>
                  </div>`
						)
						.addTo(map)
				})

				map.on("mouseleave", `geoJsonLayer-${index}`, () => {
					map.getCanvas().style.cursor = ""
					popup.remove()
				})
			})
		}
	}, [map, floorPlan, devices, guests, visitors, showOccupancy])

	return <div style={{ height: "600px" }} ref={mapContainerRef} />
}
