import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	listLoading: false,
	actionsLoading: false,
	lastError: null,
	nodesInventory: [],
	modelsInventory: [],
	firmwaresInventory: [],
	customers: null
}
export const callTypes = {
	list: "list",
	action: "action"
}

export const inventorySlice = createSlice({
	name: "inventory",
	initialState: initialState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		createdNodes: (state, action) => {
			state.listLoading = false
			state.error = null
			const newData = action.payload.data.map(newNode => {
				return {
					...newNode,
					id: newNode.node_address.toString()
				}
			})
			const updatedNodes = [...state.nodesInventory, ...newData]
			updatedNodes.sort((a, b) => a.id - b.id)
			state.nodesInventory = updatedNodes
		},
		updatedNodes: (state, action) => {
			state.listLoading = false
			state.error = null
			const updatedNodes = state.nodesInventory.map(node => {
				// Check if the node ID exists in the action payload data nodes array
				if (action.payload.data.nodes.includes(node.id)) {
					// Update the node as needed
					return {
						...node,
						firmwareId: action.payload.data.firmwareId,
						modelId: action.payload.data.modelId,
						notes: action.payload.data.notes
					}
				} else {
					return node // If ID doesn't match, return the node unchanged
				}
			})

			// Update the state with the updated nodes
			state.nodesInventory = updatedNodes
		},
		assignedNodes: (state, action) => {
			state.listLoading = false
			state.error = null
			const updatedNodes = state.nodesInventory.map(node => {
				// Check if the node ID exists in the action payload data nodes array
				if (action.payload.data.nodes.includes(node.id)) {
					// Update the node as needed
					return {
						...node,
						customerId: action.payload.data.customerId,
						siteId: action.payload.data.siteId,
						status: "assigned"
					}
				} else {
					return node // If ID doesn't match, return the node unchanged
				}
			})
			// Update the state with the updated nodes
			state.nodesInventory = updatedNodes
		},
		unassignedNodes: (state, action) => {
			state.listLoading = false
			state.error = null
			const updatedNodes = state.nodesInventory.map(node => {
				// Check if the node ID exists in the action payload data nodes array
				if (action.payload.data.nodes.includes(node.node_address)) {
					// Update the node as needed
					return {
						...node,
						customerId: null,
						customerName: null,
						siteId: null,
						status: "unassigned"
					}
				} else {
					return node // If ID doesn't match, return the node unchanged
				}
			})
			// Update the state with the updated nodes
			state.nodesInventory = updatedNodes
		},
		deletedNode: (state, action) => {
			state.listLoading = false
			state.error = null
			// state.entities = state.entities.filter(el => !action.payload.ids.includes(el.id))
			const oldNodes = state.nodesInventory || []

			if (action.payload.nodes) {
				const newNodes = oldNodes.filter(
					node => !action.payload.nodes.includes(node.node_address?.toString())
				)

				state.nodesInventory = newNodes
			}
		},
		createdModel: (state, action) => {
			state.listLoading = false
			state.error = null
			state.modelsInventory.push(action.payload.data)
		},
		updatedModel: (state, action) => {
			state.listLoading = false
			state.error = null
			const updatedModel = action.payload.data

			const updatedModels = state.modelsInventory.map(model => {
				if (model.id === updatedModel.id) {
					return updatedModel
				}
				return model
			})

			state.modelsInventory = updatedModels
		},
		deletedModel: (state, action) => {
			state.listLoading = false
			state.error = null

			const oldModels = state.modelsInventory || []

			if (action.payload.modelId) {
				const newModels = oldModels.filter(model => !action.payload.modelId.includes(model.id))

				state.modelsInventory = newModels
			}
		},
		createdFirmware: (state, action) => {
			state.listLoading = false
			state.error = null
			state.firmwaresInventory.push(action.payload.data)
		},
		deletedFirmware: (state, action) => {
			state.listLoading = false
			state.error = null

			const oldFirmwares = state.firmwaresInventory || []

			if (action.payload.firmwareId) {
				const newFirmwares = oldFirmwares.filter(
					firmware => !action.payload.firmwareId.includes(firmware.id)
				)

				state.firmwaresInventory = newFirmwares
			}
		},

		inventoryNodesFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.nodesInventory = action.payload
		},
		inventoryModelsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.modelsInventory = action.payload
		},
		inventoryFirmwaresFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.firmwaresInventory = action.payload
		},
		inventoryCustomersFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.customers = action.payload
		}
	}
})
