import React, { createContext, useContext, useState, useCallback } from "react"
import { isEqual, isFunction } from "lodash"

const initialFilter = {
	filter: {
		searchText: "",
		searchField: ""
	},
	sortOrder: "asc", // asc||desc
	sortField: "displayName",
	pageNumber: 1,
	pageSize: 10
}

const initialNodesColumnsOrder = [
	{
		field: "node_address",
		sort: null
	},
	{
		field: "mac_address",
		sort: null
	},
	{
		field: "nodeType",
		sort: null
	},
	{
		field: "type",
		sort: null
	},
	{
		field: "status",
		sort: null
	},
	{
		field: "customerName",
		sort: null
	},
	{
		field: "networkId",
		sort: null
	},
	{
		field: "floorPlanId",
		sort: null
	},
	{
		field: "model",
		sort: null
	},
	{
		field: "firmware",
		sort: null
	},
	{
		field: "firmwareVendor",
		sort: null
	},
	{
		field: "notes",
		sort: null
	}
]
const initialModelsColumnsOrder = [
	{
		field: "name",
		sort: "null"
	},
	{
		field: "vendor",
		sort: "null"
	},
	{
		field: "nodeType",
		sort: "null"
	},
	{
		field: "type",
		sort: "null"
	},
	{
		field: "battery",
		sort: "null"
	},
	{
		field: "chipset",
		sort: "null"
	},
	{
		field: "features.led",
		sort: "null"
	},
	{
		field: "ipProtection",
		sort: "null"
	},
	{
		field: "features.button",
		sort: "null"
	},
	{
		field: "features.buzzer",
		sort: "null"
	},
	{
		field: "features.motion",
		sort: "null"
	},
	{
		field: "features.nfc",
		sort: "null"
	}
]

const initialFirmwaresColumnsOrder = [
	{ field: "name", sort: null },
	{ field: "version", sort: null },
	{ field: "severity", sort: null },
	{ field: "vendor", sort: null },
	{ field: "modelId", sort: null },
	{ field: "notes", sort: null }
]

const UIContext = createContext()

export function useUIContext() {
	return useContext(UIContext)
}

export const UIConsumer = UIContext.Consumer

export function UIProvider({ UIEvents, children }) {
	const [queryParams, setQueryParamsBase] = useState(initialFilter)
	const [idsInventoryNodes, setIdsInventoryNodes] = useState([])
	const [idsInventoryModels, setIdsInventoryModels] = useState([])
	const [idsInventoryFirmwares, setIdsInventoryFirmwares] = useState([])
	const [technology, setTechnology] = useState("")
	const [rowsToUseNodesTable, setRowsToUseNodesTable] = useState([])
	const [rowsToUseModelsTable, setRowsToUseModelsTable] = useState([])
	const [rowsToUseFirmwaresTable, setRowsToUseFirmwaresTable] = useState([])
	const [columnVisibilityModelNodesTable, setColumnVisibilityModelNodesTable] = useState({}) //visibile columns object for exporting
	const [columnVisibilityModelModelsTable, setColumnVisibilityModelModelsTable] = useState({}) //visibile columns object for exporting
	const [columnVisibilityModelFirmwaresTable, setColumnVisibilityModelFirmwaresTable] = useState(
		{}
	) //visibile columns object for exporting
	const [nodesColumnsOrder, setNodesColumnsOrder] = useState(initialNodesColumnsOrder)
	const [currentNodesTablePageInfo, setCurrentNodesTablePageInfo] = useState({}) //page and pageSize to export current page only
	const [modelsColumnsOrder, setModelsColumnsOrder] = useState(initialModelsColumnsOrder)
	const [currentModelsTablePageInfo, setCurrentModelsTablePageInfo] = useState({}) //page and pageSize to export current page only
	const [firmwaresColumnsOrder, setFirmwaresColumnsOrder] = useState(initialFirmwaresColumnsOrder)
	const [currentFirmwaresTablePageInfo, setCurrentFirmwaresTablePageInfo] = useState({}) //page and pageSize to export current page only

	const setQueryParams = useCallback(nextQueryParams => {
		setQueryParamsBase(prevQueryParams => {
			if (isFunction(nextQueryParams)) {
				nextQueryParams = nextQueryParams(prevQueryParams)
			}

			if (isEqual(prevQueryParams, nextQueryParams)) {
				return prevQueryParams
			}

			return nextQueryParams
		})
	}, [])

	const value = {
		technology,
		setTechnology,
		queryParams,
		setQueryParams,
		setQueryParamsBase,
		//* ━━━━━━━ NODES ━━━━━━━
		idsInventoryNodes,
		setIdsInventoryNodes,
		columnVisibilityModelNodesTable,
		setColumnVisibilityModelNodesTable,
		nodesColumnsOrder,
		setNodesColumnsOrder,
		currentNodesTablePageInfo,
		setCurrentNodesTablePageInfo,
		rowsToUseNodesTable,
		setRowsToUseNodesTable,
		openEditInventoryNodesModal: UIEvents.openEditInventoryNodesModal,
		openDeleteInventoryNodesModal: UIEvents.openDeleteInventoryNodesModal,
		openUnassignInventoryNodesModal: UIEvents.openUnassignInventoryNodesModal,
		openAssignInventoryNodesModal: UIEvents.openAssignInventoryNodesModal,
		openPrintNodeLabelModal: UIEvents.openPrintNodeLabelModal,
		//* ━━━━━━━ MODELS ━━━━━━━
		idsInventoryModels,
		setIdsInventoryModels,
		columnVisibilityModelModelsTable,
		setColumnVisibilityModelModelsTable,
		modelsColumnsOrder,
		setModelsColumnsOrder,
		currentModelsTablePageInfo,
		setCurrentModelsTablePageInfo,
		rowsToUseModelsTable,
		setRowsToUseModelsTable,
		openEditInventoryModelsModal: UIEvents.openEditInventoryModelsModal,
		openDeleteInventoryModelsModal: UIEvents.openDeleteInventoryModelsModal,
		//* ━━━━━━━ FIRMWARES ━━━━━━━
		idsInventoryFirmwares,
		setIdsInventoryFirmwares,
		columnVisibilityModelFirmwaresTable,
		setColumnVisibilityModelFirmwaresTable,
		firmwaresColumnsOrder,
		setFirmwaresColumnsOrder,
		currentFirmwaresTablePageInfo,
		setCurrentFirmwaresTablePageInfo,
		rowsToUseFirmwaresTable,
		setRowsToUseFirmwaresTable,
		openDeleteInventoryFirmwaresModal: UIEvents.openDeleteInventoryFirmwaresModal
	}

	return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}
