import React, { useEffect, useState } from "react"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"
import { shallowEqual, useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core"
import { Pagination } from "@material-ui/lab"
import { SearchBarCustom1 } from "../../../../_partials/inputs/SearchBarCustom1"
import SearchIcon from "@material-ui/icons/Search"
import { Dropdown } from "react-bootstrap"
import { Card, CardBody } from "../../../../_partials/Card"
import { filterBySearchString } from "../../../Admin/manage-customer/manage-asset-types/Helpers/AssetTypesHelpers"
import * as UIHelpers from "../../UIHelpers"
import { DataGrid } from "@mui/x-data-grid"
import { useGridApiContext, useGridRootProps } from "@mui/x-data-grid"

export const TimesheetDailyAssets = () => {
	const classes = useStyles()

	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const { isLoading, singleAssetTimesheet } = useSelector(
		state => ({
			isLoading: state.reporting.singleAssetTimesheetLoading,
			singleAssetTimesheet: state.reporting.singleAssetTimesheet
		}),

		shallowEqual
	)

	const [dataRows, setDataRows] = useState([])
	const [searchedRows, setSearchedRows] = useState([])
	const [searchString, setSearchString] = useState("")
	const [page, setPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)

	// ━━━━━━━━━━━━━ UseEffect ━━━━━━━━━━━━━
	useEffect(() => {
		if (isLoading) {
			setDataRows([])
		} else if (!singleAssetTimesheet) {
			setDataRows([])
		} else {
			setDataRows(singleAssetTimesheet)
		}
	}, [singleAssetTimesheet, isLoading])

	const columns = [
		// { field: "id", headerName: "ID", width: 90 },
		{
			field: "day",
			headerName: "Day",
			minWidth: 140,
			editable: false,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "asset",
			headerName: "Asset",
			minWidth: 200,
			editable: false,
			flex: 1,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "type",
			headerName: "Type",
			minWidth: 200,
			editable: false,
			flex: 1,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "company",
			headerName: "Company",
			minWidth: 200,
			editable: false,
			flex: 1,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "start",
			headerName: "Check-in",
			// type: "number",
			minWidth: 140,
			editable: false,
			flex: 0,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "end",
			headerName: "Check-out",
			// type: "number",
			minWidth: 140,
			editable: false,
			flex: 0,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "hours",
			headerName: "Total Hours",
			minWidth: 140,
			editable: false,
			flex: 0,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		}
	]

	const handleSearch = value => {
		setSearchString(value)
	}

	// Filter the data based on searchText if it is not null or empty string
	useEffect(() => {
		if (!dataRows) return

		if (searchString && searchString.trim() !== "") {
			const fieldsToSearch = ["asset", "type", "company", "day"] // Define the fields to search
			const filteredRows = filterBySearchString(dataRows, searchString, fieldsToSearch)

			setSearchedRows(filteredRows)
		} else {
			setSearchedRows(null)
		}
	}, [searchString, dataRows])

	return (
		<>
			<Card
			// data-testid="manage-asset_types_ManageAssetTypesCard"
			>
				<CardBody>
					<div
						// className={`${classes.root} ${showScrollBar ? "" : "hide-scrollbar"}`}
						// data-testid="attendance_list_toolkit_provider"
						style={{ minHeight: "450px", height: "100%", width: "100%", background: "white" }}
						data-testid="asset-types-table-datagrid-daily"
					>
						<div className="d-flex flex-column flex-lg-row flex-md-row justify-content-lg-between justify-content-md-between align-items-center mb-5">
							<div className="d-flex flex-column">
								<h3 style={{ color: "#212121", fontSize: 15.3, fontWeight: 600 }}>
									Daily Asset timesheet
								</h3>
								<h5
									style={{
										color: "#b5b5c3",
										fontSize: 11.5,
										fontWeight: 500,
										marginTop: "0px"
									}}
								>
									Assets Timesheet
								</h5>
							</div>
							<div className="mt-2 mt-lg-0 mt-md-0">
								<SearchBarCustom1
									onSearch={handleSearch}
									searchText={searchString}
									disabled={isLoading ? true : false}
									placeholder="Search"
									size="small"
									style={{
										width: "200px",
										background: "#F2F2F2"
										// marginRight: "10px"
									}}
									InputProps={{
										startAdornment: (
											<SearchIcon
												fontSize="small"
												style={{
													color: "#7F8C8D",
													marginRight: "0.7rem"
												}}
											/>
										)
									}}
								/>
							</div>
						</div>
						{isLoading && <ModalProgressBar />}
						<DataGrid
							// data-testid="asset-types-table-datagrid"
							rows={searchedRows || dataRows || []}
							columns={columns}
							// loading={isLoading}
							autoHeight
							rowHeight={60}
							pageSize={pageSize}
							columnBuffer={4}
							rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
							page={page}
							onPageChange={newPage => setPage(newPage)}
							onPageSizeChange={e => {
								setPageSize(e)
							}}
							disableSelectionOnClick
							components={{
								Pagination: CustomPagination
							}}
							style={{
								fontFamily: "Poppins",
								fontWeight: 400,
								fontSize: "13px",
								border: "none",
								height: "100%",
								width: "100%",
								color: "#464e5f"
							}}
							className={classes.root}
						/>
					</div>
				</CardBody>
			</Card>
		</>
	)
}

function CustomPagination() {
	// const { state, apiRef } = useGridSlotComponentProps()
	const apiRef = useGridApiContext()
	// const [state] = useGridState(apiRef)
	const state = apiRef.current.state
	const rootElement = apiRef.current.rootElementRef
	const rootProps = useGridRootProps()
	const classes = useStyles()
	const options = [5, 10, 20, 30, 50, 100]

	const handlePageSizeChange = pageSize => {
		apiRef.current.setPageSize(pageSize)
	}

	return (
		<>
			<Dropdown>
				<Dropdown.Toggle variant="light" id="dropdown-basic">
					{state.pagination.pageSize}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{options.map(option => {
						return (
							<Dropdown.Item
								key={option}
								as="button"
								onClick={() => handlePageSizeChange(option)}
							>
								{option}
							</Dropdown.Item>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>

			<Pagination
				className={classes.root}
				id="custom-pagination"
				color="primary"
				count={state.pagination.pageCount}
				page={state.pagination.page + 1}
				onChange={(event, value) => apiRef.current.setPage(value - 1)}
			/>
		</>
	)
}
const useStyles = makeStyles(() => UIHelpers.rootStylesTimesheet)
