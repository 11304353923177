import React from "react"
import ReactDOM from "react-dom"
import "./DrawerListAnchors.css"
import AnchorsListDragTable from "../AnchorsTable/AnchorsListDragTable"

export default class DrawerListAnchors extends React.Component {
	constructor(props) {
		super(props)
		this.onAdd = this.onAdd.bind(this)
		this.onRemove = this.onRemove.bind(this)
	}

	onAdd(map) {
		this.map = map
		this.container = document.createElement("div")
		this.container.className = "mapboxgl-ctrl"
		this.container.style.margin = "0"

		this.content = (
			<div
				className="container_drawer_list_bar_content"
				style={{
					display: !this.props.selectedSite
						? "none"
						: this.props.drawerListOptions.openList
						? "block"
						: "none"
				}} // Hide if !selectedSite
			>
				{this.props.drawerListOptions.openList && (
					<AnchorsListDragTable
						anchorsPositionData={this.props.anchorsPositionData}
						map={this.props.map}
						drawerListMarkers={this.props.drawerListMarkers}
						setDrawerListMarkers={this.props.setDrawerListMarkers}
						handleDrawerListMarkerPositionChange={
							this.props.handleDrawerListMarkerPositionChange
						}
						modelsInventory={this.props.modelsInventory}
					/>
				)}
			</div>
		)

		ReactDOM.render(this.content, this.container)
		return this.container
	}

	onRemove() {
		this.container.parentNode && this.container.parentNode.removeChild(this.container)
		return
	}
}
