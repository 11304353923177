import React, { useRef, useState, useEffect } from "react"
import { IconButton, Grid } from "@material-ui/core"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../_partials/typography"
import CloseIcon from "@material-ui/icons/Close"
import Webcam from "react-webcam"
import { Button } from "../../../../_partials/Button"
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto"

const WebcamModal = ({ show, onHide, urlsWebcam, setUrlsWebcam, title }) => {
	const webcamRef = useRef(null)
	const [isCaptureButtonDisabled, setCaptureButtonDisabled] = useState(true)
	const [hasWebcamAccess, setHasWebcamAccess] = useState(false)

	const videoConstraints = {
		width: 400,
		facingMode: "environment"
	}

	useEffect(() => {
		if (show) {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then(() => {
					setHasWebcamAccess(true)
				})
				.catch(() => {
					setHasWebcamAccess(false)
				})
		}
	}, [show])

	useEffect(() => {
		if (!show || !hasWebcamAccess) return

		const timer = setTimeout(() => {
			setCaptureButtonDisabled(false)
		}, 2000)

		return () => clearTimeout(timer)
	}, [show, hasWebcamAccess])

	const capturePhoto = React.useCallback(() => {
		const newImage = webcamRef.current.getScreenshot()
		setUrlsWebcam(prevState => [...prevState, newImage])
	}, [webcamRef, setUrlsWebcam])

	const onImageRemove = indexToRemove => {
		setUrlsWebcam(prevUrls => prevUrls.filter((_, index) => index !== indexToRemove))
	}

	return (
		<Modal show={show} onHide={onHide} contentClassName="bg-transparent">
			<Modal.Body
				style={{
					padding: "0",
					backgroundColor: "#FFF",
					borderRadius: "0.42rem"
				}}
			>
				<div className="pl-7 pr-7 pb-7 pt-2">
					{/* Title */}
					<div className="d-flex align-items-center justify-content-between mb-2">
						<div>
							<Bold>Take photos to add to {title} marker</Bold>
						</div>
						<div>
							<IconButton onClick={onHide} style={{ left: "12px" }}>
								<CloseIcon />
							</IconButton>
						</div>
					</div>

					{/* Body */}
					<Grid container direction="row" className="d-flex justify-content-center">
						<div
							className="d-flex flex-column align-items-center justify-content-center"
							style={{ width: "100%", height: "310px" }}
						>
							<div>
								<Webcam
									ref={webcamRef}
									audio={false}
									screenshotFormat="image/jpeg"
									videoConstraints={videoConstraints}
								/>
							</div>
						</div>
						<div
							className="d-flex justify-content-center mb-3"
							style={{ width: "100%", height: "45px", padding: "5px" }}
						>
							<Button
								disabled={isCaptureButtonDisabled}
								variant="contained"
								color="primary"
								onClick={capturePhoto}
								text="Capture"
								endIcon={<AddAPhotoIcon style={{ width: "13px", height: "21px" }} />}
							/>
						</div>

						{urlsWebcam &&
							urlsWebcam.map((image, index) => (
								<div key={index} className="image-item text-center m-3">
									<div className="addDropDiv">
										<img
											src={image}
											alt={`Captured ${index}`}
											width="100"
											className="addDropImg"
											style={{ borderRadius: "0" }}
										/>
									</div>
									<div className="image-item__btn-wrapper mb-4">
										<Button
											style={{ height: "2.7vh" }}
											text="Remove"
											variant="outlined"
											color="primary"
											onClick={() => onImageRemove(index)}
										/>
									</div>
								</div>
							))}
					</Grid>

					{/* Footer */}
					<div className="d-flex">
						<Button
							fullWidth
							color="primary"
							variant="outlined"
							onClick={() => {
								onHide()
								setUrlsWebcam([])
							}}
							className="mr-2"
							style={{ padding: "0" }}
							text="Cancel"
						/>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={() => setUrlsWebcam([])}
							className="ml-2"
							text="Remove All"
						/>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={onHide}
							className="ml-2"
							text="Next"
						/>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default WebcamModal
