import React from "react"
import { ProgressBar } from "../../layout/components/extras/ProgressBar"

export function ModalProgressBar() {
	return (
		<ProgressBar.LinearIndeterminate
			style={{
				height: "3px",
				width: "100%"
			}}
			variant={"indeterminate"}
			color={"primary"}
		/>
	)
}
