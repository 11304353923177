export const filterPreferencesHandler = ({
	drawerPreferences,
	user,
	setShowAreaInfoMarkers,
	setShowPeople,
	setShowAssets,
	setShowUnassignedTags,
	setShowPOIs,
	setShowConnectors,
	setShowAnchors,
	setShowGateways,
	setRoleFilterButtonStates,
	setAssetFilterButtonStates,
	setMarkersSize,
	roles,
	differentAssetTypes
}) => {
	const foundedPreferences = drawerPreferences.find(val => val.userId === user?.id)

	if (foundedPreferences) {
		setShowAreaInfoMarkers(
			foundedPreferences?.preferences?.areas
				? foundedPreferences?.preferences?.areas
				: !foundedPreferences?.preferences?.areas
				? false
				: true
		)
		setShowPeople(
			foundedPreferences?.preferences?.people
				? foundedPreferences?.preferences?.people
				: !foundedPreferences?.preferences?.people
				? false
				: true
		)
		setShowAssets(
			foundedPreferences?.preferences?.assets
				? foundedPreferences?.preferences?.assets
				: !foundedPreferences?.preferences?.assets
				? false
				: true
		)
		setShowUnassignedTags(
			foundedPreferences?.preferences?.unassignedTags
				? foundedPreferences?.preferences?.unassignedTags
				: !foundedPreferences?.preferences?.unassignedTags
				? false
				: true
		)
		setShowPOIs(
			foundedPreferences?.preferences?.pois
				? foundedPreferences?.preferences?.pois
				: !foundedPreferences?.preferences?.pois
				? false
				: true
		)
		setShowConnectors(
			foundedPreferences?.preferences?.connectors
				? foundedPreferences?.preferences?.connectors
				: !foundedPreferences?.preferences?.connectors
				? false
				: true
		)

		setShowAnchors(
			foundedPreferences?.preferences?.anchors
				? foundedPreferences?.preferences?.anchors
				: !foundedPreferences?.preferences?.anchors
				? false
				: true
		)
		setShowGateways(
			foundedPreferences?.preferences?.gateways
				? foundedPreferences?.preferences?.gateways
				: !foundedPreferences?.preferences?.gateways
				? false
				: true
		)

		// if (foundedPreferences?.preferences?.roleFilter) {
		// 	const removedDuplicatesRoleFilter = foundedPreferences?.preferences?.roleFilter.filter(
		// 		(value, index, self) => {
		// 			return self.indexOf(value) === index
		// 		}
		// 	)
		// 	setRoleFilterButtonStates(removedDuplicatesRoleFilter.map(rol => rol))
		// } else {
		// 	setRoleFilterButtonStates(roles && roles.map(rol => rol?.name))
		// }

		// if (foundedPreferences?.preferences?.assetTypeFilter) {
		// 	const removedDuplicatesAssetTypeFilter =
		// 		foundedPreferences?.preferences?.assetTypeFilter.filter((value, index, self) => {
		// 			return self.indexOf(value) === index
		// 		})

		// 	setAssetFilterButtonStates(removedDuplicatesAssetTypeFilter.map(asset => asset))
		// } else {
		// 	setAssetFilterButtonStates(differentAssetTypes && differentAssetTypes.map(asset => asset))
		// }

		if (foundedPreferences?.preferences?.markersSize) {
			setMarkersSize(foundedPreferences?.preferences?.markersSize)
		}
	}
}
