import moment from "moment"
import { Timestamp } from "firebase/firestore"
import { gapi } from "gapi-script"
import { firestoreOld } from "../../../../firebase"
import { bookingSlice } from "../../_redux/booking/bookingSlice"
const { actions } = bookingSlice

export async function syncCalendarBookings(params) {
	const { customerId, user, resources, dispatch } = params

	const response = await gapi.client.calendar.events.list({
		calendarId: "primary",
		timeMin: moment().subtract(30, "days").toISOString(),
		timeMax: moment().add(30, "days").toISOString(),
		showDeleted: true,
		singleEvents: true,
		orderBy: "startTime"
	})
	//Filter events where there is a room that is also a resource in our app
	const events = response.result.items.filter(
		val =>
			val.attendees &&
			val.attendees.some(v => resources.some(r => r.calendarResource === v.email))
	)

	events.forEach(val => {
		firestoreOld
			.collection("Customers")
			.doc(customerId)
			.collection("Bookings")
			.where("calendarEventId", "==", val.id)
			.where("deleted", "==", false)
			.get()
			.then(snapshot => {
				const docs = snapshot.docs
				const _attendees = val.attendees.filter(a => !a.resource).map(a => a.email)
				//If event is cancelled delete booking
				if (val.status === "cancelled") {
					const _resource = resources.find(r =>
						val.attendees.some(a => a.email === r.calendarResource)
					)
					docs.forEach(doc => {
						firestoreOld
							.collection("Customers")
							.doc(customerId)
							.collection("Bookings")
							.doc(doc.id)
							.update({
								deleted: true,
								deletedInfo: {
									reason: "deleted_in_calendar",
									userInteraction: false,
									id: user.id,
									email: user.email,
									date: Timestamp.now()
								}
							})
						dispatch(actions.bookingCanceled(doc.id))
						sendToBigQuery({
							customerId: customerId,
							raw: {
								bookingId: doc.id,
								status: "deleted",
								resourceId: _resource.id,
								resourceType: _resource.type,
								bookingStart: moment(val.start.dateTime).format(),
								bookingEnd: moment(val.end.dateTime).format(),
								attendees: _attendees,
								updatedBy: user.email,
								updatedOn: moment(Timestamp.now().toDate()).format()
							}
						})
						console.log("Running 1")
					})
					return
				}
				// If there are no bookings with this id create a new one
				if (docs.length === 0) {
					const _resource = resources.find(r =>
						val.attendees.some(a => a.email === r.calendarResource)
					)
					const _doc = firestoreOld
						.collection("Customers")
						.doc(customerId)
						.collection("Bookings")
						.doc()

					_doc.set({
						deleted: false,
						summary: val.summary || "",
						resource: _resource.id,
						calendarEventId: val.id,
						date: moment(val.start.dateTime).startOf("day").toDate(),
						start: moment(val.start.dateTime).toDate(),
						end: moment(val.end.dateTime).toDate(),
						attendees: _attendees,
						created: {
							id: user.id,
							email: user.email,
							date: Timestamp.now(),
							eventCreator: val.creator.email
						}
					})
					dispatch(
						actions.resourceBooked({
							deleted: false,
							summary: val.summary || "",
							resource: _resource.id,
							calendarEventId: val.id,
							date: moment(val.start.dateTime).startOf("day"),
							start: moment(val.start.dateTime),
							end: moment(val.end.dateTime),
							attendees: _attendees,
							created: {
								id: user.id,
								email: user.email,
								date: Timestamp.now(),
								eventCreator: val.creator.email
							}
						})
					)
					sendToBigQuery({
						customerId: customerId,
						raw: {
							bookingId: _doc.id,
							status: "booked",
							resourceId: _resource.id,
							resourceType: _resource.type,
							bookingStart: moment(val.start.dateTime).format(),
							bookingEnd: moment(val.end.dateTime).format(),
							attendees: _attendees,
							createdBy: user.email,
							createdOn: moment(Timestamp.now().toDate()).format()
						}
					})
					console.log("Running 2")
					return
				}
				// If there are is one booking with this id check and edit if needed
				if (docs.length === 1) {
					const _doc = docs[0]
					const _data = _doc.data()

					//Check if something needs is different and need to be updated
					if (
						((!val.summary && _data.summary.length === 0) ||
							(val.summary && _data.summary === val.summary)) &&
						_data.attendees.length === _attendees.length &&
						_data.attendees
							.slice()
							.sort()
							.every((value, index) => {
								return value === _attendees.slice().sort()[index]
							}) &&
						moment(val.start.dateTime).isSame(_data.start.toDate()) &&
						moment(val.end.dateTime).isSame(_data.end.toDate())
					) {
						return
					}

					const _resource = resources.find(r =>
						val.attendees.some(a => a.email === r.calendarResource)
					)

					firestoreOld
						.collection("Customers")
						.doc(customerId)
						.collection("Bookings")
						.doc(_doc.id)
						.update({
							deleted: false,
							summary: val.summary || "",
							date: moment(val.start.dateTime).startOf("day").toDate(),
							start: moment(val.start.dateTime).toDate(),
							end: moment(val.end.dateTime).toDate(),
							attendees: _attendees,
							updated: {
								id: user.id,
								email: user.email,
								date: Timestamp.now()
							}
						})
					dispatch(
						actions.bookingEdited({
							deleted: false,
							summary: val.summary || "",
							date: moment(val.start.dateTime).startOf("day"),
							start: moment(val.start.dateTime),
							end: moment(val.end.dateTime),
							attendees: _attendees,
							updated: {
								id: user.id,
								email: user.email,
								date: Timestamp.now()
							},
							resource: _doc.resource,
							created: _doc.created,
							calendarEventId: _doc.calendarEventId
						})
					)
					sendToBigQuery({
						customerId: customerId,
						raw: {
							bookingId: _doc.id,
							status: "booked",
							resourceId: _resource.id,
							resourceType: _resource.type,
							bookingStart: moment(val.start.dateTime).format(),
							bookingEnd: moment(val.end.dateTime).format(),
							attendees: _attendees,
							updatedBy: user.email,
							updatedOn: moment(Timestamp.now().toDate()).format()
						}
					})
					console.log("Running 3")
					return
				}
				// If there are more than one bookings with this id delete all and create just one with updated data
				if (docs.length > 1) {
					docs.forEach((doc, index) => {
						if (index === 0) {
							const _resource = resources.find(r =>
								val.attendees.some(a => a.email === r.calendarResource)
							)
							firestoreOld
								.collection("Customers")
								.doc(customerId)
								.collection("Bookings")
								.doc(doc.id)
								.update({
									deleted: false,
									summary: val.summary || "",
									date: moment(val.start.dateTime).startOf("day").toDate(),
									start: moment(val.start.dateTime).toDate(),
									end: moment(val.end.dateTime).toDate(),
									attendees: _attendees,
									updated: {
										id: user.id,
										email: user.email,
										date: Timestamp.now()
									}
								})
							dispatch(
								actions.bookingEdited({
									deleted: false,
									summary: val.summary || "",
									date: moment(val.start.dateTime).startOf("day"),
									start: moment(val.start.dateTime),
									end: moment(val.end.dateTime),
									attendees: _attendees,
									updated: {
										id: user.id,
										email: user.email,
										date: Timestamp.now()
									},
									resource: doc.resource,
									created: doc.created,
									calendarEventId: doc.calendarEventId
								})
							)
							sendToBigQuery({
								customerId: customerId,
								raw: {
									bookingId: doc.id,
									status: "booked",
									resourceId: _resource.id,
									resourceType: _resource.type,
									bookingStart: moment(val.start.dateTime).format(),
									bookingEnd: moment(val.end.dateTime).format(),
									attendees: _attendees,
									updatedBy: user.email,
									updatedOn: moment(Timestamp.now().toDate()).format()
								}
							})
							return
						}
						firestoreOld
							.collection("Customers")
							.doc(customerId)
							.collection("Bookings")
							.doc(doc.id)
							.update({
								deleted: true,
								deletedInfo: {
									reason: "duplicated_booking",
									userInteraction: false,
									id: user.id,
									email: user.email,
									date: Timestamp.now()
								}
							})
						dispatch(actions.bookingCanceled(doc.id))
					})
					console.log("Running 4")
					return
				}
			})
	})
}

function sendToBigQuery(values) {
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
	myHeaders.append("Content-Type", "application/json")
	const raw = JSON.stringify(values.raw)
	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/booking/streamBQ/${values.customerId}`,
		requestOptions
	).catch(error => console.log("Error", error))
}
