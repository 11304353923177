import React, { useState, useEffect, useMemo, useRef } from "react"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { useSelector, shallowEqual } from "react-redux"
import { Collapse, TextField, makeStyles } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Button } from "../../../../_partials/Button"
import NodesInventoryTable from "./NodesInventoryTable"
import NodesInventoryFilter from "./NodesInventoryFilter"
import { SearchBarCustom1 } from "../../../../_partials/inputs/SearchBarCustom1"
import SearchIcon from "@material-ui/icons/Search"
import { useUIContext } from "../UIContext"
import moment from "moment"
import * as XLSX from "xlsx"
import { useReactToPrint } from "react-to-print"
import FilterListIcon from "@material-ui/icons/FilterList"
import NewNodeModal from "../NewNode/NewNodeModal"
import { ExportModal } from "../Export/ExportModal"
import TableToPrint from "../TableToPrint/TableToPrint"
import CurrentTableToPrint from "../TableToPrint/CurrentTableToPrint"

const useStyles = makeStyles(() => ({
	autocomplete: {
		width: "170px"
	},
	select: {
		borderColor: "red",
		height: "36px",
		backgroundColor: "#F2F2F2",
		borderRadius: "5px",

		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "transparent"
		},
		"& .MuiInputBase-input": {
			fontWeight: "500"
		}
	}
}))

export const NodesInventoryCard = ({
	nodesInventory,
	technology,
	setTechnology,
	handleTechnology,
	technologyList,
	allCustomers,
	firmwaresInventory
}) => {
	const classes = useStyles()

	//* ━━━━━━━━━━━━━━━━━━━━━━━━━ UIContext
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			rowsToUseNodesTable: UIContext.rowsToUseNodesTable,
			columnVisibilityModelNodesTable: UIContext.columnVisibilityModelNodesTable,
			setColumnVisibilityModelNodesTable: UIContext.setColumnVisibilityModelNodesTable,
			currentNodesTablePageInfo: UIContext.currentNodesTablePageInfo
		}
	}, [UIContext])

	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const { listLoading, customers, isSuper } = useSelector(
		state => ({
			listLoading: state.inventory.listLoading,
			customers: state?.inventory?.customers,
			isSuper: state.auth.claims.isSuper
		}),

		shallowEqual
	)

	// ━━━━━━━━━ useStates  ━━━━━━━━━
	const [showFilter, setShowFilter] = useState(false)
	const [showNewNodeModal, setShowNewNodeModal] = useState(false)
	//export
	const [showExportModal, setShowExportModal] = useState(false)
	const [checked, setChecked] = useState(false)
	const [optionsOfExport, setOptionsOfExport] = useState(null)
	const [columnVisibilityModelBeforePrint, setColumnVisibilityModelBeforePrint] = useState({})
	//for both components have acess to table rows
	const [rows, setRows] = useState([])
	const [searchText, setSearchText] = React.useState("")
	//fill this options with inventory state
	const [filterTypeOptions, setFilterTypeOptions] = useState([])
	const [filterCustomerOptions, setFilterCustomerOptions] = useState([])
	const [filterSiteOptions, setFilterSiteOptions] = useState([])
	const [filterTypeNodeOptions, setFilterTypeNodeOptions] = useState([])
	const [filterStatusOptions, setFilterStatusOptions] = useState([])
	const [filterVendorOptions, setFilterVendorOptions] = useState([])
	const [filterModelOptions, setFilterModelOptions] = useState([])
	const [filterFirmwareOptions, setFilterFirmwareOptions] = useState([])
	const [loadingFilter, setLoadingFilter] = useState(false)

	// ━━━━━━━━━ useEffects  ━━━━━━━━━
	useEffect(() => {
		if (!nodesInventory || nodesInventory.length === 0 || !technology) {
			setLoadingFilter(false)
			return
		}
		// console.log("🪲 ~ useEffect ~ nodesInventory:", nodesInventory)

		setLoadingFilter(true) // Set loadingFilter to true before starting data processing

		let mergedInventory = [...nodesInventory]

		// Merge Customer Information
		if (allCustomers) {
			// const dataForFirmwareTooltip = firmwaresInventory?.filter(firm => firm.id === firmwareId)

			mergedInventory = mergedInventory.map(node => {
				const customer = allCustomers.find(c => c.id === node.customerId)
				if (customer) {
					const siteInfo = customer.sites?.find(site => site.id === node.siteId) || {}
					return {
						...node,
						customerName: customer.name || "",
						siteId: siteInfo.id || "",
						siteName: siteInfo.name || ""
					}
				}
				return node
			})
		}

		// Merge Firmware Information
		if (firmwaresInventory) {
			mergedInventory = mergedInventory.map(node => {
				const firmware = firmwaresInventory.find(f => f.id === node.firmwareId)
				return firmware
					? {
							...node,
							firmwareVersionValue: firmware.version || "",
							firmwareVendor: firmware.vendor || ""
					  }
					: node
			})
		}

		// Utility function to create unique options
		const createOptions = key => {
			return Array.from(
				new Set(mergedInventory.filter(node => node[key]).map(node => node[key]))
			).map(value => ({ node: value, selected: false }))
		}

		// Generating filter options
		setFilterTypeOptions(createOptions("nodeType"))
		setFilterTypeNodeOptions(createOptions("type"))
		setFilterStatusOptions(createOptions("status"))
		setFilterVendorOptions(createOptions("firmwareVendor"))
		setFilterModelOptions(createOptions("model"))
		setFilterFirmwareOptions(createOptions("firmwareVersion"))

		// Generating customer filter options
		if (customers) {
			const customerOptions = Array.from(
				new Set(customers.filter(c => c.name).map(c => c.name))
			).map(name => ({ node: name, selected: false }))
			setFilterCustomerOptions(customerOptions)
			const sitesOptions = customers.flatMap(customer => {
				const siteOptions = customer.sites.map(site => {
					return {
						group: customer.name,
						node: site.name,
						selected: false
					}
				})
				return siteOptions
			})

			setFilterSiteOptions(sitesOptions)
		}

		setLoadingFilter(false) // Set loadingFilter to false after data processing is complete
	}, [nodesInventory, technology, allCustomers, firmwaresInventory, customers])

	useEffect(() => {
		if (!nodesInventory || !technology) return
		if (optionsOfExport === "print_all_pages") {
			handlePrint()
		}
		if (optionsOfExport === "print_current_page") {
			handleCurrentPagePrint()
		}
		if (optionsOfExport === "excel_all_pages") {
			exportToExcel()
		}
		if (optionsOfExport === "excel_current_page") {
			exportToExcel(UIProps.currentNodesTablePageInfo)
		}
	}, [optionsOfExport])
	useEffect(() => {
		setOptionsOfExport(null)
	}, [optionsOfExport])

	//  ━━━━━━━ useRef ━━━━━━━
	const componentRef = useRef()
	const currentPageComponentRef = useRef()

	//  ━━━━━━━ Handlers ━━━━━━━
	const header = (
		<div className="d-flex align-items-center">
			<span>Nodes</span>
		</div>
	)
	const handleSearch = value => {
		setSearchText(value)
	}
	const exportToExcel = (currentNodesTablePageInfo = null) => {
		if (UIProps.rowsToUseNodesTable) {
			const filteredDataAfterSort =
				UIProps.rowsToUseNodesTable &&
				UIProps.rowsToUseNodesTable.map(node => ({
					customerId: node.customerId || "",
					customerName: node.customerName || "",
					firmware: node.firmware || "",
					firmwareId: node.firmwareId || "",
					firmwareVendor: node.firmwareVendor || "",
					floorPlanId: node.floorPlanId || "",
					floorPlanName: node.floorPlanName || "",
					id: node.id || "",
					model: node.model || "",
					modelId: node.modelId || "",
					modelVendor: node.modelVendor || "",
					// networkId: node.networkId || "",
					nodeType: node.nodeType || "",
					node_address: node.node_address || "",
					notes: node.notes || "",
					siteId: node.siteId || "",
					siteName: node.siteName || "",
					status: node.status || "",
					type: node.type || ""
				}))

			const filteredDataAfterColumnsChange =
				filteredDataAfterSort &&
				filteredDataAfterSort.map(personData => {
					const filteredData = Object.entries(personData)
						.filter(([key]) => {
							const isVisible = UIProps.columnVisibilityModelNodesTable[key]
							if (isVisible === undefined) {
								console.warn(`Visibility setting for ${key} is undefined.`)
							}
							return isVisible !== false && isVisible !== undefined
						})
						.reduce((acc, [key, value]) => {
							acc[key] = value
							return acc
						}, {})
					return filteredData
				})

			let finalData = []
			if (currentNodesTablePageInfo) {
				// console.log(currentNodesTablePageInfo?.page && currentNodesTablePageInfo?.pageSize)
				finalData =
					filteredDataAfterColumnsChange &&
					filteredDataAfterColumnsChange.slice(
						currentNodesTablePageInfo?.page * currentNodesTablePageInfo?.pageSize,
						(currentNodesTablePageInfo?.page + 1) * currentNodesTablePageInfo?.pageSize
					)
			} else {
				finalData = filteredDataAfterColumnsChange && filteredDataAfterColumnsChange
			}

			const workbook = XLSX.utils.book_new()
			const worksheet = XLSX.utils.json_to_sheet(finalData) // send Data to Excel

			// Set column widths manually (in pixels)
			const columnWidths = [
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				// { wch: 30 },
				{ wch: 30 },
				{ wch: 30 }
			]

			const dateToday = moment().format("ddd DD MMM")

			worksheet["!cols"] = columnWidths

			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

			const excelBuffer = XLSX.write(workbook, { type: "buffer" })
			const blob = new Blob([excelBuffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			})
			const url = URL.createObjectURL(blob)
			const a = document.createElement("a")
			a.href = url
			a.download = `Inventory_Nodes_Table_${dateToday}.xlsx`
			a.click()
			URL.revokeObjectURL(url)
		}
	}
	const getPageMargins = () => {
		return `@page { margin: 2rem 2rem !important; }`
	}
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: () => getPageMargins()
	})
	const handleCurrentPagePrint = useReactToPrint({
		content: () => currentPageComponentRef.current,
		pageStyle: () => getPageMargins()
	})

	return (
		<Card
			data-testid="NodesInventoryCard"
			style={{
				display: "flex",
				flexDirection: "column"
			}}
		>
			<CardHeader
				title={header}
				toolbar={
					<div className="d-md-flex align-items-center">
						<SearchBarCustom1
							onSearch={handleSearch}
							searchText={searchText}
							disabled={listLoading || !technology ? true : false}
							placeholder="Search"
							size="small"
							style={{
								width: "200px",
								background: "#F2F2F2",
								marginRight: "10px"
							}}
							InputProps={{
								startAdornment: (
									<SearchIcon
										fontSize="small"
										style={{
											color: "#7F8C8D",
											marginRight: "0.7rem"
										}}
									/>
								)
							}}
						/>
						<Autocomplete
							id="audit_tool_users_autocomplete"
							selectOnFocus
							clearOnBlur
							disableClearable
							handleHomeEndKeys
							className={classes.autocomplete}
							options={technologyList || []}
							size="small"
							getOptionLabel={option => {
								return option || ""
							}}
							value={technology}
							onChange={handleTechnology}
							renderInput={params => (
								<TextField
									className={classes.select}
									{...params}
									placeholder="Technology"
									variant="outlined"
								/>
							)}
							popupIcon={<ExpandMoreIcon style={{ color: "#8C8CA2" }} />} // Your custom arrow icon
						/>
						<Button
							id="manage_site_new_building_btn"
							className="ml-2 mr-3"
							color="primary"
							variant="contained"
							text="FILTER"
							style={{
								height: "34.44px"
							}}
							disabled={listLoading || !technology ? true : false}
							onClick={() => {
								setShowFilter(prev => !prev)
							}}
							endIcon={<FilterListIcon />}
							data-testid="filter"
						/>
						<Button
							disabled={listLoading || !technology ? true : false}
							color="primary"
							className="mr-3"
							variant="contained"
							text="New node"
							onClick={() => {
								setShowNewNodeModal(prev => !prev)
							}}
						/>
						<Button
							disabled={listLoading || !technology ? true : false}
							color="primary"
							variant="contained"
							text="Export"
							onClick={() => {
								setShowExportModal(prev => !prev)
							}}
						/>
					</div>
				}
				// line={false}
			/>
			<Collapse in={true}>
				<CardBody style={{ padding: "10px" }}>
					<NodesInventoryTable
						filterCustomerOptions={filterCustomerOptions}
						filterSiteOptions={filterSiteOptions}
						filterTypeOptions={filterTypeOptions}
						filterTypeNodeOptions={filterTypeNodeOptions}
						filterStatusOptions={filterStatusOptions}
						filterVendorOptions={filterVendorOptions}
						filterModelOptions={filterModelOptions}
						filterFirmwareOptions={filterFirmwareOptions}
						technology={technology}
						rows={rows}
						setRows={setRows}
						searchText={searchText}
						setSearchText={setSearchText}
					/>
					{showFilter && (
						<NodesInventoryFilter
							showFilter={showFilter}
							setShowFilter={setShowFilter}
							filterSiteOptions={filterSiteOptions}
							setFilterSiteOptions={setFilterSiteOptions}
							filterCustomerOptions={filterCustomerOptions}
							setFilterCustomerOptions={setFilterCustomerOptions}
							filterTypeNodeOptions={filterTypeNodeOptions}
							setFilterTypeNodeOptions={setFilterTypeNodeOptions}
							filterTypeOptions={filterTypeOptions}
							setFilterTypeOptions={setFilterTypeOptions}
							filterStatusOptions={filterStatusOptions}
							setFilterStatusOptions={setFilterStatusOptions}
							filterVendorOptions={filterVendorOptions}
							setFilterVendorOptions={setFilterVendorOptions}
							filterModelOptions={filterModelOptions}
							setFilterModelOptions={setFilterModelOptions}
							filterFirmwareOptions={filterFirmwareOptions}
							setFilterFirmwareOptions={setFilterFirmwareOptions}
							isSuper={isSuper}
							loadingFilter={loadingFilter}
						/>
					)}

					{showNewNodeModal && (
						<NewNodeModal
							showNewNodeModal={showNewNodeModal}
							setShowNewNodeModal={setShowNewNodeModal}
							technology={technology}
						/>
					)}

					<div style={{ overflow: "hidden", height: "0" }}>
						<TableToPrint
							rowsToUse={UIProps.rowsToUseNodesTable}
							columnVisibilityModel={UIProps.columnVisibilityModelNodesTable}
							columns={[
								{
									dataField: "node_address",
									text: "Node Address",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "mac_address",
									text: "Mac Address",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "nodeType",
									text: "Node Type",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "type",
									text: "Type",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "status",
									text: "Status",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "customerName",
									text: "Customer",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								// {
								// 	dataField: "networkId",
								// 	text: "Site",
								// 	type: "string",
								// 	style: {
								// 		verticalAlign: "middle",
								// 		minWidth: "150px"
								// 	}
								// },
								{
									dataField: "floorPlanId",
									text: "Floorplan",
									type: "string",
									minWidth: 150,
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "model",
									text: "Model",
									type: "string",
									minWidth: 150,
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "firmware",
									text: "firmware",
									type: "string",
									minWidth: 150,
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "firmwareVendor",
									text: "Firmware Vendor",
									type: "string",
									minWidth: 150,
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "notes",
									text: "Notes",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								}
							]}
							ref={componentRef}
						/>
						<CurrentTableToPrint
							rowsToUse={UIProps.rowsToUseNodesTable}
							currentTablePageInfo={UIProps.currentNodesTablePageInfo}
							columnVisibilityModel={UIProps.columnVisibilityModelNodesTable}
							columns={[
								{
									dataField: "node_address",
									text: "Node Address",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "mac_address",
									text: "Mac Address",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "nodeType",
									text: "Node Type",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "type",
									text: "Type",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "status",
									text: "Status",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "customerName",
									text: "Customer",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								// {
								// 	dataField: "networkId",
								// 	text: "Site",
								// 	type: "string",
								// 	style: {
								// 		verticalAlign: "middle",
								// 		minWidth: "150px"
								// 	}
								// },
								{
									dataField: "floorPlanId",
									text: "Floorplan",
									type: "string",
									minWidth: 150,
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "model",
									text: "Model",
									type: "string",
									minWidth: 150,
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "firmware",
									text: "firmware",
									type: "string",
									minWidth: 150,
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "firmwareVendor",
									text: "Firmware Vendor",
									type: "string",
									minWidth: 150,
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "notes",
									text: "Notes",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								}
							]}
							ref={currentPageComponentRef}
						/>
					</div>

					<ExportModal
						contentClassName="bg-transparent"
						show={showExportModal}
						onHide={() => setShowExportModal(false)}
						columnsForExport={[
							"node_address",
							"mac_address",
							"nodeType",
							"type",
							"status",
							"customerName",
							// "networkId",
							"floorPlanId",
							"model",
							"firmware",
							"firmwareVendor",
							"notes"
						]}
						setOptionsOfExport={setOptionsOfExport}
						columnVisibilityModelBeforePrint={columnVisibilityModelBeforePrint}
						rowsToUse={UIProps.rowsToUseNodesTable}
						setColumnVisibilityModel={UIProps.setColumnVisibilityModelNodesTable}
					/>
				</CardBody>
			</Collapse>
		</Card>
	)
}
