import React from "react"
import { ProgressBar } from "../../_metronic/layout/components/extras/ProgressBar"

export function ModalProgressBar({ style, dataTestid = "modal_progress_bar" }) {
	return (
		<ProgressBar.LinearIndeterminate
			variant={"indeterminate"}
			color={"primary"}
			style={{
				width: "100%",
				height: "5px",

				...style
			}}
			data-testid={dataTestid}
		/>
	)
}
