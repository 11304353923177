import * as requestFromServer from "./notificationsCrud"
import { notificationsSlice, callTypes } from "./notificationsSlice"
import { convertCollectionSnapshotToMap } from "../_helpers/ActionHelpers"

const { actions } = notificationsSlice

export const fetchNotifications = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams || queryParams.customerId === null) {
		return Promise.reject(new Error("Invalid params"))
	}

	return requestFromServer
		.getNotifications(queryParams)
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			const notifications = convertCollectionSnapshotToMap(response)

			dispatch(actions.notificationsFetched(notifications))
		})
		.catch(error => {
			console.log("Fetch Notifications: ", error)
			error.clientMessage = "Can't fetch Notifications"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const editNotifications = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	if (!queryParams || queryParams.id === null || queryParams.notifications === null) {
		return Promise.reject(new Error("Invalid params"))
	}
	return requestFromServer
		.editNotifications(queryParams)
		.then(() => {
			// Prepare the Notifications object indexed by their IDs
			const notificationsMap =
				queryParams.notifications &&
				queryParams.notifications.reduce((acc, notification) => {
					acc[notification.id] = notification
					return acc
				}, {})

			notificationsMap &&
				dispatch(
					actions.notificationEdited({ id: queryParams.id, notifications: notificationsMap })
				)
			notificationsMap &&
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: "Notifications edited with success"
				})
			// queryParams.onHide()
		})
		.catch(error => {
			console.log("Edit Notifications error: ", error)
			error.clientMessage = "Can't edit Notifications"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
			// queryParams.onHide()
		})
}

export const deleteNotifications = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	if (
		!queryParams ||
		!queryParams.notificationsToDelete ||
		queryParams.notificationsToDelete.length === 0
	) {
		return Promise.reject(new Error("Invalid params"))
	}

	const validNotifications = queryParams.notificationsToDelete.filter(
		notification => notification && notification.id
	)

	if (validNotifications.length === 0) {
		return Promise.reject(new Error("No valid notifications to delete"))
	}

	return requestFromServer
		.deleteNotifications({ ...queryParams, notificationsToDelete: validNotifications })
		.then(() => {
			dispatch(actions.notificationDeleted(queryParams.notificationsToDelete.id))
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: "Notifications deleted with success"
			})
		})
		.catch(error => {
			console.log("Delete notifications error: ", error)
			error.clientMessage = "Can't delete notifications"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: error.clientMessage
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}
