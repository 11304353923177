import React, { useEffect, useMemo, useState } from "react"
import { Timeline } from "./timeline/Timeline"
import { Teammates } from "./booking-teammates/Teammates"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import * as actions2 from "../_redux/booking/bookingActions"
import { bookingSlice } from "../_redux/booking/bookingSlice"
import { useUIContext } from "./UIContext"
import { firestoreOld } from "../../../firebase"
import moment from "moment"
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined"
import { PageTitle } from "../../_partials/widgets/PageTitle"
import { GeralInfoRow } from "../../_partials/widgets/GeralInfoRow"
import { Small } from "../../_partials/typography/Small"
import { ButtonText } from "../../_partials/typography/ButtonText"
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined"
import EventSeatIcon from "@material-ui/icons/EventSeat"
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows"
import StarIcon from "@material-ui/icons/Star"
import { gapi } from "gapi-script"
import { Button } from "react-bootstrap"
import { BookingStatistics } from "./booking-statistics/BookingStatistics"
import { OfficeDays } from "./office-days/OfficeDays"
import { Map } from "./map/Map"
import { Timestamp } from "firebase/firestore"
import { PeopleStatus } from "./people-status/PeopleStatus"
import { syncCalendarBookings } from "./_helpers/ActionHelpers"
import "./Booking.css"
import "../../_assets/sass/pages/booking/booking-geral-info-row.scss"

const { actions } = bookingSlice

export function Booking() {
	const dispatch = useDispatch()

	//━━━ Data from UIContext ━━━\\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			selectedFloor: UIContext.selectedFloor,
			setSelectedFloor: UIContext.setSelectedFloor,
			calendarSignedInInfo: UIContext.calendarSignedInInfo,
			setCalendarSignedInInfo: UIContext.setCalendarSignedInInfo
		}
	}, [UIContext])

	//━━━ Get data from redux ━━━\\
	const {
		selectedCustomer,
		currentCustomer,
		selectedLocation,
		bookings,
		resources,
		department,
		user,
		users,
		profileLvl
	} = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer?.customerId,
			currentCustomer: state.profile?.currentCustomer,
			selectedLocation: state.profile?.currentLocation?.netId,
			bookings: state.booking?.bookings,
			resources: state.booking?.resources,
			department: state.basePage?.department,
			user: state.auth?.user,
			users: state.booking?.users,
			profileLvl: state.auth?.claims?.profileLvl
		}),
		shallowEqual
	)

	//★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
	//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
	const [weekDeskBookings, setWeekDeskBookings] = useState(0)
	const [weekMeetingBookings, setWeekMeetingBookings] = useState(0)
	const [monthBookings, setMonthBookings] = useState(0)
	const [signedInfo, setSignedInfo] = useState({
		signedIn: false,
		email: null,
		error: null
	})

	//★━━━━━━━━━━━━━━━★ UseEffects ★━━━━━━━━━━━━━━━★\\
	//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
	useEffect(() => {
		if (selectedCustomer && selectedLocation) {
			dispatch(
				actions2.fetchResources({
					customer: selectedCustomer,
					location: selectedLocation
				})
			)
		}
	}, [selectedCustomer, selectedLocation])

	useEffect(() => {
		if ((selectedCustomer, resources)) {
			const startOfMonth = moment(Timestamp.now().toDate()).startOf("month").toDate()
			const endOfMonth = moment(Timestamp.now().toDate()).endOf("month").add(1, "month").toDate()

			Promise.all(
				resources.map(val => {
					return firestoreOld
						.collection(`Customers/${selectedCustomer}/Bookings`)
						.where("deleted", "==", false)
						.where("resource", "==", val.id)
						.where("date", ">=", startOfMonth)
						.where("date", "<=", endOfMonth)
						.get()
				})
			).then(results => {
				const _bookings = results
					.map(snapshot => {
						return snapshot.docs.map(docSnapshot => {
							return {
								...docSnapshot.data(),
								id: docSnapshot.id
							}
						})
					})
					.flat()
				dispatch(
					actions.bookingsFetched({
						entities: _bookings
					})
				)
			})
		}
	}, [selectedCustomer, resources])

	function handleClientLoad() {
		gapi.load("client:auth2", initClient)
	}

	function initClient() {
		gapi.client
			.init({
				apiKey: process.env.REACT_APP_CALENDAR_API_KEY,
				clientId: process.env.REACT_APP_CALENDAR_CLIENT_ID,
				discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
				scope: "https://www.googleapis.com/auth/calendar"
			})
			.then(
				() => {
					gapi.auth2.getAuthInstance().isSignedIn.listen(e =>
						setSignedInfo({
							...signedInfo,
							signedIn: e
						})
					)
					setSignedInfo({
						...signedInfo,
						signedIn: gapi.auth2.getAuthInstance().isSignedIn.get()
					})
				},
				err => {
					//appendPre(JSON.stringify(error, null, 2));
				}
			)
	}

	function handleAuthClick() {
		gapi.auth2 &&
			gapi.auth2
				.getAuthInstance()
				.signIn()
				.catch(err => {
					console.log("Err: ", err)
					setSignedInfo({
						...signedInfo,
						error: err.error
					})
				})
	}

	function handleSignoutClick() {
		gapi.auth2.getAuthInstance().signOut()
	}

	useEffect(() => {
		handleClientLoad()
	}, [])

	useEffect(() => {
		if (signedInfo.signedIn) {
			setSignedInfo({
				...signedInfo,
				email: gapi.auth2.getAuthInstance().currentUser.le.wt?.getEmail()
			})
		} else {
			setSignedInfo({
				...signedInfo,
				email: null
			})
			//handleAuthClick()
		}
	}, [signedInfo.signedIn])

	useEffect(() => {
		UIProps.setCalendarSignedInInfo(signedInfo)
	}, [signedInfo])

	useEffect(() => {
		const _bookings =
			bookings &&
			bookings.filter(val => {
				if (department?.manager === user?.id || profileLvl >= 2) {
					return val
				}
				return val.created.id === user.id
			})
		if (_bookings) {
			const activeBookingsThisWeek =
				_bookings.filter(val => {
					return (
						moment(val.end.toDate()).isSameOrAfter(moment()) &&
						moment(val.start.toDate()).startOf("day").isSameOrBefore(moment().endOf("week"))
					)
				}) || []
			const deskBookings = activeBookingsThisWeek.filter(val => {
				const resource = resources.find(res => res.id === val.resource)
				return resource?.type === "desk"
			})

			const bookingsThisWeek =
				_bookings.filter(val => {
					return (
						moment(val.end.toDate()).isSameOrAfter(moment().startOf("week")) &&
						moment(val.start.toDate()).startOf("day").isSameOrBefore(moment().endOf("week"))
					)
				}) || []
			const meetBookings = bookingsThisWeek.filter(val => {
				const resource = resources.find(res => res.id === val.resource)
				return resource?.type === "meeting"
			})

			const bookingsThisMonth =
				_bookings.filter(val => {
					return (
						moment(val.end.toDate()).isSameOrAfter(moment().startOf("month")) &&
						moment(val.start.toDate()).startOf("day").isSameOrBefore(moment().endOf("month"))
					)
				}) || []
			setWeekDeskBookings(deskBookings?.length || 0)
			setWeekMeetingBookings(meetBookings?.length || 0)
			setMonthBookings(bookingsThisMonth.length)
		}
	}, [bookings, resources, department, user, profileLvl])

	// 👨‍💻 Fetch users from auth 👨‍💻 \\
	useEffect(() => {
		//!This runs one more time than needed
		// if (department) {
		//    const people = [...department.employees, department.manager]
		//    let uniqueIds = people.filter((element, index) => {
		//       return people.indexOf(element) === index
		//    })
		//    const functions = firebase.app().functions("europe-west2")
		//    const listAllusers = functions.httpsCallable("getUsersByIDs")
		//    listAllusers({
		//       tenantID: currentCustomer.tenantID,
		//       ids: uniqueIds,
		//    }).then(users => {
		//       const filteredUsers = users.data.filter(val => !val.error)
		//       dispatch(actions.usersFetched(filteredUsers))
		//    })
		// } else {
		//    const functions = firebase.app().functions("europe-west2")
		//    const listAllusers = functions.httpsCallable("getUsersByIDs")
		//    listAllusers({
		//       tenantID: currentCustomer.tenantID,
		//       ids: [user.id],
		//    }).then(users => {
		//       dispatch(actions.usersFetched(users.data))
		//    })
		// }
	}, [currentCustomer, department, user])

	// 👨‍💻 Fetch users docs 👨‍💻 \\
	useEffect(() => {
		if (users) {
			Promise.all(
				users.map(val => {
					return firestoreOld
						.collection("Customers")
						.doc(selectedCustomer)
						.collection("Users")
						.doc(val.uid)
						.get()
				})
			)
				.then(response => {
					const data = response.map(doc => {
						return { ...doc.data(), id: doc.id }
					})
					dispatch(actions.usersDocsFetched(data))
				})
				.catch(err => console.log("Err: ", err))
		}
	}, [users])

	const [didSyncing, setDidSyncing] = useState(false)
	// Sync calendar events with bookings \\
	useEffect(() => {
		if (signedInfo.signedIn && resources && bookings && !didSyncing) {
			syncCalendarBookings({
				customerId: selectedCustomer,
				user,
				resources,
				dispatch
			})
			setDidSyncing(true)
		}
	}, [signedInfo.signedIn, user, resources, bookings])

	const data = [
		{
			icon: <EventSeatIcon style={{ fill: "#8C8CA2" }} />,
			value: weekDeskBookings,
			text: "Active desk bookings this week"
		},
		{
			icon: <DesktopWindowsIcon style={{ fill: "#8C8CA2" }} />,
			value: "0",
			text: "Booked tools",
			className: "booking-geral-info-extra-2"
		},
		{
			icon: <PeopleAltOutlinedIcon style={{ fill: "#8C8CA2" }} />,
			value: weekMeetingBookings,
			text: "Meeting rooms booked this week",
			className: "booking-geral-info-extra"
		},
		{
			icon: <StarIcon style={{ fill: "#8C8CA2" }} />,
			value: monthBookings,
			text: "Bookings this month"
			/* icon: <div>
         <StarIcon style={{ fill: "#8C8CA2" }} />
         <StarIcon style={{ fill: "#8C8CA2" }} />
         <StarIcon style={{ fill: "#8C8CA2" }} />
         <StarIcon style={{ fill: "#8C8CA2" }} />
         <StarBorderIcon style={{ fill: "#8C8CA2" }} />
      </div>,
      value: "4 / 5",
      text: "Average booking rating" */
		}
	]

	return (
		<>
			<PageTitle
				title="Booking"
				icon={<CalendarTodayOutlinedIcon style={{ height: "22px", width: "20px" }} />}
			/>
			<GeralInfoRow data={data} />
			<div
				className="row mt-5"
				style={{
					margin: "auto",
					backgroundColor: signedInfo.signedIn ? "#D3EEE8" : "#F4E1E6",
					padding: "5px 15px",
					borderRadius: "10px"
				}}
			>
				{!signedInfo.signedIn ? (
					<div className="w-100 d-flex justify-content-between">
						<Small
							style={{ display: "flex", alignItems: "center" }}
							text="Currently not signed in to your calendar, some features will be disabled"
							color="#D03157"
						/>
						<Button
							size="sm"
							style={{
								marginLeft: "auto",
								backgroundColor: "#C3C4DD",
								border: 0
							}}
							onClick={handleAuthClick}
						>
							<ButtonText text="SIGN IN" color="#323389" />
						</Button>
					</div>
				) : (
					<div className="w-100 d-flex justify-content-between">
						<Small
							style={{ display: "flex", alignItems: "center" }}
							text={`Signed in as: ${signedInfo.email}`}
							color="#31D0AA"
						/>
						<Button
							size="sm"
							style={{
								marginLeft: "auto",
								backgroundColor: "#C3C4DD",
								border: 0
							}}
							onClick={handleSignoutClick}
						>
							<ButtonText text="SIGN OUT" color="#323389" />
						</Button>
					</div>
				)}
			</div>
			<div className="row mt-5">
				<div className="col">
					<Map />
				</div>
			</div>
			<div className="row">
				<div className="col-12 col-xl-8" style={{ marginTop: "30px" }}>
					<Timeline />
				</div>
				<div
					className="col-12 col-xl-4 col-offset-2"
					style={{
						display: "flex",
						flexDirection: "column",
						marginTop: "30px"
					}}
				>
					<Teammates />
				</div>
			</div>
			<div className="row" style={{ marginTop: "30px" }}>
				<div className="col">
					<OfficeDays />
				</div>
			</div>
			<div className="row" style={{ marginTop: "30px" }}>
				<div className="col">
					<BookingStatistics />
				</div>
			</div>
			{(department?.manager === user?.id || profileLvl >= 2) && (
				<div className="row" style={{ marginTop: "30px" }}>
					<div className="col">
						<PeopleStatus />
					</div>
				</div>
			)}
		</>
	)
}
