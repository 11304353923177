import React, { useState, useEffect } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { firestoreOld } from "../../../../../firebase"
import { collection, query, where, getDocs } from "firebase/firestore"
import SensorsPageInfoTopRow from "./SensorsPageInfoTopRow"
import { SensorsCard } from "./SensorsCard"
import * as actionsInventory from "../../../_redux/admin/inventory/InventoryActions"
import { DesignStudioSlice } from "../../../_redux/admin/designStudio/DesignStudioSlice"

const actionsDesignStudio = DesignStudioSlice.actions

const SensorsPositioningPage = () => {
	const dispatch = useDispatch()

	const { selectedSite, currentFloorPlan } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			currentFloorPlan: state.profile?.currentFloorPlan
		}),
		shallowEqual
	)

	const [sensorCounts, setSensorCounts] = useState({
		assigned: 0,
		planned: 0,
		placed: 0,
		approved: 0,
		online: 0,
		lost: 0,
		removed: 0
	})

	const [allTotalCount, setAllTotalCount] = useState({
		assigned: 0,
		planned: 0,
		placed: 0,
		approved: 0,
		online: 0,
		lost: 0,
		removed: 0
	})

	const [isFetching, setIsFetching] = useState(false)
	const [collectionChanged, setCollectionChanged] = useState(false)
	const [allSensorsData, setAllSensorsData] = useState([])

	useEffect(() => {
		setCollectionChanged(false)
	}, [collectionChanged])

	useEffect(() => {
		if (!selectedSite || !currentFloorPlan) return

		setIsFetching(true)
		setCollectionChanged(false)

		const fetchData = async () => {
			try {
				const allData = await Promise.all([
					fetchSensorsByStatus("assigned"),
					fetchSensorsByStatus("planned"),
					fetchSensorsByStatus("placed"),
					fetchNodesByStatus("approved", "sensor"),
					fetchNodesByStatus("online", "sensor"),
					fetchNodesByStatus("lost", "sensor"),
					fetchNodesByStatus("removed", "sensor"),
					fetchModels()
				])

				const combined = allData.flat()
				const cleanedData = combined.filter(item => item !== undefined)

				setAllSensorsData(cleanedData)

				if (cleanedData.length > 0) {
					dispatch(actionsDesignStudio.addSensorsDesignStudio(cleanedData))
				} else {
					dispatch(actionsDesignStudio.addSensorsDesignStudio([]))
				}
			} catch (error) {
				console.error("Error fetching data:", error)
			} finally {
				setIsFetching(false)
			}
		}

		fetchData()
	}, [currentFloorPlan, selectedSite, collectionChanged])

	const fetchSensorsByStatus = async status => {
		const collectionRef = collection(firestoreOld, `Sites/${selectedSite.id}/SensorsPosition`)
		const q = query(collectionRef, where("status", "==", status))

		try {
			const snapshot = await getDocs(q)
			const data = snapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))

			const filteredData = data.filter(sensor => sensor.floorPlanId === currentFloorPlan.id)
			// console.log(`Filtered data for status ${status}:`, filteredData)

			setSensorCounts(prevState => ({
				...prevState,
				[status]: filteredData.length
			}))
			setAllTotalCount(prevState => ({
				...prevState,
				[status]: snapshot.size
			}))

			// return filteredData
		} catch (error) {
			console.error("Error fetching sensors:", error)
			// return []
		}
	}

	const fetchNodesByStatus = async (status, nodeType) => {
		const collectionRef = collection(firestoreOld, `Sites/${selectedSite.id}/Nodes`)
		const q = query(
			collectionRef,
			where("status", "==", status),
			where("nodeType", "==", nodeType)
		)

		try {
			const snapshot = await getDocs(q)
			const data = snapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))

			const filteredData = data.filter(sensor => sensor.floorPlanId === currentFloorPlan.id)
			// console.log(`Filtered data for ${nodeType} with status ${status}:`, filteredData)

			setAllSensorsData(prev => [...prev, ...filteredData])

			setSensorCounts(prevState => ({
				...prevState,
				[status]: filteredData.length
			}))
			setAllTotalCount(prevState => ({
				...prevState,
				[status]: snapshot.size
			}))

			return filteredData
		} catch (error) {
			console.error("Error fetching nodes:", error)
			return []
		}
	}

	const fetchModels = async () => {
		dispatch(actionsInventory.getModels({ technology: "Wirepas" }))
		return []
	}

	return (
		<div className="mt-3" data-testid="sensors-positioning-page">
			<SensorsPageInfoTopRow
				isFetching={isFetching}
				anchorCounts={sensorCounts}
				allTotalCount={allTotalCount}
			/>
			<SensorsCard setCollectionChanged={setCollectionChanged} />
		</div>
	)
}

export default SensorsPositioningPage
