import React from "react"

export function ButtonTextSmall({ text, color }) {
	return (
		<span
			style={{
				fontFamily: "Poppins",
				fontWeight: "bold",
				fontSize: "14px",
				letterSpacing: "6%",
				color: color
			}}
		>
			{text}
		</span>
	)
}
