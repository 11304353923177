import React, { useMemo, useEffect, useRef, useCallback, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../_redux/reporting/people/peopleActions"
import { useUIContext } from "../UIContext"
import { AttendanceByRole } from "./Charts/AttendanceByRole"
import { TimesheetPerRole } from "./Charts/TimesheetPerRole"
import TimesheetDaily from "./Charts/TimesheetDaily"
import TimesheetDailyToPrintChart from "./Charts/TimesheetDailyToPrint/TimesheetDailyToPrintChart"
import CurrentTimesheetDailyToPrintChart from "./Charts/TimesheetDailyToPrint/CurrentTimesheetDailyToPrintChart"
import { debounce } from "lodash"

import { useReactToPrint } from "react-to-print"
import * as XLSX from "xlsx"
import moment from "moment"
import { ExportModal } from "../export-modal/ExportModal"

export function PeopleTimesheetPage() {
	const dispatch = useDispatch()

	// ━━━━━━━━━━━━━━━ UIContext ━━━━━━━━━━━━━━━ \\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			queryParams: UIContext.queryParams,
			setQueryParams: UIContext.setQueryParams,
			//for data grid
			ids: UIContext.ids,
			setIds: UIContext.setIds,
			columnsOrder: UIContext.columnsOrder,
			rowsToUse: UIContext.rowsToUse,
			columnVisibilityModel: UIContext.columnVisibilityModel,
			setColumnVisibilityModel: UIContext.setColumnVisibilityModel,
			currentTablePageInfo: UIContext.currentTablePageInfo
		}
	}, [UIContext])

	// ━━━━━━━━━━━━━━━ useSelector ━━━━━━━━━━━━━━━ \\
	const { group, customer, selectedSite, siteId, people, roles, vendors, singlePeopleTimesheet } =
		useSelector(
			state => ({
				group: state.persistentReporting?.group,
				customer: state.profile?.currentCustomer,
				selectedSite: state.profile?.currentSite,
				siteId: state.profile?.currentSite?.id,
				people: state.basePage?.people || [],
				roles: state.profile?.currentCustomer?.roles || [],
				vendors: state.profile?.currentCustomer?.vendors || [],
				singlePeopleTimesheet: state.reporting.singlePeopleTimesheet
			}),
			shallowEqual
		)

	// ━━━━━━━━━━━━━━━ useState ━━━━━━━━━━━━━━━ \\
	//for data grid
	const [checked, setChecked] = useState(false)
	const [optionsOfExport, setOptionsOfExport] = useState(null)
	const [showExporModal, setShowExporModal] = useState(false)
	const [assetsData, setAssetsData] = useState() // AssetsData parsed
	const [columnVisibilityModelBeforePrint, setColumnVisibilityModelBeforePrint] = useState({})
	const [rows, setRows] = useState([])

	// ━━━━━━━━━━━━━━━ useEffect ━━━━━━━━━━━━━━━ \\

	useEffect(() => {
		fetchSummary()

		// Cleanup the debounce on component unmount
		return () => {
			fetchSummary.cancel()
		}
	}, [fetchSummary])

	// ━━━━━━━━━━━━━━━ handlers ━━━━━━━━━━━━━━━ \\
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fetchSummary = useCallback(
		debounce(() => {
			if (!customer || !selectedSite) return

			dispatch(
				actions.fetchPeopleTimesheetFetchAllPeople({
					customerId: customer.id,
					siteId,
					selectedSite,
					people: people,
					roles: roles,
					vendors: vendors,
					nodeType: "people",
					...UIProps.queryParams
				})
			)
		}, 300), // Adjust the debounce delay as needed
		[customer, UIProps.queryParams, selectedSite, siteId, people, roles, vendors]
	)

	useEffect(() => {
		fetchSummaryTheSecond()

		// Cleanup the debounce on component unmount
		return () => {
			fetchSummaryTheSecond.cancel()
		}
	}, [fetchSummaryTheSecond])

	// ━━━━━━━━━━━━━━━ handlers ━━━━━━━━━━━━━━━ \\
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fetchSummaryTheSecond = useCallback(
		debounce(() => {
			if (!customer || !selectedSite) return

			dispatch(
				actions.fetchPeopleTimesheetFetchAllPeopleToTimesheets({
					customerId: customer.id,
					siteId,
					selectedSite,
					people: people,
					roles: roles,
					vendors: vendors,
					nodeType: "people", //TODO: estes params vão chegar do novo header ou dropdown se for people ou assets
					...UIProps.queryParams
				})
			)
		}, 300), // Adjust the debounce delay as needed
		[customer, UIProps.queryParams, selectedSite, siteId, people, roles, vendors]
	)

	useEffect(() => {
		fetchSummaryTheThird()

		// Cleanup the debounce on component unmount
		return () => {
			fetchSummaryTheThird.cancel()
		}
	}, [fetchSummaryTheThird])

	// ━━━━━━━━━━━━━━━ handlers ━━━━━━━━━━━━━━━ \\
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fetchSummaryTheThird = useCallback(
		debounce(() => {
			if (!customer || !selectedSite) return

			dispatch(
				actions.fetchPeopleTimesheetFetchSinglePeopleToTimesheets({
					customerId: customer.id,
					siteId,
					selectedSite,
					people: people,
					roles: roles,
					vendors: vendors,
					nodeType: "people", //TODO: estes params vão chegar do novo header ou dropdown se for people ou assets
					...UIProps.queryParams
				})
			)
		}, 300), // Adjust the debounce delay as needed
		[customer, UIProps.queryParams, selectedSite, siteId, people, roles, vendors]
	)

	const componentRef = useRef()
	//for data grid
	const currentPageComponentRef = useRef()

	const getPageMargins = () => {
		return `@page { margin: 2rem 2rem !important; }`
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: () => getPageMargins()
	})

	//for data grid
	const handleCurrentPagePrint = useReactToPrint({
		content: () => currentPageComponentRef.current,
		pageStyle: () => getPageMargins()
	})

	// changed for data grid
	const exportToExcel = (currentTablePageInfo = null) => {
		if (UIProps.rowsToUse) {
			const filteredDisabledTimesheetDaily =
				UIProps.rowsToUse &&
				UIProps.rowsToUse
					.filter(val => !val.disabled)
					.filter(val => val?.assetDetails?.name.trim() !== "")

			if (filteredDisabledTimesheetDaily.length === 0) {
				console.warn("No data available for export.")
				return
			}

			const initialColumnsOrder = [
				{
					field: "day",
					sort: null
				},
				{
					field: "worker",
					sort: null
				},
				{
					field: "role",
					sort: null
				},
				{
					field: "company",
					sort: null
				},
				{
					field: "start",
					sort: null
				},
				{
					field: "end",
					sort: null
				},
				{
					field: "hours",
					sort: null
				}
			]

			const filteredDataAfterSort =
				filteredDisabledTimesheetDaily &&
				filteredDisabledTimesheetDaily.map(val => ({
					day: val?.day || "",
					worker: val?.worker || "",
					role: val?.role || "",
					company: val?.company || "",
					start: val?.start || "",
					end: val?.end || "",
					hours: val?.hours || ""
				}))

			const filteredDataAfterColumnsChange =
				filteredDataAfterSort &&
				filteredDataAfterSort.map(assetData => {
					// Filter out fields based on columnVisibilityModel
					return Object.entries(assetData)
						.filter(([key]) => UIProps.columnVisibilityModel[key] !== false)
						.reduce((acc, [key, value]) => {
							acc[key] = value
							return acc
						}, {})
				})

			let finalData = []
			if (currentTablePageInfo) {
				finalData =
					filteredDataAfterColumnsChange &&
					filteredDataAfterColumnsChange.slice(
						currentTablePageInfo?.page * currentTablePageInfo?.pageSize,
						(currentTablePageInfo?.page + 1) * currentTablePageInfo?.pageSize
					)
			} else {
				finalData = filteredDataAfterColumnsChange && filteredDataAfterColumnsChange
			}

			const workbook = XLSX.utils.book_new()
			const worksheet = XLSX.utils.json_to_sheet(finalData) // send Data to Excel

			// Set column widths manually (in pixels)
			const columnWidths = [
				{ wch: 50 }, // Width of the first column (Name)
				{ wch: 50 }, // Width of the first column (Company)
				{ wch: 50 }, // Width of the first column (Email)
				{ wch: 50 }, // Width of the first column (Phone)
				{ wch: 50 }, // Width of the first column (Phone)
				{ wch: 50 }, // Width of the first column (Phone)
				{ wch: 50 } // Width of the first column (Phone)
			]

			const dateToday = moment().format("ddd DD MMM")

			worksheet["!cols"] = columnWidths

			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

			const excelBuffer = XLSX.write(workbook, { type: "buffer" })
			const blob = new Blob([excelBuffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			})
			const url = URL.createObjectURL(blob)
			const a = document.createElement("a")
			a.href = url
			a.download = customer
				? `${customer?.customerInfo?.name}_${selectedSite?.siteInfo?.name}_TimesheetDaily_${dateToday}.xlsx`
				: `Workers_${dateToday}.xlsx`
			a.click()
			URL.revokeObjectURL(url)
		}
	}

	//changed for data grid
	useEffect(() => {
		if (!singlePeopleTimesheet) return

		if (optionsOfExport === "print_all_pages") {
			handlePrint()
		}
		if (optionsOfExport === "print_current_page") {
			handleCurrentPagePrint()
		}
		if (optionsOfExport === "excel_all_pages") {
			exportToExcel()
		}
		if (optionsOfExport === "excel_current_page") {
			exportToExcel(UIProps.currentTablePageInfo)
		}
	}, [optionsOfExport])

	useEffect(() => {
		setOptionsOfExport(null)
	}, [optionsOfExport])

	useEffect(() => {
		if (!selectedSite || !customer) {
			checked &&
				dispatch({
					type: "SNACKBAR_WARNING",
					payload: `No location selected. Please select a location on top.`
				})

			return
		}
	}, [customer, selectedSite, checked])

	return (
		<div className="row" id="people_timesheet_main_div">
			<>
				<div className="col-12">
					<AttendanceByRole />
				</div>
				<div className="col-12">
					<TimesheetPerRole />
				</div>
				<div className="col-12">
					<TimesheetDaily
						ref={componentRef}
						setShowExporModal={setShowExporModal}
						singlePeopleTimesheet={singlePeopleTimesheet}
						rows={rows}
						setRows={setRows}
					/>
				</div>

				<div className="col-12" style={{ overflow: "hidden", height: "0" }}>
					{/* <TimesheetDailyToPrint ref={componentRef} /> */}
					<TimesheetDailyToPrintChart
						rowsToUse={rows}
						columnVisibilityModel={UIProps.columnVisibilityModel}
						ref={componentRef}
					/>

					<CurrentTimesheetDailyToPrintChart
						rowsToUse={UIProps.rowsToUse}
						currentTablePageInfo={UIProps.currentTablePageInfo}
						columnVisibilityModel={UIProps.columnVisibilityModel}
						ref={currentPageComponentRef}
					/>
				</div>

				{/* added for data grid */}
				{UIProps?.rowsToUse && UIProps?.rowsToUse.length > 0 && (
					<ExportModal
						show={showExporModal}
						onHide={() => setShowExporModal(false)}
						contentClassName="bg-transparent"
						columnsForExport={["name", "company", "description", "type", "uuid"]}
						setOptionsOfExport={setOptionsOfExport}
						columnVisibilityModelBeforePrint={columnVisibilityModelBeforePrint}
						setColumnVisibilityModelBeforePrint={setColumnVisibilityModelBeforePrint}
					/>
				)}
			</>
		</div>
	)
}
