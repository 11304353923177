import React from "react"
import { IconButton } from "@material-ui/core"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../../_partials/typography"
import CloseIcon from "@material-ui/icons/Close"
import { Button } from "../../../../../_partials/Button"

export default function DeleteModal({ show, onHide, onDelete, poi }) {
	return (
		<Modal show={show} onHide={onHide} contentClassName="bg-transparent">
			<Modal.Body
				style={{
					padding: "0",
					backgroundColor: "#ECF1F4",
					borderRadius: "10px",
					overflow: "hidden"
				}}
			>
				<div className="pl-7 pr-7 pb-7 pt-2">
					{/* ━━★  TITLE  ★━━ */}
					<div className="d-flex align-items-center justify-content-between mt-3">
						<div>
							<Bold>Delete Anchor</Bold>
						</div>
						<div>
							<IconButton size="small" onClick={onHide} style={{ left: "12px" }}>
								<CloseIcon />
							</IconButton>
						</div>
					</div>

					{/* ━━★  BODY  ★━━ */}
					<div className="mt-5 pb-5">
						<span>
							Are you sure to unplace anchor <b>{poi?.UUID}</b> ?
						</span>
					</div>

					{/* ━━★  FOOTER  ★━━ */}
					<div className="row" style={{ marginTop: "2rem" }}>
						<div className="col">
							<Button
								fullWidth
								variant="outlined"
								color="primary"
								onClick={onHide}
								className="mr-2"
							>
								Cancel
							</Button>
						</div>
						<div className="col">
							<Button
								fullWidth
								color="primary"
								variant="contained"
								onClick={onDelete}
								className="ml-2"
							>
								Unplace
							</Button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
