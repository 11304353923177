import React, { useState, useEffect, useMemo } from "react"
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar
} from "../../../_metronic/_partials/controls"
import { MapWidget } from "./map/MapWidget"
import { MapWidget2 } from "./map/MapWidget2"
import { MapWidget3 } from "./map/MapWidget3"
import { MapWidget4 } from "./map/MapWidget4"
import { MapWidget5 } from "./map/MapWidget5"
import { MapWidget6 } from "./map/MapWidget6"
import { GeojsonWallTest } from "./wallTest/GeojsonWallTest"
import { WallFrameTest } from "./wallFrameTest/WallFrameTest"
import { Nav, Tab } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { useUIContext } from "./UIContext"
import * as actions from "../_redux/booking/bookingActions"
import { Button, ButtonGroup } from "@material-ui/core/"

export function MapTestCard() {
	const floorPlanData = useSelector(state => state.booking?.floorPlans)
	const superAdmin = useSelector(state => state.auth?.claims?.isSuper)
	const claimsCustomer = useSelector(state => state.auth?.claims?.customerId)
	const selectedCustomer = useSelector(state => state.profile?.currentCustomer?.customerId)
	const selectedLocation = useSelector(state => state.profile?.currentLocation?.netId)

	const [key, setKey] = useState()

	const dispatch = useDispatch()

	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			changesMade: UIContext.changesMade,
			selectedFloor: UIContext.selectedFloor,
			setSelectedFloor: UIContext.setSelectedFloor
		}
	}, [UIContext])

	useEffect(() => {
		//dispatch(actions.fetchFloorPlans(superAdmin ? selectedCustomer : claimsCustomer))
		if (selectedCustomer && selectedLocation) {
			dispatch(
				actions.fetchResources({
					customer: superAdmin ? selectedCustomer : claimsCustomer,
					location: selectedLocation
				})
			)
		}
	}, [selectedCustomer, selectedLocation])

	useEffect(() => {
		if (floorPlanData) {
			UIProps.setSelectedFloor(floorPlanData[0]?.floorPlanId)
		}
	}, [floorPlanData])

	useEffect(() => {
		setKey(UIProps.selectedFloor)
	}, [UIProps.selectedFloor])

	return (
		<Card>
			{selectedLocation ? (
				<>
					<CardHeader title="Booking">
						<CardHeaderToolbar>
							{floorPlanData && (
								<div className="card-toolbar">
									<ButtonGroup variant="contained" color="default" disableElevation>
										{floorPlanData.map(
											values =>
												values.netId == selectedLocation &&
												(values.floorPlanId == key ? (
													<Button key={values.floorPlanId} color="secondary">
														{values.name}
													</Button>
												) : (
													<Button
														key={values.floorPlanId}
														onClick={e =>
															UIProps.setSelectedFloor(values.floorPlanId)
														}
													>
														{values.name}
													</Button>
												))
										)}
									</ButtonGroup>
								</div>
							)}
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{/* <WallFrameTest /> */}
						<MapWidget6 />
					</CardBody>
				</>
			) : (
				<h3 style={{ margin: "10px", color: "red" }}>Please select a location!</h3>
			)}
		</Card>
	)
}
