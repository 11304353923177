import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
	makeStyles,
	ButtonGroup,
	Tabs,
	Tab,
	Typography,
	Box,
	SvgIcon,
	Button
} from "@material-ui/core"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../_redux/reporting/reportingActions"

const TabsReporting = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const { page } = useSelector(
		state => ({
			page: state.persistentReporting?.page
		}),
		shallowEqual
	)

	const [value, setValue] = useState(0)
	const [secondPage, setSecondPage] = useState(0)
	const [newPageName, setNewPageName] = useState({ first: null, second: null, onChange: false })

	// When header changes, change to the corresponding page
	useEffect(() => {
		if (newPageName.onChange) {
			if (newPageName?.second === "Summary" && newPageName.first === "people") {
				dispatch(actions.setPage("peopleSummary"))
			}
			if (newPageName?.second === "Timesheet" && newPageName.first === "people") {
				dispatch(actions.setPage("peopleTimesheet"))
			}
			if (newPageName?.second === "Summary" && newPageName.first === "asset") {
				dispatch(actions.setPage("assetSummary"))
			}
			if (newPageName?.second === "Timesheet" && newPageName.first === "asset") {
				dispatch(actions.setPage("assetTimesheet"))
			}
		}
	}, [newPageName])

	// When component is mounted, set the headers settings to the corresponding page
	useEffect(() => {
		if (page && page.includes("people")) {
			setValue(0) // people
			setNewPageName(prevState => ({
				...prevState,
				first: "people",
				onChange: false
			}))
		}
		if (page && page.includes("asset")) {
			setValue(1) // asset
			setNewPageName(prevState => ({ ...prevState, first: "asset", onChange: false }))
		}
		if (page && page.includes("Summary")) {
			setSecondPage(0) // Summary
			setNewPageName(prevState => ({
				...prevState,
				second: "Summary",
				onChange: false
			}))
		}
		if (page && page.includes("Timesheet")) {
			setSecondPage(1) // Timesheet
			setNewPageName(prevState => ({
				...prevState,
				second: "Timesheet",
				onChange: false
			}))
		}
	}, [page])

	const handleChange = (event, newValue) => {
		setValue(newValue)

		// set the new page name settings to the newValue
		if (newValue === 0) {
			setNewPageName({
				...newPageName,
				first: "people",
				onChange: true
			})
		}
		if (newValue === 1) {
			setNewPageName({
				...newPageName,
				first: "asset",
				onChange: true
			})
		}
	}

	const colorActiveIcon = index => (value === index ? "#2673F0" : "#4A4A68")
	const variantActiveColor = index => (secondPage === index ? "contained" : "outlined")
	const textActiveColor = index => (secondPage === index ? "#FFFFFF" : "#8C8CA1")

	return (
		<div>
			<div className="d-flex justify-content-end">
				<Tabs
					value={value}
					onChange={handleChange}
					// variant="scrollable"
					// scrollButtons="on"
					indicatorColor="primary"
					textColor="inherit"
				>
					<Tab
						style={{ minWidth: "100px" }}
						label={
							<span
								style={{
									color: value === 0 ? "#2673F0" : "#4A4A68",
									fontSize: "12px",
									fontWeight: 700
								}}
							>
								PEOPLE
							</span>
						}
						icon={
							<SvgIcon>
								<svg
									width="26"
									height="26"
									viewBox="0 0 14 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g id="user">
										<path
											id="Vector"
											d="M8.56469 2.69463C8.02632 2.3349 7.39336 2.1429 6.74586 2.1429C5.87759 2.1429 5.04489 2.48782 4.43093 3.10177C3.81697 3.71573 3.47205 4.54844 3.47205 5.41671C3.47205 6.0642 3.66406 6.69716 4.02379 7.23554C4.38352 7.77391 4.89482 8.19353 5.49303 8.44131C6.09124 8.6891 6.7495 8.75393 7.38455 8.62761C8.01961 8.50129 8.60295 8.18949 9.0608 7.73164C9.51865 7.27379 9.83045 6.69045 9.95677 6.0554C10.0831 5.42034 10.0183 4.76208 9.77047 4.16387C9.52268 3.56566 9.10307 3.05436 8.56469 2.69463ZM4.1995 1.6058C4.95323 1.10218 5.83937 0.833374 6.74586 0.833374C7.96144 0.833374 9.12723 1.31626 9.98677 2.1758C10.8463 3.03534 11.3292 4.20113 11.3292 5.41671C11.3292 6.3232 11.0604 7.20934 10.5568 7.96307C10.0531 8.71679 9.33732 9.30425 8.49983 9.65115C7.66233 9.99806 6.74078 10.0888 5.8517 9.91197C4.96262 9.73512 4.14595 9.2986 3.50496 8.65761C2.86397 8.01662 2.42745 7.19995 2.2506 6.31087C2.07375 5.42179 2.16451 4.50024 2.51142 3.66274C2.85832 2.82525 3.44577 2.10943 4.1995 1.6058ZM11.984 19.1667H13.2935V15.8929C13.2935 14.6773 12.8106 13.5115 11.9511 12.652C11.0915 11.7924 9.92572 11.3096 8.71015 11.3096H4.78158C3.566 11.3096 2.40021 11.7924 1.54067 12.652C0.681128 13.5115 0.198242 14.6773 0.198242 15.8929V19.1667H1.50777V15.8929C1.50777 15.0246 1.85268 14.1919 2.46664 13.578C3.0806 12.964 3.91331 12.6191 4.78158 12.6191H8.71015C9.14007 12.6191 9.56578 12.7038 9.96298 12.8683C10.3602 13.0328 10.7211 13.274 11.0251 13.578C11.3291 13.882 11.5702 14.2429 11.7348 14.6401C11.8993 15.0373 11.984 15.463 11.984 15.8929V19.1667Z"
											fill={colorActiveIcon(0)}
										/>
									</g>
								</svg>
							</SvgIcon>
						}
						{...a11yProps(0)}
					/>
					<Tab
						style={{ minWidth: "100px" }}
						label={
							<span
								style={{
									color: value === 1 ? "#2673F0" : "#4A4A68",
									fontSize: "12px",
									fontWeight: 700
								}}
							>
								ASSETS
							</span>
						}
						icon={
							<SvgIcon>
								<svg
									width="28"
									height="28"
									viewBox="0 0 23 22"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g id="Communication / Asset">
										<path
											id="Vector"
											d="M18.6521 6.1944L11.3188 1.91663C11.2253 1.8621 11.119 1.83337 11.0108 1.83337C10.9026 1.83337 10.7964 1.8621 10.7029 1.91663L3.36958 6.1944C3.27732 6.24822 3.20078 6.32528 3.14759 6.4179C3.09439 6.51052 3.0664 6.61546 3.06641 6.72227V15.2778C3.0664 15.3846 3.09439 15.4896 3.14759 15.5822C3.20078 15.6748 3.27732 15.7519 3.36958 15.8057L10.7029 20.0834C10.7964 20.138 10.9026 20.1667 11.0108 20.1667C11.119 20.1667 11.2253 20.138 11.3188 20.0834L18.6521 15.8057C18.7443 15.7519 18.8209 15.6748 18.8741 15.5822C18.9273 15.4896 18.9553 15.3846 18.9553 15.2778V6.72227C18.9553 6.61546 18.9273 6.51052 18.8741 6.4179C18.8209 6.32528 18.7443 6.24822 18.6521 6.1944ZM11.0108 3.15199L17.1315 6.72227L11.0108 10.2926L4.8902 6.72227L11.0108 3.15199ZM4.28863 7.78634L10.3997 11.3509V18.4915L4.28863 14.9269V7.78634ZM11.6219 18.4915V11.3509L17.733 7.78634V14.9269L11.6219 18.4915Z"
											fill={colorActiveIcon(1)}
										/>
									</g>
								</svg>
							</SvgIcon>
						}
						{...a11yProps(1)}
					/>
				</Tabs>
			</div>
			<div className="d-flex justify-content-end" style={{ margin: "2rem 0 1rem 0" }}>
				<ButtonGroup>
					<Button
						classes={{ outlinedPrimary: classes.outlinedPrimary }}
						onClick={() => {
							setSecondPage(0)
							setNewPageName({
								...newPageName,
								second: "Summary",
								onChange: true
							})
						}}
						variant={variantActiveColor(0)}
						color="primary"
						startIcon={<TimesheetIcon1 textActiveColor={() => textActiveColor(0)} />}
						style={{
							fontSize: "14px",
							fontWeight: 700,
							color: textActiveColor(0)
						}}
					>
						SUMMARY
					</Button>
					<Button
						classes={{ outlinedPrimary: classes.outlinedPrimary }}
						onClick={() => {
							setSecondPage(1)
							setNewPageName({
								...newPageName,
								second: "Timesheet",
								onChange: true
							})
						}}
						variant={variantActiveColor(1)}
						color="primary"
						startIcon={<TimesheetIcon2 textActiveColor={() => textActiveColor(1)} />}
						style={{
							fontSize: "14px",
							fontWeight: 700,
							color: textActiveColor(1)
						}}
					>
						TIMESHEET
					</Button>
				</ButtonGroup>
			</div>
		</div>
	)
}

export default TabsReporting

const TimesheetIcon1 = ({ textActiveColor }) => {
	return (
		<SvgIcon>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="23"
				height="23"
				viewBox="0 0 23 23"
				fill="none"
			>
				<path
					d="M15.625 3.54712H17.6875C18.0522 3.54712 18.4019 3.69198 18.6598 3.94985C18.9176 4.20771 19.0625 4.55745 19.0625 4.92212V19.3596C19.0625 19.7243 18.9176 20.074 18.6598 20.3319C18.4019 20.5898 18.0522 20.7346 17.6875 20.7346H5.3125C4.94783 20.7346 4.59809 20.5898 4.34023 20.3319C4.08237 20.074 3.9375 19.7243 3.9375 19.3596V4.92212C3.9375 4.55745 4.08237 4.20771 4.34023 3.94985C4.59809 3.69198 4.94783 3.54712 5.3125 3.54712H7.375V2.85962C7.375 2.49495 7.51987 2.14521 7.77773 1.88735C8.03559 1.62948 8.38533 1.48462 8.75 1.48462H14.25C14.6147 1.48462 14.9644 1.62948 15.2223 1.88735C15.4801 2.14521 15.625 2.49495 15.625 2.85962V3.54712ZM14.25 2.85962H8.75V5.60962H14.25V2.85962ZM5.3125 19.3596H17.6875V4.92212H15.625V6.98462H7.375V4.92212H5.3125V19.3596ZM7.375 9.04712H15.625V10.4221H7.375V9.04712ZM7.375 12.4846H12.875V13.8596H7.375V12.4846ZM10.8125 15.9221H7.375V17.2971H10.8125V15.9221Z"
					fill={textActiveColor(0)}
				/>
			</svg>
		</SvgIcon>
	)
}

const TimesheetIcon2 = ({ textActiveColor }) => (
	<SvgIcon>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="23"
			viewBox="0 0 22 23"
			fill="none"
		>
			<g>
				<path
					d="M4.125 20.7346H9.625V19.3596H4.125V2.85962H15.125V9.73462H16.5V2.85962C16.4996 2.49506 16.3546 2.14555 16.0968 1.88777C15.8391 1.62999 15.4896 1.485 15.125 1.48462H4.125C3.76044 1.485 3.41093 1.62999 3.15315 1.88777C2.89537 2.14555 2.75038 2.49506 2.75 2.85962V19.3596C2.75038 19.7242 2.89537 20.0737 3.15315 20.3315C3.41093 20.5892 3.76044 20.7342 4.125 20.7346ZM13.75 4.23462H5.5V5.60962H13.75V4.23462ZM9.625 9.73462H5.5V11.1096H9.625V9.73462ZM5.5 6.98462H13.75V8.35962H5.5V6.98462ZM9.625 16.6096H5.5V17.9846H9.625V16.6096Z"
					fill={textActiveColor(1)}
				/>
				<path
					d="M16.042 22.1096C14.9542 22.1096 13.8908 21.7871 12.9864 21.1827C12.0819 20.5784 11.3769 19.7194 10.9607 18.7144C10.5444 17.7094 10.4355 16.6035 10.6477 15.5366C10.8599 14.4697 11.3837 13.4897 12.1529 12.7205C12.9221 11.9513 13.9021 11.4275 14.969 11.2153C16.0359 11.0031 17.1418 11.112 18.1468 11.5283C19.1517 11.9446 20.0107 12.6495 20.6151 13.554C21.2194 14.4585 21.542 15.5218 21.542 16.6096C21.542 18.0683 20.9625 19.4673 19.9311 20.4987C18.8996 21.5302 17.5007 22.1096 16.042 22.1096ZM16.042 12.4846C15.2261 12.4846 14.4286 12.7265 13.7503 13.1798C13.0719 13.6331 12.5432 14.2773 12.231 15.0311C11.9188 15.7848 11.8371 16.6142 11.9963 17.4144C12.1554 18.2145 12.5483 18.9495 13.1252 19.5264C13.7021 20.1033 14.4371 20.4962 15.2372 20.6554C16.0374 20.8145 16.8668 20.7328 17.6206 20.4206C18.3743 20.1084 19.0185 19.5797 19.4718 18.9013C19.9251 18.223 20.167 17.4255 20.167 16.6096C20.167 15.5156 19.7324 14.4664 18.9588 13.6928C18.1852 12.9192 17.136 12.4846 16.042 12.4846Z"
					fill={textActiveColor(1)}
				/>
				<path
					d="M16.9056 18.6721L15.125 16.8915V13.8596H16.5V16.3277L17.875 17.7027L16.9056 18.6721Z"
					fill={textActiveColor(1)}
				/>
			</g>
			<defs>
				<clipPath id="clip0_12075_135793">
					<rect width="22" height="22" fill="white" transform="translate(0 0.109619)" />
				</clipPath>
			</defs>
		</svg>
	</SvgIcon>
)

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
}

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		"aria-controls": `scrollable-force-tabpanel-${index}`
	}
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: "100%",
		backgroundColor: theme.palette.background.paper
	},
	outlinedPrimary: {
		borderColor: "#8C8CA1",
		"&:hover": {
			borderColor: "#505063" // Border color on hover
		}
	}
}))
