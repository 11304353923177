import { firestore } from "../../../firebase"
import { collection, doc, getDocs, deleteDoc, writeBatch } from "firebase/firestore"

export function getNotifications(queryParams) {
	// Add protection against getNotifications being null or undefined
	if (!queryParams || !queryParams.customerId) return Promise.resolve() // Return early without performing any actions
	// Add protection against customerId being empty strings
	// if (typeof queryParams.customerId !== "string" || queryParams.customerId === "") {
	// 	return Promise.reject(new Error("customerId must be a non-empty string"))
	// }

	const customerRef = doc(firestore, "Customers", queryParams.customerId)
	const messagesRef = collection(customerRef, "Messages")
	return getDocs(messagesRef)
}

export function editNotifications(queryParams) {
	// Add protection against editNotifications being null or undefined
	if (!queryParams || !queryParams?.customerId || !queryParams?.notifications)
		return Promise.reject(new Error("all params must be filled"))
	const { customerId, notifications } = queryParams

	// Add protection against customerId being empty strings
	// if (typeof customerId !== "string" || customerId === "") {
	// 	return Promise.reject(new Error("customerId must be a non-empty string"))
	// }

	if (!Array.isArray(notifications) || notifications.length === 0) {
		return Promise.reject(new Error("notifications must be a non-empty array"))
	}

	const customerRef = doc(firestore, "Customers", customerId)

	const updatePromises = notifications.map(notification => {
		// Add protection against notification.id being null, undefined, or empty
		if (!notification.id) {
			return Promise.reject(new Error("Each notification must have a valid non-empty id"))
		}
	})

	const batch = writeBatch(firestore)

	queryParams.notifications.forEach(notification => {
		const notificationsRef = doc(collection(customerRef, "Messages"), notification.id)
		batch.update(notificationsRef, notification.data)
	})

	return batch.commit()
}

export function deleteNotifications(queryParams) {
	// Add protection against deleteNotifications being null or undefined
	if (!queryParams || !queryParams.customerId || !queryParams.notificationsToDelete)
		return Promise.reject(new Error("all params must be filled"))
	const { customerId, notificationsToDelete } = queryParams

	// Add protection against customerId being empty strings
	// if (typeof customerId !== "string" || customerId === "") {
	// 	return Promise.reject(new Error("customerId must be a non-empty string"))
	// }

	if (!Array.isArray(notificationsToDelete) || notificationsToDelete.length === 0) {
		return Promise.reject(new Error("notificationsToDelete must be a non-empty array"))
	}

	const customerRef = doc(firestore, "Customers", customerId)
	const notificationsRef = collection(customerRef, "Messages")

	const promises = notificationsToDelete.map(val => {
		// Add protection against notification.id being null, undefined, or empty
		// if (!val.id || typeof val.id !== "string" || val.id.trim() === "") {
		// 	return Promise.reject(new Error("Each notifications must have a valid non-empty id"))
		// }

		const notificationRef = doc(notificationsRef, val.id)
		return deleteDoc(notificationRef)
	})

	return Promise.all(promises)
}
