//button has children because without it, I couldn't get buttons with progress animations to work.
//To change startIcon color you have to pass it by style ex. style={{color:#color}}

import React from "react"
import { Button as MUIButton, makeStyles } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
	button: {
		minWidth: "0px",
		borderRadius: "4px",
		boxShadow: "none",
		"&.MuiButton-outlined": {
			borderColor: props => theme.palette[props.color]?.main || theme.palette.primary.main
		},
		"& .MuiButton-startIcon": {
			marginLeft: "0 ",
			marginRight: "0 "
		}
	},
	label: {
		fontFamily: "Poppins",
		fontWeight: "700",
		// fontSize: "14px",
		lineHeight: "21px",
		letterSpacing: "0.04em"
	},
	primaryLight: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
		"&:hover": {
			backgroundColor: "#acd1fa !important"
		}
	},
	warning: {
		backgroundColor: theme.palette.warning.main,
		color: "#fff",
		"&:hover": {
			backgroundColor: "#f4a623 !important"
		}
	},
	warningLight: {
		backgroundColor: theme.palette.warning.light,
		color: theme.palette.warning.main,
		"&:hover": {
			backgroundColor: "#f8cd82 !important"
		}
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: "#FFF",
		"&:hover": {
			backgroundColor: "#c34415 !important"
		}
	},
	errorLight: {
		backgroundColor: theme.palette.error.light,
		color: "#E65019",
		"&:hover": {
			backgroundColor: "#f5c6b3 !important"
		}
	},
	successLight: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.success.main,
		"&:hover": {
			backgroundColor: "#77db72 !important"
		}
	},
	greyLight: {
		backgroundColor: theme.palette.grey.light,
		color: theme.palette.grey.dark,
		"&:hover": {
			backgroundColor: theme.palette.grey.main
		}
	}
}))

export function Button({ text, className, color, ...props }) {
	const classes = useStyles()

	return (
		<MUIButton
			color={
				color !== "primaryLight" &&
				color !== "successLight" &&
				color !== "error" &&
				color !== "errorLight" &&
				color !== "warning" &&
				color !== "warningLight" &&
				color !== "greyLight"
					? color
					: "default"
			}
			className={clsx(
				classes.button,
				// To be able to receive extra classes
				className,
				color === "primaryLight" && classes.primaryLight,
				color === "successLight" && classes.successLight,
				color === "error" && classes.error,
				color === "errorLight" && classes.errorLight,
				color === "warning" && classes.warning,
				color === "warningLight" && classes.warningLight,
				color === "greyLight" && classes.greyLight
			)}
			{...props}
		>
			{props.children}
			{/* With this I can make sure that my label css gets to the buttons inside a ButtonGroup */}
			{props.startIcon ? "" : <span className={classes.label}>{text}</span>}
		</MUIButton>
	)
}
