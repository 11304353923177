import React from "react"

export function Header3({ text }) {
	return (
		<span
			style={{
				fontFamily: "Poppins",
				fontWeight: "bold",
				fontSize: "24px",
				letterSpacing: "-2%"
			}}
		>
			{text}
		</span>
	)
}
