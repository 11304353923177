import React, { useRef, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import Fullscreen from "../MapControls/Fullscreen"
import {
	addGeoJsonLayerAndMarkersToMap,
	addImageLayerToMap,
	addPersonMarkers
} from "../AuditToolHelpers/AuditToolHelpers"
import ChangeFloorControlAudit from "../MapControls/ChangeFloorControlAudit"
import { profileSlice } from "../../../../redux/profile/profileSlice"

const { actions } = profileSlice

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const AuditToolMapWidget = ({
	map,
	setMap,
	floorPlans,
	selectedFloorPlan,
	updatedCurrentFloorPlan,
	setUpdatedCurrentFloorPlan,
	selectedCustomer,
	selectedSite,
	areas,
	data
}) => {
	const mapContainerRef = useRef()
	const dispatch = useDispatch()

	const [floorsControl, setFloorsControl] = useState() // Controls to select floorplan
	const [isFullscreen, setIsFullscreen] = useState(false)

	// ━━━━━━━━━━ Map ━━━━━━━━━━
	useEffect(() => {
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: "mapbox://styles/mapbox/streets-v11"
		})

		map.on("load", function (e) {
			// Map controls
			map.addControl(
				new mapboxgl.NavigationControl({
					showCompass: false
				}),
				"top-right"
			)
			map.addControl(new Fullscreen(), "top-left")

			setMap(map)
		})

		document.addEventListener("fullscreenchange", function () {
			if (document.fullscreenElement) {
				setIsFullscreen(true)
			} else {
				setIsFullscreen(false)
			}
		})

		// map.on("click", e => {
		// 	console.log(`A click event has occurred at ${e.lngLat}`)
		// })

		return () => {
			if (!map) return
			map.remove()
		}
		// return () => {
		// 	if (!map) return
		// 	setMap(prevMap => {
		// 		if (prevMap) {
		// 			prevMap.remove()
		// 		}
		// 		return null
		// 	})
		// }
	}, [])

	// ━━━━━━━━━━━━ Updated Current Floorplan  ━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!floorPlans || !selectedFloorPlan) return

		// ↓ (this is needed to fetch the floorplan with all updates like new areas, routes, etc. selectedFloorPlan hasn't the data updated.
		const updatedFloorplanInFloorPlanData =
			floorPlans && floorPlans.find(f => f?.id === selectedFloorPlan?.id)

		updatedFloorplanInFloorPlanData && setUpdatedCurrentFloorPlan(updatedFloorplanInFloorPlanData)
	}, [floorPlans, selectedFloorPlan])

	// ━━━━━━━━━━━ FloorPlan Image on Map ━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !updatedCurrentFloorPlan) return
		addImageLayerToMap({ map, floorPlan: updatedCurrentFloorPlan })
	}, [map, updatedCurrentFloorPlan])

	// ━━━━━━━━━━━━ GeoJson Areas && Areas markers ━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !updatedCurrentFloorPlan) return

		updatedCurrentFloorPlan &&
			addGeoJsonLayerAndMarkersToMap({
				map,
				geoJson: updatedCurrentFloorPlan?.geoJson,
				layerTypes: selectedCustomer?.layerTypes,
				areas
			})
	}, [map, updatedCurrentFloorPlan, selectedCustomer, areas])

	// ━━━━━━━━━━━━ Change Floors Control Button ━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map) return

		if (floorsControl) map.removeControl(floorsControl)

		const _floorsControl = new ChangeFloorControlAudit({
			dispatch: dispatch,
			selectedSite: selectedSite,
			selectedFloorPlan: selectedFloorPlan,
			floorPlans: floorPlans,
			handleClick
		})
		map.addControl(_floorsControl, "bottom-left")
		setFloorsControl(_floorsControl)
	}, [floorPlans, selectedSite, selectedFloorPlan, map])

	// ━━━━━ Select new floorPlan (control floors buttons)
	const handleClick = floorId => {
		if (!floorId) return

		const foundedFloorPlan = floorPlans && floorPlans.find(f => f.id === floorId)
		foundedFloorPlan && dispatch(actions.floorPlanSelected(foundedFloorPlan)) // changes floorplan
	}

	const [personMarkers, setPersonMarkers] = useState() // Person markers

	// ━━━━━━ Person marker ━━━━━━ \\
	useEffect(() => {
		if (!map) return

		personMarkers && personMarkers.forEach(marker => marker.remove())

		if (!data || !updatedCurrentFloorPlan) return

		const newPersonMarkers = addPersonMarkers({
			map,
			data,
			updatedCurrentFloorPlan: updatedCurrentFloorPlan,
			floorPlans: floorPlans,
			containerRef: mapContainerRef,
			isFullscreen: isFullscreen,
			dispatch
		})

		setPersonMarkers(newPersonMarkers)
	}, [map, data, updatedCurrentFloorPlan, mapContainerRef, isFullscreen])

	return (
		<div
			className="pl-0 pr-0"
			style={{ margin: "0rem 0 2rem 0" }}
			data-testid="audit-tool-map-widget"
		>
			<div ref={mapContainerRef} style={{ height: "600px", borderRadius: "3px" }} />
		</div>
	)
}

export default AuditToolMapWidget
