// import * as requestFromServer from "./assignResourcesCrud";
// import { assignResourcesSlice, callTypes } from "./assignResourcesSlice";
// import { convertCollectionSnapshotToMap } from "../../../_helpers/ActionHelpers";
import * as requestFromServer from "./routesCrud"
import { routesSlice, callTypes } from "./routesSlice"
import { basePageSlice } from "../../../../../redux/basePage/basePageSlice"
import { convertCollectionSnapshotToMap } from "../../../_helpers/ActionHelpers"
import { firestoreOld } from "../../../../../../firebase"
import firebase from "firebase/compat/app"

const { actions } = routesSlice
const basePageActions = basePageSlice.actions

// export const fetchFloorPlans = (customerId) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.list }));

//   if (!customerId) {
//     dispatch(actions.floorPlansFetched({ entities: [] }));
//   }

//   return requestFromServer
//     .getFloorPlans(customerId)
//     .then((response) => {
//       if (!response) {
//         throw Error("Can't make a request for server");
//       }

//       const floors = convertCollectionSnapshotToMap(response);
//       console.log("fetch floors success", { response, floors, customerId });

//       dispatch(actions.floorPlansFetched({ entities: floors }));
//     })
//     .catch((error) => {
//       console.log("fetch floors", error);
//       error.clientMessage = "Can't find floors";
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };

export const saveRoutesAndGeoJsonAndConnectorsNewPositions = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	let features = queryParams.data.map(val => {
		// console.log("🚀 ~ val", val);
		if (val.id === queryParams.newConnectorPositionOnFloors.connectorId) {
			// console.log("encontrou val", val);
			const alreadyExistsFloorId =
				val.properties.connectorPositionOnFloors &&
				val.properties.connectorPositionOnFloors.some(
					f => f.floorPlanId === queryParams.newConnectorPositionOnFloors.floorPlanId
				)
			// console.log("🚀 ~ alreadyExistsFloorId", alreadyExistsFloorId);

			// console.log(
			//   val.properties.connectorPositionOnFloors,
			//   "val.properties.connectorPositionOnFloors"
			// );

			let newVal = val.properties?.connectorPositionOnFloors && [
				...val.properties?.connectorPositionOnFloors
			]
			// console.log("🚀 ~ newVal 1", newVal);

			const alreadyExistsFloorIdIndex =
				newVal &&
				newVal.findIndex(
					f => f.floorPlanId === queryParams.newConnectorPositionOnFloors.floorPlanId
				)

			// console.log("🚀 ~ alreadyExistsFloorIdIndex", alreadyExistsFloorIdIndex);

			if (newVal) {
				newVal[alreadyExistsFloorIdIndex] = {
					floorPlanId: newVal && queryParams.newConnectorPositionOnFloors.floorPlanId,
					coords: newVal && queryParams.newConnectorPositionOnFloors.coords,
					connectorId: newVal && queryParams.newConnectorPositionOnFloors.connectorId
				}
			}

			return {
				...val,
				properties: {
					...val.properties,
					connectorPositionOnFloors:
						val.properties?.connectorPositionOnFloors && alreadyExistsFloorId
							? newVal
							: val.properties?.connectorPositionOnFloors && !alreadyExistsFloorId
							? //    ||
							  // alreadyExistsFloorId !== undefined
							  [
									...val.properties?.connectorPositionOnFloors,
									queryParams.newConnectorPositionOnFloors
							  ]
							: [queryParams.newConnectorPositionOnFloors]
				}
			}
		}

		return {
			...val,
			properties: {
				...val.properties
			}
		}
	})

	let newParams = {
		...queryParams,
		geoJsonRoutes: {
			type: "FeatureCollection",
			features: features
		}
	}

	// console.log(features, "features");
	// console.log(newParams, "newParams");

	return requestFromServer
		.saveRoutesGeoJsonNewConnectorPosition(newParams)
		.then(response => {
			const newFloor = {
				...queryParams.floor,
				geoJsonRoutes: newParams.geoJsonRoutes
			}

			// Fetch new floorplan with updated geoJsonRoutes
			dispatch(basePageActions.updatedFloorPlan(newFloor))

			// Remove popup from UI
			const popupElement = document.getElementById("popover3")
			if (popupElement) {
				popupElement.remove()
				// popupElement.style.display = "none";
			}

			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Saved connector position successfully.`
			})
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save connector position."
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error saving`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const saveRoutesAndGeoJson = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	// console.log(queryParams, "params actions");

	let features = queryParams.data.map(val => {
		let coordinates = null
		if (val.geoJson.geometry.type === "LineString") {
			// console.log(val, "val LineStrings");
			coordinates = val.geoJson.geometry?.coordinates.map(v => {
				// console.log(v, "v");
				// console.log(v[0], v[1], "v[0], v[1]");
				return new firebase.firestore.GeoPoint(v[1], v[0])
			})
		}

		if (val.geoJson.geometry.type === "Point") {
			// console.log(val, "val Point");
			const lng = val.geoJson.geometry?.coordinates[1]
			const lat = val.geoJson.geometry?.coordinates[0]
			const coordinatesToGeoPoint = new firebase.firestore.GeoPoint(lng, lat)
			coordinates = [coordinatesToGeoPoint]
		}

		// console.log("🚀 ~ coordinates", coordinates);

		return {
			...val.geoJson,
			properties: {
				...val.geoJson.properties,
				name: val.name,
				disableVisibility: val.geoJson.properties.disableVisibility
				// id: val.id,
				// color: val.properties.color,
			},
			geometry: {
				type: val.geoJson.geometry.type,
				coordinates
			}
		}
	})

	let newParams = {
		...queryParams,
		geoJsonRoutes: {
			type: "FeatureCollection",
			features: features
		}
	}

	// console.log(features, "features");
	// console.log(newParams, "newParams");

	return requestFromServer
		.saveRoutesGeoJson(newParams)
		.then(response => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Saved successfully`
			})
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error saving`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const deleteRoutesGeoJsonFromDB = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	// console.log(queryParams, "params actions");

	//  const filteredData1 = queryParams.data.filter(
	//     (val) => val.id !== queryParams.zoneToDelete.id
	//   );

	// const filteredData2 = queryParams.data.filter(
	//   ({ id: id1 }) =>
	//     !queryParams.allZonesToDelete.some(({ id: id2 }) => id2 === id1)
	// );
	// console.log("🚀 ~ filteredData2", filteredData2);

	let filteredData1

	if (queryParams.zoneToDelete.geoJson.geometry.type === "LineString") {
		filteredData1 = queryParams.data.filter(
			val =>
				val.id !== queryParams.zoneToDelete.id &&
				val.geoJson.properties?.routeId !== queryParams.zoneToDelete.id // ← If this route has connectors, they get deleted as well.
		)
	}

	if (queryParams.zoneToDelete.geoJson.geometry.type === "Point") {
		filteredData1 = queryParams.data.filter(val => val.id !== queryParams.zoneToDelete.id)
	}

	// console.log(filteredData1, "filteredData1");

	let features = filteredData1.map(val => {
		let coordinates = null
		if (val.geoJson.geometry.type === "LineString") {
			// console.log(val, "val LineStrings");
			coordinates = val.geoJson.geometry?.coordinates.map(v => {
				// console.log(v, "v");
				// console.log(v[0], v[1], "v[0], v[1]");
				return new firebase.firestore.GeoPoint(v[1], v[0])
			})
		}

		if (val.geoJson.geometry.type === "Point") {
			// console.log(val, "val Point");
			const lng = val.geoJson.geometry?.coordinates[1]
			const lat = val.geoJson.geometry?.coordinates[0]
			const coordinatesToGeoPoint = new firebase.firestore.GeoPoint(lng, lat)
			coordinates = [coordinatesToGeoPoint]
		}

		// console.log("🚀 ~ coordinates", coordinates);
		// const coordinates = val.geoJson.geometry.coordinates.map((v) => {
		//   console.log(v, "v")
		//   return new firebase.firestore.GeoPoint(v[1], v[0]);
		// });

		return {
			...val.geoJson,
			properties: {
				...val.geoJson.properties,
				name: val.name,
				disableVisibility: val.geoJson.properties.disableVisibility
				// id: val.id,
				// color: val.properties.color,
			},
			geometry: {
				type: val.geoJson.geometry.type,
				coordinates
			}
		}
	})

	// console.log(features, "features");

	let newParams = {
		...queryParams,
		geoJsonRoutes: {
			type: "FeatureCollection",
			features: features
		}
	}

	return requestFromServer
		.deleteRoutes(newParams)
		.then(response => {
			if (queryParams.zoneToDelete.geoJson.geometry.type === "LineString") {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: `Route successfully deleted`
				})
			}
			if (queryParams.zoneToDelete.geoJson.geometry.type === "Point") {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: `Connector successfully deleted`
				})
			}
			// dispatch({
			//   type: "SNACKBAR_SUCCESS",
			//   payload: `Route successfully deleted`,
			// });
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't delete"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error deleting`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}
