import React, { useState, useEffect, useMemo } from "react"
import { UserGrouping } from "../UserGrouping"
import { useUIContext } from "../UIContext"
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	GridOverlay,
	useGridApiContext,
	useGridRootProps
} from "@mui/x-data-grid"
import { useSelector, shallowEqual } from "react-redux"
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap"
import { Pagination } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core"
import { ProgressBar } from "../../../../../_metronic/layout/components/extras/ProgressBar"
import CheckIcon from "@material-ui/icons/Check"
import WarningIcon from "@material-ui/icons/Warning"
import inventoryFiltersIcon from "../../../../../app/assets/ck-icons/inventory-filters-icon.svg"
import {
	filterBySearchString,
	compareVersionStrings
} from "../../../Admin/manage-customer/UIHelpers"
import "../Inventory.css"
import { hide } from "@popperjs/core"

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		border: "none",
		"& .MuiPagination-root makeStyles-root-130": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},

		"& .MuiDataGrid-cell:focus": {
			outline: "none"
		},
		"& .MuiDataGrid-selectedRowCount": {
			display: "none"
		},
		"& .MuiDataGrid-colCell": {
			minWidth: "0" // Ensure that minWidth is set to 0 to allow percentage width
		},
		"& .MuiDataGrid-columnSeparator": {
			opacity: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
			fontWeight: 600,
			fontSize: "1rem",
			color: "#4a4a68"
		},
		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--textLeft": {
			fontSize: "14px",
			fontWeight: 500
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			paddingLeft: 0,
			paddingRight: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
			paddingRight: "0px",
			paddingLeft: "10px"
		},
		"& .hide-scrollbar .MuiDataGrid-scrollbar": {
			display: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
			paddingLeft: "2px"
		},

		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
			maxHeight: "65px !important",
			minHeight: "65px !important"
		},
		"& .MuiPagination-root": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		"& .MuiPaginationItem-root": {
			borderRadius: "3px !important",
			color: "#3077d3 !important",
			padding: "0 !important",
			margin: "-1px !important",
			border: "1px solid #f4f8fe !important"
		},

		"& .MuiPaginationItem-textPrimary.Mui-selected": {
			color: "#fff !important",
			border: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon, & .MuiDataGrid-root .MuiDataGrid-filterIcon, & .MuiDataGrid-root .MuiDataGrid-sortIcon":
			{
				color: "#3077d3"
			},
		"& .MuiDataGrid-root .MuiDataGrid-viewport": {
			minHeight: "700px",
			maxHeight: "none"
		},
		"& .MuiDataGrid-renderingZone": {
			maxHeight: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
			minHeight: "90px !important"
		}
	}
}))

export default function NodesInventoryTable({
	filterCustomerOptions,
	filterSiteOptions,
	filterTypeOptions,
	filterTypeNodeOptions,
	filterStatusOptions,
	filterVendorOptions,
	filterModelOptions,
	filterFirmwareOptions,
	technology,
	rows,
	setRows,
	searchText
}) {
	// ━━━━━━━━━━━━━  useUIContext  ━━━━━━━━━━━━━ \\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.idsInventoryNodes,
			setIds: UIContext.setIdsInventoryNodes,
			setTechnology: UIContext.setTechnology,
			openEditInventoryNodesModal: UIContext.openEditInventoryNodesModal,

			nodesColumnsOrder: UIContext.nodesColumnsOrder,
			setNodesColumnsOrder: UIContext.setNodesColumnsOrder,
			setRowsToUseNodesTable: UIContext.setRowsToUseNodesTable,

			columnVisibilityModelNodesTable: UIContext.columnVisibilityModelNodesTable,
			setColumnVisibilityModelNodesTable: UIContext.setColumnVisibilityModelNodesTable,
			setCurrentNodesTablePageInfo: UIContext.setCurrentNodesTablePageInfo
		}
	}, [UIContext])

	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const {
		nodesInventory,
		firmwaresInventory,
		modelsInventory,
		listLoading,
		floorPlans,
		allCustomers
	} = useSelector(
		state => ({
			nodesInventory: state.inventory.nodesInventory,
			firmwaresInventory: state.inventory.firmwaresInventory,
			modelsInventory: state.inventory.modelsInventory,
			listLoading: state.inventory.listLoading,
			floorPlans: state.basePage.floorPlans,
			allCustomers: state.inventory.customers,
			columnVisibilityModelNodesTable: UIContext.columnVisibilityModelNodesTable,
			setColumnVisibilityModelNodesTable: UIContext.setColumnVisibilityModelNodesTable
		}),

		shallowEqual
	)

	// ━━━━━━━━━━━━━  useState  ━━━━━━━━━━━━━ \\
	const [page, setPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [activeFilters, setActiveFilters] = useState(false)
	const [searchedRows, setSearchedRows] = useState(null)
	const [selectionModel, setSelectionModel] = useState([])
	const [rowsToUse, setRowsToUse] = useState([])
	const [currentRows, setCurrentRows] = useState([])
	const [rowCounter, setRowCounter] = useState(0)
	const [rowCountState, setRowCountState] = useState(rowCounter)
	const [loading, setLoading] = useState(false)
	const [sortModel, setSortModel] = useState([
		{
			field: "name",
			sort: "asc"
		}
	])
	const classes = useStyles()
	// ━━━━━━━━━━━━━  useEffect  ━━━━━━━━━━━━━ \\
	//setRows and setActiveFilters

	useEffect(() => {
		if (!nodesInventory || nodesInventory.length === 0 || !technology) return
		let unfilteredModels =
			nodesInventory.length > 0
				? nodesInventory.map(node => {
						let firmwareVersion = null
						let firmwareVendor = null
						let customerId, customerName, siteId, siteName

						if (node.firmwareId) {
							if (firmwaresInventory && firmwaresInventory.length > 0) {
								firmwaresInventory.forEach(firmware => {
									if (node.firmwareId === firmware.id) {
										firmwareVersion = firmware.version
										firmwareVendor = firmware.vendor
									}
								})
							}
						}

						if (allCustomers && allCustomers.length > 0) {
							if (node.siteId) {
								allCustomers.forEach(customer => {
									const site = customer.sites?.find(site => site.id === node.siteId)
									if (site) {
										customerId = customer.id
										customerName = customer.name
										siteId = site.id
										siteName = site.name
									}
								})
							}
							// else if (node.customerId) {
							// 	// Handle the case where node has a customerId
							// 	const customer = allCustomers.find(
							// 		customer => customer.id === node.customerId
							// 	)
							// 	if (customer) {
							// 		customerId = customer.id
							// 		customerName = customer.name
							// 		if (customer.sites && customer.sites.length > 0) {
							// 			// If you need to get a site even if only customerId is provided
							// 			siteId = customer.sites[0].id // Example: get the first site
							// 			siteName = customer.sites[0].name
							// 		}
							// 	}
							// }
						}

						return {
							...node,
							firmwareVersion: firmwareVersion,
							firmwareVendor: firmwareVendor,
							siteId: siteId,
							siteName: siteName,
							customerId: customerId,
							customerName: customerName
						}
				  })
				: []

		if (
			!filterCustomerOptions ||
			!filterSiteOptions ||
			!filterTypeOptions ||
			!filterTypeNodeOptions ||
			!filterStatusOptions ||
			!filterVendorOptions ||
			!filterModelOptions ||
			!filterFirmwareOptions
		)
			return

		const anyFilterActive =
			filterCustomerOptions.find(filter => filter.selected) ||
			filterSiteOptions.find(filter => filter.selected) ||
			filterTypeOptions.find(filter => filter.selected) ||
			filterTypeNodeOptions.find(filter => filter.selected) ||
			filterStatusOptions.find(filter => filter.selected) ||
			filterVendorOptions.find(filter => filter.selected) ||
			filterModelOptions.find(filter => filter.selected) ||
			filterFirmwareOptions.find(filter => filter.selected)

		let filteredRows = null
		if (anyFilterActive) {
			setActiveFilters(true)

			const isAnyOptionSelected =
				filterTypeOptions.find(filter => filter.selected) ||
				filterTypeNodeOptions.find(filter => filter.selected) ||
				filterStatusOptions.find(filter => filter.selected) ||
				filterVendorOptions.find(filter => filter.selected) ||
				filterModelOptions.find(filter => filter.selected) ||
				filterFirmwareOptions.find(filter => filter.selected)

			if (filterCustomerOptions.find(filter => filter.selected)) {
				let filteredWithCustomerOrSite = []
				let multipleSelection = []
				filteredWithCustomerOrSite = unfilteredModels.filter(node => {
					const customerMatch = filterCustomerOptions.find(
						filter => filter.node === node.customerName && filter.selected
					)
					return customerMatch
				})

				multipleSelection = filteredWithCustomerOrSite

				if (filterSiteOptions.find(filter => filter.selected)) {
					filteredWithCustomerOrSite = filteredWithCustomerOrSite.filter(node => {
						const siteMatch = filterSiteOptions.find(
							filter => filter.node === node.siteName && filter.selected
						)
						if (siteMatch && node.customerName === siteMatch.group) {
							return true
						} else {
							return false
						}
					})

					if (filterCustomerOptions.filter(filter => filter.selected).length > 1) {
						filteredWithCustomerOrSite = multipleSelection
						const items = []
						filteredWithCustomerOrSite.forEach(node => {
							const selectedSite = filterSiteOptions.find(
								filter => filter.group === node.customerName && filter.selected
							)

							const currentSiteSelected = filterSiteOptions.find(
								filter => filter.node === node.siteName && filter.selected
							)

							// If there is a selected site and it's not the one with no data
							if (!selectedSite) {
								items.push(node)
							}
							if (currentSiteSelected) {
								items.push(node)
							}
							if (!selectedSite && !currentSiteSelected) {
								items.push(node)
							}
						})
						filteredWithCustomerOrSite = items
						setRows(filteredWithCustomerOrSite)
					}
				}

				if (!isAnyOptionSelected) {
					setRows(filteredWithCustomerOrSite)
				} else {
					const filtered = filteredWithCustomerOrSite.filter(node => {
						const typeMatch = filterTypeOptions.find(
							filter => filter.node === node.nodeType && filter.selected
						)
						const typeNodeMatch = filterTypeNodeOptions.find(
							filter => filter.node === node.type && filter.selected
						)

						const statusMatch = filterStatusOptions.find(
							filter => filter.node === node.status && filter.selected
						)
						const vendorMatch = filterVendorOptions.find(
							filter => filter.node === node.modelVendor && filter.selected
						)

						const modelMatch = filterModelOptions.find(
							filter => filter.node === node.model && filter.selected
						)

						const firmwareMatch = filterFirmwareOptions.find(
							filter => filter.node === node.firmware && filter.selected
						)

						return (
							typeMatch ||
							typeNodeMatch ||
							statusMatch ||
							vendorMatch ||
							modelMatch ||
							firmwareMatch
						)
					})
					setRows(filtered)
				}
			} else {
				filteredRows = unfilteredModels.filter(node => {
					const typeMatch = filterTypeOptions.find(
						filter => filter.node === node.nodeType && filter.selected
					)

					const typeNodeMatch = filterTypeNodeOptions.find(
						filter => filter.node === node.type && filter.selected
					)

					const statusMatch = filterStatusOptions.find(
						filter => filter.node === node.status && filter.selected
					)
					const vendorMatch = filterVendorOptions.find(
						filter => filter.node === node.modelVendor && filter.selected
					)

					const modelMatch = filterModelOptions.find(
						filter => filter.node === node.model && filter.selected
					)

					const firmwareMatch = filterFirmwareOptions.find(
						filter => filter.node === node.firmware && filter.selected
					)

					return (
						typeMatch ||
						typeNodeMatch ||
						statusMatch ||
						vendorMatch ||
						modelMatch ||
						firmwareMatch
					)
				})
				setRows(filteredRows)
			}
		} else {
			setActiveFilters(false)
			setRows(unfilteredModels)
		}
	}, [
		nodesInventory,
		filterCustomerOptions,
		filterSiteOptions,
		filterTypeOptions,
		filterTypeNodeOptions,
		filterStatusOptions,
		filterVendorOptions,
		filterModelOptions,
		filterFirmwareOptions,
		technology,
		floorPlans,
		allCustomers
	])

	//search logic with filterBySearchString
	useEffect(() => {
		if (!rows) return

		if (!searchText && searchText.trim() == "") setSearchedRows(filteredRows)
		// return
		setTimeout(() => {
			// console.log("🚀 ~ useEffect ~ rows:", rows[rows.length - 1])
		}, 100)

		const fieldsToSearch = [
			"customerId",
			"customerName",
			"firmware",
			"firmwareId",
			"firmwareVendor",
			"firmwareVersion",
			"floorPlanId",
			"floorPlanName",
			"id",
			"model",
			"modelId",
			"modelVendor",
			// "networkId",
			"nodeType",
			"node_address",
			"notes",
			"siteId",
			"siteName",
			"status",
			"type"
		] // Define the fields to search
		const filteredRows = filterBySearchString(rows, searchText, fieldsToSearch)

		if (filteredRows && filteredRows.length > 0) {
			setSearchedRows(filteredRows)
		} else {
			setSearchedRows([])
		}
	}, [rows, searchText])

	//rowsToUse is responsible for saving the data state when ever a new data filters appears
	//this means on searching, sorting, filtering or hiding rowsToUse will always have all data
	useEffect(() => {
		if (!rows && !searchedRows) return
		if (searchedRows) {
			setRowsToUse(searchedRows)
		} else if (rows && rows.length > 0) {
			setRowsToUse(rows)
		}
	}, [rows, searchedRows])

	//currentRows is responsible for keeping track of the current rows the user is viewing
	//directly connect to page and pageSize for selection rows to be working properly
	useEffect(() => {
		updateCurrentRows()
		UIProps.setCurrentNodesTablePageInfo({ page: page, pageSize: pageSize })
	}, [rowsToUse, page, pageSize])

	//this controlers the rowsCount prop from data grid
	//it enables to change page and page size and keep track of past selected rows and "select all" only for current page
	useEffect(() => {
		setRowCountState(prevRowCountState =>
			rowCounter !== undefined ? rowCounter : prevRowCountState
		)
	}, [rowCounter])

	// ━━━━━━━━━━━━━  handlers  ━━━━━━━━━━━━━ \\

	// Update the handleSortModelChange function in your PeopleTable component
	const handleSortModelChange = newSortModel => {
		// Sort the full dataset (rowsToUse) based on the sort model
		const sortedRows = [...rowsToUse].sort((a, b) => {
			// Iterate over the sort model
			for (const sortItem of newSortModel) {
				const field = sortItem.field
				const sortOrder = sortItem.sort === "asc" ? 1 : -1 // Only consider asc and desc
				if (sortItem.sort !== null) {
					if (field === "disabled") {
						// Sort based on boolean value for the "disabled" field
						if (a[field] && !b[field]) return sortOrder
						if (!a[field] && b[field]) return -sortOrder
						continue // Move to the next sort item
					}
					// For other fields, handle sorting based on their type
					if (a[field] === null && b[field] === null) continue // If both values are null, maintain current order
					if (a[field] === null) return -sortOrder // If 'a' is null, place it before 'b'
					if (b[field] === null) return sortOrder // If 'b' is null, place it before 'a'
					// Sort non-null values based on column type
					if (typeof a[field] === "string") {
						const comparison = a[field].localeCompare(b[field], undefined, { numeric: true })
						if (comparison !== 0) return comparison * sortOrder
					} else if (typeof a[field] === "number") {
						return (a[field] - b[field]) * sortOrder
					}
				}
			}
			return 0 // If no column has a defined sort order, maintain current order
		})

		// Update the sorted data and currentRows
		setRowsToUse(sortedRows) //all data
		setCurrentRows(sortedRows.slice(page * pageSize, (page + 1) * pageSize))
		setSortModel(newSortModel)
	}

	// Function to update the current rows based on the current page and page size
	const updateCurrentRows = () => {
		const startIndex = page * pageSize
		const endIndex = Math.min(startIndex + pageSize, rowsToUse.length)
		const newCurrentRows = rowsToUse.slice(startIndex, endIndex)
		setCurrentRows(newCurrentRows)
		setRowCounter(rowsToUse.length)
		UIProps.setRowsToUseNodesTable(rowsToUse) //for printing all data
		//UIProps.setRowsToUse(rowsToUse.slice(page * pageSize, (page + 1) * pageSize)) //data from current page and page size
	}

	const columns = [
		{
			field: "__check__",
			headerName: "Checkbox selection",
			width: 50,
			minWidth: 50,
			align: "center",
			sortable: false,
			filterable: false,
			hideable: false
		},
		// {
		// 	field: "id",
		// 	headerName: "ID",
		// 	type: "string",
		// 	minWidth: 150
		// 	// description: "Test Description usage on mui data grid"
		// },
		{
			field: "node_address",
			headerName: "Node Address",
			type: "string",
			hideable: false,
			minWidth: 150,
			renderCell: params => {
				return params.row.node_address ? params.row.node_address : params.row.id
			}
		},
		{
			field: "mac_address",
			headerName: "Mac Address",
			type: "string",
			minWidth: 150
		},
		{
			field: "nodeType",
			headerName: "Node Type",
			type: "string",
			minWidth: 150,
			renderCell: params => {
				const { firmwareId, modelId } = params.row

				const firmware = firmwaresInventory?.filter(firm => firm.modelId === modelId)
				const dataForTooltip = modelsInventory?.filter(model =>
					firmware?.some(firm => firm.modelId === model.id)
				)

				if (dataForTooltip?.length > 0) {
					const { features, battery } = dataForTooltip[0]
					const featureStrings = Object.entries(features).map(([key, value], index) => (
						<span key={index}>{`${key}: ${value}`}</span>
					))
					const featuresText = featureStrings.map((feature, index) => {
						return <span key={index}>{feature?.props?.children}, </span>
					})
					const tooltipText = `Battery: ${battery}, Features: ${featureStrings.map(feature => {
						return feature?.props?.children
					})}`

					return (
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip id="firebase-notification-tooltip">{tooltipText}</Tooltip>}
						>
							<span>{dataForTooltip[0].nodeType}</span>
						</OverlayTrigger>
					)
				} else {
					return <span>-</span>
				}
			}
		},
		{
			field: "type",
			headerName: "Type",
			type: "string",
			minWidth: 150,
			renderCell: params => {
				const { firmwareId, modelId } = params.row

				const firmware = firmwaresInventory?.filter(firm => firm.modelId === modelId)
				const dataForTooltip = modelsInventory?.filter(model =>
					firmware?.some(firm => firm.modelId === model.id)
				)

				return dataForTooltip?.length > 0 ? (
					<span>{dataForTooltip[0].type}</span>
				) : (
					<span>-</span>
				)
			}
		},
		{
			field: "status",
			headerName: "Status",
			type: "string",
			minWidth: 150
		},
		{
			field: "customerName",
			headerName: "Customer",
			type: "string",
			minWidth: 150,
			renderCell: params => {
				const { customerId, customerName } = params.row
				// console.log("🚀 ~ params.row:", params.row)

				if (customerName) {
					return (
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip id="firebase-notification-tooltip">{customerId}</Tooltip>}
						>
							<span>{customerName}</span>
						</OverlayTrigger>
					)
				} else if (customerId) return <span>{customerId}</span>
				return <span>-</span>
			}
		},
		// {
		// 	field: "networkId",
		// 	headerName: "Site",
		// 	type: "string",
		// 	minWidth: 150,
		// 	renderCell: params => {
		// 		const { customerId, networkId, siteId } = params.row

		// 		const customer = allCustomers?.find(customer => customer.id === customerId)

		// 		if (customer) {
		// 			const site = customer.sites?.find(site => site.networkId === networkId)
		// 			if (site) {
		// 				const tooltipText = `siteId: ${site.id} || networkId: ${networkId}`

		// 				return (
		// 					<OverlayTrigger
		// 						placement="bottom"
		// 						overlay={
		// 							<Tooltip id="firebase-notification-tooltip">{tooltipText}</Tooltip>
		// 						}
		// 					>
		// 						<span>{site.name}</span>
		// 					</OverlayTrigger>
		// 				)
		// 			}
		// 		}

		// 		if (siteId) {
		// 			let siteName = null

		// 			if (allCustomers) {
		// 				allCustomers.forEach(customer => {
		// 					if (customer.sites) {
		// 						const site = customer.sites.find(site => site.id === siteId)
		// 						if (site) {
		// 							siteName = site.name
		// 						}
		// 					}
		// 				})
		// 			}

		// 			if (siteName) {
		// 				return (
		// 					<OverlayTrigger
		// 						placement="bottom"
		// 						overlay={<Tooltip id="firebase-notification-tooltip">{siteId}</Tooltip>}
		// 					>
		// 						<span>{siteName}</span>
		// 					</OverlayTrigger>
		// 				)
		// 			}
		// 			return <span>{siteId}</span>
		// 		}

		// 		return <span>-</span>
		// 	}
		// },
		{
			field: "siteId",
			headerName: "Site",
			type: "string",
			minWidth: 150,
			renderCell: params => {
				const { customerId, siteId } = params.row

				const customer = allCustomers?.find(customer => customer.id === customerId)

				if (customer) {
					const site = customer.sites?.find(site => site.id === siteId)
					if (site) {
						const tooltipText = `siteId: ${site.id}`

						return (
							<OverlayTrigger
								placement="bottom"
								overlay={
									<Tooltip id="firebase-notification-tooltip">{tooltipText}</Tooltip>
								}
							>
								<span>{site.name}</span>
							</OverlayTrigger>
						)
					}
				}

				if (siteId) {
					let siteName = null

					if (allCustomers) {
						allCustomers.forEach(customer => {
							if (customer.sites) {
								const site = customer.sites.find(site => site.id === siteId)
								if (site) {
									siteName = site.name
								}
							}
						})
					}

					if (siteName) {
						return (
							<OverlayTrigger
								placement="bottom"
								overlay={<Tooltip id="firebase-notification-tooltip">{siteId}</Tooltip>}
							>
								<span>{siteName}</span>
							</OverlayTrigger>
						)
					}
					return <span>{siteId}</span>
				}

				return <span>-</span>
			}
		},
		// {
		// 	field: "floorPlanId",
		// 	headerName: "Floorplan",
		// 	type: "string",
		// 	minWidth: 150,
		// 	renderCell: params => {
		// 		const { floorPlanId, floorPlanName } = params.row

		// 		if (floorPlanName) {
		// 			return (
		// 				<OverlayTrigger
		// 					placement="bottom"
		// 					overlay={<Tooltip id="firebase-notification-tooltip">{floorPlanId}</Tooltip>}
		// 				>
		// 					<span>{floorPlanName}</span>
		// 				</OverlayTrigger>
		// 			)
		// 		}
		// 		return <span>{floorPlanId}</span>
		// 	}
		// },
		// {
		// 	field: "model",
		// 	headerName: "Model",
		// 	type: "string",
		// 	minWidth: 150,
		// 	renderCell: params => {
		// 		const { firmwareId, node_address, } = params.row

		// 		const firmware = firmwaresInventory?.filter(firm => firm.id === firmwareId)
		// 		const dataForTooltip = modelsInventory?.filter(model =>
		// 			firmware?.some(firm => firm.modelId === model.id)
		// 		)
		// 		if (node_address === 300107) {
		// 			console.log("🚀 ~ dataForTooltip:", dataForTooltip)
		// 			console.log("🚀 ~ firmware:", firmware)
		// 		}

		// 		if (dataForTooltip?.length > 0) {
		// 			const { features, battery } = dataForTooltip[0]
		// 			const featureStrings = Object.entries(features).map(([key, value], index) => (
		// 				<span key={index}>{`${key}: ${value}`}</span>
		// 			))
		// 			const featuresText = featureStrings.map((feature, index) => (
		// 				<span key={index}>{feature}, </span>
		// 			))
		// 			const tooltipText = `Battery: ${battery}, Features: ${featuresText}`

		// 			return (
		// 				<OverlayTrigger
		// 					placement="bottom"
		// 					overlay={<Tooltip id="firebase-notification-tooltip">{tooltipText}</Tooltip>}
		// 				>
		// 					<span>{dataForTooltip[0].name}</span>
		// 				</OverlayTrigger>
		// 			)
		// 		} else {
		// 			return <span>-</span>
		// 		}
		// 	}
		// },
		{
			field: "model",
			headerName: "Model",
			type: "string",
			minWidth: 150,
			renderCell: params => {
				const { modelId, firmwareId, node_address } = params.row

				const firmware = firmwaresInventory?.filter(firm => firm.modelId === modelId)
				const dataForTooltip = modelsInventory?.filter(model =>
					firmware?.some(firm => firm.modelId === model.id)
				)

				if (dataForTooltip?.length > 0) {
					const { features, battery } = dataForTooltip[0]
					const featureStrings = Object.entries(features).map(([key, value], index) => (
						<span key={index}>{`${key}: ${value}`}</span>
					))
					const featuresText = featureStrings.map((feature, index) => (
						<span key={index}>{feature}, </span>
					))
					const tooltipText = `Battery: ${battery}, Features: ${featuresText}`

					return (
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip id="firebase-notification-tooltip">{tooltipText}</Tooltip>}
						>
							<span>{dataForTooltip[0].name}</span>
						</OverlayTrigger>
					)
				} else {
					return <span>-</span>
				}
			}
		},
		{
			field: "firmwareVersion",
			headerName: "Firmware Version",
			type: "string",
			minWidth: 150,
			renderCell: params => {
				const { firmwareId, firmwareVersion } = params.row

				const dataForTooltip = firmwaresInventory?.filter(firm => firm.id === firmwareId)

				if (dataForTooltip?.length > 0) {
					return (
						<OverlayTrigger
							placement="bottom"
							overlay={
								<Tooltip id="firebase-notification-tooltip">{`Severity: ${dataForTooltip[0].severity}`}</Tooltip>
							}
						>
							<a
								href={dataForTooltip[0].url}
								rel="noreferrer"
								target="_blank"
								style={{
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis"
								}}
							>
								{firmwareVersion}
							</a>
						</OverlayTrigger>
					)
				} else {
					return <span>-</span>
				}
			},
			sortComparator: compareVersionStrings
		},
		{
			field: "firmwareVendor",
			headerName: "Firmware Vendor",
			type: "string",
			minWidth: 150,
			renderCell: params => {
				const { firmwareId } = params.row

				const data = firmwaresInventory?.filter(firm => firm.id === firmwareId)

				if (data?.length > 0) {
					return data[0].vendor
				} else {
					return <span>-</span>
				}
			}
		},
		{
			field: "notes",
			headerName: "Notes",
			type: "string",
			minWidth: 150
		}
	]

	useEffect(() => {
		if (!currentRows) return
		setLoading(false)
	}, [currentRows])

	return (
		<div style={{ width: "100%" }} data-testid="NodesInventoryTable">
			<div className="row justify-content-end text-end">
				<span className="selected-filters-title">Selected filters</span>
			</div>
			<div className="row mb-5" style={{ marginRight: "2px" }}>
				<div className="selected-actions col align-self-end">
					<UserGrouping setSelectionModel={setSelectionModel} />
				</div>
				{/* //TODO list selected filters options */}
				{/* <div className="col-7 d-flex flex-row-reverse overflow-auto"> */}
				<div
					className="col d-flex flex-wrap-reverse justify-content-end pr-0"
					style={{
						maxHeight: "75px",
						overflow: "auto"
					}}
				>
					{!activeFilters && (
						<div>
							<div className="no-selected-filters">
								<span>
									NO SELECTED FILTERS <img src={inventoryFiltersIcon} />
								</span>
							</div>
						</div>
					)}

					<FilterOptionsOverlay name="Customer" options={filterCustomerOptions} />
					<FilterOptionsOverlay name="Site" options={filterSiteOptions} />
					<FilterOptionsOverlay name="Node Types" options={filterTypeOptions} />
					<FilterOptionsOverlay name="Types" options={filterTypeNodeOptions} />
					<FilterOptionsOverlay name="Status" options={filterStatusOptions} />
					<FilterOptionsOverlay name="Vendor" options={filterVendorOptions} />
					<FilterOptionsOverlay name="Model" options={filterModelOptions} />
					<FilterOptionsOverlay name="Firmware" options={filterFirmwareOptions} />
				</div>
			</div>
			{listLoading && <ProgressBar.LinearIndeterminate />}
			<DataGrid
				style={{
					fontFamily: "Poppins",
					fontWeight: 400,
					fontSize: "13px",
					border: "none",
					width: "100%",
					color: "#464e5f"
				}}
				rows={currentRows}
				columns={columns}
				className={classes.root}
				density="standard"
				disableExtendRowFullWidth={true}
				pagination
				disableSelectionOnClick
				loading={listLoading || !technology || loading}
				disableColumnMenu={true}
				checkboxSelection
				components={{
					Toolbar: QuickToolbar,
					Pagination: props => <CustomPagination {...props} rows={rows} />,
					NoRowsOverlay: technology ? CustomNoRowsOverlay : CustomNoTechnologyOverlay
				}}
				rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
				page={page}
				onPageChange={newPage => {
					setPage(newPage)
				}}
				pageSize={pageSize}
				onPageSizeChange={e => {
					setPageSize(e)
				}}
				autoHeight
				rowCount={rowCountState}
				//* to selected only current page rows
				paginationMode="server"
				onSelectionModelChange={newSelectionModel => {
					UIProps.setIds(newSelectionModel)
					setSelectionModel(newSelectionModel)
				}}
				selectionModel={selectionModel}
				keepNonExistentRowsSelected //* to be able to change page and keep selected rows
				//* for printing with matching table order
				sortingOrder={["desc", "asc"]}
				sortModel={sortModel}
				onSortModelChange={handleSortModelChange}
				//* for printing only visible columns
				onColumnVisibilityModelChange={e => {
					UIProps.setColumnVisibilityModelNodesTable(e)
				}}
				columnVisibilityModel={UIProps.columnVisibilityModelNodesTable}
			/>
		</div>
	)
}

const FilterOptionsOverlay = ({ name, options }) => {
	const isAnySelected = options?.find(filter => filter.selected)

	if (!isAnySelected) return <></>
	return (
		<div>
			<OverlayTrigger
				placement="auto"
				overlay={
					<Tooltip id="firebase-notification-tooltip">
						<div
							style={{
								overflow: "hidden"
							}}
						>
							{options &&
								options.map((type, index) => {
									if (type.selected) {
										return (
											<div
												key={index}
												style={{
													padding: "0 1.5rem",
													display: "flex",
													alignItems: "start",
													width: "500px"
												}}
											>
												<span style={{ display: "list-item", fontSize: "12px" }}>
													{type.node}
												</span>
											</div>
										)
									} else {
										return null
									}
								})}
						</div>
					</Tooltip>
				}
			>
				<div className="selected-filters">
					<span>
						{name} <CheckIcon className="selected-filters-icon" />
					</span>
				</div>
			</OverlayTrigger>
		</div>
	)
}

function CustomPagination({ rows }) {
	// const { state, apiRef } = useGridSlotComponentProps()
	const apiRef = useGridApiContext()
	// const [state] = useGridState(apiRef)
	const state = apiRef.current.state
	const rootElement = apiRef.current.rootElementRef

	const rootProps = useGridRootProps()
	const classes = useStyles()
	const options = [5, 10, 20, 30, 50, 100]

	const handlePageSizeChange = pageSize => {
		apiRef.current.setPageSize(pageSize)
	}

	return (
		<>
			<Dropdown>
				<Dropdown.Toggle variant="light" id="dropdown-basic">
					{state.pagination.pageSize}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{options.map(option => {
						return (
							<Dropdown.Item
								key={option}
								as="button"
								onClick={() => handlePageSizeChange(option)}
							>
								{option}
							</Dropdown.Item>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>

			<Pagination
				className={classes.root}
				id="custom-pagination"
				color="primary"
				count={state.pagination.pageCount}
				page={state.pagination.page + 1}
				onChange={(event, value) => apiRef.current.setPage(value - 1)}
			/>

			<span style={{ minWidth: "110px" }}>Total Rows: {(rows && rows?.length) || 0}</span>
		</>
	)
}

function QuickToolbar(props) {
	return (
		<div>
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				{/* <GridToolbarFilterButton /> */}
				<GridToolbarDensitySelector />
			</GridToolbarContainer>
		</div>
	)
}

function CustomNoRowsOverlay() {
	return (
		<GridOverlay>
			<div>No Rows</div>
		</GridOverlay>
	)
}

function CustomNoTechnologyOverlay() {
	return (
		<GridOverlay>
			<div className="overlay-modal-no-technology">
				<div className="row" style={{ marginLeft: "10px" }}>
					<div className="col">
						<div className="row overlay-modal-no-technology-title">
							<WarningIcon style={{ marginRight: "10px" }} />
							No data to display
						</div>
					</div>
				</div>
				<div className="row" style={{ marginLeft: "10px" }}>
					<div className="col">
						<div className="row overlay-modal-no-technology-text">
							Please select the technology in order to be provided with more information.{" "}
						</div>
					</div>
				</div>
			</div>
		</GridOverlay>
	)
}
