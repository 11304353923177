import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { TotalRoleOccupancyGraph } from "./TotalRoleOccupancyGraph"
import { Button } from "../../../../_partials/Button"

export function TotalRoleOccupancyCard() {
	const { tags, companies } = useSelector(
		state => ({
			tags: state.liveData?.tagsWithPeople,
			companies: state.profile?.currentCustomer?.vendors || []
		}),
		shallowEqual
	)

	// useState
	const [graphData, setGraphData] = useState({ series: [], labels: [] })
	const [companyOccupancy, setCompanyOccupancy] = useState(true)

	useEffect(() => {
		if (!tags || !companies || companies.length === 0) return

		const tagsWithPeople = tags
			.map(val => {
				if (!val.person) return
				return val
			})
			.filter(val => val)

		if (companyOccupancy) {
			// Total Company Occupancy
			const labelsCompany = []

			// add company name to each person
			const peopleWithCompany =
				tagsWithPeople &&
				tagsWithPeople.map(val => {
					const foundCompany =
						companies?.length > 0 &&
						companies.find(company => company?.id === val?.person?.companyId)

					return {
						...val,
						person: {
							...val.person,
							company: foundCompany ? foundCompany?.company : null
						}
					}
				})

			// add graph labels array
			peopleWithCompany &&
				peopleWithCompany.forEach(val => {
					let companyProp = val.person?.company || "No company"
					if (labelsCompany.some(value => value === companyProp)) return
					labelsCompany.push(companyProp)
				})

			// add peopleOnSite and absentPeople count logic
			let peopleOnSite = []
			let absentPeople = []
			labelsCompany.forEach(val => {
				peopleOnSite.push({ company: val, counter: 0 })
				absentPeople.push({ company: val, counter: 0 })
			})

			peopleWithCompany &&
				peopleWithCompany.forEach(val => {
					const index = labelsCompany.findIndex(
						value => value === (val.person.company || "No company")
					)

					if (val.tagState === "inactive") {
						absentPeople[index].counter = absentPeople[index].counter + 1
					} else {
						peopleOnSite[index].counter = peopleOnSite[index]?.counter + 1
					}
				})

			// Combine peopleOnSite and absentPeople on same array
			const combinedData = peopleOnSite.map((value, index) => ({
				company: value.company,
				onSite: value.counter,
				absent: absentPeople[index].counter
			}))

			// Sort combinedData based on 'onSite' count in descending order
			const sortedData = combinedData.sort((a, b) => b.onSite - a.onSite)

			// Filter non-zero values
			const filteredData = sortedData.filter(entry => entry.onSite !== 0)

			// Extract filtered labels and series
			const filteredLabels = filteredData.map(entry => entry.company)

			const filteredOnSiteSeries = filteredData.map(entry => entry.onSite)

			const filteredAbsentSeries = filteredData.map(entry => entry.absent)

			setGraphData({
				labels: filteredLabels || [],
				series: [
					{
						name: "On-site people",
						data: filteredOnSiteSeries || []
					},
					{
						name: "Off-site people",
						data: filteredAbsentSeries || []
					}
				]
			})

			// let peopleOnSiteSeries = []

			// peopleOnSite.forEach(val => {
			// 	peopleOnSiteSeries.push(val.counter)
			// })

			// console.log("🚀  peopleOnSiteSeries:", peopleOnSiteSeries)

			// let absentPeopleSeries = []

			// absentPeople.forEach(val => {
			// 	absentPeopleSeries.push(val.counter)
			// })

			// // Use the filtered indices to filter absentPeopleSeries, (get the indices where peopleOnSiteSeries is not zero)
			// const nonZeroIndices = peopleOnSiteSeries.reduce((indices, value, index) => {
			// 	if (value !== 0) {
			// 		indices.push(index)
			// 	}
			// 	return indices
			// }, [])

			// // Filter filteredPeopleOnSite where the value is zero
			// const filteredPeopleOnSite = peopleOnSiteSeries.filter(value => value !== 0)

			// // Filter absentPeopleSeries at the exact non-zero indices
			// const filteredAbsentPeople =
			// 	nonZeroIndices && nonZeroIndices.map(index => absentPeopleSeries[index])

			// // Filter labels at the exact non-zero indices
			// const filteredLabels = nonZeroIndices && nonZeroIndices.map(index => labelsCompany[index])
		} else {
			// Total Role Occupancy
			const labels = []

			// add graph labels array
			tagsWithPeople &&
				tagsWithPeople.forEach(val => {
					let role = val.person.role || "No role"
					if (labels.some(value => value === role)) return
					labels.push(role)
				})

			// add peopleOnSite and absentPeople count logic
			let peopleOnSite = []
			let absentPeople = []
			labels.forEach(val => {
				peopleOnSite.push({ role: val, counter: 0 })
				absentPeople.push({ role: val, counter: 0 })
			})

			tagsWithPeople &&
				tagsWithPeople.forEach(val => {
					const index = labels.findIndex(value => value === (val.person.role || "No role"))

					if (val.tagState === "inactive") {
						absentPeople[index].counter = absentPeople[index].counter + 1
					} else {
						peopleOnSite[index].counter = peopleOnSite[index]?.counter + 1
					}
				})

			// Combine peopleOnSite and absentPeople on same array
			const combinedData = peopleOnSite.map((value, index) => ({
				role: value.role,
				onSite: value.counter,
				absent: absentPeople[index].counter
			}))

			// Sort combinedData based on 'onSite' count in descending order
			const sortedData = combinedData.sort((a, b) => b.onSite - a.onSite)

			// Filter non-zero values
			const filteredData = sortedData.filter(entry => entry.onSite !== 0)

			// Extract filtered labels and series
			const filteredLabels = filteredData.map(entry => entry.role)

			const filteredOnSiteSeries = filteredData.map(entry => entry.onSite)

			const filteredAbsentSeries = filteredData.map(entry => entry.absent)

			setGraphData({
				labels: filteredLabels || [],
				series: [
					{
						name: "On-site people",
						data: filteredOnSiteSeries || []
					},
					{
						name: "Off-site people",
						data: filteredAbsentSeries || []
					}
				]
			})

			// let peopleOnSiteSeries = []
			// peopleOnSite.forEach(val => {
			// 	peopleOnSiteSeries.push(val.counter)
			// })

			// let absentPeopleSeries = []

			// absentPeople.forEach(val => {
			// 	absentPeopleSeries.push(val.counter)
			// })

			// // Use the filtered indices to filter absentPeopleSeries, (get the indices where peopleOnSiteSeries is not zero)
			// const nonZeroIndices = peopleOnSiteSeries.reduce((indices, value, index) => {
			// 	if (value !== 0) {
			// 		indices.push(index)
			// 	}
			// 	return indices
			// }, [])

			// // Filter filteredPeopleOnSite where the value is zero
			// const filteredPeopleOnSite = peopleOnSiteSeries.filter(value => value !== 0)

			// // Filter absentPeopleSeries at the exact non-zero indices
			// const filteredAbsentPeople =
			// 	nonZeroIndices && nonZeroIndices.map(index => absentPeopleSeries[index])

			// // Filter labels at the exact non-zero indices
			// const filteredLabels = nonZeroIndices && nonZeroIndices.map(index => labels[index])

			// setGraphData({
			// 	labels: filteredLabels || [],
			// 	series: [
			// 		{
			// 			name: "On-site people",
			// 			data: filteredPeopleOnSite || []
			// 		},
			// 		{
			// 			name: "Off-site people",
			// 			data: filteredAbsentPeople || []
			// 		}
			// 	]
			// })
		}
	}, [tags, companyOccupancy, companies])

	if (!graphData?.series || graphData?.series?.length === 0) return <div></div>

	return (
		<div className="col-12 col-xxl">
			<Card>
				<CardHeader
					title={companyOccupancy ? "Total Company Occupancy" : "Total Role Occupancy"}
					subtitle="Daily number of people"
					line={false}
					toolbar={
						<>
							<Button
								color={companyOccupancy ? "primary" : "default"}
								onClick={() => setCompanyOccupancy(true)}
								text="Company"
								style={{
									color: !companyOccupancy && "#B8BCCA"
								}}
							/>
							<Button
								color={companyOccupancy ? "default" : "primary"}
								onClick={() => setCompanyOccupancy(false)}
								text="Role"
								style={{
									color: companyOccupancy && "#B8BCCA"
								}}
							/>
						</>
					}
				/>
				<CardBody padding="13px" style={{ height: "385px" }}>
					<TotalRoleOccupancyGraph graphData={graphData} />
				</CardBody>
			</Card>
		</div>
	)
}
