import React from "react"
import ReactDOM from "react-dom"
import { IconButton } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import "./DrawerAnchors.css"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import NewSearchAnchors from "./NewSearchAnchors"

export default class DrawerAnchors {
	constructor(props) {
		this.props = props
	}

	onAdd(map) {
		this.map = map
		this.container = document.createElement("div")
		this.container.className = "mapboxgl-ctrl hide-search-drawer-bar"

		const icon = this.props.openDrawerBar ? (
			<ExpandLessIcon fontSize="default" style={{ color: "#2C3E50" }} />
		) : (
			<ExpandMoreIcon fontSize="default" style={{ color: "#2C3E50" }} />
		)

		this.content = (
			<div
				className="container_drawer_bar_content anchors_drawer_bar_content"
				style={{ display: !this.props.selectedSite ? "none" : "flex" }} //  ━━━━ Hide if !selectedSite
			>
				{/* // ━━━━━━━━━━━━━━━━━━━ Components drawer options  ━━━━━━━━━━━━━━━━━━━━━━ \\  */}
				{/* // ━━━━ ↓ Search component */}
				{this.props.drawerOptions.openSearch && (
					<div className="search_wrapper">
						<NewSearchAnchors
							drawerOptions={this.props.drawerOptions}
							selectedSite={this.props.selectedSite}
							map={this.props.map}
							dispatch={this.props.dispatch}
							activeAnchors={this.props.activeAnchors}
							positionAnchors={this.props.positionAnchors}
							floorPlans={this.props.floorPlans}
							floorPlan={this.props.floorPlan}
							setGoTo={this.props.setGoTo}
							setShowAnchorsOnline={this.props.setShowAnchorsOnline}
						/>
					</div>
				)}

				{/* // ━━━━━━━━━━━━━━━━━━━ Drawer Bar ━━━━━━━━━━━━━━━━━━━━━━ \\  */}
				<div className="wrapper_drawer_bar_content">
					{/* // ━━━━━━ Option - Search filter */}
					{/* ↓ If openSearch is opened, it closes the overlayTrigger */}
					{!this.props.drawerOptions.openSearch ? (
						<OverlayTrigger
							trigger={["hover", "focus"]}
							placement="left"
							overlay={<Tooltip id="tooltip">Search</Tooltip>}
						>
							<div className="drawer_icon">
								<IconButton
									className="livemap_drawer_open_search"
									style={{
										background: this.props.drawerOptions.openSearch ? "#DDE9FD" : null,
										padding: "8px",
										fontSize: "12px"
									}}
									onClick={() => {
										this.props.setDrawerOptions(prevState => ({
											openSearch: !prevState.openSearch,
											openCreate: false
										}))
									}}
								>
									<SearchIcon
										style={{
											fontSize: "20px",
											color: this.props.drawerOptions.openSearch ? "#0277DA" : "#2C3E50"
										}}
									/>
								</IconButton>
							</div>
						</OverlayTrigger>
					) : (
						<div className="drawer_icon">
							<IconButton
								className="livemap_drawer_open_search"
								style={{
									background: this.props.drawerOptions.openSearch ? "#DDE9FD" : null,
									padding: "8px",
									fontSize: "12px"
								}}
								onClick={() => {
									this.props.setDrawerOptions(prevState => ({
										openSearch: !prevState.openSearch,
										openCreate: false
									}))
								}}
							>
								<SearchIcon
									style={{
										fontSize: "20px",
										color: this.props.drawerOptions.openSearch ? "#0277DA" : "#2C3E50"
									}}
								/>
							</IconButton>
						</div>
					)}

					<div
						id="drawer_class"
						className={
							this.props.openDrawerBar ? "expanded_drawer_anchors" : "collapsed_drawer"
						}
					></div>
				</div>
			</div>
		)

		ReactDOM.render(this.content, this.container)
		return this.container
	}

	onRemove() {
		this.container.parentNode && this.container.parentNode.removeChild(this.container)
		this.map = undefined
	}
}
