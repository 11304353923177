/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react"
import { useLocation } from "react-router"
import { NavLink } from "react-router-dom"
import { checkIsActive } from "../../../../../_metronic/_helpers"
// import ComputerIcon from "@material-ui/icons/Computer"
import Pages from "../../../../Pages"
import LockOpenIcon from "@material-ui/icons/LockOpen"

export function AsideMenuList({ layoutProps }) {
	const location = useLocation()
	const getMenuItemActive = url => {
		return checkIsActive(location, url) ? " menu-item-active menu-item-open " : ""
	}

	return (
		<ul className={`menu-nav ${layoutProps.ulClasses}`}>
			{Pages().map((page, i) => {
				if (page.subpages) {
					return (
						<li
							key={i}
							className={`menu-item menu-item-submenu ${getMenuItemActive(page.path)}`}
							aria-haspopup="true"
							data-menu-toggle="hover"
							// onClick={() => console.log("clicked dropdown", layoutProps)}
						>
							<NavLink className="menu-link menu-toggle" to={page.path}>
								<span className="svg-icon menu-icon">
									{page?.icon ? page.icon : <LockOpenIcon />}
								</span>
								<span className="menu-text">{page.name}</span>
								<i className="menu-arrow" />
							</NavLink>
							<div className="menu-submenu">
								<ul className="menu-subnav">
									<ul className="menu-subnav">
										<li className="menu-item  menu-item-parent" aria-haspopup="true">
											<span className="menu-link">
												<span className="menu-text">{page.name}</span>
											</span>
										</li>
										{page.subpages.map(subpage => {
											return (
												<li
													key={subpage.name}
													className={`menu-item ${getMenuItemActive(subpage.path)}`}
													aria-haspopup="true"
												>
													<NavLink className="menu-link" to={subpage.path}>
														<i className="menu-bullet menu-bullet-dot">
															<span />
														</i>
														<span className="menu-text">{subpage.name}</span>
													</NavLink>
												</li>
											)
										})}
									</ul>
								</ul>
							</div>
						</li>
					)
				}

				return (
					<li
						key={i}
						className={`menu-item ${getMenuItemActive(page.path)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to={page.path}>
							<span className="svg-icon menu-icon">{page.icon}</span>
							<span className="menu-text">{page.name}</span>
						</NavLink>
					</li>
				)
			})}
		</ul>
	)
}
