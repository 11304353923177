import React from "react"
import ReactDOM from "react-dom"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"

export const activateGeofence = async ({
	map,
	notificationWarning,
	tags,
	markersSize,
	dispatch,
	actions,
	notificationsActions,
	geofencePulseMarkers,
	setGeofencePulseMarkers,
	updatedCurrentFloorPlan,
	floorPlans
}) => {
	// geofencePulseMarkers && geofencePulseMarkers.forEach(marker => marker.remove())

	const newPulseMarkers = []
	const layers = map.getStyle()

	if (layers) {
		// Iterate through tags and associate them with corresponding notification warnings
		const tagToAnimated =
			tags &&
			notificationWarning &&
			tags
				.map(tag => {
					const matchingNotification = notificationWarning.find(
						not => not.person?.id === tag.person?.id
					)
					if (matchingNotification) {
						return {
							...tag,
							notification: matchingNotification
						}
					}
					return null
				})
				.filter(Boolean) // Remove null values from the array

		//moved this upper because if I had two notifications and deleted one ,
		//it would create a second div on top of the existing one
		//* so lets remove all on the begining and then if there are any left to create, create them
		if (tagToAnimated) {
			const element = document.getElementsByClassName("warning_tag")

			if (element) {
				element.forEach(val => {
					val.remove()
				})
			}
		}

		//create div with pulse animation
		tagToAnimated &&
			updatedCurrentFloorPlan &&
			tagToAnimated.map(tag => {
				if (tag.floorPlanId === updatedCurrentFloorPlan.id) {
					const divElement = document.createElement("div")

					const content = (
						<div
							className={
								tag.notification?.read
									? ""
									: tag.notification?.severity === 3
									? "warning_tag critical_pulse"
									: "warning_tag warning_pulse"
							}
							style={{
								width:
									tag.person && markersSize?.people
										? `${Math.abs(
												Math.floor((markersSize?.people / 100) * (80 - 14 + 1) + 14)
										  )}px`
										: tag.asset && markersSize?.assets
										? `${Math.abs(
												Math.floor((markersSize?.assets / 100) * (80 - 14 + 1) + 14)
										  )}px`
										: !tag.person && !tag.asset
										? `${Math.abs(
												Math.floor(
													(markersSize?.unassignedTags / 100) * (80 - 14 + 1) + 14
												)
										  )}px`
										: "26px",
								height:
									tag.person && markersSize?.people
										? `${Math.abs(
												Math.floor((markersSize?.people / 100) * (80 - 14 + 1) + 14)
										  )}px`
										: tag.asset && markersSize?.assets
										? `${Math.abs(
												Math.floor((markersSize?.assets / 100) * (80 - 14 + 1) + 14)
										  )}px`
										: !tag.person && !tag.asset
										? `${Math.abs(
												Math.floor(
													(markersSize?.unassignedTags / 100) * (80 - 14 + 1) + 14
												)
										  )}px`
										: "26px"
							}}
						></div>
					)

					ReactDOM.render(content, divElement)

					const marker = new mapboxgl.Marker({
						element: divElement
					})
						.setLngLat([tag?.gpsData?.longitude, tag?.gpsData?.latitude])
						.addTo(map)
					newPulseMarkers.push(marker)
				}
			})

		//if bigger then 1 zoom out on update current floor plan area
		// if (tagToAnimated && tagToAnimated && tagToAnimated.length > 1) {
		if (tagToAnimated) {
			const anySeeOnMap = tagToAnimated.find(tag => tag.notification?.seeOnMap)

			if (anySeeOnMap) {
				for (const animatedTag of tagToAnimated) {
					if (animatedTag?.notification?.seeOnMap) {
						const foundedFloorPlan =
							floorPlans && floorPlans.find(f => f.id === animatedTag?.floorPlanId)
						// eslint-disable-next-line no-await-in-loop
						foundedFloorPlan && (await dispatch(actions.floorPlanSelected(foundedFloorPlan))) // changes floorplan

						map.flyTo({
							center: [animatedTag?.gpsData?.longitude, animatedTag?.gpsData?.latitude],
							zoom: 20
						})

						// Reset seeOnMap
						// Dispatch an action to update the Redux store
						dispatch(notificationsActions.resetSeeOnMap())
					}
				}
			}
		}
	}

	// return newPulseMarkers
	setGeofencePulseMarkers(newPulseMarkers)
}
