import React from "react"
import { UIProvider } from "./UIContext"
import { ContactTracingCard } from "./ContactTracingCard"
import { useDispatch } from "react-redux"
import { contactTracingSlice } from "../../_redux/contactTracing/contactTracingSlice"
import "./ContactTracing.css"
import AuditToolTitle from "./AuditToolTitle/AuditToolTitle"

export const ContactTracingPage = ({ location }) => {
	// location comes from AttendanceListTable and going to ContactTracingCard
	const { person, areas } = location
	const dispatch = useDispatch()

	React.useEffect(() => {
		//to reset the state when you enters the component
		dispatch(contactTracingSlice.actions.resetStateContactTrace())
	}, [])

	const UIEvents = {}

	return (
		<UIProvider UIEvents={UIEvents}>
			<AuditToolTitle data-testid="audit-tool-title" />
			<ContactTracingCard
				person={person}
				dashboardAreas={areas}
				data-testid="card-ContactTracingCard"
			/>
		</UIProvider>
	)
}
