import React, { useState, useMemo, useEffect } from "react"
import { useUIContext } from "../UIContext"
import MapWidget from "./MapWidget"
import { Card, CardHeader, CardHeaderToolbar, CardBody } from "../../../_partials/Card"

export function Map() {
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			mapDate: UIContext.mapDate,
			setMapDate: UIContext.setMapDate,
			openResourceCalendar: UIContext.openResourceCalendar
		}
	}, [UIContext])

	const [date, setDate] = useState(UIProps.mapDate)

	useEffect(() => {
		UIProps.setMapDate(date)
	}, [date])

	return (
		<Card>
			<CardHeader title="Map" />
			<CardBody>
				<MapWidget />
			</CardBody>
		</Card>
	)
}
