import React from "react"

export function Subtitle({ text }) {
	return (
		<span
			style={{
				fontFamily: "Poppins",
				fontWeight: 500,
				fontSize: "20px",
				lineHeight: "30px"
			}}
		>
			{text}
		</span>
	)
}
