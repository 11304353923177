/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { Button } from "../../../../_partials/Button"
import { Logo } from "../_partials"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
	FormControlLabel,
	Checkbox,
	Step,
	StepLabel,
	Stepper,
	makeStyles,
	useMediaQuery
} from "@material-ui/core"
import * as actions from "../../../_redux/data-validation/dataValidationActions"
import SVG from "react-inlinesvg"
import clsx from "clsx"

const TrainingVideos = ({ validationCode, setSelectedPage }) => {
	const dispatch = useDispatch()
	const videoRef = useRef(null)

	const { isLoading, personData, trainingVideos, questions } = useSelector(
		state => ({
			isLoading: state.dataValidation?.actionsLoading,
			personData: state.dataValidation?.personData,
			trainingVideos: state.dataValidation?.personData?.trainingVideos || [],
			questions: state.dataValidation?.questions || []
		}),
		shallowEqual
	)

	const [currentVideoIndex, setCurrentVideoIndex] = useState()
	const [isPlaying, setIsPlaying] = useState(false)
	const [duration, setDuration] = useState("00:00")
	const [selectedAnswers, setSelectedAnswers] = useState([])

	useEffect(() => {
		if (!trainingVideos) return

		let videoIndex = null
		trainingVideos.forEach((val, i) => {
			if (!val.watched && videoIndex === null) {
				videoIndex = i
			}
		})
		if (videoIndex === null) {
			videoIndex = trainingVideos.length
		}
		setCurrentVideoIndex(videoIndex)
		setIsPlaying(false)
	}, [personData])

	useEffect(() => {
		if (currentVideoIndex === null || currentVideoIndex === undefined || !trainingVideos) return

		if (currentVideoIndex === trainingVideos.length) {
			dispatch(
				actions.fetchQuestions({
					validationCode: validationCode,
					questionIds: personData.trainingQuestions
				})
			)
			return
		}

		const trainingVideo = trainingVideos[currentVideoIndex]
		if (!trainingVideo || trainingVideo?.url || trainingVideo?.watched) return

		dispatch(
			actions.fetchVideoUrl({
				videoIndex: currentVideoIndex,
				customerId: personData.customerId,
				videoId: trainingVideo,
				validationCode: validationCode
			})
		)
	}, [currentVideoIndex, personData])

	const handleTimeUpdate = e => {
		const totalTime = e.currentTarget.duration
		const minutes = Math.floor(totalTime / 60)
		const seconds = Math.floor(totalTime % 60)
		setDuration(`${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`)
	}

	return (
		<div
			data-testid="data-validation_TrainingVideos"
			style={{
				maxWidth: "958px"
			}}
		>
			<Logo />
			<Button
				style={{ color: "#fff" }}
				onClick={() => setSelectedPage("dataValidationForm")}
				startIcon={<SVG style={{ marginRight: "5px" }} src="svg/navigation/arrow-left.svg" />}
			>
				Go back to data validation
			</Button>
			{/* <button onClick={() => setSelectedPage("dataValidationForm")}>GO BACK</button> */}
			<Card isLoading={isLoading}>
				<CardHeader
					title="Training Videos"
					toolbar={
						selectedAnswers?.length > 0 && !selectedAnswers.some(val => val === null) ? (
							<Button
								variant="contained"
								color="primary"
								disabled={isLoading}
								onClick={() => {
									const answers = selectedAnswers.map((val, i) => {
										const questionId = questions[i]?.docId
										return { id: questionId, answer: val }
									})
									dispatch(
										actions.setQuestionAnswers({
											validationCode: validationCode,
											customerId: personData?.customerId,
											personId: personData?.docId,
											answers: answers
										})
									)
								}}
								text="SUBMIT"
								data-testid="final-quiz-submit-button"
							/>
						) : (
							<div />
						)
					}
				/>
				<CardBody padding="20px">
					<div
						style={{
							color: "#8C8CA1",
							fontFamily: "Poppins",
							fontSize: "16px",
							fontStyle: "normal",
							fontWeight: 500,
							lineHeight: "24px",
							marginBottom: "20px"
						}}
					>
						You were attributed {trainingVideos?.length || 0} training videos, please follow
						the steps in order to finish your training
					</div>
					<div className="d-flex flex-column flex-md-row">
						<div className="mb-4 mb-md-0">
							<VideoSteps videos={trainingVideos || []} activeStep={currentVideoIndex} />
						</div>
						{currentVideoIndex < trainingVideos?.length ? (
							<div className="flex-grow-1">
								<div
									style={{
										color: "#4A4A68",
										fontFamily: "Poppins",
										fontSize: "20px",
										fontStyle: "normal",
										fontWeight: 500,
										lineHeight: "32px",
										marginBottom: "8px"
									}}
								>
									{trainingVideos[currentVideoIndex]?.title}
								</div>
								<div
									style={{ position: "relative" }}
									data-testid="training-video-click"
									onClick={() => {
										const currentVideo = document.getElementById("training-video")
										if (currentVideo.paused) {
											currentVideo.play()
											setIsPlaying(true)
										} else {
											currentVideo.pause()
											setIsPlaying(false)
										}
									}}
								>
									<div
										className={`people-onboarding video-overlay ${
											isPlaying ? "playing" : ""
										}`}
										data-testid="training-video-overlay"
									>
										<SVG src="svg/navigation/play-solid.svg" />
										<span className="duration">{duration} min</span>
									</div>
									<video
										data-testid="training-video"
										ref={videoRef}
										id="training-video"
										style={{ width: "100%", height: "100%" }}
										src={trainingVideos[currentVideoIndex]?.url}
										onLoadedMetadata={handleTimeUpdate}
										onEnded={() => {
											dispatch(
												actions.defineVideoAsWatched({
													validationCode: validationCode,
													customerId: personData?.customerId,
													personId: personData?.docId,
													videoId: trainingVideos[currentVideoIndex].id
												})
											)
										}}
									/>
								</div>
							</div>
						) : currentVideoIndex === trainingVideos.length ? (
							<FinalQuiz
								questions={questions}
								selectedAnswers={selectedAnswers}
								setSelectedAnswers={setSelectedAnswers}
							/>
						) : (
							<div />
						)}
					</div>
				</CardBody>
			</Card>
		</div>
	)
}

export default TrainingVideos

const useStyles = makeStyles(() => ({
	stepper: {
		minWidth: "125px",
		padding: "0 !important",
		"& .MuiSvgIcon-root": {
			width: "30px",
			height: "30px"
		},
		"& .MuiStepLabel-iconContainer": {
			paddingRight: "15px"
		},
		"& .MuiStepLabel-label": {
			color: "#0A1B3D",
			fontFamily: "Poppins",
			fontSize: "16px",
			fontStyle: "normal",
			fontWeight: 500,
			lineHeight: "24px"
		},
		"& .MuiStepConnector-root": {
			padding: "6px 0px"
		}
	},
	stepperHorizontal: {
		"& .MuiStepLabel-iconContainer": {
			padding: 0
		}
	},
	stepperVertical: {
		marginRight: "25px",
		"& .MuiStepConnector-root": {
			marginLeft: "14px"
		}
	},
	formControlLabel: {
		borderRadius: "4px",
		background: "#F7F7F7",
		width: "100%",
		border: "1px solid transparent",
		"&.active": {
			border: "1px solid #2673F0"
		},
		"& .MuiIconButton-root:hover": {
			background: "transparent"
		}
	}
}))

function VideoSteps({ videos, activeStep }) {
	const classes = useStyles()
	const isSmallScreen = useMediaQuery("(max-width:768px)")

	return (
		<Stepper
			className={clsx(
				classes.stepper,
				isSmallScreen ? classes.stepperHorizontal : classes.stepperVertical
			)}
			activeStep={activeStep}
			orientation={isSmallScreen ? "horizontal" : "vertical"}
		>
			{videos.map((vid, index) => (
				<Step key={index}>
					<StepLabel data-testid="data-validation_TrainingVideos_video">
						{!isSmallScreen && "Video"}
					</StepLabel>
				</Step>
			))}
			<Step key={"Quiz"}>
				<StepLabel data-testid="data-validation_TrainingVideos_quiz">
					{!isSmallScreen && "Final Quiz"}
				</StepLabel>
			</Step>
		</Stepper>
	)
}

function FinalQuiz({ questions, selectedAnswers, setSelectedAnswers }) {
	const classes = useStyles()
	return (
		<div className="d-flex flex-column w-100">
			{questions.map((val, questionIndex) => {
				return (
					<div className="people-onboarding final-quiz" key={questionIndex}>
						<div className="title">{val.question}</div>
						<div className="row">
							{val.answers.map((answer, answerIndex) => {
								const checked = selectedAnswers[questionIndex] === answerIndex
								return (
									<div
										key={answerIndex}
										className={clsx(val.answers.length > 3 ? "col-6" : "col-12", "p-2")}
									>
										<FormControlLabel
											className={clsx(classes.formControlLabel, checked ? "active" : "")}
											control={
												<Checkbox
													color={checked ? "primary" : "default"}
													checked={checked}
													onChange={() => {
														setSelectedAnswers(prevState => {
															var newState = JSON.parse(JSON.stringify(prevState))
															newState[questionIndex] = answerIndex
															return newState
														})
													}}
												/>
											}
											label={answer}
										/>
									</div>
								)
							})}
						</div>
					</div>
				)
			})}
		</div>
	)
}
