/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { Card, CardBody, CardHeader } from "../../../_partials/Card"
import SVG from "react-inlinesvg"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import PerfectScrollbar from "react-perfect-scrollbar"
import clsx from "clsx"
import "./FirebaseModal.css"

import { makeStyles, withStyles } from "@material-ui/core/styles"
import {
	Button,
	Popover,
	InputBase,
	IconButton,
	Select,
	MenuItem,
	Switch,
	Collapse,
	Tooltip,
	Grid,
	Slider
} from "@material-ui/core"

import SearchIcon from "@material-ui/icons/Search"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import MoreVertIcon from "@material-ui/icons/MoreVert"

import { notificationsSlice } from "../../../redux/notifications/notificationsSlice"
import { editNotifications } from "../../../redux/notifications/notificationsActions"

const { actions } = notificationsSlice

//Custom Slider
const VolumeSlider = withStyles({
	root: {
		color: "#2673F0 !important",
		display: "grid",
		marginTop: 8,
		padding: "10rem 0"
	},
	thumb: {
		height: 12,
		width: 12,
		backgroundColor: "#2673F0",
		border: "2px solid currentColor",
		marginTop: -3,
		"&:focus, &:hover, &$active": {
			boxShadow: "inherit"
		}
	},
	active: {},
	valueLabel: {
		left: "auto"
	},
	track: {
		height: 6,
		borderRadius: 4
	},
	rail: {
		height: 6,
		borderRadius: 4,
		background: "#DDE9FD",
		opacity: "1"
	}
})(Slider)

const useStyles = makeStyles(theme => ({
	modal: {
		position: "fixed",
		top: "calc(0% + 60px)",
		right: "calc(0% + 185px)",
		width: "380px",
		height: "505px",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		padding: "12px 8px 12px 8px"
	},
	optionsDiv: {
		display: "flex",
		justifyContent: "space-between",
		margin: "15px 0"
	},
	"& .MuiInput-underline": {
		display: "none"
	},
	searchRoot: {
		display: "flex",
		width: 315,
		height: 32,
		padding: "0px 6px 0px 10px",
		alignItems: "center",
		borderRadius: "5px",
		background: "#F2F2F2"
	},
	dotMenu: {
		width: 34,
		height: 32,
		borderRadius: 5,
		marginLeft: 10,
		background: "#E9E9E9",
		"&:hover": {
			backgroundColor: "#4A4A68",
			color: "#FFFFFF"
		}
	},
	popover: {
		top: "calc(0% + 10px) !important",
		left: "calc(0% - 180px) !important",
		"& .MuiPaper-root": {
			overflow: "hidden"
		}
	},
	button: {
		display: "flex",
		justifyContent: "left",
		width: "237px",
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: "400",
		lineHeight: "normal",
		textTransform: "capitalize",
		padding: "1rem",
		margin: "0.5rem"
	},
	searchInput: {
		marginLeft: theme.spacing(1),
		flex: 1,
		color: "#8C8CA1",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%"
	},
	searchIconButton: {
		padding: 10,
		color: "#4A4A68"
	},
	switchRoot: {
		padding: "6px",
		"& .MuiSwitch-track": {
			borderRadius: 13
		},
		"& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
			backgroundColor: "#2673F0 !important",
			opacity: 1
		},
		"& .MuiSwitch-thumb": {
			backgroundColor: "#FFF"
		},
		"& .MuiIconButton-label": {
			background: "#FFF",
			borderRadius: 13,
			width: "19px",
			height: "19.53px"
		}
	},
	cardContainer: {
		height: 380
	},
	volumeGrid: {
		display: "flex",
		justifyContent: "left",
		width: "237px",
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: "400",
		lineHeight: "normal",
		textTransform: "capitalize",
		padding: "0 1rem 0 1.5rem",
		margin: "0",
		height: "30px"
	},
	volumeIcons: {
		fontSize: "14px",
		color: "#2673F0"
	}
}))

const perfectScrollbarOptions = {
	wheelSpeed: 2,
	wheelPropagation: false
}

const FirebaseNotificationsModalBody = ({
	allNotifications,
	alertContents,
	setAlertContents,
	reopenDisplayedAlert
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	//━━━━━━━━━━━━━━━ useSelector ━━━━━━━━━━━━━━━\\
	const { notificationSwitch, selectedCustomer, notificationsVolume } = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			notificationSwitch: state.notifications?.notificationSwitch,
			notificationsVolume: state.notifications?.notificationsVolume || 50
		}),
		shallowEqual
	)
	//━━━━━━━━━━━━━━━ States ━━━━━━━━━━━━━━━\\
	const [expandedCardIndex, setExpandedCardIndex] = useState(null)
	const [time, setTime] = useState(3) // Initialize the selected time value to 0 (Last 30 min)
	const [filteredNotifications, setFilteredNotifications] = useState()
	const [checkedNotification, setCheckedNotification] = useState(notificationSwitch)
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [searchText, setSearchText] = useState("") //store the search text
	const [volumeValue, setVolumeValue] = useState(notificationsVolume)

	// ━━━━━━━━━━━━━━━ useEffect ━━━━━━━━━━━━━━━\\
	useEffect(() => {
		// Calculate the appropriate time threshold based on the selected value of 'time'
		const currentTime = new Date()
		let timeThreshold = new Date(currentTime)

		switch (time) {
			case 0: // Last 15min
				timeThreshold.setMinutes(currentTime.getMinutes() - 15)
				break
			case 1: // Last 30min
				timeThreshold.setMinutes(currentTime.getMinutes() - 30)
				break
			case 2: // Last 1 hour
				timeThreshold.setHours(currentTime.getHours() - 1)
				break
			case 3: // Last 2 hours
				timeThreshold.setHours(currentTime.getHours() - 2)
				break
			// case 4: // Last 24 hours
			// 	timeThreshold.setMinutes(currentTime.getMinutes() - 1440)
			// 	break
			default: // Last 2 hours
				timeThreshold.setHours(currentTime.getHours() - 2)
				break
		}

		// Filter the notifications based on the calculated time threshold
		const updatedFilteredNotifications =
			allNotifications &&
			allNotifications
				.filter(notification => {
					let notificationTimestamp = new Date(
						notification.date.seconds * 1000 + notification.date.nanoseconds / 1000000
					)
					return notificationTimestamp > timeThreshold
				})
				.sort((a, b) => {
					// Check if both notifications have severity
					const hasSeverityA = typeof a.severity === "number"
					const hasSeverityB = typeof b.severity === "number"

					// Sort by timestamp in descending order
					if (b.date.seconds !== a.date.seconds) {
						return b.date.seconds - a.date.seconds
					}

					// Handle cases where one or both notifications lack severity
					if (hasSeverityA && hasSeverityB) {
						// Sort by severity if both have severity
						return b.severity - a.severity
					} else if (hasSeverityA) {
						// Put notifications without severity at the end
						return -1
					} else if (hasSeverityB) {
						// Put notifications without severity at the end
						return 1
					}

					// If severities are the same or both notifications lack severity, prioritize unread messages
					return a.read ? 1 : -1
				})

		// Apply search filtering if searchText is not empty
		const filteredRows = searchText
			? updatedFilteredNotifications.filter(row => {
					const { from, subject, body } = row

					const matchFound =
						from?.toLowerCase().includes(searchText.toLowerCase()) ||
						subject?.toLowerCase().includes(searchText.toLowerCase()) ||
						body?.toLowerCase().includes(searchText.toLowerCase())

					return matchFound
			  })
			: updatedFilteredNotifications

		setFilteredNotifications(filteredRows)
	}, [allNotifications, searchText, time])

	useEffect(() => {
		dispatch(actions.notificationSwitchChanged(checkedNotification))
	}, [checkedNotification])

	useEffect(() => {
		dispatch(actions.updateNotificationsVolumeState(volumeValue))
	}, [volumeValue])

	// ━━━━━━━━━━━━━━━ Handlers ━━━━━━━━━━━━━━━\\
	const handleSearch = value => {
		setSearchText(value.target.value)
	}

	const handleCardToggle = index => {
		if (expandedCardIndex === index) {
			setExpandedCardIndex(null)
		} else {
			setExpandedCardIndex(index)
		}
	}

	const handleReOpen = index => {
		filteredNotifications && reopenDisplayedAlert(filteredNotifications[index])
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleSwitchChange = event => {
		setCheckedNotification(event.target.checked)
	}

	const handleMarkAllAsRead = () => {
		const notificationsToEdit = allNotifications
			.filter(notification => !notification.read)
			.map(notification => ({
				id: notification.id,
				data: { read: true }
			}))

		dispatch(
			editNotifications({
				customerId: selectedCustomer?.id,
				notifications: notificationsToEdit
			})
		)
			.then(() => {
				setFilteredNotifications(prevNotifications => {
					const updatedNotifications = prevNotifications.map(notification => {
						if (
							notificationsToEdit.some(
								editNotification => editNotification.id === notification.id
							)
						) {
							return {
								...notification,
								read: true
							}
						}
						return notification
					})

					return updatedNotifications
				})
				// handleAnimations()
				handleClose()
			})
			.then(() => {
				setAlertContents(prevAlertContents => {
					const updatedAlertContents =
						prevAlertContents &&
						prevAlertContents.map(alert => {
							const a = {
								...alert,
								notification: { ...alert.notification, read: true },
								show: false
							}

							return a
						})
					dispatch(actions.notificationWarningChanged(updatedAlertContents))
				})
			})
			.catch(error => {
				console.error("Error marking Notifications as read:", error)
				// Handle error if necessary
			})
	}

	const handleReadUnread = (id, read) => {
		if (id) {
			dispatch(
				editNotifications({
					customerId: selectedCustomer?.id,
					notifications: [{ id: id, data: { read: !read } }]
				})
			)
				.then(() => {
					setFilteredNotifications(prevNotifications => {
						const updatedNotifications = prevNotifications.map(notification => {
							if (notification.id === id) {
								return {
									...notification,
									read: !read
								}
							}

							return notification
						})

						return updatedNotifications
					})
				})
				.then(() => {
					// filteredAlertContents &&
					// Use filter to exclude the item with the specified id
					const filteredAlertContents =
						alertContents && alertContents.filter(content => content.notification?.id !== id)

					// Assuming you want to update the state with the filtered contents
					// Use setAlertContents or dispatch an action to update the state
					setAlertContents(filteredAlertContents)
					dispatch(actions.notificationWarningChanged(filteredAlertContents))
				})
			return
		}
	}

	const handleVolumeChange = (event, newValue) => {
		if (!newValue) return
		setVolumeValue(newValue)
	}

	return (
		<>
			<div className={classes.modal}>
				<div style={{ display: "flex" }}>
					<div className={classes.searchRoot}>
						<InputBase
							className={classes.searchInput}
							placeholder="Search"
							inputProps={{ "aria-label": "search" }}
							onChange={handleSearch}
						/>
						<IconButton
							type="submit"
							className={classes.searchIconButton}
							aria-label="search"
						>
							<SearchIcon />
						</IconButton>
					</div>
					<div>
						<IconButton
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={handleClick}
							className={classes.dotMenu}
						>
							<MoreVertIcon />
						</IconButton>
						<Popover
							id="simple-menu"
							className={classes.popover}
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left"
							}}
						>
							<Button
								color="default"
								className={classes.button}
								onClick={handleMarkAllAsRead}
							>
								Mark all as read
							</Button>
							<hr
								style={{
									width: "225px",
									background: "#E2E6EA",
									marginTop: "0.5rem",
									marginBottom: "0.5rem"
								}}
							/>
							<Grid className={classes.volumeGrid}>
								<Grid style={{ marginRight: "12px" }}>Volume</Grid>
								<Grid
									style={{ display: "flex", marginRight: "6px", marginTop: "3px" }}
									className={classes.volumeIcons}
								>
									<SVG src={toAbsoluteUrl("/media/svg/icons/General/volume-down.svg")} />
								</Grid>
								<Grid style={{ flexGrow: "1", maxWidth: "100%", flexBasis: "0" }}>
									<VolumeSlider
										aria-label="continous-volume-slider"
										aria-labelledby="continous-volume-slider"
										value={notificationsVolume}
										onChange={handleVolumeChange}
									/>
								</Grid>
								<Grid
									style={{ display: "flex", marginLeft: "6px", marginTop: "3px" }}
									className={classes.volumeIcons}
								>
									<SVG src={toAbsoluteUrl("/media/svg/icons/General/volume-up.svg")} />
								</Grid>
							</Grid>
						</Popover>
					</div>
				</div>
				{/* TIME FILTER & SWITCH */}
				<div className={classes.optionsDiv}>
					<div>
						<AccessTimeIcon style={{ fontSize: 20, marginRight: "5px" }} />
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={time}
							onChange={e => {
								setTime(e.target.value)
							}}
							disableUnderline={true}
							IconComponent={ExpandMoreIcon}
						>
							<MenuItem value={0}>Last 15min</MenuItem>
							<MenuItem value={1}>Last 30min</MenuItem>
							<MenuItem value={2}>Last 1h</MenuItem>
							<MenuItem value={3}>Last 2h</MenuItem>
						</Select>
					</div>
					<Switch
						checked={checkedNotification}
						checkedIcon={
							<SVG
								src={toAbsoluteUrl("/media/svg/icons/General/notification--filled.svg")}
							/>
						}
						onChange={handleSwitchChange}
						color="primary"
						name="checkedNotificationSwitch"
						inputProps={{ "aria-label": "primary checkbox" }}
						className={classes.switchRoot}
					/>
				</div>
				{/* NOTIFICATIONS */}
				<PerfectScrollbar
					options={perfectScrollbarOptions}
					className="perfect-scroll"
					style={{
						position: "relative"
					}}
				>
					<div className={classes.cardContainer}>
						{filteredNotifications &&
							filteredNotifications.map((sms, index) => {
								const { id, subject, body, date, read, severity } = sms
								const isCardExpanded = expandedCardIndex === index
								const currentTime = new Date()
								let timeInfo = ""

								let notificationTimestamp = new Date(
									date.seconds * 1000 + date.nanoseconds / 1000000
								)

								const timeDifferenceMinutes = Math.floor(
									(currentTime - notificationTimestamp) / (1000 * 60)
								)

								switch (true) {
									case timeDifferenceMinutes < 2:
										timeInfo = "right now"
										break
									case timeDifferenceMinutes < 5:
										timeInfo = "5 mins ago"
										break
									case timeDifferenceMinutes < 10:
										timeInfo = "10 mins ago"
										break
									case timeDifferenceMinutes < 15:
										timeInfo = "15 mins ago"
										break
									case timeDifferenceMinutes < 20:
										timeInfo = "20 mins ago"
										break
									case timeDifferenceMinutes < 25:
										timeInfo = "25 mins ago"
										break
									case timeDifferenceMinutes < 45:
										timeInfo = "45 mins ago"
										break
									case timeDifferenceMinutes < 60:
										timeInfo = "1 hour ago"
										break
									case timeDifferenceMinutes < 90:
										timeInfo = "1 hour and 30 mins ago"
										break
									case timeDifferenceMinutes < 120:
										timeInfo = "2 hours ago"
										break
									default:
										timeInfo = "2 hours ago"
										break
								}

								const icon = isCardExpanded ? (
									<Tooltip title="Read less">
										<ExpandLessIcon
											fontSize="large"
											style={{
												color: read
													? "#D1D1D1"
													: severity === 3
													? "#E65019"
													: severity === 2
													? "#F6B952"
													: "#2673F0"
											}}
										/>
									</Tooltip>
								) : (
									<Tooltip title="Read more">
										<ExpandMoreIcon
											fontSize="large"
											style={{
												color: read
													? "#D1D1D1"
													: severity === 3
													? "#E65019"
													: severity === 2
													? "#F6B952"
													: "#2673F0"
											}}
										/>
									</Tooltip>
								)

								const headerTitle = (
									<div className="d-flex align-items-center">
										<div
											style={{
												color: "#0A1B3D",
												fontSize: "12px",
												fontStyle: "normal",
												fontWeight: 500,
												lineHeight: "24px",
												overflow: "hidden",
												whiteSpace: "nowrap",
												textOverflow: "ellipsis",
												width: "150px"
											}}
										>
											<span className="FirebaseModal-title">{subject}</span> <br />
											<span
												className="FirebaseModal-time"
												style={{
													color: read
														? "#BBBBBB"
														: severity === 3
														? "#E65019"
														: severity === 2
														? "#F6B952"
														: "#5591f3"
												}}
											>
												{timeInfo}
											</span>
										</div>
									</div>
								)

								const headerToolbar = (
									<div className="d-flex align-items-center">
										{/* ONLY ON HOVER */}
										<div className="hoverAction">
											<Tooltip title={!read ? "Mark as read" : "Mark as unread"}>
												<IconButton
													size="small"
													onClick={() => handleReadUnread(id, read)}
												>
													<SVG
														style={{
															fill: read
																? "#4A4A68"
																: severity === 3
																? "#E65019"
																: severity === 2
																? "#F6B952"
																: "#2673F0",
															width: "20px"
														}}
														src={toAbsoluteUrl(
															!read
																? "/media/svg/icons/General/notification-unread.svg"
																: "/media/svg/icons/General/notification-new.svg"
														)}
													/>
												</IconButton>
											</Tooltip>
										</div>
										<div className="manage_header_spacing">
											<IconButton size="small">{icon}</IconButton>
										</div>
									</div>
								)

								return (
									<Card
										margin={false}
										key={index}
										className={clsx(
											!read ? "read" : "notRead",
											severity === 2 && !read && "warningHover",
											severity === 3 && !read && "criticalHover"
										)}
										onClick={() => handleCardToggle(index)}
									>
										<CardHeader
											title={headerTitle}
											id="header_manage_customer_collapse"
											toolbar={headerToolbar}
											onClick={() => {
												;(severity === 2 || severity === 3) && handleReOpen(index)
											}}
											style={{
												background: read
													? "#EFEEEE"
													: severity === 3
													? "#FBE6DE"
													: severity === 2
													? "#FDF1DC"
													: "#DDE9FD"
											}}
											read={read}
											headerForMessaging={true}
											severity={severity}
											headerForMessagingIcon={
												<SVG
													src={toAbsoluteUrl(
														severity === 3
															? "/media/svg/icons/General/critical-notification-filled.svg"
															: severity === 2
															? "/media/svg/icons/General/warning--notification--filled.svg"
															: !read
															? "/media/svg/icons/General/notification-new.svg"
															: "/media/svg/icons/General/notification-unread.svg"
													)}
													style={{
														cursor: "pointer"
													}}
												/>
											}
										/>
										<Collapse in={isCardExpanded}>
											<CardBody>{body}</CardBody>
										</Collapse>
									</Card>
								)
							})}
					</div>
				</PerfectScrollbar>
			</div>
		</>
	)
}
export default FirebaseNotificationsModalBody
