import React from "react"
import { Carousel } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"

const BigImageModal = ({ show, handleClose, node, selectedImageIndexPosition }) => {
	return (
		<Modal
			show={show}
			onHide={handleClose}
			dialogClassName="big_image_modal_nodes"
			contentClassName="big_image_modal_nodes_content"
		>
			<Modal.Body style={{ padding: 0 }}>
				<Carousel
					indicators={false}
					controls={
						node?.imagesUrls && node?.imagesUrls?.length > 0 && node?.imagesUrls?.length > 1
					}
					interval={1500}
					defaultActiveIndex={selectedImageIndexPosition}
				>
					{node &&
						node?.imagesUrls?.length > 0 &&
						node?.imagesUrls.map((image, index) => (
							<Carousel.Item key={index} interval={1000}>
								<img
									src={image}
									alt="Large Image"
									style={{
										width: "100%",
										height: "43rem",
										maxHeight: "43rem", // You can adjust this value
										objectFit: "cover"
									}}
								/>
							</Carousel.Item>
						))}
				</Carousel>
				<div className="d-flex justify-content-between align-items-center popup_title pt-5 pb-5">
					<span>UUID: {node && node?.uuid}</span>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default BigImageModal
