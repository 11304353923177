export const CustomerStatusCssClasses = ["danger", "success", "info", ""]
export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""]
export const CustomerTypeCssClasses = ["success", "primary", ""]
export const CustomerTypeTitles = ["Business", "Individual", ""]
export const defaultSorted = [{ dataField: "id", order: "asc" }]
export const sizePerPageList = [
	{ text: "5", value: 5 },
	{ text: "20", value: 20 },
	{ text: "50", value: 50 },
	{ text: "100", value: 100 },
	{ text: "300", value: 300 }
]
export const initialFilter = {
	filter: {
		searchText: "",
		searchField: ""
	},
	sortOrder: "asc", // asc||desc
	sortField: "displayName",
	pageNumber: 1,
	pageSize: 50,
	pageToken: null
}

//capitalize only the first letter of string
export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

//capitalize only the first letter of several strings / words.
export const upperCase = names => {
	const toLower = names.toLowerCase().split(" ")
	const namesUpper = []
	if (toLower) {
		for (const wordName of toLower) {
			wordName[0] && namesUpper.push(wordName[0].toUpperCase() + wordName.slice(1))
		}
	}
	return namesUpper.join(" ")
}

// create universal unique ID (better than Math.random)
export function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		// eslint-disable-next-line no-mixed-operators
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	)
}

export function formatString(str) {
	return (
		str &&
		str
			.replace(/(\B)[^ ]*/g, match => match.toLowerCase())
			.replace(/^[^ ]/g, match => match.toUpperCase())
	)
}
