import React from "react"
import { Header3 } from "../../../_partials/typography/Header3"
import { HybridWorkBalance } from "./HybridWorkBalance"
import { Employees } from "./Employees"

export function PeopleStatus() {
	return (
		<>
			<Header3 text="People status" />
			<div className="row">
				<div
					className="col-12 col-xl-7 pt-4"
					style={{
						display: "flex",
						flexDirection: "column"
					}}
				>
					<Employees />
				</div>
				<div className="col-12 col-xl-5 pt-4">
					<HybridWorkBalance />
				</div>
			</div>
		</>
	)
}
