import React, { useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import Alert from "@material-ui/lab/Alert"
import { useUIContext } from "../UIContext"
import { Bold } from "../../../_partials/typography"
import CloseIcon from "@material-ui/icons/Close"
import { IconButton, makeStyles, Select as SelectMui } from "@material-ui/core"
import { Button } from "../../../_partials/Button"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

const useStyles = makeStyles(() => ({
	formControl: {
		width: "240px",
		marginTop: "2px"
	},
	indeterminateColor: {
		color: "#f50057"
	},
	selectAllText: {
		fontWeight: 400
	},
	selectedAll: {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.08)"
		}
	},
	filled: {
		backgroundColor: "#ECF1F4",
		"& .MuiSelect-underline": {
			display: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	select: {
		height: "38px",
		backgroundColor: "#ECF1F4",
		borderRadius: "5px",
		"& .MuiSelect-select:focus": {
			borderRadius: "0",
			backgroundColor: "transparent"
		},
		"& .MuiSelect-root": {
			padding: "16px 12px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		"& .MuiInput-underline:before": {
			borderBottom: "none"
		},
		"& .MuiInput-underline:after": {
			borderBottom: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		},
		"&:hover": {
			backgroundColor: "#ECF1F4",
			border: "1px solid grey",
			borderRadius: "5px"
		},
		"& .MuiSelect-icon": {
			color: "#4A4A68", // arrow icon color
			marginRight: "0.5rem"
		}
	},
	checkboxIcon: {
		"& .MuiSvgIcon-root": {
			color: "#3077D3"
		}
	},
	input2: {
		width: "100%",
		backgroundColor: "#F2F2F2",
		borderRadius: "4px",
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#F2F2F2"
			},
			"&:hover fieldset": {
				border: "none"
			},
			"&:focus fieldset": {
				border: "none"
			},
			"&.Mui-focused fieldset": {
				border: "none"
			}
		}
	},

	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "12px",
		fontWeight: "500"
	}
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	},
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "center"
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "center"
	},
	variant: "menu"
}

export function ExportModal({
	show,
	onHide,
	setOptionsOfExport,
	columnVisibilityModelBeforePrint
}) {
	const classes = useStyles()

	//* ━━━━━━━━━━━━━━━━━━━━━━━━━ UIContext
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			rowsToUse: UIContext.rowsToUse,
			columnVisibilityModel: UIContext.columnVisibilityModel,
			setColumnVisibilityModel: UIContext.setColumnVisibilityModel
		}
	}, [UIContext])

	//* ━━━━━━━━━━━━━━━━━━━━━━━━━ useSelector
	//* ━━━━━━━━━━━━━━━━━━━━━━━━━ useState
	const [selectedPrintType, setSelectedPrintType] = useState("excel")
	const [checked, setChecked] = useState(true)
	//* ━━━━━━━━━━━━━━━━━━━━━━━━━ useEffect
	const handlePrint = () => {
		if (!selectedPrintType) return

		if (selectedPrintType === "excel") {
			if (checked) {
				setOptionsOfExport("excel_current_page")
			} else {
				setOptionsOfExport("excel_all_pages")
			}
		}

		if (selectedPrintType === "pdf") {
			if (checked) {
				setOptionsOfExport("print_current_page")
			} else {
				setOptionsOfExport("print_all_pages")
			}
		}
	}

	const handlePdfOrExcelSelect = event => {
		setSelectedPrintType(event.target.value)
	}

	const handleChangeCheckbox = event => {
		setChecked(event.target.checked)
	}

	const hide = () => {
		// UIProps.setColumnVisibilityModel(columnVisibilityModelBeforePrint)
		onHide()
	}

	return (
		<Modal show={show} onHide={hide} data-testid="ExporModal">
			<Modal.Header>
				<div className="d-flex w-100 align-items-center justify-content-between">
					<div>
						<Bold>Export</Bold>
					</div>
					<div className="col-auto">
						<IconButton onClick={hide} style={{ left: "12px" }} data-testid="close-button">
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Modal.Header>
			<Modal.Body
				style={{
					padding: "0",
					overflow: "hidden"
				}}
			>
				{/* PDF / Excel selector */}
				<div className="p-5">
					{selectedPrintType === "pdf" && (
						<div className="mb-4">
							<Alert
								style={{
									color: "rgb(102, 60, 0)",
									backgroundColor: "rgb(255, 244, 229)",
									alignItems: "center"
								}}
								severity="warning"
							>
								<b>Printing Tip:</b> Consider the <b>varying widths</b> of columns when
								making your selection, as this can significantly influence the <b>layout</b>{" "}
								on A4 sheets.
								{/* removed this because only landspace prints after excel are good ? */}
								{/* Alternatively, adjusting the <b>layout</b> to{" "}
								<b>landscape orientation</b> may help accommodate wider columns more
								effectively. */}
							</Alert>
						</div>
					)}

					<div
						style={{
							marginBottom: "10px",
							color: "#4A4A68",
							fontSize: "12px",
							fontFamily: "Poppins",
							fontWeight: "500"
						}}
					>
						PDF/Excel:
					</div>
					<FormControl className={classes.formControl}>
						<SelectMui
							classes={{
								filled: classes.filled,
								icon: classes.checkboxIcon
							}}
							disableUnderline
							className={classes.select}
							labelId="select-to-print"
							value={selectedPrintType}
							onChange={handlePdfOrExcelSelect}
							MenuProps={MenuProps}
						>
							<MenuItem value={"pdf"}>PDF</MenuItem>
							<MenuItem value={"excel"}>Excel</MenuItem>
						</SelectMui>
					</FormControl>

					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={checked}
									onChange={handleChangeCheckbox}
									inputProps={{ "aria-label": "controlled" }}
									color="primary"
								/>
							}
							label="Current page"
						/>
					</FormGroup>

					<div className="d-flex justify-content-end" style={{ marginTop: "2rem" }}>
						<div className="mr-2">
							<Button
								variant="outlined"
								color="primary"
								style={{ width: "100%" }}
								onClick={hide}
								text="Cancel"
								data-testid="cancel-button"
							/>
						</div>
						<div>
							<Button
								variant="contained"
								color="primary"
								endIcon={
									selectedPrintType != "excel" && (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="19"
											height="19"
											viewBox="0 0 19 19"
											fill="none"
										>
											<path
												d="M16.625 5.34375H14.8438V1.78125H4.15625V5.34375H2.375C2.06006 5.34375 1.75801 5.46886 1.53531 5.69156C1.31261 5.91426 1.1875 6.21631 1.1875 6.53125V12.4688C1.1875 12.7837 1.31261 13.0857 1.53531 13.3084C1.75801 13.5311 2.06006 13.6562 2.375 13.6562H4.15625V17.2188H14.8438V13.6562H16.625C16.9399 13.6562 17.242 13.5311 17.4647 13.3084C17.6874 13.0857 17.8125 12.7837 17.8125 12.4688V6.53125C17.8125 6.21631 17.6874 5.91426 17.4647 5.69156C17.242 5.46886 16.9399 5.34375 16.625 5.34375ZM5.34375 2.96875H13.6562V5.34375H5.34375V2.96875ZM13.6562 16.0312H5.34375V10.0938H13.6562V16.0312ZM16.625 12.4688H14.8438V8.90625H4.15625V12.4688H2.375V6.53125H16.625V12.4688Z"
												fill="white"
											/>
										</svg>
									)
								}
								onClick={() => {
									handlePrint()
								}}
								// text={"Print"}
								text={selectedPrintType === "excel" ? "Export" : "Print"}
								data-testid="print-people-button"
								// disabled={!canPrint}
							/>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
