import React from "react"
import { makeStyles, FormControl, MenuItem, FormHelperText } from "@material-ui/core"
import { Select as SelectMUI } from "@material-ui/core"
import moment from "moment"
import { Scrollbars } from "react-custom-scrollbars"

const useStyles = makeStyles(theme => ({
	formControl: {
		width: "100%",
		marginTop: "2px"
	},
	select: {
		backgroundColor: "#FFFFFF",
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		}
	},
	menuPaper: {
		height: 200,
		width: 130
	},
	helperText: {
		margin: 0
	}
}))

export function TimeSelect({ label, withFeedbackLabel = true, form, field, startTime, ...props }) {
	const classes = useStyles()
	const { touched, errors, setFieldValue, setFieldTouched } = form
	const { name, value } = field
	const error = withFeedbackLabel && touched[name] && errors[name]

	var dates = []
	if (startTime) {
		const hour = startTime.split(":")[0]
		const minute = startTime.split(":")[1]
		const date = moment().hour(hour).minute(minute)
		const dateAfter = moment().add(1, "day").hour(hour).minute(minute)
		const startDate = moment().hour(hour).minute(minute)
		while (date.isBefore(dateAfter)) {
			const durBefore = moment.duration(date.diff(startDate))
			if (durBefore.hours() > 0) {
				date.add(30, "minutes")
			} else {
				date.add(15, "minutes")
			}
			const dur = moment.duration(date.diff(startDate))
			const hours =
				date.hours().toString().length > 1
					? date.hours().toString()
					: "0" + date.hours().toString()
			const minutes =
				date.minutes().toString().length > 1
					? date.minutes().toString()
					: "0" + date.minutes().toString()
			const hoursTil = dur.hours() > 0 ? dur.hours() : ""
			const minutesTil =
				dur.minutes() !== 0
					? dur.hours() > 0
						? dur.minutes() === 30
							? "5"
							: dur.minutes()
						: dur.minutes()
					: ""
			const point = dur.hours() > 0 && dur.minutes() > 0 ? "." : ""
			const sufix =
				dur.hours() === 0 ? " mins" : dur.hours() === 1 && dur.minutes() === 0 ? " hr" : " hrs"

			//dates.push(`${hours.length > 1 ? hours : "0" + hours}:${minutes.length > 1 ? minutes : "0" + minutes}`)
			dates.push({
				id: `${hours}:${minutes}`,
				name: `${hours}:${minutes} (${hoursTil}${point}${minutesTil}${sufix})`
			})
		}
		dates.pop()
	} else {
		const date = moment().startOf("day")
		const day = date.date()
		while (date.date() === day) {
			const hours =
				date.hours().toString().length > 1
					? date.hours().toString()
					: "0" + date.hours().toString()
			const minutes =
				date.minutes().toString().length > 1
					? date.minutes().toString()
					: "0" + date.minutes().toString()
			//dates.push(`${hours.length > 1 ? hours : "0" + hours}:${minutes.length > 1 ? minutes : "0" + minutes}`)
			dates.push({
				id: `${hours}:${minutes}`,
				name: `${hours}:${minutes}`
			})
			date.add(15, "minutes")
		}
	}

	return (
		<>
			{label && <b>{label}</b>}
			<div>
				<FormControl
					className={classes.formControl}
					variant="outlined"
					size="small"
					error={error}
				>
					<SelectMUI
						name={name}
						id="select-partial"
						className={classes.select}
						MenuProps={{
							classes: {
								paper: classes.menuPaper
							},
							MenuListProps: {
								component: Scrollbars
							}
						}}
						//MenuProps={MenuProps}
						value={value}
						renderValue={val => val}
						onClose={() => {
							/*!touched[name] so ti doesn't set touched true when it's already true*/
							/*Without this errors will always be one update behind the real value*/
							!touched[name] && setFieldTouched(name, true)
						}}
						onChange={e => {
							!touched[name] && setFieldTouched(name, true)
							setFieldValue(name, e.target.value)
						}}
						{...props}
					>
						{dates.map((val, i) => {
							return (
								<MenuItem key={i} value={val.id}>
									{val.name}
								</MenuItem>
							)
						})}
					</SelectMUI>
					{error && (
						<FormHelperText className={classes.helperText}>{errors[name]}</FormHelperText>
					)}
				</FormControl>
			</div>
		</>
	)
}
