import * as requestFromServer from "./dataValidationCrud"
import { dataValidationSlice, callTypes } from "./dataValidationSlice"

const { actions } = dataValidationSlice

export const getPersonData = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.fetchPersonData(queryParams)
		.then(response => response.json())
		.then(result => {
			console.log("🚀 . result:", result)
			if (!result) throw Error("Missing response from API")

			// if (result.failedFinalQuiz === true || result.failedFinalQuiz) {
			// 	dispatch(actions.personDataFetched({ failedFinalQuiz: true }))
			// 	return
			// }

			const personData = {
				customerId: result.customerId,
				docId: result.docId,
				name: result.name,
				email: result.email,
				phoneNumber: result.phoneNumber,
				company: result.company,
				jobSiteCode: result.jobSiteCode,
				picture: result.picture,
				role: result.role,
				idNumber: result.idNumber,
				emergencyName: result.emergencyName,
				emergencyNumber: result.emergencyNumber,
				termsAndConditionsAccepted: result.termsAndConditionsAccepted,
				dataValidated: result.dataValidated,
				trainingVideos: result.trainingVideos,
				trainingQuestions: result.trainingQuestions,
				failedFinalQuiz: result.failedFinalQuiz
			}
			dispatch(actions.personDataFetched(personData))
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error getting data"
			})
			console.log("Get data error: ", error)
			error.clientMessage = "Can't get data"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const handleTermsAcception = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.acceptTermsAndConditions(queryParams)
		.then(response => response.json())
		.then(result => {
			console.log("🚀 . result:", result)
			if (result !== null && result !== undefined) {
				dispatch(actions.termsResponded(result))
			}
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error accepting terms"
			})
			console.log("Accept terms error: ", error)
			error.clientMessage = "Can't accept terms"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const handleTermsRefusal = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.refuseTermsAndConditions(queryParams)
		.then(response => response.json())
		.then(result => {
			console.log("🚀 . result:", result)
			if (result !== null && result !== undefined) {
				dispatch(actions.termsResponded(result))
			}
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error creating person"
			})
			console.log("Create person error: ", error)
			error.clientMessage = "Can't create person"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchVideoUrl = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.getVideoUrl(queryParams)
		.then(response => response.json())
		.then(result => {
			console.log("🚀 . result fetch video:", result)
			if (result !== null && result !== undefined) {
				dispatch(
					actions.videoUrlFetched({
						title: result.title,
						url: result.url,
						videoIndex: queryParams.videoIndex
					})
				)
			}
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error accepting terms"
			})
			console.log("Accept terms error: ", error)
			error.clientMessage = "Can't accept terms"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchQuestions = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.getQuestions(queryParams)
		.then(response => response.json())
		.then(result => {
			console.log("🚀 . questions result:", result)
			if (!result) return

			dispatch(actions.questionsFetched(result))
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error accepting terms"
			})
			console.log("Accept terms error: ", error)
			error.clientMessage = "Can't accept terms"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const defineVideoAsWatched = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.defineVideoAsWatched(queryParams)
		.then(response => response.json())
		.then(result => {
			console.log("🚀 . result:", result)
			if (result) {
				dispatch(actions.videoWatched(result))
			}
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error accepting terms"
			})
			console.log("Accept terms error: ", error)
			error.clientMessage = "Can't accept terms"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const setQuestionAnswers = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.setQuestionAnswers(queryParams)
		.then(response => response.json())
		.then(result => {
			console.log("🚀 . result:", result)
			if (result !== null && result !== undefined) {
				dispatch(actions.personDataFetched({ failedFinalQuiz: result }))
			}
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error accepting terms"
			})
			console.log("Accept terms error: ", error)
			error.clientMessage = "Can't accept terms"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const validateData = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.validateData(queryParams)
		.then(response => response.json())
		.then(result => {
			console.log("🚀 . result:", result)
			if (result) {
				dispatch(actions.personDataFetched(result))
			}
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error accepting terms"
			})
			console.log("Accept terms error: ", error)
			error.clientMessage = "Can't accept terms"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}
