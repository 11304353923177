import React from "react"
import { LiveDataSection } from "./LiveData/LiveDataSection"
import { UIProvider } from "./UIContext"
import DashboardTop from "./LiveData/DashboardTop/DashboardTop"

export function Dashboard() {
	return (
		<UIProvider>
			<DashboardTop />
			<div className="row">
				<LiveDataSection />
				{/* {selectedLocation && <HistoricalDataSection />} */}
			</div>
		</UIProvider>
	)
}
