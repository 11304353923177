import React, { useState, useEffect, useMemo } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardHeader, CardBody } from "../../../_partials/Card"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
import "react-big-calendar/lib/css/react-big-calendar.css"
import "./calendarStyle.css"
import { Subtitle } from "../../../_partials/typography/Subtitle"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import { Small } from "../../../_partials/typography/Small"
import Popover from "@material-ui/core/Popover"
import { DropdownSelect } from "../DropdownSelect"
import IconButton from "@material-ui/core/IconButton"
import Edit from "@material-ui/icons/Edit"
import { useUIContext } from "../UIContext"

const localizer = momentLocalizer(moment) // or globalizeLocalizer

export function AllBookings() {
	//━━━ Get data from redux ━━━\\
	const { currentCustomer, bookings, resources, department, user, users, profileLvl } =
		useSelector(
			state => ({
				currentCustomer: state.profile?.currentCustomer,
				bookings: state.booking?.bookings,
				resources: state.booking?.resources,
				department: state.basePage?.department,
				user: state.auth?.user,
				users: state.booking?.users,
				profileLvl: state.auth?.claims?.profileLvl
			}),
			shallowEqual
		)

	//★━━━━━━━━━━━━━━━★ States ★━━━━━━━━━━━━━━━★\\
	//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
	const [events, setEvents] = useState([])
	const [filteredBookings, setFilteredBookings] = useState()
	const [dataFilter, setDataFilter] = useState({ value: "me", label: "Me" })

	useEffect(() => {
		if (bookings && users) {
			if (dataFilter.value === "department") {
				const filtered = bookings.filter(val => {
					return users.some(u => {
						return u.uid === val.created.id || val.attendees?.includes(u.email)
					})
				})
				setFilteredBookings(filtered)
			} else {
				const filtered = bookings.filter(val => {
					return user.id === val.created.id || val.attendees?.includes(user.email)
				})
				setFilteredBookings(filtered)
			}
		}
	}, [bookings, users, dataFilter, user])

	useEffect(() => {
		if (filteredBookings) {
			const newEvents = filteredBookings.map(val => {
				return {
					id: val,
					allDay: true,
					start: new Date(val.start.toDate()),
					end: new Date(val.end.toDate())
				}
			})

			//━━━ This gives an object with dates as keys ━━━\\
			const groups = newEvents.reduce((groups, event) => {
				const date = moment(event.start).startOf("day").format()
				if (!groups[date]) {
					groups[date] = []
				}
				groups[date].push(event)
				return groups
			}, {})

			//━━━ This returns all day events with bookings inside for popup ━━━\\
			const groupArrays = Object.keys(groups).map((date, index) => {
				return {
					id: index,
					books: groups[date],
					allDay: true,
					start: moment(date).startOf("day").toDate(),
					end: moment(date).endOf("day").toDate()
				}
			})
			setEvents(groupArrays)
		}
	}, [filteredBookings])

	return (
		<Card>
			<CardHeader
				title="All bookings"
				subtitle="Monthly view"
				toolbar={
					profileLvl >= 2 &&
					department?.manager === user.id && (
						<DropdownSelect
							dataFilter={dataFilter}
							setDataFilter={setDataFilter}
							options={[
								{ value: "me", label: "Me" },
								{ value: "department", label: "Department" }
							]}
						/>
					)
				}
			/>
			<CardBody style={{ height: "250px" }}>
				<div className="row h-100">
					<div className="col">
						<div className="d-flex align-items-center">
							<Subtitle text={filteredBookings?.length || 0} />
							<ArrowRightAltIcon
								style={{
									transform: "rotate(-90deg)",
									marginLeft: "6px",
									color: "#31D0AA"
								}}
							/>
						</div>
						<div
							style={{
								width: "119px",
								height: "45px"
							}}
						>
							<Small text="Total bookings this month" color="#8C8CA1" />
						</div>
					</div>
					<div className="col-6">
						<Calendar
							events={events}
							onDrillDown={() => null} //When days are clicked!
							onSelectEvent={() => console.log("Event")} //When event are clicked!
							defaultDate={new Date()}
							style={{ height: "100%" }}
							localizer={localizer}
							startAccessor="start"
							endAccessor="end"
							toolbar={false}
							/* views={{
                     month: true,
                     week: false,
                     day: false,
                     agenda: false
                   }} */
							//dayPropGetter={customDayPropGetter}
							/* eventPropGetter={customSlotPropGetter} */
							components={{
								event: Event,
								eventWrapper: e => EventWrapper({ event: e.event, user, users, resources })
								//dateCellWrapper: ColoredDateCellWrapper,
								/* month: {
                        header: header
    
                     } */
							}}
						/>
					</div>
				</div>
			</CardBody>
		</Card>
	)
}

function Event({ event }) {
	return (
		<div
			style={{
				height: "10px",
				width: "10px",
				backgroundColor: "red"
			}}
		/>
	)
}

function EventWrapper({ event, user, users, resources }) {
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			openBookModel: UIContext.openBookModel,
			openBookModelWithBookingId: UIContext.openBookModelWithBookingId
		}
	}, [UIContext])

	const [anchorEl, setAnchorEl] = useState(null)
	const [anchorEl2, setAnchorEl2] = useState(null)
	const [popoverContent2, setPopoverContent2] = useState(null)

	const open = Boolean(anchorEl)
	const open2 = Boolean(anchorEl2)
	const id = open ? "simple-popover" : undefined

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handlePopoverOpen2 = ({ target, content }) => {
		setPopoverContent2(content)
		setAnchorEl2(target)
	}
	const handlePopoverClose2 = () => {
		setAnchorEl2(null)
	}

	return (
		<>
			<div
				onClick={handleClick}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "30px",
					width: "31px",
					backgroundColor: "#D3EEE7",
					borderRadius: "39px",
					cursor: "pointer",
					zIndex: 999,
					color: "#323389",
					fontFamily: "Poppins",
					fontStyle: "normal",
					fontWeight: "500",
					fontSize: "13px",
					lineHeight: "170%",
					border:
						moment(event.start).format("DD") === moment().format("DD") && "2px solid #31D0AA"
				}}
			>
				<span>{moment(event.start).format("DD")}</span>
			</div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				{event.books.map(val => {
					const thisBookUser = users && users.find(u => u.uid === val.id.created.id)
					return (
						<div
							key={val.id}
							className="row m-0 d-flex"
							style={{
								padding: "10px"
							}}
						>
							<div className="pr-4">
								<div
									//title={thisBookUser?.displayName || thisBookUser?.email || "Error"}
									style={{
										borderRadius: "50%",
										width: "30px",
										height: "30px",
										cursor: "pointer",
										backgroundSize: "cover",
										backgroundImage: `url(${
											thisBookUser?.photoURL
												? thisBookUser.photoURL
												: "/media/users/default.jpg"
										}), url(/media/users/default.jpg)`
									}}
									onMouseEnter={e =>
										handlePopoverOpen2({
											target: e.currentTarget,
											content: { user: thisBookUser }
										})
									}
									onMouseLeave={handlePopoverClose2}
									//src={thisBookUser?.photoURL ? thisBookUser.photoURL : "/media/users/default.jpg"}
								/>
							</div>
							<div>
								{resources && resources.find(v => v.id === val.id.resource)?.name}
								<br />
								<span>{moment(val.id.start.toDate()).format("HH:mm")}</span>
								<span>{" - "}</span>
								<span>{moment(val.id.end.toDate()).format("HH:mm")}</span>
							</div>
							{val.id.created.id === user?.id && (
								<div className="ml-auto">
									<IconButton
										aria-label="cancel_booking"
										component="span"
										style={{ padding: "5px", marginLeft: "auto" }}
										onClick={() => UIProps.openBookModelWithBookingId(val.id.id)}
									>
										<Edit style={{ color: "#323389" }} />
									</IconButton>
								</div>
							)}
						</div>
					)
				})}
			</Popover>
			<Popover
				id="mouse-over-popover"
				style={{
					pointerEvents: "none"
				}}
				open={open2}
				anchorEl={anchorEl2}
				onClose={handlePopoverClose2}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				disableRestoreFocus
			>
				<div
					style={{
						padding: "12px 20px 20px 20px",
						backgroundColor: "#f7f7f7",
						width: "300px"
					}}
				>
					<div
						className="row d-flex justify-content-between"
						style={{
							margin: "auto"
						}}
					>
						<div
							className="row"
							style={{
								margin: 0
								//paddingTop: "10px"
							}}
						>
							<div>
								<div
									style={{
										borderRadius: "50%",
										width: "45px",
										height: "45px",
										backgroundSize: "cover",
										backgroundImage: `url(${
											popoverContent2?.user?.photoURL
												? popoverContent2.user.photoURL
												: "/media/users/default.jpg"
										}), url(/media/users/default.jpg)`
									}}
								/>
							</div>
							<div
								style={{
									paddingLeft: "5px",
									marginTop: "auto"
								}}
							>
								<span
									style={{
										fontFamily: "Poppins",
										fontStyle: "normal",
										fontWeight: "500",
										fontSize: "16px",
										lineHeight: "160%",
										color: "#4A4A68",
										overflow: "hidden",
										textOverflow: "ellipsis"
									}}
								>
									{popoverContent2?.user?.displayName || popoverContent2?.user?.email}
								</span>
								<br />
								<span
									style={{
										fontFamily: "Poppins",
										fontStyle: "normal",
										fontWeight: "500",
										fontSize: "13px",
										lineHeight: "160%",
										color: "#4A4A68"
									}}
								>
									{popoverContent2?.user?.email}
								</span>
							</div>
						</div>
					</div>
				</div>
			</Popover>
		</>
	)
}
