import React, { useState, useEffect } from "react"
import { Card, CardBody, CardHeader } from "../../../_partials/Card"
import SVG from "react-inlinesvg"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import PerfectScrollbar from "react-perfect-scrollbar"
import "./FirebaseModal.css"

import { makeStyles, withStyles } from "@material-ui/core/styles"
import {
	Button,
	InputBase,
	IconButton,
	Select,
	MenuItem,
	Switch,
	Collapse,
	Tooltip,
	Popover,
	Slider,
	Grid
} from "@material-ui/core"

import SearchIcon from "@material-ui/icons/Search"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { notificationsSlice } from "../../../redux/notifications/notificationsSlice"
import {
	editNotifications,
	deleteNotifications
} from "../../../redux/notifications/notificationsActions"

const { actions } = notificationsSlice

//Custom Slider
const VolumeSlider = withStyles({
	root: {
		color: "#2673F0 !important",
		display: "grid",
		marginTop: 8,
		padding: "10rem 0"
		// height: 8
	},
	thumb: {
		height: 12,
		width: 12,
		// backgroundColor: "#fff",
		backgroundColor: "#2673F0",
		border: "2px solid currentColor",
		marginTop: -3,
		// marginLeft: -12,
		"&:focus, &:hover, &$active": {
			boxShadow: "inherit"
		}
	},
	active: {},
	valueLabel: {
		// left: "calc(-50% + 0px)"
		left: "auto"
	},
	track: {
		height: 6,
		borderRadius: 4
	},
	rail: {
		height: 6,
		borderRadius: 4,
		background: "#DDE9FD",
		opacity: "1"
	}
})(Slider)

const useStyles = makeStyles(theme => ({
	modal: {
		position: "fixed",
		top: "calc(0% + 60px)",
		right: "calc(0% + 240px)",
		width: "380px",
		height: "505px",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		padding: "12px 8px 12px 8px"
	},
	optionsDiv: {
		display: "flex",
		justifyContent: "space-between",
		margin: "15px 0"
	},
	"& .MuiInput-underline": {
		display: "none"
	},
	searchRoot: {
		display: "flex",
		width: 315,
		height: 32,
		padding: "0px 6px 0px 10px",
		alignItems: "center",
		borderRadius: "5px",
		background: "#F2F2F2"
	},
	dotMenu: {
		width: 34,
		height: 32,
		borderRadius: 5,
		marginLeft: 10,
		background: "#E9E9E9",
		"&:hover": {
			backgroundColor: "#4A4A68",
			color: "#FFFFFF"
		}
	},
	menu: {
		"& .MuiList-padding": {
			paddingTop: "18px",
			paddingBottom: "18px"
		}
	},
	popover: {
		top: "calc(0% + 10px) !important",
		left: "calc(0% - 215px) !important",
		"& .MuiPaper-root": {
			overflow: "hidden"
		}
	},
	button: {
		display: "flex",
		justifyContent: "left",
		width: "237px",
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: "400",
		lineHeight: "normal",
		textTransform: "capitalize",
		padding: "1rem",
		margin: "0.5rem"
	},
	searchInput: {
		marginLeft: theme.spacing(1),
		flex: 1,
		color: "#8C8CA1",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%"
	},
	searchIconButton: {
		padding: 10,
		color: "#4A4A68"
	},
	switchRoot: {
		padding: "6px",
		"& .MuiSwitch-track": {
			borderRadius: 13
		},
		"& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
			backgroundColor: "#2673F0 !important",
			opacity: 1
		},
		"& .MuiSwitch-thumb": {
			backgroundColor: "#FFF"
		},
		"& .MuiIconButton-label": {
			background: "#FFF",
			borderRadius: 13,
			width: "19px",
			height: "19.53px"
		}
	},
	cardContainer: {
		height: 380
	},
	volumeGrid: {
		display: "flex",
		justifyContent: "left",
		width: "237px",
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: "400",
		lineHeight: "normal",
		textTransform: "capitalize",
		padding: "0 1rem 0 1.5rem",
		margin: "0",
		height: "30px"
	},
	volumeIcons: {
		fontSize: "14px",
		color: "#2673F0"
	}
}))

const perfectScrollbarOptions = {
	wheelSpeed: 2,
	wheelPropagation: false
}

const FirebaseMessagingModalBody = ({ allMessages }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	//â”â”â”â”â”â”â”â”â”â”â”â”â”â”â” useSelector â”â”â”â”â”â”â”â”â”â”â”â”â”â”â”\\
	//Ã¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€Â useSelector Ã¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€Â\\
	const { messagingSwitch, selectedCustomer, messagingVolume } = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			messagingSwitch: state.notifications?.messagingSwitch,
			messagingVolume: state.notifications?.messagingVolume || 50
		}),
		shallowEqual
	)
	//â”â”â”â”â”â”â”â”â”â”â”â”â”â”â” States â”â”â”â”â”â”â”â”â”â”â”â”â”â”â”\\
	//Ã¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€Â States Ã¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€Â\\
	const [expandedCardIndex, setExpandedCardIndex] = useState(null)
	const [time, setTime] = useState(3) // Initialize the selected time value to 0 (Last 24h)
	const [filteredMessages, setFilteredMessages] = useState()
	const [checkedMessaging, setCheckedMessaging] = useState(messagingSwitch)
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [searchText, setSearchText] = useState("") //store the search text
	const [volumeValue, setVolumeValue] = useState(messagingVolume)

	// â”â”â”â”â”â”â”â”â”â”â”â”â”â”â” useEffect â”â”â”â”â”â”â”â”â”â”â”â”â”â”â”\\
	// Ã¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€Â useEffect Ã¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€Â\\
	useEffect(() => {
		// Calculate the appropriate time threshold based on the selected value of 'time'
		const currentTime = new Date()
		let timeThreshold = new Date(currentTime)

		switch (time) {
			case 0: // Last 30min
				timeThreshold.setMinutes(currentTime.getMinutes() - 30)
				break
			case 1: // Last 6h
				timeThreshold.setHours(currentTime.getHours() - 6)
				break
			case 2: // Last 12h
				timeThreshold.setHours(currentTime.getHours() - 12)
				break
			case 3: // Last 24h
				timeThreshold.setHours(currentTime.getHours() - 24)
				break
			default:
				// Last 24h
				timeThreshold.setHours(currentTime.getHours() - 24)
				break
		}

		// Filter the messages based on the calculated time threshold
		const updatedFilteredMessages = allMessages.filter(message => {
			let messageTimestamp = new Date(
				message.date.seconds * 1000 + message.date.nanoseconds / 1000000
			)
			return messageTimestamp > timeThreshold
		})

		// Apply search filtering if searchText is not empty
		const filteredRows = searchText
			? updatedFilteredMessages.filter(row => {
					const { from, subject, body } = row

					const matchFound =
						from?.toLowerCase().includes(searchText.toLowerCase()) ||
						subject?.toLowerCase().includes(searchText.toLowerCase()) ||
						body?.toLowerCase().includes(searchText.toLowerCase())

					return matchFound
			  })
			: updatedFilteredMessages

		setFilteredMessages(filteredRows)
	}, [allMessages, searchText, time])

	useEffect(() => {
		dispatch(actions.messagingSwitchChanged(checkedMessaging))
	}, [checkedMessaging])

	useEffect(() => {
		dispatch(actions.updateMessagingVolumeState(volumeValue))
	}, [volumeValue])

	// â”â”â”â”â”â”â”â”â”â”â”â”â”â”â” Handlers â”â”â”â”â”â”â”â”â”â”â”â”â”â”â”\\
	// Ã¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€Â Handlers Ã¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€ÂÃ¢â€Â\\
	const handleSearch = value => {
		setSearchText(value.target.value)
	}

	const handleCardToggle = index => {
		if (expandedCardIndex === index) {
			setExpandedCardIndex(null)
		} else {
			setExpandedCardIndex(index)
		}
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleSwitchChange = event => {
		setCheckedMessaging(event.target.checked)
	}

	const handleMarkAllAsRead = () => {
		const messagesToEdit = allMessages.map(message => ({
			id: message.id,
			data: { read: true }
		}))

		dispatch(
			editNotifications({
				customerId: selectedCustomer?.id,
				notifications: messagesToEdit
			})
		)
			.then(() => {
				setFilteredMessages(prevMessages => {
					const updatedMessages = prevMessages.map(message => {
						if (messagesToEdit.some(editMessage => editMessage.id === message.id)) {
							return {
								...message,
								read: true
							}
						}
						return message
					})
					return updatedMessages
				})
				handleClose()
			})
			.catch(error => {
				console.error("Error marking messages as read:", error)
				// Handle error if necessary
			})
	}

	const handleDeleteAllMessages = () => {
		dispatch(
			deleteNotifications({
				customerId: selectedCustomer?.id,
				notificationsToDelete: allMessages ? allMessages : []
			})
		).then(() => {
			setFilteredMessages([])
			handleClose()
		})
	}

	const handleReadUnread = (id, read) => {
		if (id) {
			dispatch(
				editNotifications({
					customerId: selectedCustomer?.id,
					notifications: [{ id: id, data: { read: !read } }]
				})
			).then(() => {
				setFilteredMessages(prevMessages => {
					const updatedMessages = prevMessages.map(message => {
						if (message.id === id) {
							return {
								...message,
								read: !read
							}
						}
						return message
					})
					return updatedMessages
				})
			})
			return
		}
	}

	const handleDelete = id => {
		const notificationsToDelete = filteredMessages
			? filteredMessages.filter(message => message.id.includes(id))
			: []

		dispatch(
			deleteNotifications({
				customerId: selectedCustomer?.id,
				notificationsToDelete: notificationsToDelete
			})
		).then(() => {
			const updatedFilteredMessages = filteredMessages.filter(message => message.id !== id)
			setFilteredMessages(updatedFilteredMessages)
		})
	}

	const handleVolumeChange = (event, newValue) => {
		if (!newValue) return
		setVolumeValue(newValue)
	}

	return (
		<>
			<div className={classes.modal} data-testid="firebase_messaging_modal_body_main_div">
				<div style={{ display: "flex" }}>
					{/* SEARCH TEXT FIELD */}
					<div className={classes.searchRoot}>
						<InputBase
							className={classes.searchInput}
							placeholder="Search"
							inputProps={{ "aria-label": "search" }}
							onChange={handleSearch}
						/>
						<IconButton
							type="submit"
							className={classes.searchIconButton}
							aria-label="search"
						>
							<SearchIcon />
						</IconButton>
					</div>
					<div>
						<IconButton
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={handleClick}
							className={classes.dotMenu}
						>
							<MoreVertIcon />
						</IconButton>
						<Popover
							id="simple-menu"
							className={classes.popover}
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left"
							}}
							// classes={{ paper: classes.customPaper }}
						>
							<Button
								color="default"
								className={classes.button}
								onClick={handleMarkAllAsRead}
							>
								Mark all as read
							</Button>
							<Button
								color="default"
								className={classes.button}
								onClick={() => {
									handleDeleteAllMessages()
									handleClose()
								}}
							>
								Delete all
							</Button>
							<hr
								style={{
									width: "225px",
									background: "#E2E6EA",
									marginTop: "0.5rem",
									marginBottom: "0.5rem"
								}}
							/>
							<Grid container spacing={2} className={classes.volumeGrid}>
								<Grid style={{ marginRight: "12px" }}>Volume</Grid>
								<Grid
									style={{ display: "flex", marginRight: "6px", marginTop: "3px" }}
									className={classes.volumeIcons}
								>
									<SVG src={toAbsoluteUrl("/media/svg/icons/General/volume-down.svg")} />
								</Grid>
								<Grid style={{ flexGrow: "1", maxWidth: "100%", flexBasis: "0" }}>
									<VolumeSlider
										// valueLabelDisplay="auto"
										aria-label="continous-volume-slider"
										aria-labelledby="continous-volume-slider"
										value={messagingVolume}
										min={0}
										max={100}
										onChange={handleVolumeChange}
									/>
								</Grid>
								<Grid
									style={{ display: "flex", marginLeft: "6px", marginTop: "3px" }}
									className={classes.volumeIcons}
								>
									<SVG src={toAbsoluteUrl("/media/svg/icons/General/volume-up.svg")} />
								</Grid>
							</Grid>
						</Popover>
					</div>
				</div>
				{/* TIME FILTER & SWITCH */}
				<div className={classes.optionsDiv}>
					<div>
						<AccessTimeIcon style={{ fontSize: 20, marginRight: "5px" }} />
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={time}
							onChange={e => {
								setTime(e.target.value)
							}}
							disableUnderline={true}
							IconComponent={ExpandMoreIcon}
						>
							<MenuItem value={0}>Last 30min</MenuItem>
							<MenuItem value={1}>Last 6h</MenuItem>
							<MenuItem value={2}>Last 12h</MenuItem>
							<MenuItem value={3}>Last 24h</MenuItem>
						</Select>
					</div>
					<Switch
						checked={checkedMessaging}
						checkedIcon={
							<SVG src={toAbsoluteUrl("/media/svg/icons/General/mail--filled.svg")} />
						}
						onChange={handleSwitchChange}
						color="primary"
						name="checkedMessagingSwitch"
						inputProps={{ "aria-label": "primary checkbox" }}
						className={classes.switchRoot}
					/>
				</div>
				{/* NOTIFICATIONS */}
				<PerfectScrollbar
					options={perfectScrollbarOptions}
					className="perfect-scroll"
					style={{
						position: "relative"
					}}
				>
					<div className={classes.cardContainer}>
						{filteredMessages &&
							filteredMessages.map((sms, index) => {
								const { id, subject, body, date, read } = sms
								const isCardExpanded = expandedCardIndex === index
								const currentTime = new Date()
								let timeInfo = ""

								let messageTimestamp = new Date(
									date.seconds * 1000 + date.nanoseconds / 1000000
								)

								const timeDifferenceMinutes = Math.floor(
									(currentTime - messageTimestamp) / (1000 * 60)
								)

								switch (true) {
									case timeDifferenceMinutes < 5:
										timeInfo = "right now"
										break
									case timeDifferenceMinutes < 30:
										timeInfo = "30 min ago"
										break
									case timeDifferenceMinutes < 60:
										timeInfo = "1 hour ago"
										break
									case timeDifferenceMinutes < 120:
										timeInfo = "2 hours ago"
										break
									case timeDifferenceMinutes < 360:
										timeInfo = "6 hours ago"
										break
									case timeDifferenceMinutes < 720:
										timeInfo = "12 hours ago"
										break
									case timeDifferenceMinutes < 1200:
										timeInfo = "20 hours ago"
										break
									default:
										timeInfo = "more than 20 hours ago"
										break
								}

								const icon = isCardExpanded ? (
									<Tooltip title="Read less">
										<ExpandLessIcon
											fontSize="large"
											style={{ color: !read ? "#2673F0" : "#D1D1D1" }}
										/>
									</Tooltip>
								) : (
									<Tooltip title="Read more">
										<ExpandMoreIcon
											fontSize="large"
											style={{ color: !read ? "#2673F0" : "#D1D1D1" }}
										/>
									</Tooltip>
								)

								const headerTitle = (
									<div className="d-flex align-items-center">
										<div
											style={{
												color: "#0A1B3D",
												fontSize: "12px",
												fontStyle: "normal",
												fontWeight: 500,
												lineHeight: "24px",
												overflow: "hidden",
												whiteSpace: "nowrap",
												textOverflow: "ellipsis",
												width: "150px"
											}}
										>
											<span className="FirebaseModal-title">{subject}</span> <br />
											<span
												className="FirebaseModal-time"
												style={{ color: !read ? "#5591f3" : "#BBBBBB" }}
											>
												{timeInfo}
											</span>
										</div>
									</div>
								)

								const headerToolbar = (
									<div className="d-flex align-items-center">
										{/* ONLY ON HOVER */}
										<div className="hoverAction">
											<Tooltip title={!read ? "Mark as read" : "Mark as unread"}>
												<IconButton
													size="small"
													onClick={() => handleReadUnread(id, read)}
												>
													<SVG
														style={{
															fill: !read ? "#2673F0" : "#4A4A68",
															width: "20px"
														}}
														src={toAbsoluteUrl(
															!read
																? "/media/svg/icons/General/email-read.svg"
																: "/media/svg/icons/General/email-new.svg"
														)}
													/>
												</IconButton>
											</Tooltip>
											<Tooltip title="Delete">
												<IconButton size="small" onClick={() => handleDelete(id)}>
													<SVG
														style={{
															fill: !read ? "#2673F0" : "#4A4A68",
															width: "20px"
														}}
														src={toAbsoluteUrl(
															"/media/svg/icons/General/Trashcan.svg"
														)}
													/>
												</IconButton>
											</Tooltip>
										</div>
										<div className="manage_header_spacing">
											<IconButton size="small">{icon}</IconButton>
										</div>
									</div>
								)

								return (
									<Card
										margin={false}
										key={index}
										className={!read ? "read" : "notRead"}
										onClick={() => handleCardToggle(index)}
									>
										<CardHeader
											title={headerTitle}
											id="header_manage_customer_collapse"
											toolbar={headerToolbar}
											style={{ background: !read ? "#DDE9FD" : "#EFEEEE" }}
											read={read}
											headerForMessaging={true}
											headerForMessagingIcon={
												<SVG
													src={toAbsoluteUrl(
														!read
															? "/media/svg/icons/General/email-new.svg"
															: "/media/svg/icons/General/email.svg"
													)}
												/>
											}
										/>
										<Collapse in={isCardExpanded}>
											<CardBody>{body}</CardBody>
										</Collapse>
									</Card>
								)
							})}
					</div>
				</PerfectScrollbar>
			</div>
		</>
	)
}
export default FirebaseMessagingModalBody
