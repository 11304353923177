import React, { createContext, useContext, useState } from "react"

const UIContext = createContext()

export function useUIContext() {
	return useContext(UIContext)
}

export const UIConsumer = UIContext.Consumer

export function UIProvider({ children }) {
	const [liveData, setLiveData] = useState(false)
	const [resetSnapshot, setResetSnapshot] = useState(false)

	const value = {
		liveData,
		setLiveData,
		resetSnapshot,
		setResetSnapshot
	}

	return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}
