import { firestoreOld, storage } from "../../../firebase"

const customersRef = firestoreOld.collection("Customers")

export function getPeople(queryParams) {
	// Add protection against getPeople being null or undefined
	if (!queryParams || !queryParams.customerId) return Promise.resolve() // Return early without performing any actions
	// Add protection against customerId being empty strings
	if (typeof queryParams.customerId !== "string" || queryParams.customerId === "")
		return Promise.reject(new Error("customerId must be a non-empty string"))

	return firestoreOld
		.collection("Customers")
		.doc(queryParams.customerId)
		.collection("People")
		.get()
}

export function getAssets(queryParams) {
	if (!queryParams || !queryParams.customerId) return Promise.resolve()

	if (typeof queryParams.customerId !== "string" || queryParams.customerId === "")
		return Promise.resolve()

	return firestoreOld
		.collection("Customers")
		.doc(queryParams.customerId)
		.collection("Assets")
		.get()
}

export async function getGlobalOptions() {
	const generalInfo = await firestoreOld.collection("GlobalOptions").doc("GeneralInfo").get()
	const map = await firestoreOld.collection("GlobalOptions").doc("Map").get()
	const defaultAssetTypes = await firestoreOld
		.collection("GlobalOptions")
		.doc("DefaultAssetTypes")
		.get()

	return {
		generalInfo: generalInfo?.data() || null,
		map: map?.data() || null,
		defaultAssetTypes: defaultAssetTypes.data()?.types || []
	}
}

export async function getPeopleWithImages(queryParams) {
	if (!queryParams) return Promise.resolve(null)

	const images = await Promise.allSettled(
		queryParams.people.map(person => {
			const personPicturePath = person?.personalData?.picture?.replace("gs://", "")

			const index = personPicturePath?.indexOf("/")
			const pathWithoutBucket = personPicturePath?.slice(index)
			// console.log("🚀 . pathWithoutBucket", pathWithoutBucket)

			if (!pathWithoutBucket) return Promise.resolve(null)

			return storage
				.ref(pathWithoutBucket)
				.getDownloadURL()
				.catch(err => console.log("Error: ", err))
		})
	)

	return queryParams.people.map((person, index) => {
		return {
			...person,
			picture: images[index]?.status === "fulfilled" ? images[index].value : null
		}
	})
}

export function getFloorPlans(queryParams) {
	if (!queryParams) return Promise.resolve()

	if (!queryParams.siteId) return Promise.resolve()

	return firestoreOld
		.collection("Sites")
		.doc(queryParams.siteId)
		.collection("FloorPlans")
		.orderBy("level", "desc")
		.get()
}

export function createFloorplan(queryParams) {
	const { siteId, floorplanData, selectedCustomer, user } = queryParams

	if (!siteId || !floorplanData) {
		return Promise.resolve(null)
	}

	const reqParams = {
		email: queryParams.user.email,
		userId: queryParams.user.id,
		floorplans: [queryParams?.floorplanData]
	}
	// console.log("🚀  reqParams:", reqParams)
	// console.log("🚀  reqParams:", JSON.stringify(reqParams))

	// Call API function
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")
	var raw = JSON.stringify(reqParams)

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/manageFloorplans/${selectedCustomer.id}/${siteId}`,
		requestOptions
	)
}

export function getAreas(queryParams) {
	if (!queryParams || !queryParams.siteId) return Promise.resolve()

	// if (typeof queryParams.siteId !== "string" || queryParams.siteId === "") return Promise.resolve()

	return firestoreOld.collection("Sites").doc(queryParams.siteId).collection("Areas").get()
}

export function getDepartments(queryParams) {
	if (!queryParams || !queryParams.customerId || !queryParams.userId) return Promise.resolve()

	// if (typeof queryParams.customerId !== "string" || queryParams.customerId === "")
	// 	return Promise.resolve()

	// if (typeof queryParams.userId !== "string" || queryParams.userId === "") return Promise.resolve()

	return Promise.all([
		customersRef
			.doc(queryParams.customerId)
			.collection(`Departments`)
			.where("manager", "==", queryParams.userId)
			.get(),
		customersRef
			.doc(queryParams.customerId)
			.collection(`Departments`)
			.where("employees", "array-contains", queryParams.userId)
			.get()
	])
}

export function getResources(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return customersRef
		.doc(queryParams.customer)
		.collection(`Resources`)
		.where("networkId", "==", queryParams.location)
		.get()
}

const sitesRef = firestoreOld.collection("Sites")

export async function deleteFloorPlan({
	siteId,
	floorplansToDelete,
	areasToDelete,
	poisToDelete,
	user,
	selectedCustomer
}) {
	// console.log("🚀  poisToDelete:", poisToDelete)
	// console.log("🚀  areasToDelete:", areasToDelete)
	// console.log("🚀  floorplansToDelete:", floorplansToDelete)
	if (!siteId || !floorplansToDelete || !user || !selectedCustomer) return Promise.resolve()

	const reqParams = {
		email: user.email,
		userId: user.id,
		floorplans: floorplansToDelete
	}

	// Call API function
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")
	var raw = JSON.stringify(reqParams)

	var requestOptions = {
		method: "DELETE",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return Promise.all([
		areasToDelete &&
			areasToDelete.map(area => {
				return sitesRef.doc(`${siteId}/Areas/${area.id}`).delete()
			}),
		poisToDelete &&
			poisToDelete.map(poi => {
				return sitesRef.doc(`${siteId}/PointsOfInterest/${poi.id}`).delete()
			}),
		fetch(
			`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/manageFloorplans/${selectedCustomer.id}/${siteId}`,
			requestOptions
		)
	])

	// return Promise.all([
	// 	areasToDelete &&
	// 		areasToDelete.map(area => {
	// 			return sitesRef.doc(`${siteId}/Areas/${area.id}`).delete()
	// 		}),
	// 	poisToDelete &&
	// 		poisToDelete.map(poi => {
	// 			return sitesRef.doc(`${siteId}/PointsOfInterest/${poi.id}`).delete()
	// 		}),
	// 	floorplansToDelete &&
	// 		floorplansToDelete.map(floor => {
	// 			return sitesRef.doc(`${siteId}/FloorPlans/${floor.id}`).delete()
	// 		})
	// ])
}

export async function updateFloorPan(queryParams) {
	const { siteId, floorplanData, floorPlanToEditId, selectedCustomer, user, POISToDeleteArray } =
		queryParams

	if (!siteId || !floorplanData) {
		return Promise.resolve(null)
	}

	const reqParams = {
		email: queryParams.user.email,
		userId: queryParams.user.id,
		floorplans: [queryParams?.floorplanData]
	}

	// Call API function
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")
	var raw = JSON.stringify(reqParams)
	// console.log("🚀  raw:", raw)

	var requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/manageFloorplans/${selectedCustomer.id}/${siteId}`,
		requestOptions
	)
}

export function deletePointsOfInterestAfterEditFloorplan({
	floorPlanId,
	siteId,
	POISToDeleteArray
}) {
	if (!siteId || !floorPlanId || !POISToDeleteArray) {
		return Promise.resolve(null)
	}

	POISToDeleteArray.map(val => {
		sitesRef.doc(`${siteId}/PointsOfInterest/${val.id}`).delete()
	})
}

export function getPointsOfInterest(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return sitesRef
		.doc(queryParams.siteId)
		.collection(`PointsOfInterest`)
		.orderBy("created.date")
		.get()
}
