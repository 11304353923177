import React from "react"
import { Route } from "react-router-dom"
import { UIProvider } from "./UIContext"
import { Booking } from "./Booking"
import { CalendarModal } from "./calendar/Calendar"
import { BookModal } from "./book-modal/BookModal"

const mainRoute = "/booking"

export const BookingPage = ({ history }) => {
	const UIEvents = {
		openBookModel: id => {
			history.push(`${mainRoute}/add-booking`)
		},
		openBookModelWithId: id => {
			history.push(`${mainRoute}/${id}/add-booking`)
		},
		openBookModelWithBookingId: bookingId => {
			history.push(`${mainRoute}/${bookingId}/edit-booking`)
		}
	}

	return (
		<UIProvider UIEvents={UIEvents}>
			<Route path={`${mainRoute}/add-booking`}>
				{({ history, match }) => (
					<BookModal
						show={match != null}
						onHide={() => {
							history.push(mainRoute)
						}}
					/>
				)}
			</Route>
			<Route path={`${mainRoute}/:id/add-booking`}>
				{({ history, match }) => (
					<BookModal
						show={match != null}
						id={match && match.params.id}
						onHide={() => {
							history.push(mainRoute)
						}}
					/>
				)}
			</Route>
			<Route path={`${mainRoute}/:bookingId/edit-booking`}>
				{({ history, match }) => (
					<BookModal
						show={match != null}
						bookingId={match && match.params.bookingId}
						onHide={() => {
							history.push(mainRoute)
						}}
					/>
				)}
			</Route>
			<Booking />
		</UIProvider>
	)
}
