import React from "react"
import GlobalInfo from "./_partials/GlobalInfo"
import { SvgIcon } from "@material-ui/core"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"

const SensorsPageInfoTopRow = ({ isFetching, anchorCounts, allTotalCount }) => {
	return (
		<div className="mt-3" data-testid="anchors-positioning-top-row-info-page">
			{/* titles */}
			<div className="row" id="top_row_anchors_page_desktop">
				<div className="col-5 d-flex" style={{ marginRight: "15px", paddingRight: "0" }}>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M4.5 22.5H9V21H4.5V3H16.5V10.5H18V3C17.9996 2.6023 17.8414 2.22101 17.5602 1.9398C17.279 1.65859 16.8977 1.50042 16.5 1.5H4.5C4.1023 1.50042 3.72101 1.65859 3.4398 1.9398C3.15859 2.22101 3.00042 2.6023 3 3V21C3.00042 21.3977 3.15859 21.779 3.4398 22.0602C3.72101 22.3414 4.1023 22.4996 4.5 22.5ZM6 4.5H15V6H6V4.5ZM6 7.5H15V9H6V7.5ZM10.5 10.5H6V12H10.5V10.5ZM6 18H9V19.5H6V18ZM22.2803 14.4698L20.0303 12.2198C19.9606 12.1501 19.878 12.0948 19.787 12.0571C19.696 12.0194 19.5985 12 19.5 12C19.4015 12 19.304 12.0194 19.213 12.0571C19.122 12.0948 19.0394 12.1501 18.9697 12.2198L12 19.1895V22.5H15.3106L22.2803 15.5303C22.3499 15.4607 22.4052 15.378 22.4429 15.287C22.4806 15.196 22.5 15.0985 22.5 15C22.5 14.9015 22.4806 14.804 22.4429 14.713C22.4052 14.622 22.3499 14.5394 22.2803 14.4698ZM14.6894 21H13.5V19.8106L17.25 16.0606L18.4394 17.25L14.6894 21ZM19.5 16.1895L18.3106 15L19.5 13.8106L20.6894 15L19.5 16.1895Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Planning</b>
					</h2>
				</div>
				<div className="col d-flex" style={{ marginRight: "15px", paddingRight: "0" }}>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M8.5575 10.5L10.5 12.4425L15.4425 7.5L16.5 8.5575L10.5 14.5575L7.5 11.5575L8.5575 10.5Z"
								fill="#4A4A68"
							/>
							<path
								d="M7.36815 20.0303L12 22.5L16.6319 20.0303C17.9524 19.3278 19.0567 18.279 19.8263 16.9964C20.5959 15.7138 21.0016 14.2458 21 12.75V3C20.9996 2.6023 20.8414 2.22101 20.5602 1.9398C20.279 1.65859 19.8977 1.50042 19.5 1.5H4.5C4.10231 1.50042 3.72102 1.65859 3.43981 1.9398C3.15859 2.22101 3.00042 2.6023 3 3V12.75C2.9984 14.2458 3.40415 15.7138 4.17372 16.9964C4.94328 18.279 6.0476 19.3278 7.36815 20.0303ZM4.5 12.75V3H19.5V12.75C19.5013 13.9739 19.1693 15.175 18.5396 16.2244C17.9099 17.2738 17.0063 18.132 15.9258 18.7067L12 20.8L8.0742 18.7067C6.9937 18.132 6.0901 17.2738 5.46041 16.2244C4.83072 15.175 4.49871 13.9739 4.5 12.75Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Approval</b>
					</h2>
				</div>
				<div className="col d-flex" style={{ marginRight: "15px", paddingRight: "0" }}>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12.75 6H11.25V14.25H12.75V6ZM11.375 16.6896C11.56 16.566 11.7775 16.5 12 16.5C12.2984 16.5 12.5845 16.6185 12.7955 16.8295C13.0065 17.0405 13.125 17.3266 13.125 17.625C13.125 17.8475 13.059 18.065 12.9354 18.25C12.8118 18.435 12.6361 18.5792 12.4305 18.6644C12.225 18.7495 11.9988 18.7718 11.7805 18.7284C11.5623 18.685 11.3618 18.5778 11.2045 18.4205C11.0472 18.2632 10.94 18.0627 10.8966 17.8445C10.8532 17.6262 10.8755 17.4 10.9606 17.1945C11.0458 16.9889 11.19 16.8132 11.375 16.6896Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Others</b>
					</h2>
				</div>
			</div>

			{/* lines */}
			{isFetching && (
				<ModalProgressBar
					variant="query"
					date-testid="modal_progress_bar"
					style={{ width: "101%", marginBottom: "1.5rem" }}
				/>
			)}
			{!isFetching && (
				<div
					id="top_row_anchors_page_desktop"
					className="row"
					style={{ marginBottom: "1.5rem" }}
				>
					<div className="col-5" style={{ marginRight: "15px", paddingRight: "0" }}>
						<div
							style={{
								width: "100%",
								height: "4px",
								borderRadius: "1px",
								background: "var(--Utilitarian-Neutrals-Grey, #E4E4E8)"
							}}
						></div>
					</div>
					<div className="col" style={{ marginRight: "15px", paddingRight: "0" }}>
						<div
							style={{
								width: "100%",
								height: "4px",
								borderRadius: "1px",
								background: "var(--navigation-blue-blue-dde-9-fd, #DDE9FD)"
							}}
						></div>
					</div>
					<div className="col" style={{ paddingRight: "0" }}>
						<div
							style={{
								width: "100%",
								height: "4px",
								borderRadius: "1px",
								background: "var(--Utilitarian-Red-20, #FBE6DE)"
							}}
						></div>
					</div>
				</div>
			)}
			{/* boxes */}
			<div id="top_row_anchors_page_desktop" className="row" style={{ marginBottom: "3rem" }}>
				<GlobalInfo
					isDesktop={true}
					value={anchorCounts.assigned || 0}
					text="Assigned sensors"
					icon="/markers/sensor.svg"
					color="#8C8CA1"
					total={allTotalCount?.assigned || 0}
					newBorderRadius="50%"
				/>
				<GlobalInfo
					isDesktop={true}
					value={anchorCounts.planned || 0}
					text="Planned sensors"
					icon="/markers/sensor.svg"
					color="#8c8ca1"
					total={allTotalCount?.planned || 0}
					newBorderRadius="4px"
				/>
				<GlobalInfo
					isDesktop={true}
					value={anchorCounts.placed || 0}
					text="Placed sensors"
					icon="/markers/sensor.svg"
					color="#6CA4FF"
					total={allTotalCount?.placed || 0}
					newBorderRadius="4px"
				/>
				<GlobalInfo
					isDesktop={true}
					value={anchorCounts.approved || 0}
					text="Approved sensors"
					icon="/markers/sensor.svg"
					color="#3B7E38"
					total={allTotalCount?.approved || 0}
					newBorderRadius="50%"
				/>
				<GlobalInfo
					isDesktop={true}
					value={anchorCounts.online || 0}
					text="Online sensors"
					icon="/markers/sensor.svg"
					color="#2649AA"
					total={allTotalCount?.online || 0}
					newBorderRadius="50%"
				/>
				<GlobalInfo
					isDesktop={true}
					value={anchorCounts.lost || 0}
					text="Lost sensors"
					icon="/markers/sensor.svg"
					color="#A43912"
					total={allTotalCount?.lost || 0}
					newBorderRadius="50%"
				/>
				<GlobalInfo
					isDesktop={true}
					value={anchorCounts.removed || 0}
					text="Removed sensors"
					icon="/markers/sensor.svg"
					color="#ff0000"
					total={allTotalCount?.removed || 0}
					newBorderRadius="50%"
				/>
			</div>

			{/* mobile view */}
			<div className="row" id="top_row_anchors_page_mobile">
				<div className="col d-flex" style={{ marginRight: "15px", paddingRight: "0" }}>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M4.5 22.5H9V21H4.5V3H16.5V10.5H18V3C17.9996 2.6023 17.8414 2.22101 17.5602 1.9398C17.279 1.65859 16.8977 1.50042 16.5 1.5H4.5C4.1023 1.50042 3.72101 1.65859 3.4398 1.9398C3.15859 2.22101 3.00042 2.6023 3 3V21C3.00042 21.3977 3.15859 21.779 3.4398 22.0602C3.72101 22.3414 4.1023 22.4996 4.5 22.5ZM6 4.5H15V6H6V4.5ZM6 7.5H15V9H6V7.5ZM10.5 10.5H6V12H10.5V10.5ZM6 18H9V19.5H6V18ZM22.2803 14.4698L20.0303 12.2198C19.9606 12.1501 19.878 12.0948 19.787 12.0571C19.696 12.0194 19.5985 12 19.5 12C19.4015 12 19.304 12.0194 19.213 12.0571C19.122 12.0948 19.0394 12.1501 18.9697 12.2198L12 19.1895V22.5H15.3106L22.2803 15.5303C22.3499 15.4607 22.4052 15.378 22.4429 15.287C22.4806 15.196 22.5 15.0985 22.5 15C22.5 14.9015 22.4806 14.804 22.4429 14.713C22.4052 14.622 22.3499 14.5394 22.2803 14.4698ZM14.6894 21H13.5V19.8106L17.25 16.0606L18.4394 17.25L14.6894 21ZM19.5 16.1895L18.3106 15L19.5 13.8106L20.6894 15L19.5 16.1895Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Planning</b>
					</h2>
				</div>
			</div>
			<div id="top_row_anchors_page_mobile" className="row" style={{ marginBottom: "1.5rem" }}>
				<div className="col" style={{ marginRight: "15px", paddingRight: "0" }}>
					<div
						style={{
							width: "100%",
							height: "4px",
							borderRadius: "1px",
							background: "var(--Utilitarian-Neutrals-Grey, #E4E4E8)"
						}}
					></div>
				</div>
			</div>
			<div id="top_row_anchors_page_mobile" className="row">
				<GlobalInfo
					value={anchorCounts.assigned || 0}
					text="Assigned sensors"
					icon="/markers/sensor.svg"
					color="#8C8CA1"
					total={allTotalCount?.assigned || 0}
					newBorderRadius="50%"
					isMobile={true}
				/>
				<GlobalInfo
					value={anchorCounts.planned || 0}
					text="Planned sensors"
					icon="/markers/sensor.svg"
					color="#8C8CA1"
					total={allTotalCount?.planned || 0}
					newBorderRadius="4px"
					isMobile={true}
				/>
				<GlobalInfo
					value={anchorCounts.placed || 0}
					text="Placed sensors"
					icon="/markers/sensor.svg"
					color="#6CA4FF"
					total={allTotalCount?.placed || 0}
					newBorderRadius="4px"
				/>
			</div>

			<div className="row" id="top_row_anchors_page_mobile" style={{ marginTop: "3rem" }}>
				<div className="col d-flex" style={{ marginRight: "15px", paddingRight: "0" }}>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M8.5575 10.5L10.5 12.4425L15.4425 7.5L16.5 8.5575L10.5 14.5575L7.5 11.5575L8.5575 10.5Z"
								fill="#4A4A68"
							/>
							<path
								d="M7.36815 20.0303L12 22.5L16.6319 20.0303C17.9524 19.3278 19.0567 18.279 19.8263 16.9964C20.5959 15.7138 21.0016 14.2458 21 12.75V3C20.9996 2.6023 20.8414 2.22101 20.5602 1.9398C20.279 1.65859 19.8977 1.50042 19.5 1.5H4.5C4.10231 1.50042 3.72102 1.65859 3.43981 1.9398C3.15859 2.22101 3.00042 2.6023 3 3V12.75C2.9984 14.2458 3.40415 15.7138 4.17372 16.9964C4.94328 18.279 6.0476 19.3278 7.36815 20.0303ZM4.5 12.75V3H19.5V12.75C19.5013 13.9739 19.1693 15.175 18.5396 16.2244C17.9099 17.2738 17.0063 18.132 15.9258 18.7067L12 20.8L8.0742 18.7067C6.9937 18.132 6.0901 17.2738 5.46041 16.2244C4.83072 15.175 4.49871 13.9739 4.5 12.75Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Approval</b>
					</h2>
				</div>
				<div
					className="col d-none d-sm-flex"
					style={{ marginRight: "15px", paddingRight: "0" }}
				>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12.75 6H11.25V14.25H12.75V6ZM11.375 16.6896C11.56 16.566 11.7775 16.5 12 16.5C12.2984 16.5 12.5845 16.6185 12.7955 16.8295C13.0065 17.0405 13.125 17.3266 13.125 17.625C13.125 17.8475 13.059 18.065 12.9354 18.25C12.8118 18.435 12.6361 18.5792 12.4305 18.6644C12.225 18.7495 11.9988 18.7718 11.7805 18.7284C11.5623 18.685 11.3618 18.5778 11.2045 18.4205C11.0472 18.2632 10.94 18.0627 10.8966 17.8445C10.8532 17.6262 10.8755 17.4 10.9606 17.1945C11.0458 16.9889 11.19 16.8132 11.375 16.6896Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Others</b>
					</h2>
				</div>
			</div>
			<div id="top_row_anchors_page_mobile" className="row" style={{ marginBottom: "1.5rem" }}>
				<div className="col" style={{ marginRight: "15px", paddingRight: "0" }}>
					<div
						style={{
							width: "100%",
							height: "4px",
							borderRadius: "1px",
							background: "var(--navigation-blue-blue-dde-9-fd, #DDE9FD)"
						}}
					></div>
				</div>
				<div
					className="col d-none d-sm-flex"
					style={{ marginRight: "15px", paddingRight: "0" }}
				>
					<div
						style={{
							width: "100%",
							height: "4px",
							borderRadius: "1px",
							background: "var(--Utilitarian-Red-20, #FBE6DE)"
						}}
					></div>
				</div>
			</div>
			<div id="top_row_anchors_page_mobile" className="row " style={{ marginBottom: "3rem" }}>
				<GlobalInfo
					value={anchorCounts.approved || 0}
					text="Approved sensors"
					icon="/markers/sensor.svg"
					color="#2649AA"
					total={allTotalCount?.approved || 0}
					newBorderRadius="50%"
					isMobile={true}
				/>
				<GlobalInfo
					value={anchorCounts.online || 0}
					text="Online sensors"
					icon="/markers/sensor.svg"
					color="#2649AA"
					total={allTotalCount?.online || 0}
					newBorderRadius="50%"
					isMobile={true}
				/>
				<GlobalInfo
					value={anchorCounts.lost || 0}
					text="Lost sensors"
					icon="/markers/sensor.svg"
					color="#E65019"
					total={allTotalCount?.lost || 0}
					newBorderRadius="50%"
					isMobile={true}
					className="col d-none d-sm-flex justify-content-between align-items-start"
				/>
				<GlobalInfo
					value={anchorCounts.removed || 0}
					text="Removed sensors"
					icon="/markers/sensor.svg"
					color="#ff0000"
					total={allTotalCount?.removed || 0}
					newBorderRadius="50%"
					className="col d-none d-sm-flex justify-content-between align-items-start"
				/>
			</div>

			<div
				className="row d-flex d-sm-none force-no-padding"
				id="top_row_anchors_page_mobile"
				style={{ marginTop: "3rem" }}
			>
				<div className="col d-flex" style={{ marginRight: "15px", paddingRight: "0" }}>
					<SvgIcon style={{ height: "2rem", width: "2rem", marginRight: "12px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M12 1.5C9.9233 1.5 7.89323 2.11581 6.16652 3.26957C4.4398 4.42332 3.09399 6.0632 2.29927 7.98182C1.50455 9.90045 1.29661 12.0116 1.70176 14.0484C2.1069 16.0852 3.10693 17.9562 4.57538 19.4246C6.04383 20.8931 7.91476 21.8931 9.95156 22.2982C11.9884 22.7034 14.0996 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12.75 6H11.25V14.25H12.75V6ZM11.375 16.6896C11.56 16.566 11.7775 16.5 12 16.5C12.2984 16.5 12.5845 16.6185 12.7955 16.8295C13.0065 17.0405 13.125 17.3266 13.125 17.625C13.125 17.8475 13.059 18.065 12.9354 18.25C12.8118 18.435 12.6361 18.5792 12.4305 18.6644C12.225 18.7495 11.9988 18.7718 11.7805 18.7284C11.5623 18.685 11.3618 18.5778 11.2045 18.4205C11.0472 18.2632 10.94 18.0627 10.8966 17.8445C10.8532 17.6262 10.8755 17.4 10.9606 17.1945C11.0458 16.9889 11.19 16.8132 11.375 16.6896Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
					<h2 style={{ color: "#4A4A68" }}>
						<b>Others</b>
					</h2>
				</div>
			</div>
			<div
				id="top_row_anchors_page_mobile"
				className="row d-block d-sm-none force-no-padding"
				style={{ marginBottom: "1.5rem" }}
			>
				<div className="col" style={{ marginRight: "15px", paddingRight: "0" }}>
					<div
						style={{
							width: "100%",
							height: "4px",
							borderRadius: "1px",
							background: "var(--Utilitarian-Red-20, #FBE6DE)"
						}}
					></div>
				</div>
			</div>
			<div
				id="top_row_anchors_page_mobile"
				className="row d-flex d-sm-none"
				style={{ marginBottom: "3rem" }}
			>
				<GlobalInfo
					value={anchorCounts.lost || 0}
					text="Lost sensors"
					icon="/markers/sensor.svg"
					color="#E65019"
					total={allTotalCount?.lost || 0}
					newBorderRadius="50%"
					isMobile={true}
				/>
				<GlobalInfo
					value={anchorCounts.removed || 0}
					text="Removed sensors"
					icon="/markers/sensor.svg"
					color="#ff0000"
					total={allTotalCount?.removed || 0}
					newBorderRadius="50%"
				/>
			</div>
		</div>
	)
}

export default SensorsPageInfoTopRow
