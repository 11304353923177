import React from "react"
import "./Header.css"
import HeaderDropdownOnMobile from "./HeaderDropdownOnMobile"
import Button from "@material-ui/core/Button"

import { useSelector, shallowEqual } from "react-redux"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"

export function Header({ page, setPage }) {
	const { userClaims } = useSelector(
		state => ({
			userClaims: state.auth.claims
		}),
		shallowEqual
	)
	return (
		<div style={{ marginBottom: "40px" }}>
			<div className="row justify-content-center">
				{/* header title */}
				<div className="col d-flex align-items-center">
					<div
						style={{
							width: "30px",
							height: "30px",
							marginRight: "12px",
							marginBottom: "10px"
						}}
					>
						<SVG
							src={toAbsoluteUrl("/media/svg/icons/General/manage-infra-icon.svg")}
							style={{
								height: "2.5rem",
								width: "2.5rem",
								fill: "#4A4A68"
							}}
						/>
					</div>

					<h2 style={{ color: "#4A4A68" }}>
						<b>Manage Infrastructure</b>
					</h2>
				</div>

				{/* desktop menu */}
				<div className="d-none d-sm-flex">
					<Button
						color="primary"
						variant={page == "network" ? "contained" : "outlined"}
						onClick={() => setPage("network")}
						style={{
							borderRadorderRight: "none",
							borderTopRightRadius: "0",
							borderBottomRightRadius: "0",
							borderColor: page == "network" ? "#2673F0" : "#8C8CA1"
						}}
						startIcon={
							<SVG
								src={toAbsoluteUrl(
									"/media/svg/icons/General/network-manage-infra-icon.svg"
								)}
								style={{
									height: "2rem",
									width: "2rem",
									fill: page === "network" ? "#FFFFFF" : "#8C8CA1"
								}}
							/>
						}
						data-testid="networkButton"
					>
						<span
							style={{
								color: page == "network" ? "#FFFFFF" : "#8C8CA1",
								textAlign: "center",
								fontFamily: "Poppins",
								fontSize: "16px",
								fontStyle: "normal",
								fontWeight: "700",
								lineHeight: "normal",
								letterSpacing: "0.64px",
								textTransform: "uppercase",
								marginLeft: "10px"
							}}
						>
							Network
						</span>
					</Button>
					<Button
						color="primary"
						variant={page == "sensors" ? "contained" : "outlined"}
						onClick={() => setPage("sensors")}
						style={{
							borderRadorderLeft: "none",
							borderTopLeftRadius: "0",
							borderBottomLeftRadius: "0",
							borderColor: page == "sensors" ? "#2673F0" : "#8C8CA1"
						}}
						startIcon={
							<SVG
								src={toAbsoluteUrl(
									"/media/svg/icons/General/sensors-manage-infra-icon.svg"
								)}
								style={{
									height: "2rem",
									width: "2rem",
									fill: page === "sensors" ? "#FFFFFF" : "#8C8CA1"
								}}
							/>
						}
						data-testid="sensorsButton"
					>
						<span
							style={{
								color: page == "sensors" ? "#FFFFFF" : "#8C8CA1",
								textAlign: "center",
								fontFamily: "Poppins",
								fontSize: "16px",
								fontStyle: "normal",
								fontWeight: "700",
								lineHeight: "normal",
								letterSpacing: "0.64px",
								textTransform: "uppercase",
								marginLeft: "10px"
							}}
						>
							Sensors
						</span>
					</Button>
				</div>

				{/* mobile menu */}
				<div className="d-flex d-sm-none justify-content-center m-5">
					<div className="header_dropdown_mobile_view">
						<HeaderDropdownOnMobile page={page} setPage={setPage} />
					</div>
				</div>
			</div>
		</div>
	)
}
