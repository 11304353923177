import React from "react"
import { makeStyles, TextField, FormHelperText, Button } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
	textField: {
		//  marginTop: "2px",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		margin: 0,
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		// -------------- \\
		// --- Filled --- \\
		"& .MuiFilledInput-root": {
			backgroundColor: "#ECF1F4",
			// backgroundColor: "#F2F2F2",
			borderColor: "rgba(0,0,0,0)",
			// borderRadius: "4px"
			borderRadius: "5px"
		},
		"& .MuiFilledInput-inputMarginDense": {
			padding: "0 15px",
			height: "40px",
			verticalAlign: "middle"
		},
		"& .MuiFilledInput-multiline.MuiFilledInput-marginDense ": {
			padding: "12px"
		},
		// -------------------- \\
		// --- Autocomplete --- \\
		"& .MuiAutocomplete-inputRoot": {
			backgroundColor: "#ECF1F4",
			borderColor: "rgba(0,0,0,0)",
			borderRadius: "5px"
		}
	},
	textFieldError: {
		border: "1px solid #F64E60"
	},
	textFieldDefaultSize: {
		"& .MuiInputBase-root": {
			height: "45px",
			padding: "0px 12px",
			display: "flex",
			alignItems: "center"
		},
		"& .MuiInputBase-input": {
			padding: "0"
		}
	},
	helperText: {
		margin: 0,
		color: theme.palette.error.main
	},
	textFieldWithAppend: {
		marginTop: "2px",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		margin: 0,
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		},
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0
	},
	button: {
		marginTop: "2px",
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		textTransform: "none",
		float: "right",
		backgroundColor: "#3077d3",
		color: "#f4f4f4",
		padding: "5px",
		fontWeight: "600",
		"&:hover": {
			backgroundColor: "#5d95dc",
			color: "#f4f4f4"
		},
		"&:active": {
			backgroundColor: "#6d9fe0",
			color: "#f4f4f4"
		}
	},
	label: {
		color: "#4A4A68",
		// color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "12px",
		// fontSize: "13px",
		fontWeight: "500"
	},
	//
	// old CSS
	// button: {
	//   marginTop: "2px",
	//   borderTopLeftRadius: 0,
	//   borderBottomLeftRadius: 0,
	//   textTransform: "none",
	//   float: "right",
	//   backgroundColor: "#C3C4DD",
	//   color: "#323389",
	//   padding: "5px",
	//   "&:hover": {
	//     backgroundColor: "#CECEE3",
	//     color: "#323389",
	//   },
	//   "&:active": {
	//     backgroundColor: "#ADADD0",
	//     color: "#323389",
	//   },
	// },
	newClassName: {
		padding: "10rem"
	}
}))

export function Input({
	field,
	form,
	label,
	newId,
	withFeedbackLabel = true,
	appendButton,
	size,
	customError,
	requiredField,
	noLabelButMargin,
	labelFullWidth,
	inputFullWidth,
	type,
	...props
}) {
	if (field && form) {
		var { name, value } = field
		var { touched, errors, setFieldValue, setFieldTouched } = form
	}
	const classes = useStyles()
	const error = withFeedbackLabel && touched && touched[name] && errors && errors[name]

	return (
		<>
			{label && (
				<div style={{ marginBottom: "10px", width: labelFullWidth && "95%" }}>
					<span className={classes.label}>
						{label}
						{requiredField ? <sup style={{ color: "red" }}> *</sup> : ""}
					</span>
				</div>
			)}
			{noLabelButMargin && (
				<div style={{ marginBottom: "10px", height: "18px" }}>
					<span className={classes.label}></span>
				</div>
			)}
			<div style={{ display: "flex", flexDirection: "row", width: inputFullWidth && "95%" }}>
				<TextField
					error={error ? true : customError ? true : false}
					fullWidth
					variant="outlined"
					size={size}
					className={clsx(
						classes.textField,
						(error || customError) && classes.textFieldError,
						!size && classes.textFieldDefaultSize,
						appendButton && classes.textFieldWithAppend
					)}
					id={newId}
					// InputProps={{ disableUnderline: true }}
					name={name}
					value={value || value === 0 ? value : ""}
					onBlur={() => {
						if (!touched && !setFieldTouched) {
							return
						}
						/*!touched[name] so it doesn't set touched true when it's already true*/
						/*Without this errors will always be one update behind the real value*/
						!touched[name] && setFieldTouched(name, true)
					}}
					onChange={e => {
						if (!touched && !setFieldTouched && !setFieldValue) {
							return
						}
						!touched[name] && setFieldTouched(name, true)
						setFieldValue(name, e.target.value)
					}}
					{...props}
				/>
				{appendButton && (
					<Button
						style={{ width: props.width ? props.width : "100%" }}
						disableElevation
						variant="contained"
						className={classes.button}
						onClick={appendButton.onClick || null}
					>
						<b
							style={{
								width: "100%",
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis"
							}}
						>
							{appendButton.text}
						</b>
					</Button>
				)}
			</div>
			{(error || customError) && (
				<FormHelperText className={classes.helperText}>
					{(errors && errors[name]) || customError}
				</FormHelperText>
			)}
		</>
	)
}
