import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	error: null,
	lastError: null,
	floorPlans: []
}
export const callTypes = {
	list: "list",
	action: "action"
}

export const basePageSlice = createSlice({
	name: "basePage",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		//* PEOPLE SLICE
		peopleFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.people = action.payload
		},
		personCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.people = state.people ? [...state.people, action.payload] : state.people
		},
		personNotCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
		},
		personEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.people =
				state.people &&
				state.people.map(entity => {
					if (entity.id === action.payload.id) return action.payload
					return entity
				})
		},
		personDeleted: (state, action) => {
			state.actionsLoading = false
			state.error = null
			// state.people = state.people.filter(val => val.id !== action.payload)
		},
		peopleDisabled: (state, action) => {
			// console.log("🚀 ~ file: basePageSlice.js:62 ~ action:", action)
			state.actionsLoading = false
			state.error = null
			// state.people =
			// 	state.people &&
			// 	state.people.map(entity => {
			// 		const findPeopleToDisable = action.payload.peopleToDisable.find(
			// 			element => element.id === entity.id
			// 		)
			// 		return {
			// 			...entity,
			// 			disabled: findPeopleToDisable ? findPeopleToDisable.disabled : entity.disabled
			// 		}
			// 	})
		},
		//* ASSETS SLICE
		assetsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.assets = action.payload
		},
		assetCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
		},
		assetEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null
		},
		assetDeleted: (state, action) => {
			state.actionsLoading = false
			state.error = null
		},
		assetsDisabled: (state, action) => {
			state.actionsLoading = false
			state.error = null
		},
		assetTypesFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.assetTypes = action.payload
		},
		setTagSeenTimeout: (state, action) => {
			state.listLoading = false
			state.error = null
			state.tagSeenTimeout = action.payload
		},
		globalOptionsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.globalOptions = action.payload
		},
		floorPlansFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.floorPlans = action.payload
		},
		floorplanCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.floorPlans = state.floorPlans
				? [...state.floorPlans, action.payload]
				: state.floorPlans
		},
		areasFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.areas = action.payload
		},
		departmentFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.department = action.payload
		},
		resourcesFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.resources = entities
		},
		bookingsFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.bookings = entities
		},
		updatedFloorPlan: (state, action) => {
			// const { entities } = action.payload;
			state.listLoading = false
			state.error = null
			state.floorPlans = state.floorPlans.map(f => {
				if (f.id === action.payload.id) return action.payload
				return f
			})
		},
		deletedFloorplan: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.floorPlans = state.floorPlans.filter(val => val.id !== action.payload)
		},
		floorplanUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.floorPlans =
				state.floorPlans &&
				state.floorPlans.map(entity => {
					if (entity.id === action.payload.id) {
						return action.payload
					}
					return entity
				})
		},
		// deletedFloorplanArray: (state, action) => {
		//   state.actionsLoading = false;
		//   state.error = null;
		//   state.floorPlans = state.floorPlans.filter(
		//     (val) => !action.payload.includes(val.id)
		//   );
		//   console.log(state.floorPlans, " state.floorPlans ----- deletedFloorplan");
		// },
		pointsOfInterestFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.pointsOfInterest = entities
		}
	}
})
