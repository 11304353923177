import React, { useState, useEffect, useMemo, useRef } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { useUIContext } from "../UIContext"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import * as actionHelpers from "./_helpers/ActionHelpers"
import { addMeetingRoomZones, addMeetingRoomMarkers } from "./_helpers/ActionHelpers"
import ReactDOM from "react-dom"
import { OldDate } from "../../../_partials/inputs/OldDate"
import moment from "moment"
import * as THREE from "three"
import { Threebox } from "threebox-plugin"
import PitchToggle from "./PitchToggle"

export default function MapWidget() {
	const mapContainerRef = useRef(null)

	//━━━ Get data from redux ━━━\\
	const { floorPlanData, selectedLevel, bookings, resources, zones } = useSelector(
		state => ({
			floorPlanData: state.basePage?.floorPlans,
			selectedLevel: state.profile?.currentLevel?.floorId,
			bookings: state.booking?.bookings,
			resources: state.booking?.resources,
			zones: state.basePage?.zones
		}),
		shallowEqual
	)

	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			mapDate: UIContext.mapDate,
			setMapDate: UIContext.setMapDate,
			setMapSelectedResource: UIContext.setMapSelectedResource,
			openBookModel: UIContext.openBookModel
		}
	}, [UIContext])

	const [map, setMap] = useState()
	const [markers, setMarkers] = useState()
	const [merakiImage, setMerakiImage] = useState(true)
	const [straightMap, setStraightMap] = useState(false)
	const [meetingRoomMarkers, setMeetingRoomMarkers] = useState()
	const [date, setDate] = useState(UIProps.mapDate)

	const ctrlPolygon = new MapboxGLButtonControl({
		date: date,
		setDate: setDate
	})

	useEffect(() => {
		ctrlPolygon.update(date)
		UIProps.setMapDate(date)
	}, [date])

	{
		/* UseEffect */
	}
	useEffect(() => {
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: "mapbox://styles/mapbox/streets-v11",
			pitch: 55, // ← 3D or 2D view toggle (if not comment map starts with 3D view)
			maxZoom: 24
		})
		map.on("load", function () {
			map.addControl(new PitchToggle({ minpitchzoom: 11 }), "top-left")
			map.addControl(new mapboxgl.FullscreenControl(), "top-left")
			map.addControl(ctrlPolygon, "top-right")
			setMap(map)
		})

		window.tb = new Threebox(map, map.getCanvas().getContext("webgl"), {
			enableSelectingObjects: true,
			multiLayer: true
		})

		// three.js lights to illuminate all map and the model
		var directionalLight = new THREE.DirectionalLight(0x021212, 0.4)
		directionalLight.position.set(0, -70, 100).normalize()
		window.tb.scene.add(directionalLight)

		var directionalLight2 = new THREE.DirectionalLight(0xffffff, 1)
		directionalLight2.position.set(0, 70, 100).normalize()
		window.tb.scene.add(directionalLight2)

		var light = new THREE.DirectionalLight(0x5d6666, 1)
		light.position.set(0, 2, 2)
		light.target.position.set(0, 0, 0)
		light.castShadow = true
		window.tb.scene.add(light)

		map.on("zoom", e => {
			window.tb.scene.children[0].children.forEach(model => {
				/* //if (!window.tb.raycaster?.camera?.projectionMatrix) { return }
            const camInverseProjection = new THREE.Matrix4().copy(window.tb.camera.projectionMatrix).invert()
            const cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection)

            //console.log("Camera: ", cameraPosition)
            //console.log("Camera: ", window.tb.camera.position)
            // Create a Vec3 that holds the label's position
            var start = new THREE.Vector3()
            start.copy(model.label.position)

            // Create a var that holds the distance to the camera
            var dist = start.distanceTo(cameraPosition)

            // Apply falloff, then turn that into the label's scale
            var size = 1 / dist
            // console.log("Dist: ", dist)
            // console.log("Size: ", size)
            // console.log("Size: ", size * 3)
            var size2 = 1 / e.target.getZoom()

            const speed = (80 / e.target.getZoom()) ^ (1 / 2)
            //console.log("getZoom: ", e.target.getZoom())
            //console.log("speed: ", speed)
            var size3 = 1 / speed

            const size4 = e.target.getZoom()
            //const size4 = 1 / test
            // console.log("getZoom: ", e.target.getZoom())
            // console.log("size4: ", size4)


            model.label.element.children[0].style.transform = `scale(${size * 3})`
            //console.log("Scale: ", size2 * 3)
            // console.log("Scale: ", `scale(${size * 10})`)

             console.log("model: ", model) */
			})
			// console.log("Event: ", e.target)
			// console.log("three: ", window.tb)
			//console.log("objects: ", window.tb.scene.children[0].children)
		})
		/* map.on('mousemove', e => {
         test()
         //boxLayer.raycast(e.point, false)
       })
       
       map.on('click', e => {
         //boxLayer.raycast(e.point, true)
       }) */
		return () => map.remove()
	}, [])

	useEffect(() => {
		actionHelpers.addFloorPlanToMap({
			map: map,
			floorPlans: floorPlanData,
			selectedFloor: selectedLevel
		})
	}, [map, floorPlanData, selectedLevel])

	useEffect(() => {
		actionHelpers.mapFitBounds({
			map: map,
			floorPlans: floorPlanData,
			selectedFloor: selectedLevel,
			merakiImage: merakiImage,
			straightMap: straightMap
		})
	}, [map, floorPlanData, selectedLevel, merakiImage, straightMap])

	useEffect(() => {
		if (!map) {
			return
		}

		window.tb.world.children &&
			window.tb.world.children.forEach(o => {
				// ← removing desks on selecting diferent level
				// console.log(o, "o")
				// ↓ Apaga as desks do mapa
				o.dispose()
				if (o.layer) {
					// console.log(o.layer, "o.layer no widget")
					// window.tb.removeLayer(o.layer)
					// ↓ This method removes any children from tb.world.
					window.tb.clear()
				}
			})
	}, [map, selectedLevel, bookings])

	useEffect(() => {
		actionHelpers.add3DDesksToMap({
			map: map,
			resources: resources,
			selectedFloor: selectedLevel,
			markers: markers,
			setMarkers: setMarkers,
			openBookModel: UIProps.openBookModel,
			setMapSelectedResource: UIProps.setMapSelectedResource,
			date: UIProps.mapDate,
			bookings: bookings || []
		})
	}, [map, resources, selectedLevel, UIProps.mapDate, bookings])

	//★━━━━━━━━★ Meeting Room Zones ★━━━━━━━━★\\
	useEffect(() => {
		if (!map || !resources || !zones) {
			return
		}
		addMeetingRoomZones({ map, zones, resources, selectedLevel })
	}, [map, zones, resources, selectedLevel])

	//★━━━━━━━━★ Meeting Room Markers ★━━━━━━━━★\\
	useEffect(() => {
		if (!map) {
			return
		}
		meetingRoomMarkers && meetingRoomMarkers.forEach(marker => marker.remove())
		if (!resources || !zones) {
			return
		}
		const filteredResources = resources.filter(val => {
			return val.floorPlanId === selectedLevel && val.type === "meeting"
		})
		const newMeetingRoomMarkers = addMeetingRoomMarkers({
			map,
			zones,
			resources: filteredResources,
			bookings,
			date: UIProps.mapDate,
			openBookModal: UIProps.openBookModel,
			setMapSelectedResource: UIProps.setMapSelectedResource
		})
		setMeetingRoomMarkers(newMeetingRoomMarkers)
	}, [map, selectedLevel, zones, resources, bookings, UIProps.mapDate])

	/* useEffect(() => {
    if (map && resources) {
      const filteredResources = resources.filter((val) => val.floorPlanId === selectedLevel)

      filteredResources.forEach((resource) => {
        actionHelpers.changeMeetingPopup({
          map,
          resource,
          bookings,
          date: UIProps.mapDate,
          openBookModel: UIProps.openBookModel,
          setMapSelectedResource: UIProps.setMapSelectedResource,
        })
      })
    }
  }, [map, resources, bookings, selectedLevel, UIProps.mapDate]) */

	const booked = [
		{
			x: " ",
			y: ["1647356400557", "1647360000557"]
		}
	]

	const free = [
		{
			x: " ",
			y: ["1647302400557", "1647356400557"]
		},
		{
			x: " ",
			y: ["1647360000557", "1647388800557"]
		}
	]

	return (
		<>
			{/* <button onClick={() => console.log("layers: ", map.getStyle().layers)}>dawdaw</button> */}

			<div style={{ height: "500px" }} ref={mapContainerRef} />
		</>
	)
}

class MapboxGLButtonControl {
	constructor(props) {
		this._date = props.date
		this._setDate = props.setDate
	}

	onAdd(map) {
		this._container = document.createElement("div")
		this._container.id = "bookingMapControlContainer"
		this._container.className =
			"mapboxgl-ctrl-group mapboxgl-ctrl row d-flex align-items-center p-2"

		this.renderDatePicker(this._container, this._date)
		return this._container
	}

	update(value) {
		this.renderDatePicker(document.getElementById("bookingMapControlContainer"), value)
	}

	renderDatePicker(obj, date) {
		if (obj) {
			ReactDOM.render(
				<OldDate
					style={{ width: "120px", margin: 0 }}
					disableToolbar
					inputVariant="standard"
					value={date}
					disablePast={true}
					onChange={date => this._setDate(date)}
				/>,
				obj
			)
		}
	}

	onRemove() {
		this._container.parentNode.removeChild(this._container)
		this._map = undefined
	}
}
