import { styled } from "@mui/system"
import LinearProgress from "@material-ui/core/LinearProgress"

const LinearIndeterminate = styled(LinearProgress)(() => ({
	color: "secondary.main"
}))

export const ProgressBar = {
	LinearIndeterminate
}
