import React from "react"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { Input } from "../../../../_partials/inputs/Input"
import { Field, Formik } from "formik"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import ImageUploading from "react-images-uploading"
import { Button } from "../../../../_partials/Button"
import { Logo, Title } from "../_partials"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { EditSchema } from "../UIHelpers"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { FormHelperText } from "@material-ui/core"
import * as actions from "../../../_redux/data-validation/dataValidationActions"
import "./ValidationForm.css"

const ValidationForm = ({ validationCode, setSelectedPage }) => {
	const dispatch = useDispatch()

	const { isLoading, personData } = useSelector(
		state => ({
			isLoading: state.dataValidation?.actionsLoading,
			personData: state.dataValidation?.personData
		}),
		shallowEqual
	)

	return (
		<Formik
			enableReinitialize
			initialValues={{
				...personData,
				firstName: personData?.name ? personData?.name.split(" ")[0] : "",
				lastName: personData?.name ? personData?.name.split(" ")[1] : ""
			}}
			validationSchema={EditSchema}
			onSubmit={async values => {
				// console.log("🚀 . values:", values)

				const valuesChanged = !shallowEqual(personData, values)
				// console.log("🚀 . Values Changed?:", valuesChanged)

				if (valuesChanged || !personData.dataValidated) {
					// Check if a file is selected
					if (values.image?.file) {
						const file = values.image.file

						var { base64String, extension } = await new Promise((resolve, reject) => {
							const reader = new FileReader()

							reader.onload = function (event) {
								resolve({
									base64String: event.target.result,
									extension: file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)
								})
							}

							reader.onerror = function (event) {
								reject(event.target.error)
							}

							reader.readAsDataURL(file)
						})
					}

					dispatch(
						actions.validateData({
							validationCode,
							customerId: personData.customerId,
							personId: personData.docId,
							name: `${values.firstName} ${values.lastName}`,
							email: values.email,
							phoneNumber: values.phoneNumber,
							emergencyName: values.emergencyName,
							emergencyNumber: values.emergencyNumber,
							idCard: values.idNumber,
							picture: base64String,
							pictureExtension: extension
						})
					)
					return
				}
				setSelectedPage("trainingVideos")
			}}
		>
			{({ values, errors, touched, setFieldTouched, setFieldValue, handleSubmit }) => {
				const image = values.image
				return (
					<div data-testid="data-validation_ValidationForm">
						<Logo />
						<Card
							isLoading={isLoading}
							data-testid="admin_people-onboarding_NewPersonCard"
							margin={0}
							style={{ maxWidth: "958px" }}
						>
							<CardHeader
								title="Validate your data"
								toolbar={
									<Button
										disabled={isLoading}
										className="ml-2"
										color="primary"
										variant="contained"
										text="SAVE"
										onClick={handleSubmit}
										data-testid="VALIDATE-DATA"
									/>
								}
							/>
							<CardBody padding="20px">
								<div
									style={{
										color: "#8C8CA1",
										fontFamily: "Poppins",
										fontSize: "16px",
										fontStyle: "normal",
										fontWeight: 500,
										lineHeight: "24px",
										marginBottom: "20px"
									}}
								>
									Please validate the data that was already input by your vendor and fill
									out the missing data
								</div>
								<GeneralInformation
									isLoading={isLoading}
									values={values}
									errors={errors}
									touched={touched}
									setFieldTouched={setFieldTouched}
									setFieldValue={setFieldValue}
								/>
								<hr className="mb-6" />
								<Title text="Detailed Information" />
								<DetailedInformation
									isLoading={isLoading}
									image={image}
									picture={personData?.picture}
									setFieldValue={setFieldValue}
								/>
							</CardBody>
						</Card>
					</div>
				)
			}}
		</Formik>
	)
}

export default ValidationForm

function GeneralInformation({
	isLoading,
	values,
	errors,
	touched,
	setFieldTouched,
	setFieldValue
}) {
	return (
		<>
			<div className="form-row">
				<div className="form-group col-12 col-sm-6 col-md-4">
					<Field
						disabled={isLoading}
						name="firstName"
						label="First Name"
						placeholder="Enter first name"
						component={Input}
						variant="filled"
						requiredField
						InputProps={{
							disableUnderline: true
						}}
					/>
				</div>
				<div className="form-group col-12 col-sm-6 col-md-4">
					<Field
						disabled={isLoading}
						name="lastName"
						label="Last Name"
						placeholder="Enter last name"
						component={Input}
						variant="filled"
						requiredField
						InputProps={{
							disableUnderline: true
						}}
					/>
				</div>
				<div className="form-group col-12 col-sm-6 col-md-4">
					<Field
						disabled={isLoading}
						name="email"
						label="Email"
						placeholder="xxxxxxx@crowdkeep.com"
						component={Input}
						variant="filled"
						requiredField
						InputProps={{
							disableUnderline: true
						}}
					/>
				</div>
				<div className="form-group col-12 col-sm-6 col-md-4">
					<div style={{ marginBottom: "10px" }}>
						<span
							style={{
								color: "#4A4A68",
								fontFamily: "Poppins",
								fontSize: "12px",
								fontWeight: "500"
							}}
						>
							Phone Number
							<sup style={{ color: "red" }}> *</sup>
						</span>
					</div>
					<PhoneInput
						disabled={isLoading}
						containerClass={`people_onboarding_countryCode_container ${
							errors.phoneNumber && "people_onboarding_countryCode_containerError"
						}`}
						inputClass={`people_onboarding_countryCode_inputClass ${
							isLoading && "people_onboarding_countryCode_inputLoading"
						}`}
						buttonClass="people_onboarding_countryCode_buttonClass"
						dropdownClass="people_onboarding_countryCode_dropdownClass"
						specialLabel=""
						value={values.phoneNumber}
						onBlur={() => {
							if (!touched && !setFieldTouched) {
								return
							}
							/*!touched[name] so it doesn't set touched true when it's already true*/
							/*Without this errors will always be one update behind the real value*/
							!touched.phoneNumber && setFieldTouched(name, true)
						}}
						onChange={value => {
							if (!touched && !setFieldTouched && !setFieldValue) {
								return
							}
							!touched.phoneNumber && setFieldTouched("phoneNumber", true)
							setFieldValue("phoneNumber", value)
						}}
					/>
					{errors.phoneNumber && (
						<FormHelperText style={{ color: "#F64E60" }}>{errors.phoneNumber}</FormHelperText>
					)}
				</div>
				<div className="form-group col-12 col-sm-6 col-md-4">
					<Field
						disabled
						component={Input}
						name="company"
						label="Company"
						placeholder="Select company"
						variant="filled"
						InputProps={{
							disableUnderline: true
						}}
					/>
				</div>
				<div className="form-group col-12 col-sm-6 col-md-4">
					<Field
						disabled
						name="jobSiteCode"
						label="Job Site Code"
						placeholder="Enter job site code"
						component={Input}
						variant="filled"
						requiredField
						InputProps={{
							disableUnderline: true
						}}
					/>
				</div>
			</div>
		</>
	)
}

function DetailedInformation({ isLoading, image, picture, setFieldValue }) {
	const onImageChange = imageList => {
		if (!imageList) return
		if (!imageList[0]) return

		setFieldValue("image", imageList[0])
	}

	const getPicture = () => {
		if (!image?.data_url) {
			return `url(${picture})` || `url(${toAbsoluteUrl("/svg/communication/person-blue.svg")}`
		}
		return `url(${image.data_url})`
	}
	return (
		<div className="d-md-flex">
			<div className="align-self-center mr-md-20">
				<ImageUploading
					onChange={onImageChange}
					dataURLKey="data_url"
					acceptType={["png", "jpg", "jpeg"]}
				>
					{({ onImageUpload, onImageRemoveAll, isDragging, dragProps }) => (
						<div className="upload__image-wrapper d-flex flex-column align-items-center">
							<div
								className="image-input image-input-outline"
								id="kt_profile_avatar"
								style={{
									backgroundColor: "white",
									paddingRight: "8px",
									paddingTop: "8px",
									paddingBottom: "8px"
								}}
							>
								<div
									className="image-input-wrapper"
									style={{
										borderColor: isDragging ? "#C3C4DD" : "white",
										backgroundImage: `${getPicture()}`,
										backgroundColor: "#DDE9FD",
										backgroundSize: image || picture ? "100%" : "50%",
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center",
										cursor: "pointer",
										height: "178px",
										width: "178px"
									}}
									onClick={!isLoading ? onImageUpload : () => {}}
									{...dragProps}
								/>
								<label
									className={`btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow ${
										isLoading ? "disabled" : ""
									}`}
									data-action="change"
									data-toggle="tooltip"
									title=""
									data-original-title="Change logo"
									onClick={!isLoading ? onImageUpload : () => {}}
								>
									<i className="fa fa-pen icon-sm text-muted"></i>
								</label>
								<span
									onClick={() => {
										if (image && !isLoading) {
											onImageRemoveAll()
											setFieldValue("image", null)
										}
									}}
									className={`btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow ${
										isLoading ? "disabled" : ""
									}`}
									data-action="remove"
									data-toggle="tooltip"
									title=""
									data-original-title="Remove logo"
								>
									<i className="ki ki-bold-close icon-xs text-muted"></i>
								</span>
							</div>
							{image?.file?.size && image.file.size / (1024 * 1024) > 10 && (
								<span className="form-text text-danger mt-2">
									{`Image is too big (${(image.file.size / (1024 * 1024)).toFixed(2)}Mb).`}
									<br />
									Max imagesize: 10Mb.
								</span>
							)}
							<span className="form-text text-muted mt-2">
								Allowed file types: png, jpg, jpeg.
							</span>
						</div>
					)}
				</ImageUploading>
			</div>
			<div style={{ width: "100%" }}>
				<div className="form-row">
					<div className="form-group col-12 col-sm-6">
						<Field
							disabled
							name="role"
							label="Role"
							placeholder="Enter role"
							component={Input}
							variant="filled"
							InputProps={{
								disableUnderline: true
							}}
						/>
					</div>
					<div className="form-group col-12 col-sm-6">
						<Field
							disabled={isLoading}
							name="idNumber"
							label="ID number"
							placeholder="Enter ID number"
							component={Input}
							variant="filled"
							InputProps={{
								disableUnderline: true
							}}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-12 col-md-6">
						<Field
							disabled={isLoading}
							name="emergencyName"
							label="Emergency Contact Name"
							placeholder="Enter name"
							component={Input}
							variant="filled"
							InputProps={{
								disableUnderline: true
							}}
						/>
					</div>
					<div className="form-group col-12 col-md-6">
						<Field
							disabled={isLoading}
							name="emergencyNumber"
							label="Emergency Contact Number"
							placeholder="Enter number"
							component={Input}
							variant="filled"
							InputProps={{
								disableUnderline: true
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
