import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	lastError: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const dataValidationSlice = createSlice({
	name: "dataValidation",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		disableActionsLoading: state => {
			state.actionsLoading = false
		},
		personDataFetched: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.personData = action.payload
		},
		termsResponded: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.personData = { ...state.personData, termsAndConditionsAccepted: action.payload }
		},
		videoUrlFetched: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.personData = state.personData?.trainingVideos && {
				...state.personData,
				trainingVideos: state.personData.trainingVideos.map((val, i) => {
					if (i === action.payload.videoIndex) {
						return { id: val, url: action.payload.url, title: action.payload.title }
					}
					return val
				})
			}
		},
		questionsFetched: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.questions = action.payload
		},
		videoWatched: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.personData = state.personData?.trainingVideos && {
				...state.personData,
				trainingVideos: state.personData.trainingVideos.map(val => {
					if (val === action.payload.id || val.id === action.payload.id) {
						return action.payload
					}
					return val
				})
			}
		}
	}
})
