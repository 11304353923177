import React, { useMemo } from "react"
import { Modal } from "react-bootstrap"
import CloseIcon from "@material-ui/icons/Close"
import { IconButton } from "@material-ui/core"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/admin/inventory/InventoryActions"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"
import { Button } from "../../../../_partials/Button"
import { useUIContext } from "../UIContext"
import "../Inventory.css"
import { startButtonProgress, finishButtonProgress } from "../../utils/utils"
const DeleteInventoryFirmwaresModal = ({
	showDeleteFirmware,
	setShowDeleteFirmware,
	technology,
	setSelectionModel
}) => {
	const dispatch = useDispatch()

	// ━━━━━━━━━━━━━  useUIContext  ━━━━━━━━━━━━━ \\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.idsInventoryFirmwares,
			setIds: UIContext.setIdsInventoryFirmwares
		}
	}, [UIContext])
	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const { listLoading, user } = useSelector(
		state => ({
			listLoading: state.inventory.listLoading,
			user: state.auth.user
		}),

		shallowEqual
	)

	const [saving, setSaving] = React.useState(false)

	function onHide() {
		UIProps.setIds([])
		setSelectionModel([])
		setShowDeleteFirmware(false)
		setSaving(false)
	}

	const deleteFirmwares = () => {
		if (!UIProps.ids || UIProps.ids.length <= 0 || !user || !user.id) {
			onHide()
			return
		}

		setSaving(true)

		dispatch(
			actions.deleteFirmwares({
				firmwareId: UIProps.ids,
				callersUserId: user.id,
				technology: technology,
				onHide
			})
		)
			.then(() => {
				setSaving(false)
				onHide()
			})
			.catch(error => {
				console.error("Error saving:", error) // Handle error
				setSaving(false) // Reset saving state on error
			})
	}

	return (
		<Modal
			show={showDeleteFirmware}
			backdrop="static"
			onHide={onHide}
			data-testid="DeleteInventoryFirmwaresModal"
		>
			<Modal.Header style={{ padding: "0.5rem 1rem 0 2rem", border: "none" }}>
				{/* ━━★  TITLE  ★━━ */}
				<div className="d-flex w-100 align-items-center justify-content-between">
					<div className="col-auto">
						<div className="row">
							<div
								className="d-flex justify-content-center align-items-end"
								style={{
									width: "16px",
									height: "16px",
									position: "relative",
									overflow: "hidden",
									backgroundColor: "#ffffff",
									outline: "2px solid #4A4A68",
									borderRadius: "50%",
									margin: "auto",
									marginRight: "10px"
								}}
							>
								<img
									style={{
										margin: "auto",
										width: "36%",
										height: "auto",
										display: "block"
									}}
									alt="areasInfo"
									src={"/media/crowdkeep/information_filled.svg"}
								/>
							</div>
							<div>
								<span className="custom-modal-title">Delete Firmwares</span>
							</div>
						</div>
					</div>
					<div className="col-auto">
						<IconButton
							onClick={onHide}
							disabled={saving}
							style={{ left: "12px" }}
							data-testid="close-button"
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Modal.Header>
			<Modal.Body
				style={{
					padding: "0",
					overflow: "hidden"
				}}
			>
				{listLoading && <ModalProgressBar />}
				<div style={{ padding: "0 2rem 2rem 2rem" }}>
					{/* ━━★  BODY  ★━━ */}
					<div className="mb-4">
						{!listLoading && UIProps.ids && UIProps.ids.length > 0 && (
							<span className="custom-modal-body">
								You are currently deleting{" "}
								<span style={{ fontWeight: "bold" }}>{UIProps.ids.length}</span> Firmwares.
							</span>
						)}
						{listLoading && <span>Deleting firmware...</span>}
					</div>
					<div className="row mt-5" style={{ textAlign: "end" }}>
						<div className="col" style={{ padding: "0" }}>
							<Button
								variant="outlined"
								color="primary"
								disabled={listLoading || saving}
								onClick={onHide}
								text="Cancel"
								data-testid="cancel-button"
							/>
							<Button
								id="buttonToProgress_editPoi"
								variant="contained"
								disabled={listLoading}
								data-testid="inventory_unassign_btn"
								style={{
									marginRight: "10px",
									fontWeight: "700",
									backgroundColor: "#3077d3",
									color: "#f4f4f4"
								}}
								// text="Save"
								onClick={() => {
									// Starts the change of the saving UI button like progress bar
									const buttonToProgressEl = document.getElementById(
										"buttonToProgress_editPoi"
									)
									buttonToProgressEl &&
										startButtonProgress(
											buttonToProgressEl,
											"button__progress_editPoi",
											"button__text_editPoi"
										)

									deleteFirmwares()
								}}
								className="ml-2"
								data-progress-text="Deleting..."
								data-complete-text="Delete complete."
							>
								<div className="button__progress_editPoi"></div>
								<span className="button__text_editPoi">Delete</span>
							</Button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default DeleteInventoryFirmwaresModal
