import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	entities: null,
	lastError: null
}
export const callTypes = {
	list: "list",
	action: "action"
}

export const DesignStudioSlice = createSlice({
	name: "designStudio",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		resourceCreated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities && [...state.entities, action.payload.resource]
		},
		floorPlansFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.floorPlans = entities
		},
		poiCreated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.pointsOfInterest = state.pointsOfInterest
				? [...state.pointsOfInterest, action.payload]
				: state.pointsOfInterest
		},
		poiEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.pointsOfInterest =
				state.pointsOfInterest &&
				state.pointsOfInterest.map(entity => {
					if (entity.id === action.payload.id) {
						return action.payload
					}
					return entity
				})
		},
		pointsOfInterestFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.pointsOfInterest = entities
		},
		pointOfInterestFetchedToUpdate: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.pointOfInterestToUpdate = entities
		},
		pointOfInterestDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.pointsOfInterest = state.pointsOfInterest.filter(val => val.id !== action.payload.id)
		},
		resourceFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.resourceForEdit = entities
		},
		resourcesFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		resourceUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities =
				state.entities &&
				state.entities.map(entity => {
					if (entity.id === action.payload.resource.id) {
						return action.payload.resource
					}
					return entity
				})
		},
		resourceDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(el => el.id !== action.payload.id)
		},
		resourcesDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(el => !action.payload.ids.includes(el.id))
		},
		zonesInDBFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.zonesInDB = entities
		},
		resourcesMeetingsFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.resourcesMeetings = entities
		},
		addAnchorsDesignStudio: (state, action) => {
			state.listLoading = false
			state.error = null
			state.anchors = action.payload
			// state.anchors = state.anchors ? [...state.anchors, ...action.payload] : [action.payload]
		},
		anchorsPositioningFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			if (entities && entities.length > 0) {
				let newEntities = []
				newEntities = entities.map(poi => {
					return {
						id: poi.uuid,
						...poi
					}
				})
				state.anchorsPosition = newEntities
			} else state.anchorsPosition = entities
		},
		anchorPositionFetchedToUpdate: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.anchorsPositionToUpdate = entities
		},
		anchorUnplaced: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.anchorsPosition = state.anchorsPosition.filter(el => el.id !== action.payload.id)
		},
		anchorsUnplaced: (state, action) => {
			state.error = null
			state.actionsLoading = false
			const updatedAnchors = state.anchorsPosition.map(anchor => {
				if (action.payload.ids.includes(anchor.id)) {
					// Update the node as needed
					return {
						...anchor,
						status: "assigned",
						approved: false
					}
				} else {
					return anchor
				}
			})
			state.anchorsPosition = updatedAnchors
		},
		//for sensors
		addSensorsDesignStudio: (state, action) => {
			state.listLoading = false
			state.error = null
			state.sensors = action.payload
		},
		sensorsPositioningFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			if (entities && entities.length > 0) {
				let newEntities = []
				newEntities = entities.map(poi => {
					return {
						id: poi.uuid,
						...poi
					}
				})
				state.sensorsPosition = newEntities
			} else state.sensorsPosition = entities
		},
		sensorPositionFetchedToUpdate: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.sensorsPositionToUpdate = entities
		},
		sensorsUnplaced: (state, action) => {
			state.error = null
			state.actionsLoading = false
			const updatedSensors = state.sensorsPosition.map(sensor => {
				if (action.payload.ids.includes(sensor.id)) {
					// Update the node as needed
					return {
						...sensor,
						status: "assigned",
						approved: false
					}
				} else {
					return sensor
				}
			})
			state.sensorsPosition = updatedSensors
		},
		sensorUnplaced: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.sensorsPosition = state.sensorsPosition.filter(el => el.id !== action.payload.id)
		}
	}
})

export const { anchorUnplaced, sensorUnplaced } = DesignStudioSlice.actions
