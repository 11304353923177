import React from "react"

const GlobalInfo = ({
	value,
	text,
	icon,
	color,
	total,
	newBorderRadius,
	isDesktop,
	isMobile,
	className = "col-12 col-sm d-flex justify-content-between align-items-start"
}) => {
	return (
		<div
			className={className}
			style={{
				backgroundColor: "#FFFFFF",
				borderRadius: "5px",
				padding: "15px",
				height: "80px",
				width: "100%",
				boxShadow: "0px 4px 10px 0px rgba(27, 27, 27, 0.10)",
				margin: isDesktop && "0 15px",
				marginRight: isMobile && "15px",
				marginBottom: isMobile && "15px"
			}}
		>
			<div
				className="d-flex flex-column w-100"
				style={{
					fontFamily: "Poppins",
					fontStyle: "normal",
					color: "#4A4A68"
				}}
			>
				<div className="d-flex justify-content-between">
					<span
						className="anchors_page_value_total"
						style={{
							lineHeight: "40px",
							fontWeight: "700"
						}}
					>
						{value} <span style={{ fontSize: "14px" }}> / {total}</span>
					</span>
					<div
						className="anchors_page_marker_divs"
						style={{
							boxShadow: "0px 4px 10px 0px rgba(27, 27, 27, 0.10)",
							borderRadius: newBorderRadius,
							backgroundColor: color,
							flexShrink: 0
						}}
					>
						<img
							alt="Person Info Icon"
							src={icon}
							style={{
								width: "65%",
								height: "65%"
							}}
						/>
					</div>
				</div>
				<span
					className="anchors_page_text"
					style={{
						lineHeight: "18px",
						fontWeight: "500",
						whiteSpace: "nowrap"
					}}
				>
					{text}
				</span>
			</div>
		</div>
	)
}

export default GlobalInfo
