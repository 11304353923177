import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	lastError: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const peopleOnboardingSlice = createSlice({
	name: "peopleOnboarding",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		personCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			// state.videos = action.payload
		},
		videosFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.videos = action.payload
		},
		videosUploaded: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.videos = [...state.videos, ...action.payload]
		},
		videosUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.videos = state.videos
				? state.videos.map(vid => {
						if (action.payload.some(val => val.id === vid.id)) {
							return { ...vid, ...action.payload.find(val => val.id === vid.id) }
						}
						return vid
				  })
				: action.payload
		},
		videoDeleted: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.videos = state.videos
				? state.videos.filter(vid => {
						return action.payload !== vid.id
				  })
				: []
		},
		personDisabled: (state, action) => {
			state.actionsLoading = false
			state.error = null
		}
	}
})
