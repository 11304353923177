import React from "react"
import { Card, CardBody } from "../../../../_partials/Card"
import SVG from "react-inlinesvg"
import { Logo } from "../_partials"

const TermsAndConditionsRejected = () => {
	return (
		<div
			style={{
				maxWidth: "375px"
			}}
		>
			<Logo />
			<Card data-testid="data-validation_TermsAndConditionsRejected">
				<CardBody padding="20px">
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "25px"
						}}
					>
						<SVG
							src="/svg/signs/close-filled.svg"
							style={{
								marginBottom: "0.5rem",
								fill: "#2673F0",
								height: "50px",
								width: "auto"
							}}
						/>
						<div
							style={{
								color: "#0A1B3D",
								textAlign: "center",
								fontFamily: "Poppins",
								fontSize: "28px",
								fontStyle: "normal",
								fontWeight: 500,
								lineHeight: "40px",
								letterSpacing: "-0.28px"
							}}
						>
							Terms and Conditions Rejected!
						</div>
					</div>
					<div
						style={{
							color: "#8C8CA1",
							textAlign: "center",
							fontFamily: "Poppins",
							fontSize: "14px",
							fontStyle: "normal",
							fontWeight: 500,
							lineHeight: "22px"
						}}
					>
						The terms and conditions have been rejected, please wait for your contractor to
						contact you.
					</div>
				</CardBody>
			</Card>
		</div>
	)
}

export default TermsAndConditionsRejected
