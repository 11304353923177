import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	lastError: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const manageThingsSlice = createSlice({
	name: "manageThings",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		thingsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.things = action.payload
		},
		blesFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.bles = action.payload
		},
		thingCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.things = state.things ? [...state.things, action.payload] : [action.payload.user]
		},
		bleUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.ble =
				state.ble &&
				state.ble.map(entity => {
					if (entity.id === action.payload.id) {
						return action.payload
					}
					return entity
				})
		},
		thingEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.things =
				state.things &&
				state.things.map(entity => {
					if (entity.id === action.payload.id) {
						return action.payload
					}
					return entity
				})
		},
		thingDeleted: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.things = state.things.filter(val => val.id !== action.payload)
		}
	}
})
