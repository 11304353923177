import React from "react"
import Chart from "react-apexcharts"

export function TotalRoleOccupancyGraph({ graphData }) {
	return (
		<Chart
			options={getChartOptions(graphData)}
			series={graphData?.series}
			type="bar"
			height="100%"
		/>
	)
}

function getChartOptions(graphData) {
	return {
		chart: {
			type: "bar",
			stacked: true,
			toolbar: {
				show: false
			}
		},
		plotOptions: {
			bar: {
				columnWidth: "55%",
				endingShape: "rounded"
			}
		},
		dataLabels: {
			enabled: false
		},
		colors: ["#00B4D8", "#90E0EF"],
		stroke: {
			show: true,
			width: 2,
			colors: ["transparent"]
		},

		xaxis: {
			categories: graphData?.labels
		},
		yaxis: {
			title: {
				text: "Contracted people"
			},
			labels: {
				show: true,
				formatter: val => val.toFixed(0)
			}
		},
		fill: {
			opacity: 1
		}
	}
}
