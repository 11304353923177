import * as requestFromServer from "./assetsCrud"
import { reportingSlice, callTypes } from "../reportingSlice"
import * as ActionHelpersNew from "./ActionHelpersNew"

const { actions } = reportingSlice

// Main Promise.all function for Asset Summary
export const fetchAssetSummary = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return Promise.all([
		fetchAllAssets(queryParams, dispatch),
		fetchReportAreas(queryParams, dispatch),
		fetchReportFloorsPeople(queryParams, dispatch),
		fetchLocationReportAreas(queryParams, dispatch),
		fetchReportFloorsLocation(queryParams, dispatch),
		fetchReportBuildingsLocation(queryParams, dispatch)
	]).catch(error => {
		console.log("Error on fetchAssetSummary function : ", error)
		error.clientMessage = "Can't fetch Asset Summary"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	})
}

// Main Promise.all function for People Timesheets
export const fetchAssetsTimesheet = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return Promise.all([
		fetchAllAssets(queryParams, dispatch),
		fetchAllPeopleToTimesheets(queryParams, dispatch),
		fetchSinglePeopleToTimesheets(queryParams, dispatch)
	]).catch(error => {
		console.log("Error on fetchPeopleTimesheet function : ", error)
		error.clientMessage = "Can't fetch People By Floor"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	})
}

// Used in charts: Top 6 attendance tracking, Attendance by asset type
function fetchAllAssets(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.allAssetsDistribution }))
	if (!queryParams) dispatch(actions.allAssetsDistributionFetched(null))

	return requestFromServer
		.getAllThePeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.allAssetsDistributionFetched(null))

			const data = ActionHelpersNew.handleAllAssetsDistributionResult({
				result,
				queryParams
			})

			const newData = ActionHelpersNew.parsingAllPeopleDataHelper(data)

			const finalData =
				newData &&
				ActionHelpersNew.handleAttendenceByAssetType({
					result: newData,
					queryParams
				})

			finalData && dispatch(actions.allAssetsDistributionFetched(finalData))
		})

		.catch(error => {
			console.log("Fetch all Assets distribution: ", error)
			error.clientMessage = "Can't fetch all Assets distribution"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// Used in charts: Location Summary Areas
export function fetchLocationReportAreas(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.areaLocationTimeSpentAssets }))

	if (!queryParams) dispatch(actions.fetchLocationReportAreasFetchedAssets(null))
	return requestFromServer
		.getAvgTimeSpent(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0)
				dispatch(actions.fetchLocationReportAreasFetchedAssets(null))

			const data = ActionHelpersNew.locationTimeSpentReportAreas({
				result,
				queryParams
			})

			data && dispatch(actions.fetchLocationReportAreasFetchedAssets(data))
		})

		.catch(error => {
			console.log("fetchLocationReportAreas: ", error)
			error.clientMessage = "Can't fetch avg time spent report Areas Location"
			dispatch(actions.catchError({ error, callType: callTypes.areaLocationTimeSpentAssets }))
		})
}

// // Used in charts: Location Summary Floors
export function fetchReportFloorsLocation(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.assetsDistributionLocation }))

	if (!queryParams) dispatch(actions.assetsDistributionLocationFetched(null))
	return requestFromServer
		.getPeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0)
				dispatch(actions.assetsDistributionLocationFetched(null))

			const data = ActionHelpersNew.parseLocationReportFloors({
				result,
				queryParams
			})

			data && dispatch(actions.assetsDistributionLocationFetched(data))
		})

		.catch(error => {
			console.log("fetch Report Floors Location function error: ", error)
			error.clientMessage = "Can't fetch Report Floors Location"
			dispatch(actions.catchError({ error, callType: callTypes.assetsDistributionLocation }))
		})
}

// Used in charts: Location Summary Buildings
export function fetchReportBuildingsLocation(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.assetsBuildings }))

	if (!queryParams) dispatch(actions.assetsBuildingsFetched(null))
	return requestFromServer
		.getPeopleDistributionBuildings(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.assetsBuildingsFetched(null))

			const data = ActionHelpersNew.parseLocationReportBuildings({
				result,
				queryParams
			})

			data && dispatch(actions.assetsBuildingsFetched(data))
		})

		.catch(error => {
			console.log("fetch Report Buildings Location function error: ", error)
			error.clientMessage = "Can't fetch Report Buildings Location"
			dispatch(actions.catchError({ error, callType: callTypes.assetsBuildings }))
		})
}

// Used in charts: Total time spent per Area
export function fetchReportAreas(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.areaAvgTimeSpentAssets }))

	if (!queryParams) dispatch(actions.areaAvgTimeSpentFetchedAssets(null))
	return requestFromServer
		.getAvgTimeSpent(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result) dispatch(actions.areaAvgTimeSpentFetchedAssets(null))

			const data = ActionHelpersNew.avgTimeSpentReportAreas({
				result,
				queryParams
			})

			data && dispatch(actions.areaAvgTimeSpentFetchedAssets(data))
		})

		.catch(error => {
			console.log("Fetch avg time spent report Areas: ", error)
			error.clientMessage = "Can't fetch avg time spent report Areas"
			dispatch(actions.catchError({ error, callType: callTypes.areaAvgTimeSpentAssets }))
		})
}

// Used in charts: Total time per Floor
export function fetchReportFloorsPeople(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.assetsDistribution }))

	if (!queryParams) dispatch(actions.assetsDistributionFetched(null))
	return requestFromServer
		.getPeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result) dispatch(actions.assetsDistributionFetched(null))

			const data = ActionHelpersNew.parseReportFloors({
				result,
				queryParams
			})

			data && dispatch(actions.assetsDistributionFetched(data))
		})

		.catch(error => {
			console.log("fetchReportFloorsPeople function error: ", error)
			error.clientMessage = "Can't fetch Report Floors People"
			dispatch(actions.catchError({ error, callType: callTypes.assetsDistribution }))
		})
}

// Used in charts: Total assets per asset type timesheet
function fetchAllPeopleToTimesheets(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.allAssetsTimesheet }))
	if (!queryParams) dispatch(actions.allAssetsToTimesheetsFetched(null))

	return requestFromServer
		.getAllThePeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.allAssetsToTimesheetsFetched(null))

			const data = ActionHelpersNew.handleAllAssetsDistributionResult({
				result,
				queryParams
			})

			const newData = ActionHelpersNew.parsingAllPeopleDataHelper(data)

			newData && dispatch(actions.allAssetsToTimesheetsFetched(newData))
		})

		.catch(error => {
			console.log("Fetch all Asset distribution: ", error)
			error.clientMessage = "Can't fetch all Asset distribution"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// Used in charts: Daily contracted workers timesheet
function fetchSinglePeopleToTimesheets(queryParams, dispatch) {
	dispatch(actions.startCall({ callType: callTypes.singleAssetTimesheet }))
	if (!queryParams) dispatch(actions.singleAssetToTimesheetsFetched(null))

	return requestFromServer
		.getAllThePeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result || result.length === 0) dispatch(actions.singleAssetToTimesheetsFetched(null))

			const data = ActionHelpersNew.handleAllAssetsDistributionResult({
				result,
				queryParams
			})

			data && dispatch(actions.singleAssetToTimesheetsFetched(data))
		})

		.catch(error => {
			console.log("Fetch all People distribution: ", error)
			error.clientMessage = "Can't fetch all People distribution"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}
