import React from "react"
import { useSelector, shallowEqual } from "react-redux"
import ToggleLiveMapButton from "./ToggleLiveMapButton"

const DashboardTop = () => {
	const { selectedSite } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite
		}),
		shallowEqual
	)

	const siteFinished = selectedSite?.siteInfo?.siteFinished || null

	return (
		<div
			className="d-flex align-items-center justify-content-between mb-4"
			style={{ width: "100%" }}
		>
			<div className="col-4">
				<div className="d-flex align-items-center" style={{ paddingTop: "8px" }}>
					<div
						style={{
							width: "35px",
							height: "40px"
						}}
					>
						<img
							src="../icons/dashboard-home.svg"
							style={{ fontSize: "23px", color: "#2673F0", fill: "#2673F0" }}
						/>
					</div>

					<h2 style={{ color: "#4A4A68" }}>
						{!siteFinished ? <b>Dashboard</b> : <b>Site Summary</b>}
					</h2>
				</div>
			</div>
			{!siteFinished ? <ToggleLiveMapButton siteFinished={siteFinished} /> : <CompletedSite />}
		</div>
	)
}

export default DashboardTop

export const CompletedSite = () => {
	return (
		<div
			style={{
				minWidth: "0",
				borderRadius: "4px",
				boxShadow: "none",
				padding: "6px 16px",
				border: "1.5px solid #2673F0",
				background: "rgba(38, 115, 240, 0.20)",
				userSelect: "none"
			}}
		>
			<span
				style={{
					fontFamily: "Poppins",
					fontWeight: "700",
					// fontSize: "14px",
					lineHeight: "21px",
					textTransform: "uppercase",
					letterSpacing: "0.04em",
					color: "#2673F0"
				}}
			>
				completed
			</span>
		</div>
	)
}
