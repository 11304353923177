import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody } from "../../../../_partials/Card"
import { TextField, makeStyles } from "@material-ui/core/"
import moment from "moment"
import { storage } from "../../../../../firebase"
// eslint-disable-next-line import/no-webpack-loader-syntax
import { firestoreOld } from "../../../../../firebase"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"

const useStyles = makeStyles(theme => ({
	pointerCursor: {
		"& .MuiInputBase-root": {
			cursor: "default"
		},
		"& input": {
			cursor: "default"
		}
	},
	textField: {
		// height: "40px",
		width: "100%",
		backgroundColor: "#FFFFFF",
		borderRadius: "4px",
		margin: 0,
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "4px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#F2F2F2",
			borderRadius: "4px"
		},
		// --- Filled --- \\
		"& .MuiFilledInput-root": {
			backgroundColor: "#F2F2F2",
			borderColor: "rgba(0,0,0,0)",
			borderRadius: "4px",
			height: "40px"
		},
		"& .MuiFilledInput-inputMarginDense": {
			padding: "0 15px",
			height: "40px",
			verticalAlign: "middle"
		},
		"& .MuiInputBase-root": {
			height: "45px",
			padding: "0px 12px",
			display: "flex",
			alignItems: "center"
		},
		"& .MuiInputBase-input": {
			padding: "0",
			color: "#4A4A68",
			fontFamily: "Poppins",
			fontSize: "16px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "160%"
		}
	}
}))

const TopRowInfoForClosedSite = () => {
	const classes = useStyles()

	const { selectedSite } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite
		}),
		shallowEqual
	)

	//━━━━━━━━━━━━━━━ States ━━━━━━━━━━━━━━━\\
	const [siteNameValue, setSiteNameValue] = useState("")

	const [creationDateValue, setCreationDateValue] = useState(null)
	const [closingDateValue, setClosingDateValue] = useState(null)
	const [numberOfDaysActive, setNumberOfDaysActive] = useState(null)

	const [closedByEmail, setClosedByEmail] = useState(null)

	const [imagesCustomer, setImagesCustomer] = useState([])

	const [totalAnchors, setTotalAnchors] = useState("")

	const [currentSiteData, setCurrentSiteData] = useState()
	const [loading, setLoading] = useState(false)

	// ━━━━━━━━━━━━━━━ UseEffects ━━━━━━━━━━━━━━━\\
	useEffect(() => {
		if (!selectedSite?.id) {
			return
		}

		setLoading(true)

		firestoreOld
			.collection("Sites")
			.doc(selectedSite.id)
			.get()
			.then(doc => {
				if (doc.exists) {
					// The document with the specified ID exists
					//  console.log(doc.data(), "doc.data()");
					setCurrentSiteData({ id: doc.id, ...doc.data() })
					setLoading(false)
				} else {
					// The document with the specified ID does not exist
					console.log("No such site document!")
				}
			})
			.catch(error => {
				console.error("Error getting document: ", error)
			})
	}, [selectedSite])

	useEffect(() => {
		if (!currentSiteData) return

		// Site creation date
		const date = new Date(currentSiteData?.creationInfo?.createdOn.seconds * 1000)
		const formattedDate = moment(date).format("MM/DD/YYYY")
		formattedDate && setCreationDateValue(formattedDate)

		// Site closing date
		const closingDate = new Date(currentSiteData?.closingInfo?.closedOn.seconds * 1000)
		const formattedClosingDate = moment(closingDate).format("MM/DD/YYYY")
		formattedClosingDate && setClosingDateValue(formattedClosingDate)

		// Number of days site was active
		const diff = moment(closingDate).diff(moment(date), "days")
		setNumberOfDaysActive(diff)

		// Set site name
		setSiteNameValue(currentSiteData?.siteInfo?.name)

		// Set closed by whom
		setClosedByEmail(currentSiteData?.closingInfo?.closedByEmail)

		// Set total anchors when site closed
		setTotalAnchors(currentSiteData?.closingInfo?.totalAnchors)
	}, [currentSiteData])

	// ━━━━━━━━━━━━━━━ Functions ━━━━━━━━━━━━━━━\\

	// ━━━━━━━━━━━━━ Create customer card - getUserPic
	const getUserPic = () => {
		const siteImageUrlsFetched =
			currentSiteData?.siteInfo?.imageUrls?.length &&
			currentSiteData?.siteInfo?.imageUrls?.length !== 0
				? currentSiteData?.siteInfo?.imageUrls[0]
				: ""

		if (!imagesCustomer[0]?.data_url) {
			return siteImageUrlsFetched !== ""
				? `url(${siteImageUrlsFetched}`
				: `url(${"/media/logos/NoPicture.png"}`
		}

		return `url(${imagesCustomer[0].data_url})`
	}

	return (
		<>
			<Card data-testid="manage-site_site-info">
				<CardBody>
					{loading && <ModalProgressBar variant="query" />}
					<div className="d-flex flex-column flex-md-row w-100 align-items-center">
						<div className="align-self-center mr-md-5">
							<div className="upload__image-wrapper">
								<div
									className="image-input image-input-outline"
									style={{
										backgroundColor: "white",
										paddingRight: "8px",
										paddingTop: "8px",
										paddingBottom: "8px",
										pointerEvents: "none"
									}}
								>
									<div
										className="image-input-wrapper"
										id="edit_pic_new_manage_customer"
										style={{
											borderColor: "white",
											backgroundImage: `${getUserPic()}`,
											cursor: "pointer",
											height: "78px",
											width: "78px"
										}}
									/>
								</div>
							</div>
						</div>
						<div className="flex-grow-1">
							<div className="row align-items-center">
								<div className="col-xl-4">
									{
										<div
											style={{
												color: "#4A4A68",
												fontFamily: "Poppins",
												fontSize: "13px",
												fontStyle: "normal",
												fontWeight: "500",
												lineHeight: "170%"
											}}
										>
											Site Name
										</div>
									}
									<TextField
										id="manage_site_site_name_input"
										className={classes.textField}
										placeholder="Site name"
										variant="filled"
										InputProps={{
											disableUnderline: true
										}}
										value={siteNameValue || ""}
										disabled={true}
									/>
								</div>
								<div className="col-xl-5">
									<div
										style={{
											color: "#4A4A68",
											fontFamily: "Poppins",
											fontSize: "13px",
											fontStyle: "normal",
											fontWeight: "500",
											lineHeight: "170%"
										}}
									>
										Start Date
									</div>
									<TextField
										className={`${classes.textField} ${classes.pointerCursor}`}
										variant="filled"
										InputProps={{
											disableUnderline: true,
											readOnly: true
										}}
										disabled={true}
										value={creationDateValue || ""}
									/>
								</div>
								<div className="col-xl-3">
									<div
										style={{
											color: "#4A4A68",
											fontFamily: "Poppins",
											fontSize: "13px",
											fontStyle: "normal",
											fontWeight: "500",
											lineHeight: "170%"
										}}
									>
										End Date
									</div>
									<TextField
										className={`${classes.textField} ${classes.pointerCursor}`}
										variant="filled"
										InputProps={{
											disableUnderline: true,
											readOnly: true
										}}
										disabled={true}
										value={closingDateValue || ""}
									/>
								</div>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>

			<div>
				<div className="d-flex flex-lg-row flex-md-row flex-sm-row flex-column mb-2 mt-2">
					<div className="w-100 mr-0 mb-3 mr-lg-3 mr-md-3 mr-sm-3 mb-lg-0 mb-md-0 mb-sm-0">
						<GlobalPeopleInfo
							value={totalAnchors || "0"}
							text={"Number of Anchors"}
							icon="/media/crowdkeep/anchor.svg"
							color="#E4E4E8"
							iconWidth="18px"
						/>
					</div>
					<div className="w-100">
						<GlobalPeopleInfo
							value={numberOfDaysActive || "0"}
							text={"Site number of days active"}
							icon="/media/crowdkeep/Check.svg"
							color="#E4E4E8"
							iconWidth="20px"
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default TopRowInfoForClosedSite

export function GlobalPeopleInfo({ value, text, icon, color, iconWidth }) {
	return (
		<div
			id="dashboard_GlobalPeopleInfo_div_attendance"
			className="d-flex justify-content-between align-items-center"
			style={{
				backgroundColor: color,
				borderRadius: "5px",
				padding: "15px",
				height: "40px"
			}}
		>
			<div
				className="d-flex align-items-center justify-content-around"
				style={{
					fontFamily: "Poppins",
					fontStyle: "normal",
					color: "#4A4A68"
				}}
			>
				<div
					style={{
						alignItems: "center",
						display: "flex"
					}}
				>
					<span
						style={{
							fontSize: "18px",
							lineHeight: "40px",
							fontWeight: "700"
						}}
					>
						{value && value}
					</span>
					<span
						className="top_row_info_text"
						style={{
							marginLeft: "10px",
							fontSize: "11px",
							lineHeight: "18px",
							fontWeight: "500"
						}}
					>
						{text}
					</span>
				</div>
			</div>
			<div>
				<img alt="Person Info Icon" src={icon} style={{ width: iconWidth }} />
			</div>
		</div>
	)
}
