import React, { useEffect, useMemo, useState } from "react"
import CloseIcon from "@material-ui/icons/Close"
import { GrFilter } from "react-icons/gr"
import { Button } from "../../../../_partials/Button"
import CheckIcon from "@material-ui/icons/Check"
import AddIcon from "@material-ui/icons/Add"
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../_partials/typography"

import {
	IconButton,
	makeStyles,
	Select as SelectMui,
	ListItemIcon,
	ListSubheader
} from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"

const useStyles = makeStyles(() => ({
	formControl: {
		width: "100%",
		marginTop: "2px"
	},
	indeterminateColor: {
		color: "#f50057"
	},
	selectAllText: {
		fontWeight: 400
	},
	selectedAll: {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.08)"
		}
	},
	filled: {
		backgroundColor: "#ECF1F4",
		"& .MuiSelect-underline": {
			display: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	select: {
		height: "38px",
		backgroundColor: "#ECF1F4",
		borderRadius: "5px",
		"& .MuiSelect-select:focus": {
			borderRadius: "0",
			backgroundColor: "transparent"
		},
		"& .MuiSelect-root": {
			padding: "16px 12px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		"& .MuiInput-underline:before": {
			borderBottom: "none"
		},
		"& .MuiInput-underline:after": {
			borderBottom: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		},
		"&:hover": {
			backgroundColor: "#ECF1F4",
			border: "1px solid grey",
			borderRadius: "5px"
		},
		"& .MuiSelect-icon": {
			color: "#4A4A68", // arrow icon color
			marginRight: "0.5rem"
		}
	},
	checkboxIcon: {
		"& .MuiSvgIcon-root": {
			color: "#3077D3"
		}
	},
	input2: {
		width: "100%",
		backgroundColor: "#F2F2F2",
		borderRadius: "4px",
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#F2F2F2"
			},
			"&:hover fieldset": {
				border: "none"
			},
			"&:focus fieldset": {
				border: "none"
			},
			"&.Mui-focused fieldset": {
				border: "none"
			}
		}
	},

	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "12px",
		fontWeight: "500"
	}
}))
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	},
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "center"
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "center"
	},
	variant: "menu"
}

const NodesInventoryFilter = ({
	showFilter,
	setShowFilter,
	filterSiteOptions,
	setFilterSiteOptions,
	filterCustomerOptions,
	setFilterCustomerOptions,
	filterTypeNodeOptions,
	setFilterTypeNodeOptions,
	filterTypeOptions,
	setFilterTypeOptions,
	filterStatusOptions,
	setFilterStatusOptions,
	filterVendorOptions,
	setFilterVendorOptions,
	filterModelOptions,
	setFilterModelOptions,
	filterFirmwareOptions,
	setFilterFirmwareOptions,
	isSuper,
	loadingFilter
}) => {
	// 𓆝 𓆟 𓆞 𓆝  useState  𓆝 𓆟 𓆞 𓆝 \\
	const classes = useStyles()
	const [allSelected, setAllSelected] = useState(false)

	const [selected, setSelected] = useState([])
	const [selectedSite, setSelectedSite] = useState([])
	const [columns, setColumns] = useState(null)
	const [columnsSite, setColumnsSite] = useState(null)

	// ━━━━━━━━━━━━━  handlers  ━━━━━━━━━━━━━ \\
	const dynamicSelectedIconTypesNodes = index => {
		return filterTypeNodeOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconTypes = index => {
		return filterTypeOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconStatus = index => {
		return filterStatusOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconVendor = index => {
		return filterVendorOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconModel = index => {
		return filterModelOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}
	const dynamicSelectedIconFirmware = index => {
		return filterFirmwareOptions[index].selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}

	// ━━━━ Toggle button icons according to the state
	const toggleButtonTypesNodes = index => {
		setFilterTypeNodeOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonTypes = index => {
		setFilterTypeOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonStatus = index => {
		setFilterStatusOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonVendor = index => {
		setFilterVendorOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonModel = index => {
		setFilterModelOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}
	const toggleButtonFirmware = index => {
		setFilterFirmwareOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected

			return newState
		})
	}

	// ━━━━ Select all filterTypeOptions button
	const selectAllOptions = () => {
		setFilterTypeNodeOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterTypeOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterStatusOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterVendorOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterModelOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
		setFilterFirmwareOptions(prevState => {
			const newState = prevState.map(node => ({
				...node,
				// selected: !node.selected
				selected: !allSelected
			}))

			return newState
		})
	}

	//customer dropdown
	const isAllColumnsSelected = columns && columns.length > 0 && selected.length === columns.length
	const isAllColumnsSelectedSite =
		columnsSite && columnsSite.length > 0 && selectedSite.length === columnsSite.length

	const handleChangePeopleSelected = event => {
		const { value } = event.target

		// Check if "Select All" is selected
		const selectAllSelected = value.some(val => val.value === "all")

		// If "Select All" is selected, select all columns
		if (selectAllSelected) {
			if (selected.length === columns.length) {
				setSelected([])
				setFilterCustomerOptions(
					filterCustomerOptions.map(customer => {
						return {
							...customer,
							selected: false
						}
					})
				)

				//when clearing customer dropdown also clear site so it doens't keep the siste selection on empty customer select
				setSelectedSite([])
				setFilterSiteOptions(
					filterSiteOptions &&
						filterSiteOptions.length > 0 &&
						filterSiteOptions.map(site => {
							return {
								...site,
								selected: false
							}
						})
				)
			} else {
				setSelected(columns.map(option => option))
				setFilterCustomerOptions(
					filterCustomerOptions.map(customer => {
						return {
							...customer,
							selected: true
						}
					})
				)
			}
		} else {
			// Otherwise, update selected columns based on user's selection

			// Extract the node names from the value array
			const valueNodes = value.map(item => item.name)

			// Update filterCustomerOptions based on the selected values
			const updatedFilterCustomerOptions = filterCustomerOptions.map(customer => ({
				...customer,
				selected: valueNodes.includes(customer.node)
			}))

			setFilterCustomerOptions(updatedFilterCustomerOptions)

			setSelected(value)
		}
	}

	const handleChangePeopleSelectedSite = event => {
		const { value } = event.target

		// Check if "Select All" is selected
		const selectAllSelected = value.some(val => val.value === "all")

		// If "Select All" is selected, select all columns
		if (selectAllSelected) {
			if (selectedSite.length === columnsSite.length) {
				setSelectedSite([])
				setFilterSiteOptions(
					filterSiteOptions.map(site => {
						return {
							...site,
							selected: false
						}
					})
				)

				let columnsSite = []

				filterSiteOptions.forEach(option => {
					if (selected.some(sel => sel.name === option.group)) {
						columnsSite.push({ group: option.group, node: option.node, selected: false })
					}
				})

				setFilterSiteOptions(columnsSite)
			} else {
				setSelectedSite(columnsSite.map(option => option))
				setFilterSiteOptions(
					filterSiteOptions.map(site => {
						return {
							...site,
							selected: true
						}
					})
				)
			}
		} else {
			// Otherwise, update selectedSite columnsSite based on user's selection

			// Extract the node names from the value array
			const valueNodes = value.map(item => item.name)

			// Update filterSiteOptions based on the selectedSite values
			const updatedFilterSiteOptions = filterSiteOptions.map(site => ({
				...site,
				selected: valueNodes.includes(site.node)
			}))

			setFilterSiteOptions(updatedFilterSiteOptions)

			setSelectedSite(value)
		}
	}

	// useEffect(() => {
	// 	if (!filterSiteOptions) return
	// 	console.log("🚀 ~ filterSiteOptions: ", filterSiteOptions)
	// }, [filterSiteOptions])

	// ━━━━ useEffect
	useEffect(() => {
		if (!selected) return

		if (!filterSiteOptions) return

		let columnsSite = []

		filterSiteOptions.forEach(option => {
			if (selected.some(sel => sel.name === option.group)) {
				columnsSite.push({ group: option.group, name: option.node, value: option.selected })
			}
		})

		setColumnsSite(columnsSite)

		const foundSelected = columnsSite && columnsSite.filter(col => col.value)
		setSelectedSite(foundSelected || [])
	}, [selected])

	useEffect(() => {
		const containerTypes = document.getElementById("scroll-container-drawer-types-buttons")

		if (containerTypes) {
			const ps = new PerfectScrollbar(containerTypes, {
				wheelSpeed: 1,
				wheelPropagation: true,
				suppressScrollX: true
			})
		}

		if (!filterCustomerOptions) return

		const columns = filterCustomerOptions.map(column => {
			return {
				name: column.node,
				value: column.selected
			}
		})

		setColumns(columns)

		const foundSelected = columns.filter(col => col.value)

		setSelected(foundSelected || [])
	}, [])

	useEffect(() => {
		const allSelect =
			filterTypeOptions.every(filter => filter.selected) &&
			filterTypeNodeOptions.every(filter => filter.selected) &&
			filterStatusOptions.every(filter => filter.selected) &&
			filterVendorOptions.every(filter => filter.selected) &&
			filterModelOptions.every(filter => filter.selected) &&
			filterFirmwareOptions.every(filter => filter.selected)
		setAllSelected(allSelect)
	}, [
		filterTypeOptions,
		filterTypeNodeOptions,
		filterStatusOptions,
		filterVendorOptions,
		filterModelOptions,
		filterFirmwareOptions
	])

	useEffect(() => {
		if (filterCustomerOptions.filter(option => option.selected).length === 0) {
			setSelectedSite([])
			setFilterSiteOptions(
				filterSiteOptions.map(site => {
					return {
						...site,
						selected: false
					}
				})
			)
		}
	}, [filterCustomerOptions])

	const onHide = () => {
		setShowFilter(false)
	}

	return (
		<Modal
			show={showFilter}
			onHide={onHide}
			size="lg"
			data-testid="assignInventoryModal"
			backdrop="static"
		>
			<Modal.Header style={{ padding: "0.5rem 1rem 0 2rem", border: "none" }}>
				{/* ━━★  TITLE  ★━━ */}
				<div className="d-flex w-100 align-items-center justify-content-between">
					<div className="col-auto">
						<div className="row align-items-center">
							<GrFilter
								className="drawer_option_title_icon1"
								style={{
									filter:
										"invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
								}}
							/>
							<div style={{ marginLeft: "10px" }}>
								{/* <span className="custom-modal-title">Filter</span> */}
								<Bold>Filter</Bold>
							</div>
						</div>
					</div>
					<div className="col-auto">
						<IconButton onClick={onHide} style={{ left: "12px" }} data-testid="close-button">
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Modal.Header>
			<Modal.Body
				style={{
					padding: "1rem",
					overflow: "hidden"
				}}
			>
				<>
					{!filterCustomerOptions ||
						!filterSiteOptions ||
						!filterTypeNodeOptions ||
						!filterTypeOptions ||
						!filterStatusOptions ||
						!filterVendorOptions ||
						!filterModelOptions ||
						!filterFirmwareOptions ||
						(loadingFilter && (
							<ModalProgressBar
								date-testid="modal_progress_bar"
								style={{ width: "101%", marginBottom: "1.5rem" }}
							/>
						))}
					{!loadingFilter && (
						<div>
							<div className="col-12 d-flex px-0">
								<div className="col-6 pl-0 pr-1">
									{isSuper &&
										filterCustomerOptions &&
										filterCustomerOptions.length > 0 && (
											<p className="drawer_option_title_secondary">Customer</p>
										)}

									<div
										id="scroll-container-drawer-types-buttons"
										className="drawer_options_types_buttons"
									>
										{isSuper &&
											filterCustomerOptions &&
											filterCustomerOptions.length > 0 && (
												<div className="d-flex  mb-2">
													<FormControl className={classes.formControl}>
														<SelectMui
															classes={{
																filled: classes.filled,
																icon: classes.checkboxIcon
															}}
															disableUnderline
															className={classes.select}
															labelId="mutiple-select-label"
															multiple
															value={selected}
															onChange={handleChangePeopleSelected}
															renderValue={() => {
																if (isAllColumnsSelected) {
																	return "Select All"
																} else if (selected.length > 0) {
																	const selectedColumns = selected.map(
																		col => col.name
																	)
																	return selectedColumns.join(", ")
																} else {
																	return ""
																}
															}}
															MenuProps={MenuProps}
														>
															<MenuItem
																value={{
																	value: "all",
																	name: "Select All"
																}}
																classes={{
																	root: isAllColumnsSelected
																		? classes.selectedAll
																		: ""
																}}
															>
																<ListItemIcon>
																	<Checkbox
																		className={classes.checkboxIcon}
																		classes={{
																			indeterminate: classes.indeterminateColor
																		}}
																		checked={isAllColumnsSelected}
																		indeterminate={
																			columns &&
																			columns.length > 0 &&
																			columns.length < selected
																		}
																	/>
																</ListItemIcon>
																<ListItemText
																	classes={{ primary: classes.selectAllText }}
																	primary="Select All"
																/>
															</MenuItem>
															{columns &&
																columns.length > 0 &&
																columns.map((option, index) => {
																	return (
																		<MenuItem key={index} value={option}>
																			<ListItemIcon>
																				<Checkbox
																					className={classes.checkboxIcon}
																					checked={
																						selected.indexOf(option) > -1
																					}
																				/>
																			</ListItemIcon>
																			<ListItemText
																				classes={{
																					primary: classes.selectAllText
																				}}
																				primary={option?.name}
																			/>
																		</MenuItem>
																	)
																})}
														</SelectMui>
													</FormControl>
												</div>
											)}
									</div>
								</div>
								<div className="col-6 pl-1 pr-0">
									{isSuper && filterSiteOptions && filterSiteOptions.length > 0 && (
										<p
											className="drawer_option_title_secondary"
											style={{
												color: !selected || selected.length <= 0 ? "#e0e0e0" : ""
											}}
										>
											Site
										</p>
									)}

									<div
										id="scroll-container-drawer-types-buttons"
										className="drawer_options_types_buttons"
									>
										{isSuper && filterSiteOptions && filterSiteOptions.length > 0 && (
											<div className="d-flex  mb-2">
												<FormControl className={classes.formControl}>
													<SelectMui
														classes={{
															filled: classes.filled,
															icon: classes.checkboxIcon
														}}
														disableUnderline
														disabled={!selected || selected.length <= 0}
														className={classes.select}
														labelId="mutiple-select-label"
														multiple
														value={selectedSite}
														onChange={handleChangePeopleSelectedSite}
														renderValue={() => {
															if (isAllColumnsSelectedSite) {
																return "Select All"
															} else if (selectedSite.length > 0) {
																const selectedSiteColumns = selectedSite.map(
																	col => col.name
																)
																return selectedSiteColumns.join(", ")
															} else {
																return ""
															}
														}}
														MenuProps={MenuProps}
													>
														{columnsSite &&
															columnsSite.length > 0 &&
															columnsSite.map((option, index) => {
																return (
																	<MenuItem key={index} value={option}>
																		<ListItemIcon>
																			<Checkbox
																				className={classes.checkboxIcon}
																				checked={
																					selectedSite.indexOf(option) > -1
																				}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			classes={{
																				primary: classes.selectAllText
																			}}
																			primary={option?.name}
																		/>
																	</MenuItem>
																)
															})}
													</SelectMui>
												</FormControl>
											</div>
										)}
									</div>
								</div>
							</div>

							{filterTypeOptions && filterTypeOptions.length > 0 && (
								<p className="drawer_option_title_secondary">Node Types</p>
							)}

							<div
								id="scroll-container-drawer-types-buttons"
								className="drawer_options_types_buttons"
								style={{
									display: "grid",
									gridTemplateColumns: "repeat(4, 1fr)",
									gap: "10px"
								}} // Adjust gap as needed
							>
								{filterTypeOptions &&
									filterTypeOptions.length > 0 &&
									filterTypeOptions.map((type, index) => (
										<Button
											key={index}
											className="inventory_options_filter_button"
											endIcon={dynamicSelectedIconTypes(index)}
											style={{
												background: filterTypeOptions[index].selected
													? "#3077D3"
													: "#DBE1E5",
												color: filterTypeOptions[index].selected ? "#FFFFFF" : "#8C8CA2"
											}}
											variant="contained"
											onClick={() => {
												toggleButtonTypes(index)
											}}
										>
											<span>{type?.node}</span>
										</Button>
									))}
							</div>

							{filterTypeNodeOptions && filterTypeNodeOptions.length > 0 && (
								<p className="drawer_option_title_secondary">Types</p>
							)}

							<div
								id="scroll-container-drawer-types-buttons"
								className="drawer_options_types_buttons"
								style={{
									display: "grid",
									gridTemplateColumns: "repeat(4, 1fr)",
									gap: "10px"
								}} // Adjust gap as needed
							>
								{filterTypeNodeOptions &&
									filterTypeNodeOptions.length > 0 &&
									filterTypeNodeOptions.map((type, index) => (
										<Button
											key={index}
											className="inventory_options_filter_button"
											endIcon={dynamicSelectedIconTypesNodes(index)}
											style={{
												background: filterTypeNodeOptions[index].selected
													? "#3077D3"
													: "#DBE1E5",
												color: filterTypeNodeOptions[index].selected
													? "#FFFFFF"
													: "#8C8CA2"
											}}
											variant="contained"
											onClick={() => {
												toggleButtonTypesNodes(index)
											}}
										>
											<span>{type?.node}</span>
										</Button>
									))}
							</div>

							{filterStatusOptions && filterStatusOptions.length > 0 && (
								<p className="drawer_option_title_secondary">Status</p>
							)}
							<div
								id="scroll-container-drawer-types-buttons"
								className="drawer_options_types_buttons"
								style={{
									display: "grid",
									gridTemplateColumns: "repeat(4, 1fr)",
									gap: "10px"
								}} // Adjust gap as needed
							>
								{filterStatusOptions &&
									filterStatusOptions.length > 0 &&
									filterStatusOptions.map((type, index) => (
										<Button
											key={index}
											className="inventory_options_filter_button"
											endIcon={dynamicSelectedIconStatus(index)}
											style={{
												background: filterStatusOptions[index].selected
													? "#3077D3"
													: "#DBE1E5",
												color: filterStatusOptions[index].selected
													? "#FFFFFF"
													: "#8C8CA2"
											}}
											variant="contained"
											onClick={() => {
												toggleButtonStatus(index)
											}}
										>
											<span>{type?.node}</span>
										</Button>
									))}
							</div>

							{filterModelOptions && filterModelOptions.length > 0 && (
								<p className="drawer_option_title_secondary">Model</p>
							)}

							<div
								id="scroll-container-drawer-types-buttons"
								className="drawer_options_types_buttons"
								style={{
									display: "grid",
									gridTemplateColumns: "repeat(4, 1fr)",
									gap: "10px"
								}} // Adjust gap as needed
							>
								{filterModelOptions &&
									filterModelOptions.length > 0 &&
									filterModelOptions.map((type, index) => (
										<Button
											key={index}
											className="inventory_options_filter_button"
											endIcon={dynamicSelectedIconModel(index)}
											style={{
												background: filterModelOptions[index].selected
													? "#3077D3"
													: "#DBE1E5",
												color: filterModelOptions[index].selected
													? "#FFFFFF"
													: "#8C8CA2"
											}}
											variant="contained"
											onClick={() => {
												toggleButtonModel(index)
											}}
										>
											<span>{type?.node}</span>
										</Button>
									))}
							</div>

							{filterVendorOptions && filterVendorOptions.length > 0 && (
								<p className="drawer_option_title_secondary">Firmware Vendor</p>
							)}

							<div
								id="scroll-container-drawer-types-buttons"
								className="drawer_options_types_buttons"
								style={{
									display: "grid",
									gridTemplateColumns: "repeat(4, 1fr)",
									gap: "10px"
								}} // Adjust gap as needed
							>
								{filterVendorOptions &&
									filterVendorOptions.length > 0 &&
									filterVendorOptions.map((type, index) => (
										<Button
											key={index}
											className="inventory_options_filter_button"
											endIcon={dynamicSelectedIconVendor(index)}
											style={{
												background: filterVendorOptions[index].selected
													? "#3077D3"
													: "#DBE1E5",
												color: filterVendorOptions[index].selected
													? "#FFFFFF"
													: "#8C8CA2"
											}}
											variant="contained"
											onClick={() => {
												toggleButtonVendor(index)
											}}
										>
											<span
												style={{
													maxWidth: "125px",
													overflow: "hidden",
													whiteSpace: "nowrap",
													textOverflow: "ellipsis"
												}}
											>
												{type?.node}
											</span>
										</Button>
									))}
							</div>

							{filterFirmwareOptions && filterFirmwareOptions.length > 0 && (
								<p className="drawer_option_title_secondary">Firmware Version</p>
							)}

							<div
								id="scroll-container-drawer-types-buttons"
								className="drawer_options_types_buttons"
								style={{
									display: "grid",
									gridTemplateColumns: "repeat(4, 1fr)",
									gap: "10px"
								}} // Adjust gap as needed
							>
								{filterFirmwareOptions &&
									filterFirmwareOptions.length > 0 &&
									filterFirmwareOptions.map((type, index) => (
										<Button
											key={index}
											className="inventory_options_filter_button"
											endIcon={dynamicSelectedIconFirmware(index)}
											style={{
												background: filterFirmwareOptions[index].selected
													? "#3077D3"
													: "#DBE1E5",
												color: filterFirmwareOptions[index].selected
													? "#FFFFFF"
													: "#8C8CA2"
											}}
											variant="contained"
											onClick={() => {
												toggleButtonFirmware(index)
											}}
										>
											<span>{type?.node}</span>
										</Button>
									))}
							</div>

							<div className="d-flex align-items-center justify-content-end mt-5">
								<Button
									className="inventory_options_filter_button_select_all_filter_one"
									style={{
										backgroundColor: "#FFFFFF",
										color: "#3077D3",
										border: "1px solid #3077D3",
										width: !allSelected ? "120px" : "130px",
										height: "31px"
									}}
									variant="contained"
									text={!allSelected ? "Select All" : "Unselect All"}
									onClick={() => {
										selectAllOptions()
									}}
								/>
							</div>
						</div>
					)}
				</>
			</Modal.Body>
		</Modal>
	)
}

export default NodesInventoryFilter
